import * as React from "react";
import SuggestionList from "./SuggestionList";
import SearchList from "../../../home/components/SearchList";
import ProductVo from "../../vo/ProductVo";
import SearchSuggestionVo from "../../vo/SearchSuggestionVo";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  searchResult: ProductVo[];
  searchCount: number;
  suggestions: SearchSuggestionVo[];
  showProductSearchResult: boolean;
  showSuggestionList: boolean;
  onClickProductLink: (product: ProductVo) => void;
}

class SearchLayer extends React.PureComponent<Props> {
  renderProductSearchResult() {
    if (this.props.showProductSearchResult) {
      return (
        <div className="row">
          <h2 className="title">
            {`${dic("PRODUKTE")}`}{" "}
            <span className="title__counter">({this.props.searchCount})</span>
          </h2>
          <div className="products">
            <SearchList
              data={this.props.searchResult}
              onClickProductLink={this.props.onClickProductLink}
            />
          </div>
        </div>
      );
    }
    return;
  }

  renderSuggestionList() {
    if (this.props.suggestions.length > 0) {
      return (
        <div className="row">
          <div className="title">{`${dic("VORSCHLAEGE")}`}</div>
          <div className="suggestion-tags">
            <SuggestionList suggestions={this.props.suggestions} />
          </div>
        </div>
      );
    }
    return;
  }

  render() {
    return (
      <div className={"search-layer"} aria-hidden="true">
        {this.renderSuggestionList()}
        {this.renderProductSearchResult()}
      </div>
    );
  }
}

export default SearchLayer;
