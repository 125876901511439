import { LayoutActionsTypes } from "./ActionTypes";
import AlgoliaServices from "../../common/services/AlgoliaServices";
import { SearchActions } from "../../search/actions/searchActions";
import ElstrBaseApp from "elstr-jslib-4/dist/ElstrBaseApp";
import { ConsentValues } from "../../common/constants/Constants";

export const LayoutActions = {
  locationChangeAction: function(location) {
    SearchActions.parseURL(location);
    return null;
  },

  setAllFacets: function(facets) {
    return { type: LayoutActionsTypes.LAYOUT_LOAD_FACETS, facets };
  },

  loadAllFacets: function(lang) {
    return async (dispatch, getState) => {
      const facets: any = await AlgoliaServices.getAllFacets(lang);
      dispatch(LayoutActions.setAllFacets(facets));
    };
  },

  showHomeHeader: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_HOME_HEADER, open };
  },

  showFilterPanel: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_FILTER_SHOW, open };
  },

  openFilter: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_FILTER_OPEN, open };
  },

  openSearch: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_SEARCH_OPEN, open };
  },

  openWhishlist: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_WISHLIST_OPEN, open };
  },

  openCart: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_CART_OPEN, open };
  },

  closeCartExpicitly: function(open) {
    return { type: LayoutActionsTypes.LAYOUT_CART_CLOSE_EXPICITLY, open };
  },

  openMenu: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_MENU_OPEN, open };
  },

  openUser: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_USER_OPEN, open };
  },

  userLogin: function(username: string, password: string) {
    return { type: LayoutActionsTypes.LAYOUT_USER_LOGIN, payload: { username, password } };
  },

  openLang: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_LANG_OPEN, open };
  },

  openCurrency: function(open: boolean) {
    return { type: LayoutActionsTypes.LAYOUT_CURRENCY_OPEN, open };
  },

  acceptCookies_thunk: function(accept: ConsentValues) {
    return { type: LayoutActionsTypes.LAYOUT_COOKIES_ACCEPT, accept };
  },

  acceptCookies: function(accept: ConsentValues) {
    return (dispatch, getState) => {
      dispatch(LayoutActions.acceptCookies_thunk(accept));
      ElstrBaseApp.ELS.replaceValue("acceptCookies", getState().layout.cookiesAccepted);
    };
  },

  onCartCloseExpicitly: function() {
    return async (dispatch, getState) => {
      dispatch(LayoutActions.openCart(false));
      dispatch(LayoutActions.closeCartExpicitly(true));
    };
  },

  openCartIfNotClosedExpicitly: function() {
    return async (dispatch, getState) => {
      if (!getState().layout.cartExplicitlyClosed) {
        dispatch(LayoutActions.openCart(true));
      }
    };
  },

  setScrollState: function(scrollState: number) {
    return { type: LayoutActionsTypes.LAYOUT_SCROLL_STATE, scrollState };
  },
};
