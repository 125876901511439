import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { getUnitSymbol } from "../../common/utils/unitUtils";

declare var COMPONA_STATIC_CACHE;

/**
 *
 * @param {ProductVo} data
 * @returns {string}
 */
export function getText(value, dataAttributeKey: string): string {
  const attribute = window.COMPONA_STATIC_CACHE.attributes[dataAttributeKey];

  switch (attribute.dataType) {
    case "boolean":
      if (value === "1" || value === 1 || value === true) {
        return dic("JA");
      }
      return dic("NEIN");

    case "string":
    case "integer":
    case "float":
      /*
      if (attribute.dataType === "float") {
        value = parseFloat(value).toFixed(4);
      }
      */
      if (attribute.unitOfMeasureId) {
        return value + "\u00a0" + getUnitSymbol(attribute.unitOfMeasureId, activeLang());
      }
      return value;

    default:
      return value;
  }
}

export function getFullText(productData, attribute) {
  if (
    attribute.isRange &&
    productData[attribute.dataAttributeKey] &&
    productData[attribute.dataAttributeKey].hasOwnProperty('min') &&
    productData[attribute.dataAttributeKey].hasOwnProperty('max')
  ) {
    return `${getText(
      productData[attribute.dataAttributeKey].min,
      attribute.dataAttributeKey,
    )} ‒ ${getText(productData[attribute.dataAttributeKey].max, attribute.dataAttributeKey)}`;
  }
  if (attribute.isMultivalued && Array.isArray(productData[attribute.dataAttributeKey])) {
    const textValues = productData[attribute.dataAttributeKey].map((value, index) => {
      return getText(value, attribute.dataAttributeKey);
    });
    return textValues.join("; ");
  }
  if (productData.hasOwnProperty(attribute.dataAttributeKey)) {
    return getText(productData[attribute.dataAttributeKey], attribute.dataAttributeKey);
  }
  return "";
}
