import * as React from "react";
import ProductTable, { ProductTableColumnVo } from "../../common/components/table/ProductTable";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ProductVo from "../../common/vo/ProductVo";
import { UserState } from "../../State";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { SearchActions } from "../../search/actions/searchActions";

interface Props {
  children?: any;
  columns: any[];
  data: any[];
  sorting: { key?: string; direction?: string };
  user: UserState;
  onClickProductLink: (product: ProductVo) => void;
  addProduct: (listType, objectID, pos, quantity, comment) => void;
  onClickSortLink: (column: ProductTableColumnVo, direction) => void;
}

class ProductList extends React.PureComponent<Props> {
  private readonly resultListInnerwrap: any;

  constructor(props) {
    super(props);
    this.resultListInnerwrap = React.createRef();
    this.onClickScroll = this.onClickScroll.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
  }

  onSearchClear() {
    ElstrCore.store.dispatch(SearchActions.resetSearch());
  }

  onClickScroll(direction) {
    const scrollLeft = this.resultListInnerwrap.current.scrollLeft;
    const pxAmount = 200;
    if (direction === "right") {
      this.resultListInnerwrap.current.scrollLeft = scrollLeft + pxAmount;
    }
    if (direction === "left") {
      this.resultListInnerwrap.current.scrollLeft = scrollLeft - pxAmount;
    }
  }

  render() {
    if (this.props.data.length > 0) {
      return (
        <>
          <div className="result-list-outerwrap">
            <div className="result-list-innerwrap" ref={this.resultListInnerwrap}>
              <div className="result-list">
                <h2 className="visuallyhidden">{dic("ERGEBNISLISTE")}</h2>

                <ProductTable
                  columns={this.props.columns}
                  products={this.props.data}
                  sorting={this.props.sorting}
                  onClickProductLink={this.props.onClickProductLink}
                  user={this.props.user}
                  addProduct={this.props.addProduct}
                  onClickScroll={this.onClickScroll}
                  onClickSortLink={this.props.onClickSortLink}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="catalog__subtitle">{dic("KEINE ERGEBNISSE")}</div>
        <p className="text-condenced">
          {dic("IHRE SUCHE ERGAB KEINE TREFFER")}
          <br />
          {dic("BITTE PASSEN SIE DIE EINGABEN AN")}
        </p>

        <button className="btn" onClick={this.onSearchClear}>
          {dic("SUCHE ZURUECKSETZEN")}
        </button>
      </>
    );
  }
}

export default ProductList;
