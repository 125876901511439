import { CheckoutState, AdrVo, emptyAddress, UserState, CartState } from "../../State";
import { CheckoutActionTypes } from "../actions/ActionTypes";
import OrderVo from "../../common/vo/OrderVo";
// import { config } from "elstr-jslib-4/dist/ElstrConfig";
// import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

const checkoutReducer = (state: CheckoutState, action) => {
  if (!state) return null;

  /*
   * Gast-Bestellung:
   *
   * kundeId [DST.DNR]: fix Dummydebitor
   * adressId [ADR.INR]: 0
   * lieferadresse [LIO_ADRINR]: 0 (ohne Abacus-Verbindung)
   * rechnungsadresse [FAO_ADRINR]: 0 (ohne Abacus-Verbindung)
   */

  const formLioAdr = { ...state.formLioAdr };
  let editLioAdr = state.editLioAdr;
  const formFaoAdr = { ...state.formFaoAdr };
  let editFaoAdr = state.editFaoAdr;
  const formValidationErrors = { ...state.formValidationErrors };

  const checkoutState = state;

  let shippingDestination;
  let isAssignedErp;
  let asGuest = false;
  let lioLandNotCh = false;
  let faoLandNotCh = false;

  switch (action.type) {
    case CheckoutActionTypes.SET_CHECKOUT_DATA:
      // console.log(action.payload);
      return {
        ...state,
        ...action.payload.data,
      };

    case CheckoutActionTypes.SET_CHECKOUT_ORDER_DATAVALUE:
      // console.log(action.payload);
      const checkoutOrder = { ...state.order };
      checkoutOrder[action.payload.name] = action.payload.value;

      return {
        ...state,
        order: checkoutOrder,
      };

    case CheckoutActionTypes.RESET_CHECKOUT_ORDER:
      return {
        ...state,
        isInit: false,
        isLoading: false,
        asGuest: false,
        order: null,
        formLioAdr: {
          ...emptyAddress,
          type: "delivery",
        },
        editLioAdr: false,
        formFaoAdr: {
          ...emptyAddress,
          type: "invoice",
        },
        editFaoAdr: false,
        dkoAblnrOptions: null,
        formValidationErrors: {},
        lioLandNotCh: false,
        faoLandNotCh: false,
      };

    case CheckoutActionTypes.INIT_CHECKOUT_ORDER:
      // console.log(action.payload);

      const userState: UserState = action.payload.user;
      const cartState: CartState = action.payload.cart;

      editLioAdr = false;
      editFaoAdr = false;

      const order: OrderVo = {
        listId: cartState._id || "",
        dstDnr: "0",
        adrInr: "0",
        eoriNumber: null,
        comment: "",
        shippingDestination: "",
        shippingComment: "",
        dkoIsocode: "",
        dkoBestellBez: "",
        shippingInParts: true,
        agbConfirmed: false,
        accountNumber: null,
      };

      // dstDnr
      if (userState.dstDnr) {
        order.dstDnr = userState.dstDnr;
      }

      // adrInr
      if (Array.isArray(userState.adr)) {
        const accountAdr: AdrVo[] = userState.adr.filter(adr => adr.type === "account");
        if (Array.isArray(accountAdr) && accountAdr.length > 0) {
          if (accountAdr[0].inr) {
            // user is connected to erp
            order.adrInr = accountAdr[0].inr;
            formLioAdr.email = accountAdr[0].email;
            formFaoAdr.email = accountAdr[0].email;
            formLioAdr.name = accountAdr[0].name;
            formFaoAdr.name = accountAdr[0].name;
            formLioAdr.vorname = accountAdr[0].vorname;
            formFaoAdr.vorname = accountAdr[0].vorname;
            formLioAdr.anrNr = accountAdr[0].anrNr;
            formFaoAdr.anrNr = accountAdr[0].anrNr;
          }
        }
        const orgAdr: AdrVo[] = userState.adr.filter(adr => adr.type === "org");
        if (Array.isArray(orgAdr) && orgAdr.length > 0) {
          if (orgAdr[0].inr) {
            order.dkoLioAdrinr = orgAdr[0].inr;
            shippingDestination = orgAdr[0].land;
            if (shippingDestination !== null) {
              order.shippingDestination = shippingDestination;
            }
            order.dkoFaoAdrinr = orgAdr[0].inr;
          }
        } else {
          // Default for guests and users not connected to erp

          order.dkoLioAdrinr = "0";
          order.dkoFaoAdrinr = "-1";

          if (Array.isArray(accountAdr) && accountAdr.length > 0) {
            if (accountAdr[0].inr === null) {
              // user is not connected to erp
              formLioAdr.email = accountAdr[0].email;
              formLioAdr.name = accountAdr[0].name;
              formLioAdr.vorname = accountAdr[0].vorname;
              formLioAdr.anrNr = accountAdr[0].anrNr;
              formLioAdr.zeile1 = accountAdr[0].zeile1;
              formLioAdr.zeile2 = accountAdr[0].zeile2;
              formLioAdr.zeile3 = accountAdr[0].zeile3;
              formLioAdr.land = accountAdr[0].land;
              formLioAdr.plz = accountAdr[0].plz;
              formLioAdr.ort = accountAdr[0].ort;
              formLioAdr.tel = accountAdr[0].tel;

              shippingDestination = accountAdr[0].land;
              if (shippingDestination !== null) {
                order.shippingDestination = shippingDestination;
              }

              editLioAdr = false;
            }
          } else {
            // user is guest
            editLioAdr = true;
          }
        }
      }

      // akoAblnr
      const dkoAblnrOptions = ["410", "420"];
      if (userState.dstZlgkond) {
        if (
          userState.dstZlgkond === "1" ||
          userState.dstZlgkond === "2" ||
          userState.dstZlgkond === "3" ||
          userState.dstZlgkond === "4" ||
          userState.dstZlgkond === "5" ||
          userState.dstZlgkond === "6" ||
          userState.dstZlgkond === "7" ||
          userState.dstZlgkond === "8" ||
          userState.dstZlgkond === "9" ||
          userState.dstZlgkond === "10" ||
          userState.dstZlgkond === "11" ||
          userState.dstZlgkond === "12" ||
          userState.dstZlgkond === "13" ||
          userState.dstZlgkond === "14" ||
          userState.dstZlgkond === "15" ||
          userState.dstZlgkond === "18" ||
          userState.dstZlgkond === "19" ||
          userState.dstZlgkond === "21" ||
          userState.dstZlgkond === "22"
        ) {
          dkoAblnrOptions.unshift("400");
        }
      }
      if (checkoutState.order && checkoutState.order.dkoAblnr) {
        order.dkoAblnr = checkoutState.order.dkoAblnr;
      } else {
        order.dkoAblnr = dkoAblnrOptions[0];
      }

      // eoriNumber
      if (checkoutState.order && checkoutState.order.eoriNumber) {
        order.eoriNumber = checkoutState.order.eoriNumber;
      } else {
        if (userState.adrEorinr && userState.adrEorinr.length > 0) {
          order.eoriNumber = userState.adrEorinr;
        }
      }

      // comment
      if (checkoutState.order && checkoutState.order.comment) {
        order.comment = checkoutState.order.comment;
      }

      // shippingComment
      if (checkoutState.order && checkoutState.order.shippingComment) {
        order.shippingComment = checkoutState.order.shippingComment;
      }

      // dkoBestellBez
      if (checkoutState.order && checkoutState.order.dkoBestellBez) {
        order.dkoBestellBez = checkoutState.order.dkoBestellBez;
      }

      // shippingInParts
      if (checkoutState.order && checkoutState.order.shippingInParts) {
        order.shippingInParts = checkoutState.order.shippingInParts;
      }

      // agbConfirmed
      if (checkoutState.order && checkoutState.order.agbConfirmed) {
        order.agbConfirmed = checkoutState.order.agbConfirmed;
      }

      // #5135: Bestellungen aus dem Ausland sollten für nicht mit dem Abacus verknüpfte User nicht mehr möglich sein
      /*
      isGuestAndLioLandNotCh = false;
      if (userState.isAssignedErp !== true) {
        if (order.shippingDestination !== "CH" && order.shippingDestination !== "LI") {
          isGuestAndLioLandNotCh = true;
        }
      }
      */

      if (userState.isAssignedErp !== true) {
        asGuest = true;
      }

      if (order.shippingDestination !== "CH" && order.shippingDestination !== "LI") {
        lioLandNotCh = true;
      }

      // TODO: dkoLiefartCd

      return {
        ...state,
        isInit: true,
        order,
        formLioAdr,
        editLioAdr,
        formFaoAdr,
        editFaoAdr,
        dkoAblnrOptions,
        formValidationErrors: {},
        asGuest,
        lioLandNotCh,
        faoLandNotCh,
      };

    case CheckoutActionTypes.SET_CHECKOUT_FORMLIOADR_DATAVALUE:
      // console.log(action.payload);
      formLioAdr[action.payload.name] = action.payload.value;
      return {
        ...state,
        formLioAdr,
      };

    case CheckoutActionTypes.SET_CHECKOUT_FORMFAOADR_DATAVALUE:
      // console.log(action.payload);
      formFaoAdr[action.payload.name] = action.payload.value;
      return {
        ...state,
        formFaoAdr,
      };

    case CheckoutActionTypes.SET_CHECKOUT_ORDER_SHIPPINGDESTINATION:
      shippingDestination = action.payload.shippingDestination;
      isAssignedErp = action.payload.isAssignedErp;

      // #5135: Bestellungen aus dem Ausland sollten für nicht mit dem Abacus verknüpfte User nicht mehr möglich sein
      /*
      isGuestAndLioLandNotCh = false;
      if (isAssignedErp !== true) {
        if (shippingDestination !== "CH" && shippingDestination !== "LI") {
          isGuestAndLioLandNotCh = true;
        }
      }
      */

      if (isAssignedErp !== true) {
        asGuest = true;
      }

      if (shippingDestination !== "CH" && shippingDestination !== "LI") {
        lioLandNotCh = true;
      }

      // TODO: check if dkoLiefartCd is still possible

      return {
        ...state,
        asGuest,
        lioLandNotCh,
        order: { ...checkoutState.order, shippingDestination },
      };

    case CheckoutActionTypes.SET_CHECKOUT_FORMVALIDATION_ERROR:
      formValidationErrors[action.payload.key] = action.payload.translationKey;

      return {
        ...state,
        formValidationErrors,
      };

    case CheckoutActionTypes.RESET_CHECKOUT_FORMVALIDATION_ERROR:
      if (formValidationErrors[action.payload.key]) {
        delete formValidationErrors[action.payload.key];
      }

      return {
        ...state,
        formValidationErrors,
      };
  }

  return state;
};

export default checkoutReducer;
