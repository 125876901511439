import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { FoldingBoxState } from "../../State";
import { getFullText } from "../../common/utils/textAttributeDataType";
import {
  getAttributeDescription,
  getAttributeLongDescription,
} from "../../common/utils/attributeUtils";
import ToolTip from "../../common/components/button/ToolTip";
import { Link } from "react-router-dom";
import { createReducedProductUrl } from "../../common/utils/createUrls";

interface Props {
  data: ProductVo;
  foldingBox: FoldingBoxState;
  onClickFoldingBoxTitle: (attributeSectionId: string) => void;
}

class ProductSection extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    this.props.onClickFoldingBoxTitle(this.props.foldingBox.attributeSectionId);
    return null;
  }

  renderFoldingBoxAttributes(foldingBoxAttributes, productData) {
    return foldingBoxAttributes.map((attribute, index) => {
      if (attribute.isVisible) {
        let value = <>{getFullText(productData, attribute)}</>;
        if (attribute.dataAttributeKey === "ersatzartikel") {
          value = <Link to={createReducedProductUrl(productData.ersatzartikel, activeLang())} className="product__link">{getFullText(productData, attribute)}</Link>;
        }
        return (
          <dl
            className="info-list"
            key={`${attribute.dataAttributeKey}-${index}`}
            aria-describedby={attribute.dataAttributeKey}
          >
            <dt className="info-list__title">
              {getAttributeDescription(attribute.dataAttributeKey, activeLang())}
              <ToolTip
                id={attribute.dataAttributeKey}
                description={getAttributeLongDescription(attribute.dataAttributeKey, activeLang())}
                placement="top"
              />
            </dt>
            <dd className="info-list__cont value">{value}</dd>
          </dl>
        );
      }
      else {
        return undefined;
      }
    });
  }

  render() {
    let cls = "folding-box";
    if (this.props.foldingBox.isExpanded) {
      cls += " opened";
    }
    return (
      <div className={cls}>
        <h3 className="folding-box__titlebar" onClick={this.onClickHandler}>
          {this.props.foldingBox.name[activeLang()]}
          <span className="folding-box__arrow" aria-hidden="true" />
        </h3>
        <div className="folding-box__content" aria-expanded="false" role="region">
          {this.renderFoldingBoxAttributes(this.props.foldingBox.attributes, this.props.data)}
        </div>
      </div>
    );
  }
}

export default ProductSection;
