import * as React from "react";
import { Link } from "react-router-dom";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {}

class Footer extends React.PureComponent<Props> {
  render() {
    return (
      <footer role="contentinfo" className="footer">
        <div className="footer__aside--desktop">
          <div className="footer__logo">
            <Link to={`/home/${activeLang()}/`}>
              <img
                className="logo__img"
                src={require("./../../common/assets/img/layout/compona-logo-white.svg")}
                alt="Compona Logo"
              />
            </Link>
          </div>
          <div className="footer__iso footer__block">
            <a
              className="footer-iso__link"
              href={dic("CMSURL_ISO9001_PDF")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="footer-iso__img"
                src={require("./../../common/assets/img/layout/iso-certification.svg")}
                alt="sqs-iso logo"
              />
              <p className="footer-iso__text footer-block__text">
                {dic("ZERTIFIZIERTES MANAGEMENTSYSTEM")}
              </p>
            </a>
          </div>
        </div>
        <div className="footer__main--desktop">
          <div className="footer__block">
            <ul className="footer__prinavs">
              <li className="footer-prinav__item">
                <Link to={`/catalog/${activeLang()}/`} className="footer-prinav__link">
                  {dic("KATALOG")}
                </Link>
              </li>
              <li className="footer-prinav__item">
                <Link
                  className="footer-prinav__link"
                  to={`/cms/${activeLang()}/${dic("CMSURL_SERVICES")}`}
                >
                  {dic("SERVICES")}
                </Link>
              </li>
              <li className="footer-prinav__item">
                <Link
                  className="footer-prinav__link"
                  to={`/cms/${activeLang()}/${dic("CMSURL_AKTUELLES")}`}
                >
                  {dic("AKTUELLES")}
                </Link>
              </li>
              <li className="footer-prinav__item">
                <Link
                  className="footer-prinav__link"
                  to={`/cms/${activeLang()}/${dic("CMSURL_ABOUTUS")}`}
                >
                  {dic("ABOUT US")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__block">
            <ul className="footer__links">
              <li className="footer-link__item">
                <Link className="footer__link" to={`/cms/${activeLang()}/${dic("CMSURL_KONTAKT")}`}>
                  {dic("KONTAKT_NAV")}
                </Link>
              </li>
              <li className="footer-link__item">
                <Link className="footer__link" to={`/cms/${activeLang()}/${dic("CMSURL_FAQ")}`}>
                  {dic("FAQ")}
                </Link>
              </li>
              <li className="footer-link__item">
                <a className="footer__link" target="_blank" rel="noopener noreferrer" href={dic("CMSURL_NEWSLETTER")}>
                  {dic("NEWSLETTER")}
                </a>
              </li>
              <li className="footer-link__item">
                <Link className="footer__link" to={`/cms/${activeLang()}/${dic("CMSURL_JOBS")}`}>
                  {dic("JOBS")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__block">
            <ul className="footer__links">
              <li className="footer-link__item">
                <Link
                  className="footer__link"
                  to={`/cms/${activeLang()}/${dic("CMSURL_REGISTRIERUNG_ANMELDUNG")}`}
                >
                  {dic("REGISTRIERUNG UND ANMELDUNG")}
                </Link>
              </li>
              <li className="footer-link__item">
                <Link
                  className="footer__link"
                  to={`/cms/${activeLang()}/${dic("CMSURL_BESTELLUNG_ANFRAGE_MERKLISTE")}`}
                >
                  {dic("BESTELLUNG ANFRAGE UND MERKLISTE")}
                </Link>
              </li>
              <li className="footer-link__item">
                <Link
                  className="footer__link"
                  to={`/cms/${activeLang()}/${dic("CMSURL_VERSAND_BEZAHLUNG")}`}
                >
                  {dic("VERSAND UND BEZAHLUNG")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__block footer__block--infos">
            <div className="footer__infos">
              <dl className="footer__info">
                <dt className="footer-block__title">{dic("ADRESSE")}</dt>
                <address className="footer-block__text">
                  Compona AG
                  <br />
                  Undermülistrasse 16
                  <br />
                  8320 Fehraltorf
                  <br />
                  Schweiz
                  <br />
                </address>
              </dl>
              <dl className="footer__info">
                <dt className="footer-block__title">{dic("OEFFUNUNGSZEITEN")}</dt>
                <dd className="footer-block__text">
                  {dic("MONTAG")} - {dic("DONNERSTAG")} 07.45 -12.00 {dic("UHR")} {dic("UND")} 13.00
                  -17.00 {dic("UHR")}
                  <br />
                  {dic("FREITAG")} 07.45 -12.00 {dic("UHR")} {dic("UND")} 13.00 -16.00 {dic("UHR")}
                </dd>
              </dl>
            </div>
            <div className="footer__infos">
              <dl className="footer__info">
                <dt className="footer-block__title">{dic("TELEFON")}</dt>
                <dd className="footer-block__text">
                  <dl className="footer-info__list">
                    <dt className="footer-info__title-col">{dic("SCHWEIZ")}</dt>
                    <dd className="footer-info__cont-col tel">
                      <a className="footer__link link--tel" href="tel:0848840100">
                        0848 840 100 ({dic("ORTSTARIF")})
                      </a>
                    </dd>
                    <dt className="footer-info__title-col"/>
                    <dd className="footer-info__cont-col">
                      <a className="footer__link link--tel" href="tel:0041449562121">
                        +41 44 956 21 21
                      </a>
                    </dd>
                    <dt className="footer-info__title-col">{dic("INTERNATIONAL")}</dt>
                    <dd className="footer-info__cont-col">
                      <a className="footer__link link--tel" href="tel:0041449562121">
                        +41 44 956 21 21
                      </a>
                    </dd>
                  </dl>
                </dd>
              </dl>
              <dl className="footer__info">
                <dt className="footer-block__title">{dic("EMAIL")}</dt>
                <dd className="footer-block__text">
                  <a
                    className="footer__link link--email link--active"
                    href="mailto:info@compona.com"
                  >
                    info@compona.com
                  </a>
                </dd>
              </dl>
            </div>
          </div>

          <div className="footer__block footer__block--dark">
            <div className="footer-block__title" />
          </div>
          <div className="footer__block footer__block--dark">
            <div className="footer-block__title">{dic("LEHRBETRIEB")}</div>
            <img
              className="footer__partner-logo"
              src={require("./../../common/assets/img/layout/logo-lehrbetrieb.png")}
              alt="Lehrbetrieb logo"
            />
          </div>
          <div className="footer__block footer__block--socials footer__block--dark">
            <ul className="social-links">
              <li className="social-link__item">
                <a
                  className="social-link__link"
                  href="https://www.facebook.com/Compona-AG-176376555735516/"
                >
                  <span className="sicon sicon__facebook social-link__icon" />
                </a>
              </li>
              <li className="social-link__item">
                <a className="social-link__link" href="https://twitter.com/ComponaAG">
                  <span className="sicon sicon__twitter social-link__icon" />
                </a>
              </li>
              <li className="social-link__item">
                <a className="social-link__link" href="https://www.youtube.com/user/ComponaChannel">
                  <span className="sicon sicon__youtube social-link__icon" />
                </a>
              </li>
              <li className="social-link__item">
                <a className="social-link__link" href="https://www.linkedin.com/company/8744345/">
                  <span className="sicon sicon__linkedin social-link__icon" />
                </a>
              </li>
              <li className="social-link__item">
                <a className="social-link__link" href="https://www.xing.com/companies/componaag">
                  <span className="sicon sicon__xing social-link__icon" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__block footer__block--e-payment footer__block--dark">
            <div className="footer-block__title">{dic("SICHER BEZAHLEN MIT")}</div>
            <div className="footer__e-payment">
              <ul className="e-payment__left">
                <li className="e-payment__item">
                  <img
                    src={require("./../../common/assets/img/layout/footer__logo-mastercard.svg")}
                    alt="Mastercard logo"
                  />
                </li>
                <li className="e-payment__item">
                  <img
                    src={require("./../../common/assets/img/layout/footer__logo-visa.svg")}
                    alt="Visa logo"
                  />
                </li>
                <li className="e-payment__item">
                  <img
                    src={require("./../../common/assets/img/layout/footer__logo-twint.svg")}
                    alt="Twint logo"
                  />
                </li>
                <li className="e-payment__item e-payment__item--method-text">
                  <span className="e-payment__method-text">{dic("ABLNR 400 INFO")}</span>
                  <span className="e-payment__method-text">{dic("ABLNR 420 INFO")}</span>
                </li>
              </ul>
              <ul className="e-payment__right">
                <li className="e-payment__item">
                  <a
                    className="e-payment__link e-payment__link--datatrans"
                    href="https://www.datatrans.ch/"
                    target={`_blank`}
                  >
                    <img
                      src={require("./../../common/assets/img/layout/footer__logo-datatrans.png")}
                      alt="Datatrans logo"
                    />{" "}
                  </a>
                </li>
                <li className="e-payment__item">
                  <a
                    className="e-payment__link e-payment__link--adsigo"
                    href="https://www.adsigo.com/"
                    target={`_blank`}
                  >
                    <img
                      src={require("./../../common/assets/img/layout/footer__logo-adsigo.png")}
                      alt="Adsigo logo"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__meta-nav">
          <p className="footer__copyright footer-meta-nav__item">
            © {new Date().getFullYear()} Compona
          </p>
          <ul className="footer__links footer-meta-nav__links">
            <li className="footer-meta-nav__item">
              <Link className="footer__link" to={`/cms/${activeLang()}/${dic("CMSURL_IMPRESSUM")}`}>
                {dic("IMPRESSUM")}
              </Link>
            </li>
            <li className="footer-meta-nav__item">
              <Link className="footer__link" to={`/cms/${activeLang()}/${dic("CMSURL_AGB")}`}>
                {dic("AGB")}
              </Link>
            </li>
            <li className="footer-meta-nav__item">
              <Link
                className="footer__link"
                to={`/cms/${activeLang()}/${dic("CMSURL_DATENSCHUTZ")}`}
              >
                {dic("DATENSCHUTZ")}
              </Link>
            </li>
            <li className="footer-meta-nav__item">
              <Link className="footer__link" to={`/cms/${activeLang()}/${dic("CMSURL_LINKLISTE")}`}>
                {dic("AUSGEWAEHLTE UEBERSICHTSSEITEN")}
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
