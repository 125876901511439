import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CartState, CheckoutState, OrderState, State, UserState } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { compose } from "redux";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { CheckoutActions } from "../actions/checkoutActions";
import CheckoutSteps from "../components/CheckoutSteps";
import CheckoutAddresses from "../components/CheckoutAddresses";
import CheckoutOptions from "../components/CheckoutOptions";
import CheckoutOverview from "../components/CheckoutOverview";
import CheckoutSubmit from "../components/CheckoutSubmit";
import CheckoutOrdered from "../components/CheckoutOrdered";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ShippingOptionVo } from "../../common/vo/ShippingOptionVo";
import { Link } from "react-router-dom";
import { priceGetVatRate } from "../../common/utils/priceCalculations";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { UserActions } from "../../user/actions/userActions";
// import { CheckoutActions } from "../actions/checkoutActions";

declare var COMPONA_STATIC_CACHE;

interface CheckoutStateToProps {}

interface CheckoutDispatchToProps {
  onMount: () => void;
  proceedCheckoutAsGuest: () => void;
}

interface Props extends CheckoutStateToProps, CheckoutDispatchToProps, RouteComponentProps<any> {
  user: UserState;
  cart: CartState;
  checkout: CheckoutState;
  order: OrderState;
}

class Checkout extends React.Component<Props> {
  private readonly email: any;
  private readonly password: any;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.email = React.createRef();
    this.password = React.createRef();

    this.onLoginClickHandler = this.onLoginClickHandler.bind(this);
    this.onForgotPasswordClickHandler = this.onForgotPasswordClickHandler.bind(this);
  }

  onLoginClickHandler(e) {
    e.preventDefault();
    const email = this.email.current.value;
    const password = this.password.current.value;

    ElstrCore.store.dispatch(UserActions.login(email, password));
  }
  onForgotPasswordClickHandler(e) {
    const email = this.email.current.value;
    ElstrCore.store.dispatch(UserActions.forgotPassword(email));
  }

  componentDidMount() {
    this.props.onMount();
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.match);
  }

  // Steps
  // 1. addresses
  // 2. options
  // 3. overview
  // 4. ordered

  render() {
    let shippingDestination = "";
    if (
      this.props.checkout.order &&
      this.props.checkout.order.shippingDestination &&
      this.props.checkout.order.shippingDestination.length > 0
    ) {
      shippingDestination = this.props.checkout.order.shippingDestination;
    }

    const shippingOptions: ShippingOptionVo[] = window.COMPONA_STATIC_CACHE.lieferarten.filter(
      lieferart => {
        if (lieferart.restrictedLaender.length === 0) {
          if (lieferart.excludedLaender.length === 0) {
            return true;
          } else {
            if (!lieferart.excludedLaender.includes(shippingDestination)) {
              return true;
            }
          }
        } else {
          if (lieferart.restrictedLaender.includes(shippingDestination)) {
            if (lieferart.excludedLaender.length === 0) {
              return true;
            } else {
              if (!lieferart.excludedLaender.includes(shippingDestination)) {
                return true;
              }
            }
          }
        }
        return false;
      },
    );

    const vatRate = priceGetVatRate(shippingDestination);
    const cartSumInclVat = this.props.cart.sum + this.props.cart.sum * (vatRate / 100);

    let dkoLiefartCd = "";
    if (
      this.props.checkout.order &&
      this.props.checkout.order.dkoLiefartCd &&
      this.props.checkout.order.dkoLiefartCd.length > 0
    ) {
      dkoLiefartCd = this.props.checkout.order.dkoLiefartCd;
    }

    let dkoAblnr = "";
    if (
      this.props.checkout.order &&
      this.props.checkout.order.dkoAblnr &&
      this.props.checkout.order.dkoAblnr.length > 0
    ) {
      dkoAblnr = this.props.checkout.order.dkoAblnr;
    }

    let dkoBestellBez = "";
    if (
      this.props.checkout.order &&
      this.props.checkout.order.dkoBestellBez &&
      this.props.checkout.order.dkoBestellBez.length > 0
    ) {
      dkoBestellBez = this.props.checkout.order.dkoBestellBez;
    }

    let allowToProceed = true;
    if (this.props.checkout.lioLandNotCh && this.props.checkout.asGuest) {
      allowToProceed = false;
    }
    if (
      this.props.checkout.lioLandNotCh &&
      this.props.cart.sum < parseFloat(config("minCartSumLioLandNotCH"))
    ) {
      allowToProceed = false;
    }
    if (
      !this.props.checkout.lioLandNotCh &&
      this.props.cart.sum < parseFloat(config("minCartSumLioLandCH"))
    ) {
      allowToProceed = false;
    }

    if (
      this.props.user.isAuth ||
      this.props.checkout.asGuest ||
      this.props.match.params.step !== "addresses"
    ) {
      return (
        <main className="main main--checkout" role="main">
          <div className="content">
            <h1 className="content__page-title">Checkout</h1>
            <CheckoutSteps step={this.props.match.params.step} />
            {this.props.match.params.step === "addresses" && (
              <CheckoutAddresses
                user={this.props.user}
                cart={this.props.cart}
                checkout={this.props.checkout}
              />
            )}
            {this.props.match.params.step === "options" && (
              <CheckoutOptions
                user={this.props.user}
                checkout={this.props.checkout}
                shippingOptions={shippingOptions}
                shippingDestination={shippingDestination}
                dkoLiefartCd={dkoLiefartCd}
                dkoAblnrOptions={this.props.checkout.dkoAblnrOptions}
                dkoAblnr={dkoAblnr}
                cartSumInclVat={cartSumInclVat}
              />
            )}
            {this.props.match.params.step === "overview" && (
              <CheckoutOverview
                user={this.props.user}
                cart={this.props.cart}
                checkout={this.props.checkout}
                shippingOptions={shippingOptions}
                shippingDestination={shippingDestination}
                dkoLiefartCd={dkoLiefartCd}
                dkoAblnrOptions={this.props.checkout.dkoAblnrOptions}
                dkoAblnr={dkoAblnr}
                dkoBestellBez={dkoBestellBez}
                dstIsoCurrent={this.props.user.dstIsoCurrent}
                vatRate={vatRate}
                cartSumInclVat={cartSumInclVat}
              />
            )}
            {this.props.match.params.step === "ordered" && (
              <CheckoutOrdered
                orderId={this.props.match.params.orderId}
                user={this.props.user}
                order={this.props.order}
              />
            )}

            <CheckoutSubmit
              step={this.props.match.params.step}
              history={this.props.history}
              isLoading={this.props.checkout.isLoading}
              allowToProceed={allowToProceed}
            />
          </div>
        </main>
      );
    } else {
      return (
        <main className="main main--checkout" role="main">
          <div className="content">
            <h1 className="content__page-title">{dic("LOGIN")}</h1>
            <div className="content__2col user-register">
              <div className="col">
                <form onSubmit={this.onLoginClickHandler}>
                  <h2 className="subtitle">{dic("LOGIN")}</h2>
                  {this.props.user.isLoading && (
                    <div className="user__body">
                      <div className="loading" />
                    </div>
                  )}
                  {!this.props.user.isLoading && (
                    <div className="user__body">
                      <label className="user__label">
                        {dic("EMAIL")} <span>*</span>
                      </label>
                      <input
                        ref={this.email}
                        type="email"
                        className="input--text"
                        aria-required={true}
                        required={true}
                        autoComplete="email"
                      />
                      <label className="user__label">
                        {dic("PASSWORT")} <span>*</span>
                      </label>
                      <input
                        ref={this.password}
                        type="password"
                        className="input--text"
                        aria-required={true}
                        required={true}
                        autoComplete="password"
                      />
                    </div>
                  )}
                  <div className="user__footer">
                    <div className="row">
                      <input className="input--submit" type="submit" value={dic("LOGIN")} />
                      <input
                        onClick={this.onForgotPasswordClickHandler}
                        className="input--reset"
                        type="reset"
                        value={dic("PASSWORT VERGESSEN")}
                      />
                    </div>
                    <div className="row">
                      <Link to={"/signup/new"}>
                        <input className="input--reset" type="reset" value={dic("REGISTRIEREN")} />
                      </Link>
                    </div>
                  </div>
                </form>
              </div>

              <div className="col">
                <h2 className="subtitle">{dic("ALS GAST ODER NEUKUNDE FORTFAHREN")}</h2>
                <p className="inst-text">
                  {dic("ZUM CHEKOUT GEHEN UND AM SCHLUSS EINEN BENUTZER ERSTELLEN")}
                </p>
                <p className="inst-text">{dic("HINWEIS GAST LIEFERLAND SCHWEIZ")}</p>
                <div className="user__footer">
                  <div className="row">
                    <input
                      className="input--submit"
                      type="submit"
                      value={dic("ALS GAST BESTELLEN")}
                      onClick={this.props.proceedCheckoutAsGuest}
                    />
                    <p>
                      {dic("WESHALB REGISTRIEREN")}{" "}
                      <a
                        className="text-link"
                        href={`/cms/${activeLang()}/${dic("CMSURL_REGISTRIERUNG_ANMELDUNG")}`}
                      >
                        {dic("VORTEILE ZEIGEN")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}

const mapStateToProps = (state: State): CheckoutStateToProps => ({
  user: state.user,
  cart: state.cart,
  checkout: state.checkout,
  order: state.order,
});

const mapDispatchToProps = (dispatch): CheckoutDispatchToProps => ({
  onMount: () => {
    dispatch(LayoutActions.showFilterPanel(false));
    dispatch(LayoutActions.showHomeHeader(false));
    dispatch(CheckoutActions.initCheckoutIfNotInitialised());
  },
  proceedCheckoutAsGuest: () => dispatch(CheckoutActions.proceedCheckoutAsGuest()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Checkout);
