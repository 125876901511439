import * as React from "react";
import PopMessageItem from "../components/PopMessageItem";
import { connect } from "react-redux";
import { PopMessageVo, State } from "../../State";
import { PopMessageActions } from "../actions/popMessageActions";

interface PopMessageStateToProps {
  popMessage: PopMessageVo[];
}

interface PopMessageDispatchToProps {
  removePopMessageItem: (index) => void;
}

export interface PopMessageProps extends PopMessageStateToProps, PopMessageDispatchToProps {}

class PopMessage extends React.PureComponent<PopMessageProps> {
  render() {
    return <div className="pop-message">{this.renderPopMessageItems()}</div>;
  }

  renderPopMessageItems() {
    if (this.props.popMessage.length <= 0) return null;

    return this.props.popMessage.map((item, index) => {
      return (
        <PopMessageItem
          key={item.randNum}
          index={index}
          textBold={item.textBold}
          text={item.text}
          sicon={item.sicon}
          level={item.level}
          clickAction={item.clickAction}
          linktext={item.linktext}
          href={item.href}
          isCmsNotice={item.isCmsNotice}
          removedAfterMs={item.removedAfterMs}
          removePopMessageItem={this.props.removePopMessageItem}
        />
      );
    });
  }
}

const mapStateToProps = (state: State): PopMessageStateToProps => ({
  popMessage: state.popMessage,
});

const mapDispatchToProps = (dispatch): PopMessageDispatchToProps => ({
  removePopMessageItem: index => dispatch(PopMessageActions.removePopMessageItem(index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopMessage);
