import { HomeActionsTypes } from "../actions/HomeActionsTypes";
import ProductVo from "../../common/vo/ProductVo";

const newProductReducer = (state: ProductVo[], action) => {
  if (!state) return null;

  switch (action.type) {
    case HomeActionsTypes.SHOW_NEW_PRODUCTS:
      return action.data;
  }

  return state;
};

export default newProductReducer;
