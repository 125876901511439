import * as React from "react";
import { Link } from "react-router-dom";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { UserState } from "../../../State";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { UserActions } from "../../../user/actions/userActions";

interface Props {
  onClose: () => void;
  login: (email, password) => void;
  forgotPassword: (email) => void;
  sendVerifyAgain: (email) => void;
  logout: () => void;
  history: any;
  user: UserState;
}

class UserFlyout extends React.PureComponent<Props> {
  private readonly email: any;
  private readonly password: any;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.email = React.createRef();
    this.password = React.createRef();

    this.onLoginClick = this.onLoginClick.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
    this.onSendVerifyAgainClick = this.onSendVerifyAgainClick.bind(this);
  }

  onLoginClick(e) {
    e.preventDefault();
    const email = this.email.current.value;
    const password = this.password.current.value;
    this.props.login(email, password);
  }
  onSubmitForm(e) {
    e.preventDefault();
    const email = this.email.current.value;
    const password = this.password.current.value;
    this.props.login(email, password);
  }
  onForgotPasswordClick(e) {
    const email = this.email.current.value;
    this.props.forgotPassword(email);
  }
  onSendVerifyAgainClick(e) {
    const email = this.email.current.value;
    this.props.sendVerifyAgain(email);
  }

  onChangeEmail(e) {
    ElstrCore.store.dispatch(UserActions.setUserFlyoutLoginMsg(""));
  }
  onChangePassword(e) {
    ElstrCore.store.dispatch(UserActions.setUserFlyoutLoginMsg(""));
  }

  render() {
    // User is logged in
    if (this.props.user.isInit) {
      return (
        <div className="user flyout flyout--user" aria-hidden="true">
          <div className="user__header">
            <div className="user__title">{dic("MEIN KONTO")}</div>{" "}
            <span className="close--circle" onClick={this.props.onClose} />
          </div>
          <div className="user__body">
            <Link
              to={`/account/settings/${encodeURIComponent(this.props.user.username)}`}
              className="link--intern"
              onClick={this.props.onClose}
            >
              {dic("MEIN KONTO")} {this.props.user.username}
            </Link>
            <Link
              to={`/account/orders/${encodeURIComponent(this.props.user.username)}`}
              className="link--intern"
              onClick={this.props.onClose}
            >
              {dic("BESTELLUNGEN")}
            </Link>
            {this.props.user.isAuth &&
              this.props.user.isManager && (
                <Link to={`/manage/orders/`} className="link--intern" onClick={this.props.onClose}>
                  {dic("MANAGE")}
                </Link>
              )}
          </div>
          <div className="user__footer">
            <div className="row">
              <input
                className="input--submit"
                type="submit"
                value={dic("ABMELDEN")}
                onClick={this.props.logout}
              />
            </div>
          </div>
        </div>
      );
    }
    // User is not logged in
    return (
      <div className="user flyout flyout--user" aria-hidden="true">
        <form onSubmit={this.onSubmitForm}>
          <div className="user__header">
            <div className="user__title">{dic("LOGIN")}</div>{" "}
            <span className="close--circle" onClick={this.props.onClose} />
          </div>
          {this.props.user.isLoading && (
            <div className="user__body">
              <div className="loading" />
            </div>
          )}
          {!this.props.user.isLoading && (
            <div className="user__body">
              <label className="user__label">
                {dic("EMAIL")} <span>*</span>
              </label>
              <input
                autoFocus={true}
                ref={this.email}
                type="email"
                className="input--text"
                autoComplete="email"
                aria-required={true}
                required={true}
                onChange={this.onChangeEmail}
              />
              <label className="user__label">{dic("PASSWORT")}</label>
              <input
                ref={this.password}
                type="password"
                className="input--text"
                autoComplete="password"
                aria-required={true}
                required={true}
                onChange={this.onChangePassword}
              />
              {this.props.user.userFlyoutLoginMsg &&
                this.props.user.userFlyoutLoginMsg.length > 0 && (
                  <p className="form__error-message">{dic(this.props.user.userFlyoutLoginMsg)}</p>
                )}
            </div>
          )}
          <div className="user__footer">
            <div className="row">
              <input
                onClick={this.onLoginClick}
                className="input--submit"
                type="submit"
                value={dic("LOGIN")}
              />
              <input
                onClick={this.onForgotPasswordClick}
                className="input--reset"
                type="reset"
                value={dic("PASSWORT VERGESSEN")}
              />
              <input
                onClick={this.onSendVerifyAgainClick}
                className="input--reset"
                type="reset"
                value={dic("NEUEN VERIFIKATIONLINK SENDEN")}
              />
            </div>
            <div className="row">
              <Link to={"/signup/new"}>
                <input className="input--submit" type="reset" value={dic("REGISTRIEREN")} />
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default UserFlyout;
