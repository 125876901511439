import ProductVo from "../vo/ProductVo";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { getUnitDescription } from "./unitUtils";
import { ListProductDataVo } from "../vo/ListVo";
import ProductTakeVo from "../vo/ProductTakeVo";

/**
 *
 * @param quantity
 * @param {ProductVo | ListProductDataVo} productData
 * @returns {null | ProductTakeVo}
 */
export function productGetTakeInit(quantity, productData: ProductVo | ListProductDataVo) {
  let productTake: ProductTakeVo | null = null;
  if (
    productData.mengensprung &&
    productData.mindestbestellmenge &&
    productData.verkaufsEinheitNummer
  ) {
    productTake = {
      quantity,
      quantityString: String(quantity),
      verkaufsEinheit: getUnitDescription(productData.verkaufsEinheitNummer, activeLang()),
      minusCounter: productData.mengensprung,
      plusCounter: productData.mengensprung,
      msg: "",
    };
  }
  return productTake;
}

/**
 *
 * @param take
 * @param {ProductVo | ListProductDataVo} data
 * @param {string} direction
 * @returns {null | ProductTakeVo}
 */
export function productGetTakeClick(
  take: any,
  data: ProductVo | ListProductDataVo,
  direction: string,
) {
  let productTake: ProductTakeVo | null = null;
  let quantity = 0;

  if (take && data && data.mindestbestellmenge) {
    quantity += take.quantity;
    if (direction === "plus") {
      quantity += take.plusCounter;
    }
    if (direction === "minus") {
      quantity -= take.minusCounter;
      if (quantity < data.mindestbestellmenge) {
        quantity = data.mindestbestellmenge;
      }
    }

    productTake = {
      quantity,
      quantityString: String(quantity),
      verkaufsEinheit: take.verkaufsEinheit,
      minusCounter: take.minusCounter,
      plusCounter: take.plusCounter,
      msg: "",
    };
  }
  return productTake;
}

/**
 *
 * @param take
 * @param {ProductVo | ListProductDataVo} data
 * @param {string} quantityString
 * @returns {null | ProductTakeVo}
 */
export function productGetTakeInput(
  take: any,
  data: ProductVo | ListProductDataVo,
  quantityString: string,
) {
  let productTake: ProductTakeVo | null = null;
  let msg = "";
  let quantity = 0;

  if (take && data && data.mindestbestellmenge) {
    quantity += take.quantity;
    if (isNaN(Number.parseFloat(quantityString))) {
      quantity = data.mindestbestellmenge;

      msg = "EINGABE IST KEINE ZAHL";
    } else if (Number.parseFloat(quantityString) >= data.mindestbestellmenge) {
      quantity = Number.parseFloat(quantityString);
      // quantity muss durch den plusCounter teilbar sein
      if (quantity % take.plusCounter > 0.0001) {
        quantity -= quantity % take.plusCounter;
        msg = "EINGABE IST NICHT TEILBAR DURCH VERPACKUNGSGROESSE";
      }
    } else {
      quantity = data.mindestbestellmenge;
      msg = "EINGABE IST KLEINER ALS MINDESTBESTELLMENGE";
    }

    productTake = {
      quantity,
      quantityString,
      verkaufsEinheit: take.verkaufsEinheit,
      minusCounter: take.minusCounter,
      plusCounter: take.plusCounter,
      msg,
    };
  }
  return productTake;
}

export function productCanBeAddedToCart(productData: ProductVo | ListProductDataVo) {
  if (productData.status && productData.status < 700) {
    if (
      productData.mengensprung &&
      productData.mengensprung > 0 &&
      productData.mindestbestellmenge &&
      productData.mindestbestellmenge > 0 &&
      productData.verkaufsEinheitNummer
    ) {
      if (productData.preisgruppen && productData.preisgruppen["1"]) {
        return true;
      }
    }
  }
  return false;
}

export function productGetTitleString(productData: ProductVo) {
  // Title definition also on backend in App\HomePage\ViewObjectFactory for correct initial load
  let title = "";
  if (productData.hersteller_typennummer_formatiert) {
    title += productData.hersteller_typennummer_formatiert;
  }
  if (productData.ShortDescription) title += " " + productData.ShortDescription;
  if (title.length > 0) title += " - ";
  if (productData.herstellername) title += productData.herstellername;
  title += " | " + dic("TITLE");

  return title;
}

export function productGetDescriptionString(productData: ProductVo) {
  // Title definition is similar to the title string method
  let description = "";
  if (productData.hersteller_typennummer_formatiert) {
    description += productData.hersteller_typennummer_formatiert;
  }
  if (productData.ShortDescription) description += " " + productData.ShortDescription;
  if (description.length > 0) description += " - ";
  if (productData.herstellername) description += productData.herstellername;

  if (productData.LongDescription) description += " " + productData.LongDescription;
  if (productData.objectID) description += " " + productData.objectID;

  return description;
}
