import * as React from "react";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
}

class WebsiteStructuredData extends React.PureComponent<Props> {
  render() {
      const structuredData: any = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "id": "https://www.compona.com/#website",
        "url": "https://www.compona.com/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": `${window.location.origin}/catalog/${activeLang()}/q={search_term}`,
          "query-input": "required name=search_term",
        }
      };
      return <script type="application/ld+json">{JSON.stringify(structuredData)}</script>;
  }
}

export default WebsiteStructuredData;
