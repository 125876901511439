export enum LayoutActionsTypes {
  LAYOUT_LOAD_FACETS = "LAYOUT_LOAD_FACETS",

  LAYOUT_HOME_HEADER = "LAYOUT_HOME_HEADER",
  LAYOUT_FILTER_SHOW = "LAYOUT_FILTER_SHOW",

  LAYOUT_FILTER_OPEN = "LAYOUT_FILTER_OPEN",
  LAYOUT_SEARCH_OPEN = "LAYOUT_SEARCH_OPEN",

  LAYOUT_WISHLIST_OPEN = "LAYOUT_WISHLIST_OPEN",
  LAYOUT_CART_OPEN = "LAYOUT_CART_OPEN",
  LAYOUT_CART_CLOSE_EXPICITLY = "LAYOUT_CART_CLOSE_EXPICITLY",
  LAYOUT_MENU_OPEN = "LAYOUT_MENU_OPEN",
  LAYOUT_USER_OPEN = "LAYOUT_USER_OPEN",
  LAYOUT_USER_LOGIN = "LAYOUT_USER_LOGIN",
  LAYOUT_LANG_OPEN = "LAYOUT_LANG_OPEN",
  LAYOUT_CURRENCY_OPEN = "LAYOUT_CURRENCY_OPEN",
  LAYOUT_COOKIES_ACCEPT = "LAYOUT_COOKIES_ACCEPT",

  LAYOUT_SCROLL_STATE = "LAYOUT_SCROLL_STATE",
}
