import * as React from "react";

interface Props {
  selections: SelctionVo[];
  selectedValue?: string;
  id?: string;
  name?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

class SelectBox extends React.PureComponent<Props> {
  render() {
    return (
      <select
        id={this.props.id}
        name={this.props.name}
        value={this.props.selectedValue}
        className="selectbox"
        required={this.props.required}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
      >
        {this.props.selections.map((option, index) => {
          return (
            <option
              value={option.value}
              className="selectbox__option"
              key={`option-${option.value}-${index}`}
            >
              {option.name}
            </option>
          );
        })}
      </select>
    );
  }
}

export default SelectBox;

interface SelctionVo {
  name: string;
  value: string;
}
