import * as React from "react";
import { ProductTableColumnVo } from "./ProductTable";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ProductTableHeaderColumn from "./ProductTableHeaderColumn";

interface Props {
  children?: any;
  data: ProductTableColumnVo[];
  sorting: { key?: string; direction?: string };
  onClickSortLink: (column: ProductTableColumnVo, direction) => void;
}

class ProductTableHeader extends React.PureComponent<Props> {
  render() {
    return (
      <div className="list__header">
        <div className="list-header__product ">
          <span>{dic("PRODUKT")}</span>
        </div>
        {this.renderItems()}
      </div>
    );
  }

  renderItems() {
    return (
      <>
        {this.props.data.map((item, index) => {
          return (
            <ProductTableHeaderColumn
              key={`${item.dataAttributeKey}-${index}`}
              sorting={this.props.sorting}
              item={item}
              index={index}
              onClickSortLink={this.props.onClickSortLink}
            />
          );
        })}
      </>
    );
  }
}

export default ProductTableHeader;
