import { SignupActionTypes } from "./ActionTypes";
import COMPONA_WidgetServer_JSON_User from "../../common/services/COMPONA_WidgetServer_JSON_User";
import { routerActions } from "connected-react-router";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { PopMessageType } from "../../common/constants/Constants";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { LayoutActions } from "../../layout/actions/layoutActions";
import ElstrIo from "elstr-jslib-4/dist/ElstrIo";
import { SignupState } from "../../State";

export const SignupActions = {
  setSignupDataValue_thunk: function(name, value) {
    return { type: SignupActionTypes.SET_SIGNUP_DATAVALUE, payload: { name, value } };
  },

  setSignupIsLoading: function(isLoading: boolean) {
    return { type: SignupActionTypes.SET_SIGNUP_ISLOADING, payload: { isLoading } };
  },

  setUserExistsAlready: function(userExistsAlready: boolean) {
    return {
      type: SignupActionTypes.SET_SIGNUP_USER_EXISTS_ALREADY,
      payload: { userExistsAlready },
    };
  },

  setSignupMsg: function(msg: string) {
    return { type: SignupActionTypes.SET_SIGNUP_MSG, payload: { msg } };
  },

  setSignupNewsletter: function(signupNewsletter: boolean) {
    return { type: SignupActionTypes.SET_SIGNUP_NEWSLETTER, payload: { signupNewsletter } };
  },

  setSignupDataValue: function(name, value) {
    return async (dispatch, getState) => {
      dispatch(SignupActions.setSignupDataValue_thunk(name, value));

      if (name === "email") {
        dispatch(SignupActions.checkIfUserExists(value));
      }
    };
  },

  checkIfUserExists: function(email: string) {
    const thunk: any = async (dispatch, getState) => {
      const response = await COMPONA_WidgetServer_JSON_User.getIfUserExists(email);
      console.log(response.data);
      if (response.data && response.data === true) {
        dispatch(SignupActions.setUserExistsAlready(response.data));
      } else {
        dispatch(SignupActions.setUserExistsAlready(false));
      }
    };
    thunk.meta = {
      debounce: {
        time: 300,
        key: "checkIfUserExists",
      },
    };
    return thunk;
  },

  submitSignup: function() {
    // console.log("submitSignup");
    return async (dispatch, getState) => {
      const signupState: SignupState = getState().signup;
      if (signupState.data) {
        if (signupState.data.password !== signupState.data.passwordRepeated) {
          dispatch(SignupActions.setSignupMsg(dic("PASSWORT NICHT KORREKT WIEDERHOLT")));
          dispatch(SignupActions.setSignupDataValue("password", ""));
          dispatch(SignupActions.setSignupDataValue("passwordRepeated", ""));
        } else {
          dispatch(SignupActions.setSignupIsLoading(true));
          dispatch(SignupActions.setSignupMsg(""));
          try {
            const response = await COMPONA_WidgetServer_JSON_User.createUser(
              signupState.data.email,
              signupState.data.password,
              signupState.data.dstDnr,
              {
                anrNr: signupState.data.anrNr,
                subjekttyp: "1",
                name: signupState.data.name,
                vorname: signupState.data.vorname,
                zeile1: signupState.data.zeile1,
                zeile2: signupState.data.zeile2,
                zeile3: signupState.data.zeile3,
                ort: signupState.data.ort,
                plz: signupState.data.plz,
                land: signupState.data.land,
                tel: signupState.data.tel,
              },
              activeLang(),
              signupState.signupNewsletter,
            );

            ElstrIo.throwErrorIfResponseContainsErrorMessage(response);

            if (response.data) {
              dispatch(
                createPopMessage(PopMessageType.info, dic("EMAIL ZUR VERFIKATION GESENDET"), 0),
              );
              dispatch(
                createPopMessage(
                  PopMessageType.info,
                  `${dic("WILLKOMMEN")} ${signupState.data.vorname} ${signupState.data.name}`,
                  10000,
                ),
              );
              dispatch(routerActions.push(`/home/${activeLang()}/`));
              dispatch(SignupActions.setSignupDataValue("email", ""));
              dispatch(SignupActions.setSignupDataValue("password", ""));
              dispatch(SignupActions.setSignupDataValue("dstDnr", ""));
              dispatch(SignupActions.setSignupDataValue("anrNr", ""));
              dispatch(SignupActions.setSignupDataValue("name", ""));
              dispatch(SignupActions.setSignupDataValue("vorname", ""));
              dispatch(SignupActions.setSignupDataValue("zeile1", ""));
              dispatch(SignupActions.setSignupDataValue("zeile2", ""));
              dispatch(SignupActions.setSignupDataValue("zeile3", ""));
              dispatch(SignupActions.setSignupDataValue("ort", ""));
              dispatch(SignupActions.setSignupDataValue("plz", ""));
              dispatch(SignupActions.setSignupDataValue("land", ""));
              dispatch(SignupActions.setSignupDataValue("tel", ""));
            } else {
              throw new Error("No data in response");
            }
            dispatch(SignupActions.setSignupIsLoading(false));
          } catch (e) {
            console.log(e.message);
            dispatch(SignupActions.setSignupDataValue("password", ""));
            dispatch(SignupActions.setSignupDataValue("passwordRepeated", ""));
            dispatch(SignupActions.setSignupMsg(e.message));
            dispatch(SignupActions.setSignupIsLoading(false));
          }
        }
      }
    };
  },

  resetPassword: function(token, password, passwordRepeated) {
    // console.log("resetPassword");
    return async (dispatch, getState) => {
      dispatch(SignupActions.setSignupIsLoading(true));
      if (password === passwordRepeated) {
        try {
          dispatch(createPopMessage(PopMessageType.info, dic("ANTRAG GESENDET"), 5000));
          const response = await COMPONA_WidgetServer_JSON_User.resetPassword(token, password);
          if (response.data && response.data.successful) {
            dispatch(createPopMessage(PopMessageType.info, dic("PASSWORT GEAENDERT"), 10000));
            dispatch(routerActions.push(`/home/${activeLang()}/`));
            dispatch(LayoutActions.openUser(true));
          } else {
            if (response.messages) {
              throw new Error(response.messages);
            } else {
              throw new Error("Error on password change");
            }
          }
          dispatch(SignupActions.setSignupIsLoading(false));
        } catch (e) {
          console.log(e.message);
          dispatch(createPopMessage(PopMessageType.warning, e.message, 10000));
          dispatch(SignupActions.setSignupIsLoading(false));
        }
      } else {
        dispatch(
          createPopMessage(PopMessageType.warning, dic("PASSWORT NICHT KORREKT WIEDERHOLT"), 10000),
        );
        dispatch(SignupActions.setSignupIsLoading(false));
      }
    };
  },
};
