import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { CheckoutState, UserState } from "../../State";
import RadioBoxItemsDkoAblnr from "./RadioBoxItemsDkoAblnr";
import RadioBoxItemsDkoLiefartCd from "./RadioBoxItemsDkoLiefartCd";
import { CheckoutActions } from "../actions/checkoutActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { v1 as uuidv1 } from "uuid";
import { ShippingOptionVo } from "../../common/vo/ShippingOptionVo";

interface Props {
  user: UserState;
  checkout: CheckoutState;
  shippingOptions: any[];
  shippingDestination: string;
  dkoLiefartCd: string;
  dkoAblnrOptions: any[] | null;
  dkoAblnr: string;
  cartSumInclVat: number;
}

class CheckoutOptions extends React.PureComponent<Props> {
  onOrderInputChange = e => {
    ElstrCore.store.dispatch(CheckoutActions.onOrderInputChange(e.target.name, e.target.value));
  };

  onOrderCheckboxChange = e => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ElstrCore.store.dispatch(CheckoutActions.onOrderInputChange(e.target.name, value));
  };

  render() {
    const shippingOption: ShippingOptionVo = this.props.shippingOptions.find(
      shippingOption => shippingOption.liefartCd === this.props.dkoLiefartCd,
    );
    let mAccountNumber = false;
    let mEoriNumber = false;
    if (shippingOption) {
      mAccountNumber = shippingOption.mAccountNumber;
      mEoriNumber = shippingOption.mEoriNumber;
    }

    let shippingInParts = false;
    if (this.props.checkout && this.props.checkout.order) {
      shippingInParts = this.props.checkout.order.shippingInParts;
    }

    let accountNumber = "";
    if (
      this.props.checkout &&
      this.props.checkout.order &&
      this.props.checkout.order.accountNumber
    ) {
      accountNumber = this.props.checkout.order.accountNumber;
    }

    let eoriNumber = "";
    if (this.props.checkout && this.props.checkout.order && this.props.checkout.order.eoriNumber) {
      eoriNumber = this.props.checkout.order.eoriNumber;
    }

    let shippingComment = "";
    if (
      this.props.checkout &&
      this.props.checkout.order &&
      this.props.checkout.order.shippingComment
    ) {
      shippingComment = this.props.checkout.order.shippingComment;
    }

    const uuid = uuidv1();

    return (
      <div className="content__2col">
        <div className="col">
          <h2 className="subtitle">{`${dic("VERSAND")} [${this.props.shippingDestination}]`}</h2>

          <div
            className={`radio-box ${this.props.checkout.formValidationErrors
              .radioBoxItemsDkoLiefartCd && "radio-box--error"}`}
          >
            <RadioBoxItemsDkoLiefartCd
              dkoLiefartCd={this.props.dkoLiefartCd}
              shippingDestination={this.props.shippingDestination}
              shippingOptions={this.props.shippingOptions}
              showOnlyChecked={false}
              dstIsoCurrent={this.props.user.dstIsoCurrent}
              cartSumInclVat={this.props.cartSumInclVat}
            />
          </div>
          {this.props.checkout.formValidationErrors.radioBoxItemsDkoLiefartCd && (
            <p className="form__error-message">
              {dic(this.props.checkout.formValidationErrors.radioBoxItemsDkoLiefartCd)}
            </p>
          )}
          {mAccountNumber && (
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${uuid}_accountNumber`}>
                {dic("ACCOUNT NR")}
                <span> *</span>
              </label>
              <input
                className={`input--text ${this.props.checkout.formValidationErrors
                  .orderAccountNumber && "form-error"}`}
                value={accountNumber}
                type="text"
                id={`${uuid}_accountNumber`}
                name="accountNumber"
                placeholder={`${dic("ACCOUNT NR")}...`}
                aria-required={false}
                onChange={this.onOrderInputChange}
              />
              {this.props.checkout.formValidationErrors.orderAccountNumber && (
                <p className="form__error-message">
                  {dic(this.props.checkout.formValidationErrors.orderAccountNumber)}
                </p>
              )}
            </div>
          )}
          {mEoriNumber && (
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${uuid}_eoriNumber`}>
                {dic("EORI NUMMER")}
                <span> *</span>
              </label>
              <input
                className={`input--text ${this.props.checkout.formValidationErrors
                  .orderEoriNumber && "form-error"}`}
                value={eoriNumber}
                type="text"
                id={`${uuid}_eoriNumber`}
                name="eoriNumber"
                placeholder={`${dic("EORI NUMMER")}...`}
                aria-required={false}
                onChange={this.onOrderInputChange}
              />
              {this.props.checkout.formValidationErrors.orderEoriNumber && (
                <p className="form__error-message">
                  {dic(this.props.checkout.formValidationErrors.orderEoriNumber)}
                </p>
              )}
            </div>
          )}

          <div className="form-input__wrapper">
            <div className="label">{dic("VERSANDKOMMENTAR")}</div>
            <textarea
              rows={5}
              className="textarea"
              placeholder={`${dic("VERSANDKOMMENTAR")}...`}
              value={shippingComment}
              name="shippingComment"
              onChange={this.onOrderInputChange}
            />
          </div>
          <div className="form-input__wrapper form-input__wrapper--checkbox">
            <div className="form-row">
              <div className="checkbox">
                <input
                  className="input--checkbox"
                  id={`${uuid}_shippingInParts`}
                  name="shippingInParts"
                  type="checkbox"
                  aria-checked="false"
                  checked={shippingInParts}
                  onChange={this.onOrderCheckboxChange}
                />
                <label htmlFor={`${uuid}_shippingInParts`} className="checkbox__label">
                  <span className="checkbox__box" />
                  <strong>{dic("TEILLIEFERUNG GEWUENSCHT")}</strong>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <h2 className="subtitle">{dic("BEZAHLUNG")}</h2>

          <div className="radio-box">
            <RadioBoxItemsDkoAblnr
              dkoAblnr={this.props.dkoAblnr}
              dkoAblnrOptions={this.props.dkoAblnrOptions}
              showOnlyChecked={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutOptions;
