import { ALGOLIA_PUBLIC_KEY, ALGOLIA_APPLICATION_ID } from "../constants/Constants";
import { SearchState } from "../../State";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { LONG_DASH, stringToRange, SimpleRangeVo } from "../../common/utils/rangeUtils";
import algoliasearch, { QueryParameters } from "algoliasearch";
import { priceCalculateScaleArray, priceCalculateForQuantity } from "../utils/priceCalculations";
import { UserPriceBaseVo } from "../vo/UserPriceBaseVo";

declare var ELSTR;
declare var COMPONA_STATIC_CACHE;

enum AlgoliaIndexTypes {
  PRODUCTS = "products",
  SUGGESTIONS = "suggestions",
}

const clientOptions: any = { _useRequestCache: true };
const algoliaClient: algoliasearch.Client = algoliasearch(
  ALGOLIA_APPLICATION_ID,
  ALGOLIA_PUBLIC_KEY,
  clientOptions,
);

export default class AlgoliaServices {
  static getResultFromCache(query: string, cache: object) {
    if (query === undefined) {
      query = "_undefined_";
    }
    return cache[query];
  }

  static setResulToCache(query: string, cache: object, result: object) {
    if (query === undefined) {
      query = "_undefined_";
    }
    cache[query] = result;
  }

  private static getIndex(index: AlgoliaIndexTypes, lang: string): algoliasearch.Index {
    switch (index) {
      case AlgoliaIndexTypes.PRODUCTS:
        return algoliaClient.initIndex(
          `${ELSTR.applicationEnv}__${AlgoliaIndexTypes.PRODUCTS}__${lang}`,
        );
      case AlgoliaIndexTypes.SUGGESTIONS:
        return algoliaClient.initIndex(
          `${ELSTR.applicationEnv}__${AlgoliaIndexTypes.SUGGESTIONS}__${lang}`,
        );
    }
  }

  static NEW_PRODUCTS = {};
  static async getNewProducts(languageCode: string): Promise<algoliasearch.Response> {
    try {
      let result = AlgoliaServices.getResultFromCache(
        "homepage_fokus:1" + languageCode,
        AlgoliaServices.NEW_PRODUCTS,
      );
      if (!result) {
        const index: algoliasearch.Index = this.getIndex(AlgoliaIndexTypes.PRODUCTS, languageCode);

        result = index.search({
          attributesToRetrieve: ["*"],
          filters: "homepage_fokus:1",
          hitsPerPage: 3,
          page: 0,
        });
        AlgoliaServices.setResulToCache(
          "homepage_fokus:1" + languageCode,
          AlgoliaServices.NEW_PRODUCTS,
          result,
        );
      }
      return result;
    } catch (e) {
      throw new Error(e);
    }
  }

  static SUGGESTIONS = {};
  static async getSuggestions(
    query: string,
    languageCode: string,
  ): Promise<algoliasearch.Response> {
    try {
      let result = AlgoliaServices.getResultFromCache(
        `${query}|${languageCode}`,
        AlgoliaServices.SUGGESTIONS,
      );
      if (!result) {
        const index: algoliasearch.Index = this.getIndex(
          AlgoliaIndexTypes.SUGGESTIONS,
          languageCode,
        );
        result = await index.search({
          query,
          hitsPerPage: 7,
        });
        AlgoliaServices.setResulToCache(
          `${query}|${languageCode}`,
          AlgoliaServices.SUGGESTIONS,
          result,
        );
      }
      return result;
    } catch (e) {
      throw new Error(e);
    }
  }

  static async getAllFacets(languageCode: string): Promise<algoliasearch.Response> {
    try {
      const index: algoliasearch.Index = this.getIndex(AlgoliaIndexTypes.PRODUCTS, languageCode);
      const facets = await index.search({
        facets: "*",
      });
      return facets;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Create search string by combining value searches with OR.
   * @param {string[]} valueFilterArr
   * @returns string
   */
  static getOrFilter(valueFilterArr) {
    if (valueFilterArr.length > 1) {
      return "(" + valueFilterArr.join(" OR ") + ")";
    } else {
      // 1 or 0 elements
      return valueFilterArr.join();
    }
  }

  /**
   * Create search string by combining value searches with OR.
   * @param {string[]} valueFilterArr
   * @returns string
   */
  static algoliaEscape(value) {
    const escapedValue = value.replace('"', '\\"');
    return escapedValue;
  }

  /**
   * Get the Searchquery State and parse / send it to Algolia.
   * @param {string} languageCode
   * @param {SearchState} searchState
   * @returns {Promise<algoliasearch.Response>}
   */
  static async getProductsByFacetsTreesAndQuery(
    languageCode: string,
    searchState: SearchState,
    excludeFacetFilter: string = "",
  ): Promise<algoliasearch.Response> {
    const facetFilterArr: string[] = [];
    const numericFilterArr: string[] = [];
    const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];
    const staticCacheAttributes = window.COMPONA_STATIC_CACHE.attributes;
    //const maxValuesPerFacet = 500;

    let limitToFacet = "";

    let hits = searchState.showItems;
    //let paginationLimit = 0;
    let sortingColumn = "";
    if ("key" in searchState.sorting) {
      sortingColumn = searchState.sorting["key"];
    }
    let sortingDirection = "";
    if (searchState.sorting.hasOwnProperty("direction")) {
      sortingDirection = searchState.sorting["direction"];
    }

    if (sortingDirection && sortingDirection !== "") {
      hits = 10000;
    }

    try {
      if (searchState && searchState.filterSettings) {
        const filterSettings = searchState.filterSettings;
        for (const key in filterSettings) {
          // for facets block
          if (excludeFacetFilter !== "" && key === excludeFacetFilter) {
            limitToFacet = excludeFacetFilter;
            continue;
          }

          const valueArray: string[] = filterSettings[key];
          // gvo: build nested array for OR statement if more than one criteria of one facet is selected
          if (valueArray.length === 1 && valueArray[0].indexOf(LONG_DASH) >= 0) {
            // special case range slider values (format: x—, —y, or x—y)
            // NOTE: so far only cases where isRange= true in Excel are implemented, product range must include user range, e.g.: Temperature range
            const value = valueArray[0];
            const range: SimpleRangeVo = stringToRange(value);
            // special case range slider values (format: x—, —y, or x—y)
            if (staticCacheAttributes[key].isRange === true) {
              if (range.minValue !== undefined) {
                numericFilterArr.push(`${key}.min<=${range.minValue}`);
                if (range.maxValue === undefined) {
                  numericFilterArr.push(`${key}.max>=${range.minValue}`);
                }
              }
              if (range.maxValue !== undefined) {
                numericFilterArr.push(`${key}.max>=${range.maxValue}`);
                if (range.minValue === undefined) {
                  numericFilterArr.push(`${key}.min<=${range.maxValue}`);
                }
              }
            } else {
              if (range.minValue !== undefined) {
                numericFilterArr.push(`${key}>=${range.minValue}`);
              }
              if (range.maxValue !== undefined) {
                numericFilterArr.push(`${key}<=${range.maxValue}`);
              }
            }
          } else {
            const constraints: string[] = [];
            for (const index in valueArray) {
              let value = valueArray[index];
              value = AlgoliaServices.algoliaEscape(value);
              constraints.push(`"${key}:${value}"`);
            }
            facetFilterArr.push("[" + constraints.toString() + "]");
          }
        }
      }
      if (searchState && searchState.trees) {
        for (const root in searchState.trees) {
          const tree = searchState.trees[root];
          if (tree.constructor === Object && Object.keys(tree).length > 1) {
            // if more than one criteria given for current root tree
            const objArray: string[] = [];
            for (const elem in tree) {
              // given nodes and all their childs
              // for tree block counts
              if (excludeFacetFilter !== "" && elem === excludeFacetFilter) {
                limitToFacet = root;
                continue;
              }
              let escapedElem = AlgoliaServices.algoliaEscape(elem);
              objArray.push(`"${root}:${escapedElem}"`);
              staticCacheClassifications.forEach(classifi => {
                if (classifi.parentIds.indexOf(elem) > -1) {
                  escapedElem = AlgoliaServices.algoliaEscape(classifi.objectID);
                  objArray.push(`"${root}:${escapedElem}"`);
                }
              });
            }
            facetFilterArr.push("[" + objArray.toString() + "]");
          } else {
            // if exactely one criteria given for current root tree
            for (const elem in tree) {
              // given node and all its childs
              // for tree block counts
              if (excludeFacetFilter !== "" && elem === excludeFacetFilter) {
                limitToFacet = root;
                continue;
              }
              const objArray: string[] = [];
              let escapedElem = AlgoliaServices.algoliaEscape(elem);
              objArray.push(`"${root}:${escapedElem}"`);
              staticCacheClassifications.forEach(classifi => {
                if (classifi.parentIds.indexOf(elem) > -1) {
                  escapedElem = AlgoliaServices.algoliaEscape(classifi.objectID);
                  objArray.push(`"${root}:${escapedElem}"`);
                }
              });
              facetFilterArr.push("[" + objArray.toString() + "]");
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    // Create Text Query
    let textQuery = "";
    if (searchState && searchState.query) {
      textQuery = searchState.query;
    }

    if (limitToFacet !== "") {
      var query: QueryParameters = {
        query: textQuery,
        hitsPerPage: hits,
        facets: limitToFacet,
        facetFilters: "[" + facetFilterArr.toString() + "]",
      };
    } else {
      query = {
        query: textQuery,
        hitsPerPage: hits,
        facets: "*",
        facetFilters: "[" + facetFilterArr.toString() + "]",
      };
    }

    if (numericFilterArr.length > 0) {
      query.numericFilters = numericFilterArr;
    }

    try {
      const index: algoliasearch.Index = this.getIndex(AlgoliaIndexTypes.PRODUCTS, languageCode);
      const result = await index.search(query);
      //const result = await index.search(query, maxValuesPerFacet: 500);

      // sorting
      if (sortingColumn && sortingColumn !== "" && sortingDirection && sortingDirection !== "") {
        let tempHits = result.hits;
        tempHits = AlgoliaServices.sortByColumn(
          tempHits,
          sortingColumn,
          sortingDirection,
          staticCacheAttributes[sortingColumn].dataType,
        );
        if (tempHits.length > searchState.showItems) {
          tempHits.length = searchState.showItems;
        }
        result.hits = tempHits;
      }

      return result;
    } catch (e) {
      throw new Error(e);
    }
  }

  static sortByColumn(a, column, direction, dataType) {
    a.sort(sortFunction);

    function sortFunction(a, b) {
      if (column === "price") {
        const userPriceBase: UserPriceBaseVo = { dabKondgrpnr: 1, dstIsoCurrent: "CHF" };

        const aScaleArray = priceCalculateScaleArray(a, userPriceBase);
        let aMinBestell = 1;
        if (a.mindestbestellmenge) {
          aMinBestell = a.mindestbestellmenge;
        }
        const aPrice = priceCalculateForQuantity(aMinBestell, aScaleArray);

        const bScaleArray = priceCalculateScaleArray(b, userPriceBase);
        let bMinBestell = 1;
        if (b.mindestbestellmenge) {
          bMinBestell = b.mindestbestellmenge;
        }
        const bPrice = priceCalculateForQuantity(bMinBestell, bScaleArray);

        if (aPrice.betrag === "" || aPrice.betrag === 0) return 1;
        if (bPrice.betrag === "" || bPrice.betrag === 0) return -1;
        if (direction === "asc") {
          if (aPrice.betrag > bPrice.betrag) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (bPrice.betrag > aPrice.betrag) {
            return 1;
          } else {
            return -1;
          }
        }
      }

      if (!a[column]) {
        return 1;
      }
      if (!b[column]) {
        return -1;
      }

      if (dataType === "float") {
        if (direction === "asc") {
          if (a[column] > b[column]) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (b[column] > a[column]) {
            return 1;
          } else {
            return -1;
          }
        }
      } else {
        if (direction === "asc") {
          return a[column]
            .toString()
            .localeCompare(b[column], "de", { ignorePunctuation: false, numeric: true });
        } else {
          return b[column]
            .toString()
            .localeCompare(a[column], "de", { ignorePunctuation: false, numeric: true });
        }
      }
    }

    return a;
  }
}
