import * as React from "react";
import AutoCompleteDropDown from "../input/AutoCompleteDropDown";
import SelectBox from "../input/SelectBox";
import CheckBox from "../input/CheckBox";
import RangeSlider from "../input/RangeSlider";
import { PanelItemRenderTypes } from "../../vo/PanelDataVo";
import TagListRemoveAble from "./TagListRemoveAble";
import { SearchState } from "../../../State";

interface Props {
  id?: string;
  inputValue?: string;
  placeHolder: string;
  suggestions?: any[];
  search?: SearchState;
  filterIsActive?: boolean;
  onChange?: (e) => void;
  onItemSelected: (active: boolean, item: object) => void; // NOTE: we cannot change this interface because we have to be compatible with checkbox in Autocomplete (e.g. ROHS)
  data?: any[];
  unit: any;
}

class AutoCompleteTagList extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onItemSelectHandler = this.onItemSelectHandler.bind(this);
  }

  state = {
    autoValue: "",
  };

  onChangeHandler(e) {
    this.setState({ autoValue: e });
  }

  onItemSelectHandler(e, active = false) {
    this.setState({ autoValue: "" });
    this.props.onItemSelected(active, { id: e });
  }

  getSuggestions() {
    if (!this.props.suggestions) return [];

    const inputValue = this.state.autoValue.trim().toLowerCase();
    const inputLength = inputValue.length;

    /* gvo: added additional checked condition/filter */
    // hide/filter checked options
    // if filter not active yet => hide/fitler options with count 0
    if (!this.props.filterIsActive) {
      return inputLength === 0
        ? this.props.suggestions.filter(opt => opt.checked === false).filter(act => act.count > 0)
        : this.props.suggestions
            .filter(lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue)
            .filter(opt => opt.checked === false)
            .filter(act => act.count > 0);
    } else {
      return inputLength === 0
        ? this.props.suggestions.filter(opt => opt.checked === false)
        : this.props.suggestions
            .filter(lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue)
            .filter(opt => opt.checked === false);
    }
  }

  render() {
    var parentId = "";
    if (typeof this.props.id !== "undefined") {
      parentId = this.props.id;
    }
    return (
      <>
        <AutoCompleteDropDown
          value={this.state.autoValue}
          placeholder={this.props.placeHolder}
          suggestions={this.getSuggestions()}
          onChange={this.onChangeHandler}
          onItemSelected={this.onItemSelectHandler}
        />
        <TagListRemoveAble
          parent={parentId}
          data={this.props.suggestions}
          search={this.props.search}
          unit={this.props.unit}
          onRemove={this.onItemSelectHandler}
        />
      </>
    );
  }

  getItemRenderer(type: PanelItemRenderTypes) {
    switch (type) {
      case PanelItemRenderTypes.AUTOCOMPLETE:
        return AutoCompleteTagList;
      case PanelItemRenderTypes.LIST:
        return SelectBox;
      case PanelItemRenderTypes.SLIDER:
        return RangeSlider;
    }
    return CheckBox;
  }
}

export default AutoCompleteTagList;
