import React, { PureComponent } from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { Link } from "react-router-dom";
import { ConsentValues, CookieTypes, StorageKeys } from "../../common/constants/Constants";
import { WHITE_SPACE } from "../../common/utils/constants";
import CheckBoxCookieBanner from "./CheckBoxCookieBanner";
import { cookieBannerOptionYesNonCH } from "../scripts/cookieBannerOptionYesNonCH";

interface Props {
  isAccepted: ConsentValues | null;
}

interface State {
  isMarketingCookiesAccepted: boolean;
  isAnalyticCookiesAccepted: boolean;
}



class CookieBannerNonCH extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const marketingCookies = localStorage.getItem(StorageKeys.marketingCookies) === ConsentValues.Y;
    const analyticCookies = localStorage.getItem(StorageKeys.analyticsCookies) === ConsentValues.Y;

    this.state = {
      isMarketingCookiesAccepted: marketingCookies,
      isAnalyticCookiesAccepted: analyticCookies,
    };
  }

  componentDidMount() {
    localStorage.setItem(StorageKeys.essentialCookies, ConsentValues.Y);
  }

  handleCookieChange = (cookieType: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isAccepted = e.target.checked;
    const stateKey = cookieType === CookieTypes.MARKETING ? 'isMarketingCookiesAccepted' : 'isAnalyticCookiesAccepted';
    const storageKey = cookieType === CookieTypes.MARKETING ? StorageKeys.marketingCookies : StorageKeys.analyticsCookies;

    this.setState(prevState => ({
      ...prevState,
      [stateKey]: isAccepted
    }) as Pick<State, keyof State>);
    localStorage.setItem(storageKey, isAccepted ? ConsentValues.Y : ConsentValues.N);
  }

  render() {
    if (this.props.isAccepted !== null) {
      return null;
    }


    return (
      <div className="cookie__banner">
        <div className="cookie-banner__title">{dic("COOKIE CONSENT")}</div>
        <div className="cookie-banner__text">
          {dic("COOKIEBANNER TEXT NON SWITZERLAND")} {WHITE_SPACE}
          <Link
            className="cookie-banner__link"
            to={`/cms/${activeLang()}/${dic("CMSURL_DATENSCHUTZ")}`}
          >
            {dic("DATENSCHUTZERKLAERUNG")}
          </Link>
          <p>{dic("COOKIE SUBTEXT")}</p>
          {[
            { type: CookieTypes.ESSENTIAL, disabled: true, checked: true },
            { type: CookieTypes.MARKETING, disabled: false, checked: this.state.isMarketingCookiesAccepted },
            { type: CookieTypes.ANALYTIC_AND_STATISTIC, disabled: false, checked: this.state.isAnalyticCookiesAccepted }
          ].map(({ type, disabled, checked }) => (
            <CheckBoxCookieBanner
              key={type}
              className="checkbox__box"
              disabled={disabled}
              name={type}
              onChange={this.handleCookieChange(type)}
              id={type}
              checked={checked}
              label={dic(`${type.toUpperCase()} COOKIES`)}
            />
          ))}
        </div>
        {/*class gtm-cookie-banner is used to identify the button for triggering a gtm event*/}
        <button className="btn gtm-cookie-banner" onClick={() => cookieBannerOptionYesNonCH()}>{dic("APPLY SETTINGS")}</button>
      </div>
    )
  }
}

export default CookieBannerNonCH;
