import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ManageUsersData } from "../../State";
import AddressCard from "../../common/components/input/AddressCard";
import UserDataTable from "./UsersDataTable";
import UserDeletion from "./UserDeletion";

interface Props {
  manageUsersData: ManageUsersData[];
}

class UsersData extends React.PureComponent<Props> {
  render() {
    return (
      <>
        {this.props.manageUsersData.map((user: ManageUsersData, i) => {
          return (
            <React.Fragment key={i}>
              <div className="content__2col">
                <div className="col">
                  <div className="checkout-product-list checkout-product-list--ordered">
                    <h2 className="subtitle">{`${dic("BENUTZER")}: ${user.email}`}</h2>
                    <UserDeletion email={user.email} />
                    <UserDataTable user={user} />
                  </div>
                </div>
                <div className="col">
                  <h2 className="subtitle">{dic("ADRESSEN")}</h2>
                  {user.adr.map((adr, j) => {
                    return (
                      <AddressCard
                        key={`address_card_${i}_${j}`}
                        address={adr}
                        isEditable={false}
                        isDisablable={false}
                        displayInr={true}
                        styleEditable={true}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="form-submit__wrapper shortened" />
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

export default UsersData;
