import * as React from "react";
import Manufacture from "../components/Manufacture";
import { connect } from "react-redux";
import { ManuFactureState, SearchState, State, TypeState, UserState } from "../../State";
import Type from "../components/Type";
import { CatalogActions } from "../actions/catalogActions";
import SearchBox from "../../common/components/input/SearchBox";
import ProductList from "../components/ProductList";
import ProductViewSettingsBox from "../components/ProductViewSettingsBox";
import { LayoutActions } from "../../layout/actions/layoutActions";
import ManufactureDetail from "../components/ManufactureDetail";
import TypeDetail from "../components/TypeDetail";
import { CatalogAlgoliaActions } from "../actions/algoliaActions";
import ProductVo from "../../common/vo/ProductVo";
import { ProductActions } from "../../product/actions/productActions";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ListActions } from "../../list/actions/listActions";
import MoreButton from "../../common/components/button/MoreButton";
import { SearchActions } from "../../search/actions/searchActions";
import { ProductTableColumnVo } from "../../common/components/table/ProductTable";
import { RouteComponentProps } from "react-router-dom";
import {createGroupUrl, createImgUrl, createURLTranslation} from "../../common/utils/createUrls";
import { routerActions } from "connected-react-router";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import Helmet from "react-helmet";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import {
  titleMetaStringPrefixForAnyFilter,
  titleStringMainForAnyFilter,
  titleStringPostfixForAnyFilter,
  titleStringPrefixForAnyFilter,
} from "../../common/utils/titleStringUtils";
import SMTags from "../../common/components/metatags/SMTags";

const smImage = config("smImage");

declare var COMPONA_STATIC_CACHE;

interface CatalogStateToProps {
  // Search
  searchState: SearchState;
  user: UserState;
  manufactureState: ManuFactureState;
  typeState: TypeState;
  productListColumns: any[];
  productListColumnsViewSettings: any[];
  productListViewSettingsOpen: boolean;
  productListColumnsAvailable: any[];
  newColAutoValue: string;
  searchOpen: boolean;
}

interface CatalogDispatchToProps {
  // Product
  onClickOpenProductViewSettings: (active) => void;
  onClickProductLink: (product: ProductVo) => void;
  addProduct: (listType, objectID, pos, quantity, comment) => void;
  // Filter
  onDragDropChange: (result) => void;
  onSaveViewSettingsColumns: () => void;
  onRemoveViewSettingsColumn: (e) => void;
  onResetViewSettingsColumns: () => void;
  // New Col
  onNewColAutoChange: (e) => void;
  onNewItemSelected: (e) => void;
  onMount: () => void;

  // Search
  onClickShowResult: () => void;
  onMoreClick: () => void;
  onClickSortLink: (column: ProductTableColumnVo, direction) => void;
}

interface Props extends CatalogStateToProps, CatalogDispatchToProps, RouteComponentProps<any> {}

class Catalog extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.lang && this.props.match.params.lang !== activeLang()) {
      let newUrlParam = "";
      if (window.location.search !== "") {
        newUrlParam = createURLTranslation(
            window.location.search,
            this.props.match.params.lang,
            activeLang(),
        );
      }
      if (this.props.searchState.group && this.props.searchState.group.objectID) {
        const objectID = this.props.searchState.group.objectID;
        const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];
        const groupClassification = staticCacheClassifications.find(
            cls => cls.objectID === objectID,
        );
        if (groupClassification) {
          ElstrCore.store.dispatch(
              routerActions.replace(createGroupUrl(groupClassification, activeLang()) + newUrlParam),
          );
        }
      } else {
        ElstrCore.store.dispatch(routerActions.replace(`/catalog/${activeLang()}/` + newUrlParam));
      }
    }
  }

  render() {
    return (
        <main className="main main--catalog" role="main">
          <Helmet>
            {config("supportedLanguages").map(lang => {
              if (this.props.searchState.group && this.props.searchState.group.objectID) {
                const objectID = this.props.searchState.group.objectID;
                const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[lang];
                const groupClassification = staticCacheClassifications.find(
                    cls => cls.objectID === objectID,
                );
                if (groupClassification) {
                  return (
                      <link
                          key={`link_${lang}`}
                          rel="alternate"
                          hrefLang={lang}
                          href={`${window.location.protocol}//${window.location.host}${createGroupUrl(
                              groupClassification,
                              lang,
                          )}/${window.location.search}`}
                      />
                  );
                }
              } else {
                return (
                    <link
                        key={`link_${lang}`}
                        rel="alternate"
                        hrefLang={lang}
                        href={`${window.location.protocol}//${window.location.host}/catalog/${lang}/${window.location.search}`}
                    />
                );
              }
              return null;
            })}
          </Helmet>

          <header className="main-catalog__header">
            <div className="main-catalog-header__title">{dic("KATALOG")}</div>

            <SearchBox
                search={this.props.searchState}
                showProductSearchResult={false}
                showSuggestionList={this.props.searchOpen}
                onClickShowResult={this.props.onClickShowResult}
            />
          </header>

          <div className="main-catalog__body">{this.renderLayout()}</div>
        </main>
    );
  }

  renderLayout() {
    // url = window.location.protocol + "//" + window.location.host + createGroupUrl(groupClassification, activeLang()) + "/" + window.location.search
    // url = window.location.protocol + "//" + window.location.host + "/catalog/" + activeLang() + "/" + window.location.search
    let url = window.location.protocol + "//" + window.location.host + window.location.pathname;
    let title = dic("TITLE") + " – " + dic("TITLE_HOME_ADDON");
    let descr = dic("META DESCRIPTION");
    const imgixUrl = config("imgixUrl");
    let image =  imgixUrl + "/" + smImage;

    // if a search string or a facet filter is present
    if (
        this.props.searchState &&
        ((this.props.searchState.query && this.props.searchState.query.length > 0) ||
            Object.keys(this.props.searchState.filterSettings).length > 0)
    ) {
      /*
      let titlePostfix = "";
      if (this.props.searchState.query && this.props.searchState.query.length > 0) {
        titlePostfix = ` ${dic("GESUCHT NACH")} ${this.props.searchState.query}`;
      }
       */

      return (
          <>
            <Helmet>
              <title>{`${titleMetaStringPrefixForAnyFilter(this.props.searchState)}${dic(
                  "PRODUKTE",
              )} ${dic("ONLINE KAUFEN")} | ${dic("TITLE")}`}</title>
            </Helmet>
            <SMTags type="website" title={title} description={descr} url={url} image={image} />
            <div className="content">
              {this.renderProductFilterBox(
                  true,
                  true,
                  titleStringPrefixForAnyFilter(this.props.searchState),
                  titleStringMainForAnyFilter(this.props.searchState),
                  titleStringPostfixForAnyFilter(this.props.searchState),
              )}
            </div>
            {this.renderProductTable(true)}
          </>
      );
    }

    // if one group is active (function or manufacturer)
    if (
        this.props.searchState &&
        this.props.searchState.group &&
        this.props.searchState.group.layoutType
    ) {
      switch (this.props.searchState.group.layoutType) {
        case "MANUFACTURE_DETAIL":
          if ( this.props.manufactureState.root.elstrFiles !== undefined ) {
            image = createImgUrl(this.props.manufactureState.root.elstrFiles[0], "400", "400");
          }
          return (
              <>
                <SMTags type="website" title={this.props.manufactureState.root.descShort} description={this.props.manufactureState.root.descLong} url={url} image={image} />
                <div className="content">
                  {this.renderManufactureDetail()}
                  {this.renderManufactureList()}
                  {this.renderProductFilterBox()}
                </div>
                {this.renderProductTable(true)}
              </>
          );
        case "FUNCTION_DETAIL":
          if ( this.props.typeState.current.img !== undefined ) {
            image = this.props.typeState.current.img;
          }
          return (
              <>
                <SMTags type="website" title={this.props.typeState.current.descShort} description={this.props.typeState.current.descLong} url={url} image={image} />
                <div className="content">
                  {this.renderTypeDetail()}
                  {this.renderTypeList()}
                  {this.renderProductFilterBox()}
                </div>
                {this.renderProductTable(true)}
              </>
          );
        case "DETAIL":
          return (
              <>
                <SMTags type="website" title={title} description={descr} url={url} image={image} />
                {this.renderTypeDetail()}
                <div className="content">{this.renderProductFilterBox()}</div>
                {this.renderProductTable(true)}
              </>
          );
      }
      if ( this.props.typeState.current.img !== undefined ) {
        image = this.props.typeState.current.img;
      }
      return (
          <>
            <SMTags type="website" title={title} description={descr} url={url} image={image} />
            <div className="content">
              {this.renderTypeList(true)}
              {this.renderManufactureList(true)}
              {this.renderProductFilterBox(true)}
            </div>
            {this.renderProductTable(true)}
          </>
      );
    }

    let showHerstellerList = true;
    let showFunktionList = true;
    if (this.props.searchState.trees.hersteller instanceof Object) {
      if (Object.keys(this.props.searchState.trees.hersteller).length > 1) {
        showHerstellerList = false;
        showFunktionList = false;
      }
    }
    if (this.props.searchState.trees.funktion instanceof Object) {
      if (Object.keys(this.props.searchState.trees.funktion).length > 1) {
        showFunktionList = false;
        showHerstellerList = false;
      }
    }

    if (
        this.props.searchState.trees.hersteller instanceof Object &&
        this.props.searchState.trees.funktion instanceof Object
    ) {
      if (
          Object.keys(this.props.searchState.trees.hersteller).length > 0 &&
          Object.keys(this.props.searchState.trees.funktion).length > 0
      ) {
        showHerstellerList = false;
        showFunktionList = false;
      }
    }

    if (this.props.searchState && Object.keys(this.props.searchState.trees).length > 0) {
      // if more than one tree filter is present
      // TODO: u.U. kompliziert mit allen aktiven filtern in title
      return (
          <>
            <SMTags type="website" title={title} description={descr} url={url} image={image} />
            <div className="content">
              {this.renderManufactureList(showHerstellerList)}
              {this.renderTypeList(showFunktionList)}
              {this.renderProductFilterBox(true)}
            </div>
            {this.renderProductTable(true)}
          </>
      );
    } else {
      // if there is no filter at all - do not show products result
      if (this.props.match.params.showAll === "m") {
        showFunktionList = false;
      } else if (this.props.match.params.showAll === "f") {
        showHerstellerList = false;
      }
      return (
          <>
            <SMTags type="website" title={title} description={descr} url={url} image={image} />
            <div className="content">
              {this.renderManufactureList(showHerstellerList, "filterteaser--producer")}
              {this.renderTypeList(showFunktionList)}
            </div>
          </>
      );
    }
  }

  renderManufactureDetail(visible = true) {
    if (!visible) return null;

    return (
        <ManufactureDetail
            root={this.props.manufactureState.root}
            current={this.props.manufactureState.current}
            searchState={this.props.searchState}
        />
    );
  }

  renderManufactureList(visible = true, cls = "filterteaser--product") {
    if (!visible) return null;
    if (Object.keys(this.props.manufactureState.data).length === 0) return null;

    let showAllItems = false;
    let showLessLink = true;
    if (this.props.match.params.showAll === "m") {
      showAllItems = true;
    }

    let listTitle = dic("HERSTELLER");
    if (this.props.manufactureState.root instanceof Object) {
      if (this.props.manufactureState.root.hasOwnProperty("objectID")) {
        listTitle = dic("HERSTELLERSERIE");
        showAllItems = true;
        showLessLink = false;
      }
    }
    return this.props.manufactureState.show ? (
        <Manufacture
            title={listTitle}
            data={this.props.manufactureState.data}
            showAllItems={showAllItems}
            showLessLink={showLessLink}
            cls={cls}
        />
    ) : (
        undefined
    );
  }

  renderTypeDetail(visible = true) {
    if (!visible) return null;

    return (
        <TypeDetail current={this.props.typeState.current} searchState={this.props.searchState} />
    );
  }

  renderTypeList(visible = true) {
    if (!visible) return null;
    if (Object.keys(this.props.typeState.data).length === 0) return null;
    let showAllItems = false;
    let showLessLink = true;
    if (this.props.match.params.showAll === "f") {
      showAllItems = true;
    }
    if (this.props.typeState.root instanceof Object) {
      if (this.props.typeState.root.hasOwnProperty("objectID")) {
        showAllItems = true;
        showLessLink = false;
      }
    }

    return this.props.typeState.show ? (
        <Type
            data={this.props.typeState.data}
            showAllItems={showAllItems}
            showLessLink={showLessLink}
        />
    ) : (
        undefined
    );
  }

  renderProductFilterBox(
      visible = true,
      titleIsH1 = false,
      titlePrefix = "",
      titleMain = dic("PRODUKTE"),
      titlePostfix = "",
  ) {
    if (!visible) return null;

    return (
        <ProductViewSettingsBox
            data={this.props.productListColumnsViewSettings}
            titlePrefix={titlePrefix}
            titleMain={titleMain}
            titlePostfix={titlePostfix}
            titleIsH1={titleIsH1}
            open={this.props.productListViewSettingsOpen}
            onOpenClick={this.props.onClickOpenProductViewSettings}
            onDragChange={this.props.onDragDropChange}
            onSaveClick={this.props.onSaveViewSettingsColumns}
            onItemRemoveClick={this.props.onRemoveViewSettingsColumn}
            onResetClick={this.props.onResetViewSettingsColumns}
            onNewColAutoChange={this.props.onNewColAutoChange}
            onNewItemSelected={this.props.onNewItemSelected}
            newColAutoPlaceholder={dic("SPALTEN HINZUFUEGEN")}
            productCount={this.props.searchState.searchCount}
            productListColumnsAvailable={this.props.productListColumnsAvailable}
            newColAutoValue={this.props.newColAutoValue}
        />
    );
  }

  renderProductTable(visible = true) {
    if (!visible) return null;

    return (
        <>
          <ProductList
              key={"productTable"}
              columns={this.props.productListColumns}
              data={this.props.searchState.result}
              sorting={this.props.searchState.sorting}
              user={this.props.user}
              onClickProductLink={this.props.onClickProductLink}
              addProduct={this.props.addProduct}
              onClickSortLink={this.props.onClickSortLink}
          />
          {this.renderGreyBox()}
        </>
    );
  }

  renderGreyBox() {
    if (
        this.props.searchState.searchCount === this.props.searchState.result.length ||
        this.props.searchState.result.length > 998
    ) {
      return undefined;
    }

    return (
        <div className="grey-box">
          <MoreButton onClick={this.props.onMoreClick} />
        </div>
    );
  }
}

const mapStateToProps = (state: State): CatalogStateToProps => ({
  searchState: state.search,
  user: state.user,
  manufactureState: state.catalog.manufacture,
  typeState: state.catalog.type,
  productListColumns: state.catalog.productList.columns,
  productListColumnsViewSettings: state.catalog.productList.columnsViewSettings,
  productListViewSettingsOpen: state.catalog.productList.viewSettingsOpen,
  productListColumnsAvailable: state.catalog.productList.columnsAvaible,
  newColAutoValue: state.catalog.productList.newAutoValue,
  searchOpen: state.layout.searchOpen,
});

const mapDispatchToProps = (dispatch): CatalogDispatchToProps => ({
  onMount: () => {
    dispatch(CatalogAlgoliaActions.getManufacturersAndFunctions());
    dispatch(LayoutActions.showFilterPanel(true));
    dispatch(LayoutActions.showHomeHeader(false));
  },
  onClickOpenProductViewSettings: active =>
      dispatch(CatalogActions.toggleProductViewSettingsBox(active)),
  onDragDropChange: result => dispatch(CatalogActions.changeDragAndDropData(result)),
  onSaveViewSettingsColumns: () => dispatch(CatalogActions.saveViewSettingsColumns()),
  onResetViewSettingsColumns: () => dispatch(CatalogActions.resetViewSettingsColumns()),
  onRemoveViewSettingsColumn: e => dispatch(CatalogActions.removeViewSettingsColumn(e)),
  onNewColAutoChange: e => dispatch(CatalogActions.newColAutoChange(e)),
  onNewItemSelected: e => dispatch(CatalogActions.newColAutoSelected(e)),
  onClickProductLink: product => dispatch(ProductActions.setInitialData(product)),
  addProduct: (listType, objectID, pos, quantity, comment) =>
      dispatch(ListActions.addProduct(listType, objectID, pos, quantity, comment)),
  onClickShowResult: () => dispatch(CatalogActions.showResultClicked()),
  onMoreClick: () => dispatch(SearchActions.setResultListItems(1000)),
  onClickSortLink: (column, direction) => dispatch(SearchActions.setSorting(column, direction)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Catalog);
