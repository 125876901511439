import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ManageActions } from "../actions/manageActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";

interface Props {
  email: string;
}

class UserDeletion extends React.Component<Props> {
  deleteUser = () => {
    if (window.confirm(`${dic("BENUTZER LOESCHEN")}? ${this.props.email}`)) {
      ElstrCore.store.dispatch(ManageActions.deleteUserByEmail(this.props.email));
    }
  };

  render() {
    return (
      <button
        className="btn input--reset no-margin"
        title="BENUTZER LOESCHEN"
        onClick={this.deleteUser}
      >
        {dic("BENUTZER LOESCHEN")}
      </button>
    );
  }
}

export default UserDeletion;
