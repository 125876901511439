import * as React from "react";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { UserActions } from "../../../user/actions/userActions";

interface Props {
  onClose: () => void;
  dstIsoCurrent: string;
  faoLandNotCh: boolean;
}

class CurrencyFlyout extends React.PureComponent<Props> {
  constructor(props) {
    super(props);

    this.changeCurrencyClickHandler = this.changeCurrencyClickHandler.bind(this);
  }

  changeCurrencyClickHandler(e) {
    this.props.onClose();
    const dstIso = e.target.getAttribute("data-currency-value");
    ElstrCore.store.dispatch(UserActions.onChangeDstIsoCurrent(dstIso));
  }

  render() {
    const supportedCurrencies = config("dstIsoAvailable");

    return (
      <div className="currency flyout flyout--currency" aria-hidden="false">
        <ul className="lang-nav" aria-hidden="false">
          {supportedCurrencies.map(dstIso => {
            if(!this.props.faoLandNotCh || (this.props.faoLandNotCh && dstIso.faoLandNotChAllowed)){
              return this.renderCurrencyItem(dstIso);
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    );
  }

  renderCurrencyItem(dstIso) {
    const cls =
      this.props.dstIsoCurrent === dstIso.value
        ? "currency-nav__link link--active"
        : "currency-nav__link";

    return (
      <li key={dstIso.name} className="currency-nav__item">
        <div
          onClick={this.changeCurrencyClickHandler}
          data-currency-value={dstIso.value}
          className={cls}
        >
          {dstIso.name}
        </div>
      </li>
    );
  }
}

export default CurrencyFlyout;
