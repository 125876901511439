import * as React from "react";
import { Link } from "react-router-dom";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { OrderDataVo } from "../../State";
import OrderStatus from "./OrderStatus";

interface Props {
  orders: OrderDataVo[];
  isLoading: boolean;
  showAllIds?: boolean;
}

class OrderList extends React.PureComponent<Props> {
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    if (this.props.isLoading) {
      return <div className="loading" />;
    }

    return (
      <div className="checkout-product-list checkout-product-list--ordered">
        <h2 className="visuallyhidden">{dic("BESTELLUNGEN")}</h2>
        <div className="list__header">
          <div className="list-header__item">
            <span>{dic("BESTELLUNG")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("STATUS")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("DATUM")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("BEZAHLUNG")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("GESAMTPREIS")}</span>
          </div>
        </div>
        {this.props.orders.map((order, index) => {
          return (
            <div className="list__row" key={`oder_${order._id}`}>
              <div className="list-row__item">
                <span className="list__label">{dic("BESTELLUNG")}</span>
                <span className="list__text">
                  <Link to={`/shop/order/${order._id}`} className="text-link">
                    {order.dkoAufNr ? `#${order.dkoAufNr}` : `*${order._id}`}
                  </Link>
                  {this.props.showAllIds && (
                    <>
                      <br />
                      <span>{order.dkoAufNr ? `*${order._id}\u00A0${order._insertUser}` : ``}</span>
                    </>
                  )}
                </span>
              </div>
              <div className="list-row__item">
                <span className="list__label">{dic("STATUS")}</span>
                <span className="list__text">
                  <OrderStatus order={order} />
                </span>
              </div>
              <div className="list-row__item">
                <span className="list__label">{dic("DATUM")}</span>
                <span className="list__text">
                  {new Intl.DateTimeFormat().format(new Date(order.dkoBestellDatum))}
                  {this.props.showAllIds && (
                    <>
                      <br />
                      <span>
                        {new Intl.DateTimeFormat("de", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: false,
                        }).format(new Date(order._insertDate.replace(" ", "T")))}
                      </span>
                    </>
                  )}
                </span>
              </div>
              <div className="list-row__item">
                <span className="list__label">{dic("BEZAHLUNG")}</span>
                <span className="list__text">{dic(`ABLNR ${order.dkoAblnr} INFO`)}</span>
              </div>
              <div className="list-row__item">
                <span className="list__label">{dic("GESAMTPREIS")}</span>
                <span className="list__text">{`${order.dkoIsocode} ${order.totalPrice.toFixed(
                  4,
                )}`}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default OrderList;
