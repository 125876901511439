declare var COMPONA_STATIC_CACHE;

/**
 *
 * @param {string} key
 * @param lang
 * @param string
 * @returns {string}
 */
export function getUnitDescription(key: string, lang: string): string {
  if (key) {
    if (COMPONA_STATIC_CACHE.units[key] && COMPONA_STATIC_CACHE.units[key].description[lang]) {
      return COMPONA_STATIC_CACHE.units[key].description[lang];
    } else {
      return key;
    }
  } else {
    return "";
  }
}

/**
 *
 * @param {string} key
 * @param lang
 * @param string
 * @returns {string}
 */
export function getUnitSymbol(key: string, lang: string): string {
  if (key) {
    if (COMPONA_STATIC_CACHE.units[key] && COMPONA_STATIC_CACHE.units[key].symbol) {
      return COMPONA_STATIC_CACHE.units[key].symbol;
    } else {
      return key;
    }
  } else {
    return "";
  }
}
