import ProductVo from "../vo/ProductVo";
import { UserState } from "../../State";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { getUnitDescription } from "./unitUtils";
import { ListProductDataVo } from "../vo/ListVo";
import { ShippingOptionVo } from "../vo/ShippingOptionVo";
import { UserPriceBaseVo } from "../vo/UserPriceBaseVo";

declare var COMPONA_STATIC_CACHE;

/**
 *
 * @param {ProductVo | ListProductDataVo} data
 * @param {UserState} user
 * @returns {any[]}
 */
export function priceCalculateScaleArray(
  data: ProductVo | ListProductDataVo,
  user: UserState | UserPriceBaseVo,
): any[] {
  let scaleArray = [];
  if (data.verkaufsEinheitNummer && COMPONA_STATIC_CACHE.units[data.verkaufsEinheitNummer]) {
    const verkaufsEinheit: string = getUnitDescription(data.verkaufsEinheitNummer, activeLang());
    if (data.preisgruppen && user.dabKondgrpnr && user.dstIsoCurrent) {
      if (
        data.preisgruppen[user.dabKondgrpnr] &&
        data.preisgruppen[user.dabKondgrpnr][user.dstIsoCurrent]
      ) {
        scaleArray = data.preisgruppen[user.dabKondgrpnr][user.dstIsoCurrent].map(price => {
          return {
            mengeAb: price.a,
            betrag: price.b,
            dstIso: user.dstIsoCurrent,
            verkaufsEinheit,
          };
        });
      }
    }
  }

  return scaleArray;
}

/**
 *
 * @param {number} quantity
 * @param {any[]} scaleArray
 * @returns {any}
 */
export function priceCalculateForQuantity(quantity: number, scaleArray: any[]): any {
  let totalPriceForQuantity = 0;
  let dstIso = "";
  let biggestMengeAb = 0;
  let betrag = 0;

  const scaleArrayLength = scaleArray.length;
  for (let i = 0; i < scaleArrayLength; i++) {
    if (quantity >= scaleArray[i].mengeAb && scaleArray[i].mengeAb > biggestMengeAb) {
      betrag = scaleArray[i].betrag;
      totalPriceForQuantity = betrag * quantity;
      dstIso = scaleArray[i].dstIso;
      biggestMengeAb = scaleArray[i].mengeAb;
    }
  }

  return {
    dstIso,
    betrag,
    totalPriceForQuantity,
  };
}

export function priceCalculateShipping(
  dkoLiefartCd,
  shippingDestination,
  dstIsoCurrent,
  cartSumInclVat,
) {
  if (dkoLiefartCd !== "") {
    const shippingOptions: ShippingOptionVo[] = window.COMPONA_STATIC_CACHE.lieferarten.filter(
      lieferart => {
        if (lieferart.restrictedLaender.length === 0) {
          return true;
        }
        if (lieferart.restrictedLaender.includes(shippingDestination)) {
          return true;
        }
        return false;
      },
    );
    const shippingOption: any = shippingOptions.find(
      shippingOption => shippingOption.liefartCd === dkoLiefartCd,
    );
    if (shippingOption && dstIsoCurrent === "CHF") {
      if (
        shippingOption.hasOwnProperty("freeShippingFrom") &&
        shippingOption.freeShippingFrom.CHF !== null &&
        shippingOption.freeShippingFrom.CHF <= cartSumInclVat
      ) {
        return 0;
      } else {
        return shippingOption.priceCHF;
      }
    } else if (shippingOption && dstIsoCurrent === "EUR") {
      if (
        shippingOption.hasOwnProperty("freeShippingFrom") &&
        shippingOption.freeShippingFrom.EUR !== null &&
        shippingOption.freeShippingFrom.EUR <= cartSumInclVat
      ) {
        return 0;
      } else {
        return shippingOption.priceCHF * parseFloat(config("rateCHF2EUR"));
      }
    } else if (shippingOption && dstIsoCurrent === "USD") {
      if (
        shippingOption.hasOwnProperty("freeShippingFrom") &&
        shippingOption.freeShippingFrom.USD !== null &&
        shippingOption.freeShippingFrom.USD <= cartSumInclVat
      ) {
        return 0;
      } else {
        return shippingOption.priceCHF * parseFloat(config("rateCHF2USD"));
      }
    }
  }
  return 0;
}

export function priceGetVatRate(shippingDestination) {
  const vatRate: number = parseFloat(config("vatRate"));
  const vatLaender: any = config("vatLaender");
  if (shippingDestination && vatLaender.includes(shippingDestination)) {
    return vatRate;
  } else {
    return 0;
  }
}
