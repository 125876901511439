import { ListActionTypes } from "./ActionTypes";
import COMPONA_WidgetServer_JSON_List from "../../common/services/COMPONA_WidgetServer_JSON_List";
import ListVo, { ListProductVo } from "../../common/vo/ListVo";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ListType, SyncType, PopMessageType } from "../../common/constants/Constants";
import { CartState, UserState } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import ProductTakeVo from "../../common/vo/ProductTakeVo";
import { productGetTakeInput } from "../../common/utils/productUtils";
import ElstrBaseApp from "elstr-jslib-4/dist/ElstrBaseApp";

export const ListActions = {
  //
  // Action Callers
  //

  setListDataCart: function(data: any) {
    return { type: ListActionTypes.STORE_LIST_DATA_CART, payload: data };
  },

  setListDataWishlist: function(data: any) {
    return { type: ListActionTypes.STORE_LIST_DATA_WISHLIST, payload: data };
  },

  setListDataComparison: function(data: any) {
    return { type: ListActionTypes.STORE_LIST_DATA_COMPARISON, payload: data };
  },

  setActiveListIdWishlist: function(activeListId: null | string) {
    return { type: ListActionTypes.SET_ACTIVE_LIST_ID_WISHLIST, payload: { activeListId } };
  },

  updateCartFooter: function(user: UserState, cart: CartState) {
    return { type: ListActionTypes.UPDATE_CART_FOOTER, payload: { user, cart } };
  },

  updateCommentCartItem: function(comment: string, index: number) {
    return { type: ListActionTypes.UPDATE_COMMENT_CART_ITEM, payload: { comment, index } };
  },

  updateCommentWishlistItem: function(listId: string, comment: string, index: number) {
    return {
      type: ListActionTypes.UPDATE_COMMENT_WISHLIST_ITEM,
      payload: { listId, comment, index },
    };
  },

  updateQuantityCartItemClick: function(direction: string, index: number) {
    return {
      type: ListActionTypes.TAKE_COUNT_CLICK_CHANGE_CART_ITEM,
      payload: { direction, index },
    };
  },

  updateQuantityWishlistItemClick: function(listId: string, direction: string, index: number) {
    return {
      type: ListActionTypes.TAKE_COUNT_CLICK_CHANGE_WISHLIST_ITEM,
      payload: { listId, direction, index },
    };
  },

  updateQuantityCartItemInput: function(quantity: number, index: number) {
    return {
      type: ListActionTypes.TAKE_COUNT_INPUT_CHANGE_CART_ITEM,
      payload: { quantity, index },
    };
  },

  updateQuantityWishlistItemInput: function(listId: string, quantity: number, index: number) {
    return {
      type: ListActionTypes.TAKE_COUNT_INPUT_CHANGE_WISHLIST_ITEM,
      payload: { listId, quantity, index },
    };
  },

  deleteListById: function(listId: string) {
    return { type: ListActionTypes.DELETE_LIST_BY_ID, payload: { listId } };
  },

  resetCart: function(initState) {
    return { type: ListActionTypes.RESET_CART, payload: initState };
  },

  resetWishlist: function(initState) {
    return { type: ListActionTypes.RESET_WISHLIST, payload: initState };
  },

  resetComparisonList: function(initState) {
    return { type: ListActionTypes.RESET_COMPARISONLIST, payload: initState };
  },

  //
  // Action Logic
  //

  /**
   * Function will be called by Event Listener in the Layout.tsx
   * It will store the Data that another Tab has fetched to this Browser Tab
   * @return {(dispatch, getState) => Promise<void>}
   */
  updateListDataFromOtherTab: function() {
    return async (dispatch, getState) => {
      // Only do something when user hasInitLists, so while initializing the user the Stores
      // don't get flooded with updates
      if (getState().user.hasInitLists) {
        const listData = JSON.parse(
          String(localStorage.getItem(SyncType.updateListDataFromOtherTab)),
        );
        ListActions.storeList(dispatch, getState, listData);
      }
    };
  },

  /**
   * Stores the listData to the redux store aswell adds the list._id to the localStorage when anonymous
   * Informs other Browser Tabs by setting the listData to the localStorage, that new listData is available
   * @param dispatch
   * @param getState
   * @param {ListVo} listData
   */
  storeList: function(dispatch, getState, listData: ListVo) {
    const user: UserState = getState().user;
    const cart: CartState = getState().cart;

    // we only want to set the _id of the list if the user isn't auth
    const isAuth = user.isAuth;
    if (!listData) {
      return;
    }

    if (listData.type === ListType.cart) {
      dispatch(ListActions.setListDataCart(listData));
      dispatch(ListActions.updateCartFooter(user, cart));
      if (!isAuth) ElstrBaseApp.ELS.replaceValue(ListType.cart, listData._id);
    }

    if (listData.type === ListType.wishlist) {
      dispatch(ListActions.setListDataWishlist(listData));
      if (!isAuth) {
        ElstrBaseApp.ELS.setValueToArrayIfNotExist(ListType.wishlist, listData._id || null);
      }
    }

    if (listData.type === ListType.comparison) {
      dispatch(ListActions.setListDataComparison(listData));
      if (!isAuth) {
        ElstrBaseApp.ELS.setValueToArrayIfNotExist(ListType.comparison, listData._id || null);
      }
    }

    // Store the Data for other Browser Tabs to sync
    localStorage.setItem(SyncType.updateListDataFromOtherTab, JSON.stringify(listData));
  },

  /**
   * Handles the assigning of anonymous created data to the currently authenticated user
   * @return {(dispatch) => Promise<void>}
   */
  setCurrentUserAsListOwner: function() {
    return async dispatch => {
      // Get values from ELS
      const cartId: string | null = ElstrBaseApp.ELS.getValue(ListType.cart);
      const wishlistIds: string[] | null = ElstrBaseApp.ELS.getValue(ListType.wishlist);
      const comparisonIds: string[] | null = ElstrBaseApp.ELS.getValue(ListType.comparison);

      // add to listsToSet
      let listsToSet: string[] = [];
      if (cartId && cartId !== null) {
        listsToSet.push(cartId);
      }
      if (wishlistIds && wishlistIds !== null) {
        listsToSet = listsToSet.concat(wishlistIds);
      }
      if (comparisonIds && comparisonIds !== null) {
        listsToSet = listsToSet.concat(comparisonIds);
      }

      // setCurrentUserAsListOwner
      if (listsToSet.length > 0) {
        await COMPONA_WidgetServer_JSON_List.setCurrentUserAsListOwner(listsToSet);
      }

      // Clear cart
      ElstrBaseApp.ELS.replaceValue(ListType.cart, null);
      // Clear wishlist
      ElstrBaseApp.ELS.replaceValue(ListType.wishlist, null);
      // Clear comparison
      ElstrBaseApp.ELS.replaceValue(ListType.comparison, null);
    };
  },

  /**
   * List init for Logged in User
   * In the current situation this must be a logged where the username = email
   * @return {(dispatch) => Promise<void>}
   */
  initUserLists: function() {
    return async (dispatch, getState) => {
      // Handling for logged in user
      const email = getState().user.username;
      const response = await COMPONA_WidgetServer_JSON_List.getLists(email);
      response.data.forEach(listData => {
        ListActions.storeList(dispatch, getState, listData);
      });
    };
  },

  /**
   * Gets a list by listId and sets it in the store
   * @param dispatch
   * @param getState
   * @param listId
   * @return {Promise<void>}
   */
  getAndSetList: async function(dispatch, getState, listId) {
    const response = await COMPONA_WidgetServer_JSON_List.getListData(listId);
    ListActions.storeList(dispatch, getState, response.data);
  },

  /**
   * List init for Anon User
   * @return {(dispatch) => Promise<void>}
   */
  initAnonLists: function() {
    return async (dispatch, getState) => {
      // Handling for anonymous user

      // Get values from ELS
      const cartId: string | null = ElstrBaseApp.ELS.getValue(ListType.cart);
      const wishlistIds: string[] | null = ElstrBaseApp.ELS.getValue(ListType.wishlist);
      const comparisonIds: string[] | null = ElstrBaseApp.ELS.getValue(ListType.comparison);

      // dispatch getList and store for cartId
      if (cartId && cartId !== null) {
        ListActions.getAndSetList(dispatch, getState, cartId);
      }

      // dispatch getList and store foreach wishlistIds
      if (wishlistIds && wishlistIds !== null) {
        for (const listId of wishlistIds) {
          ListActions.getAndSetList(dispatch, getState, listId);
        }
      }

      // dispatch getList and store foreach comparisonIds
      if (comparisonIds && comparisonIds !== null) {
        for (const listId of comparisonIds) {
          ListActions.getAndSetList(dispatch, getState, listId);
        }
      }
    };
  },

  /**
   * Return the right listId for cart
   * Handles all possible cases: "newTab", "anonymous user" etc.
   *
   * Basically this is what happens here:
   * getListId from Redux Store
   * getListId from LocalStorage
   * if neither is present, we create first the list in the BE and return that listId
   * @param dispatch
   * @param getState
   * @return {Promise<string>}
   */
  getListIdForCart: async function(dispatch, getState) {
    // Check from Redux Store
    if (getState().cart._id) {
      return getState().cart._id;
    } else {
      // Check localStorage:
      const cartId: string = ElstrBaseApp.ELS.getValue(ListType.cart);
      // Check for validation
      if (cartId !== null && cartId !== "") {
        return cartId;
      } else {
        // We neither have a listId in the Redux Store
        // nor in the localStorage
        // therefore we need to create a new list first and assign that to the id
        await dispatch(ListActions.createList("", ListType.cart));
        return getState().cart._id;
      }
    }
  },

  /**
   * Return the right listId for Wishlist
   * Handles all possible cases: "newTab", "anonymous user" etc.
   *
   * Basically this is what happens here:
   * getListId from Redux Store
   * getListId from LocalStorage
   * if neither is present, we create first the list in the BE and return that listId
   * @param dispatch
   * @param getState
   * @return {Promise<string>}
   */
  getListIdForWishlist: async function(dispatch, getState) {
    // INIT VARS
    let lastWishlistIndex = 0;

    // Check from Redux Store activeListId
    const activeListId = getState().wishlist.activeListId;

    // In case there is an activeListId assign this to listId
    if (activeListId) {
      return activeListId;
    } else {
      // Check if Redux Store not is empty
      if (getState().wishlist.lists.length > 0) {
        // we want to add the product to the last wishlist so we get the last index and then the listId
        lastWishlistIndex = getState().wishlist.lists.length - 1;
        // we override activeListId and set it to the store
        const listId = getState().wishlist.lists[lastWishlistIndex]._id;
        dispatch(ListActions.setActiveListIdWishlist(listId));
        return listId;
      } else {
        // Check localStorage:
        const wishlistIds: string[] = ElstrBaseApp.ELS.getValue(ListType.wishlist);
        // Check for validation
        if (wishlistIds !== null && wishlistIds.length > 0) {
          // Get all Lists and Store them
          for (const _id of wishlistIds) {
            await ListActions.getAndSetList(dispatch, getState, _id);
          }

          // we want to add the product to the last wishlist so we get the last index and then the listId
          lastWishlistIndex = wishlistIds.length - 1;
          const listId = wishlistIds[lastWishlistIndex];
          // we override activeListId and set it to the store
          dispatch(ListActions.setActiveListIdWishlist(listId));
          return listId;
        } else {
          // We neither have a listId in the Redux Store
          // nor in the localStorage
          // therefore we need to create a new list first and assign that to the id
          await dispatch(ListActions.createList(dic("NEUE MERKLISTE"), ListType.wishlist));
          return getState().wishlist.lists[0]._id;
        }
      }
    }
  },

  /**
   * Adds a product to the a listId which will be returned by a sub-function
   * @param listType
   * @param {string} objectID
   * @param pos
   * @param quantity
   * @param comment
   * @return {(dispatch) => Promise<void>}
   */
  addProduct: function(
    listType: ListType,
    objectID: string,
    pos: number | null,
    quantity: number,
    comment: string,
  ) {
    return async (dispatch, getState) => {
      // INIT VARS
      let listId = "";

      // Assign the correct listId
      if (listType === ListType.cart) {
        listId = await ListActions.getListIdForCart(dispatch, getState);
      } else if (listType === ListType.wishlist) {
        listId = await ListActions.getListIdForWishlist(dispatch, getState);
      }

      // Start request to add the Product to the DB
      const response = await COMPONA_WidgetServer_JSON_List.addProduct(
        listId,
        objectID,
        pos,
        quantity,
        comment,
      );

      // Store in Redux
      const listData: ListVo = response.data;
      ListActions.storeList(dispatch, getState, listData);

      // Dispatch popMessage
      if (listType === ListType.cart) {
        dispatch(
          createPopMessage(
            PopMessageType.addCart,
            `${objectID}: ${dic("IN DEN WARENKORB GELEGT")}`,
          ),
        );
        dispatch(LayoutActions.openCartIfNotClosedExpicitly());
      } else if (listType === ListType.wishlist) {
        dispatch(
          createPopMessage(
            PopMessageType.addWishlist,
            `${objectID}: ${dic("ZUR MERKLISTE HINZUGEFUEGT")}`,
          ),
        );
      } else if (listType === ListType.comparison) {
        // TODO: add popMessage for comparison
      }
    };
  },

  /**
   * Before adding the Product we get the quantity from the current take state
   * @param {ListType} listType
   * @param {string} objectID
   * @param {number | null} pos
   * @param {string} comment
   * @return {(dispatch, getState) => Promise<void>}
   */
  addProductProductCard: function(
    listType: ListType,
    objectID: string,
    pos: number | null,
    comment: string,
  ) {
    return async (dispatch, getState) => {
      // Fallback 0 for products without take
      const quantity = getState().product.take ? getState().product.take.quantity : 0;
      dispatch(ListActions.addProduct(listType, objectID, pos, quantity, comment));
    };
  },

  /**
   * Adds one single product from a list to the cart, but will check and correct quantity input before
   * @param {ListType} listType
   * @param {string} objectID
   * @param {number | null} pos
   * @param {string} comment
   * @param product
   * @param take
   * @return {(dispatch, getState) => Promise<void>}
   */
  addProductFromListToCart: function(
    listType: ListType,
    objectID: string,
    pos: number | null,
    comment: string,
    product: ListProductVo,
    take: ProductTakeVo,
  ) {
    return async dispatch => {
      // first we calculate the new _take based on the input.
      // _take will also correct the quantity in case of input
      const _take = productGetTakeInput(take, product.data[activeLang()], take.quantityString);

      // assign quantity from _take
      let quantity = product.data[activeLang()].mindestbestellmenge;
      if (_take) quantity = _take.quantity;

      dispatch(ListActions.addProduct(listType, objectID, pos, quantity, comment));
    };
  },

  /**
   * Used for specific updates in a list (quantity, or comment)
   * listId, objectId and pos is to identify the right item in the list
   * quantity and comment will be updated with the new values
   *
   * @param {string} listId
   * @param {string} objectID
   * @param {number} pos
   * @param {number} quantity
   * @param {string} comment
   * @return {(dispatch) => Promise<void>}
   */
  updateProduct: function(
    listId: string,
    objectID: string,
    pos: number,
    quantity: number,
    comment: string,
  ) {
    return async (dispatch, getState) => {
      // Start request to update the Product to the DB
      const response = await COMPONA_WidgetServer_JSON_List.updateProduct(
        listId,
        objectID,
        pos,
        quantity,
        comment,
      );

      // Store in Redux
      const listData: ListVo = response.data;
      ListActions.storeList(dispatch, getState, listData);
    };
  },

  /**
   * Debounce the execution from updateProduct so that fast input changes
   * are not send to the BE which will decrease the traffic and also should prevent that fast changes
   * update the state again.
   * @param {string} listId
   * @param {string} objectID
   * @param {number} pos
   * @param {number} quantity
   * @param {string} comment
   * @return {any}
   */
  updateProductDebounced: function(
    listId: string,
    objectID: string,
    pos: number,
    quantity: number,
    comment: string,
  ) {
    const thunk: any = async dispatch => {
      dispatch(ListActions.updateProduct(listId, objectID, pos, quantity, comment));
    };

    thunk.meta = {
      debounce: {
        time: 1200,
        key: "updateProduct",
      },
    };

    return thunk;
  },

  /**
   * Will update the quantity of an input by clicking plus or minus buttons in the UI
   * Also updates the DB debounced
   * @param {string} direction
   * @param {string} listId
   * @param {string} objectID
   * @param {number} pos
   * @param {string} comment
   * @param {number} index
   * @return {(dispatch, getState) => Promise<void>}
   */
  onClickTakeChangeCart: function(
    direction: string,
    listId: string,
    objectID: string,
    pos: number,
    comment: string,
    index: number,
  ) {
    return async (dispatch, getState) => {
      await dispatch(ListActions.updateQuantityCartItemClick(direction, index));
      const quantity = getState().cart.products[index].quantity;
      dispatch(ListActions.updateProductDebounced(listId, objectID, pos, quantity, comment));
      dispatch(ListActions.updateCartFooter(getState().user, getState().cart));
    };
  },

  /**
   * Will update the quantity of an input by clicking plus or minus buttons in the UI
   * Also updates the DB debounced
   * @param {string} direction
   * @param {string} listId
   * @param {string} objectID
   * @param {number} pos
   * @param {string} comment
   * @param {number} index
   * @return {(dispatch, getState) => Promise<void>}
   */
  onClickTakeChangeWishlist: function(
    direction: string,
    listId: string,
    objectID: string,
    pos: number,
    comment: string,
    index: number,
  ) {
    return async (dispatch, getState) => {
      await dispatch(ListActions.updateQuantityWishlistItemClick(listId, direction, index));

      // find the right list and get the quantity
      const lists = getState().wishlist.lists;
      lists.forEach(list => {
        if (list._id === listId) {
          const quantity = list.products[index].quantity;
          dispatch(ListActions.updateProductDebounced(listId, objectID, pos, quantity, comment));
        }
      });
    };
  },

  /**
   * Will only update the quantity of an input
   * @param {number} quantity
   * @param {string} listId
   * @param {number} index
   * @return {(dispatch, getState) => Promise<void>}
   */
  onInputTakeChangeCart: function(quantity: number, listId: string, index: number) {
    return async (dispatch, getState) => {
      dispatch(ListActions.updateQuantityCartItemInput(quantity, index));
      dispatch(ListActions.updateCartFooter(getState().user, getState().cart));
    };
  },

  /**
   * Will update the quantity of an input according to business rules
   * Displays a PopMessage if the quantity had to be corrected by the App
   * @param product
   * @param take
   * @param {string} listId
   * @param objectID
   * @param pos
   * @param comment
   * @return {(dispatch, getState) => Promise<void>}
   */
  onBlurInputTakeCart: function(
    product: ListProductVo,
    take: ProductTakeVo,
    listId: string,
    objectID: string,
    pos: number,
    comment: string,
  ) {
    return async (dispatch, getState) => {
      // first we calculate the new _take based on the input.
      // _take will also correct the quantity in case of input
      const _take = productGetTakeInput(take, product.data[activeLang()], take.quantityString);

      // assign quantity from _take
      let quantity = product.data[activeLang()].mindestbestellmenge;
      if (_take) {
        quantity = _take.quantity;
        if (_take.msg !== "") {
          dispatch(createPopMessage(PopMessageType.warning, dic(_take.msg)));
        }
      }

      dispatch(ListActions.updateProduct(listId, objectID, pos, quantity, comment));
      dispatch(ListActions.updateCartFooter(getState().user, getState().cart));
    };
  },

  /**
   * Will only update the quantity of an input
   * @param {number} quantity
   * @param {string} listId
   * @param {number} index
   * @return {(dispatch, getState) => Promise<void>}
   */
  onInputTakeChangeWishlist: function(quantity: number, listId: string, index: number) {
    return async dispatch => {
      await dispatch(ListActions.updateQuantityWishlistItemInput(listId, quantity, index));
      // dispatch(updateProductDebounced(listId, objectID, pos, quantity, comment));
    };
  },

  /**
   * Will update the quantity of an input according to business rules
   * Displays a PopMessage if the quantity had to be corrected by the App
   * @param product
   * @param take
   * @param {string} listId
   * @param objectID
   * @param pos
   * @param comment
   * @return {(dispatch, getState) => Promise<void>}
   */
  onBlurInputTakeWishlist: function(
    product: ListProductVo,
    take: ProductTakeVo,
    listId: string,
    objectID: string,
    pos: number,
    comment: string,
  ) {
    return async dispatch => {
      // first we calculate the new _take based on the input.
      // _take will also correct the quantity in case of input
      const _take = productGetTakeInput(take, product.data[activeLang()], take.quantityString);

      // assign quantity from _take
      let quantity = product.data[activeLang()].mindestbestellmenge;
      if (_take) {
        quantity = _take.quantity;
        if (_take.msg !== "") {
          dispatch(createPopMessage(PopMessageType.warning, dic(_take.msg)));
        }
      }

      dispatch(ListActions.updateProduct(listId, objectID, pos, quantity, comment));
    };
  },

  /**
   * Adds products from given list to a cartListId which will be returned by a sub-function
   * @param {string} listId
   * @return {(dispatch, getState) => Promise<void>}
   */
  addProductsFromListToCart: function(listId: string) {
    return async (dispatch, getState) => {
      const cartListId = await ListActions.getListIdForCart(dispatch, getState);

      // Start request to add the products from a list to the cart in the DB
      const response = await COMPONA_WidgetServer_JSON_List.addProductsFromListToCart(
        listId,
        cartListId,
      );

      // Store in Redux
      const listData: ListVo = response.data;
      ListActions.storeList(dispatch, getState, listData);

      // Dispatch popMessage
      dispatch(createPopMessage(PopMessageType.addCart, dic("LISTE IN DEN WARENKORB GELEGT")));
    };
  },

  /**
   * Crates a new list and informs the user
   * Set as activeList if it's a wishlist
   *
   * @param {string} name
   * @param {string} listType
   * @param {number} comment
   * @return {(dispatch) => Promise<void>}
   */
  createList: function(name: string, listType: ListType, comment = "") {
    return async (dispatch, getState) => {
      const response = await COMPONA_WidgetServer_JSON_List.createList(name, listType, comment);
      const listData: ListVo = response.data;
      await ListActions.storeList(dispatch, getState, listData);

      // set new list as the active one
      if (listType === ListType.wishlist) {
        const listId: string = listData._id || "";
        await dispatch(ListActions.setActiveListIdWishlist(listId));
      }

      // decide which text to display in popMessage
      let text = "";
      if (listData.type === ListType.wishlist) {
        text = dic("NEUE MERKLISTE ERSTELLT");
      } else if (listData.type === ListType.comparison) {
        text = dic("NEUE VERGLEICHSLISTE ERSTELLT");
      }

      if (text) dispatch(createPopMessage(PopMessageType.info, text));
    };
  },

  /**
   * Deletes an existing user list
   * Drops the listId from the ELS
   * PopUp a message
   * @param {string} listId
   * @param {string} listType
   * @return {(dispatch) => Promise<void>}
   */
  deleteList: function(listId: string, listType: ListType) {
    return async dispatch => {
      try {
        await COMPONA_WidgetServer_JSON_List.deleteList(listId);
        await dispatch(ListActions.deleteListById(listId));

        // Drop listId from ELS
        ElstrBaseApp.ELS.removeValueFromArray(listType, listId);

        // Update other tabs
        localStorage.setItem(SyncType.deleteListId, listId);

        // Decide which text popUp
        let text = "";
        if (listType === ListType.wishlist) {
          text = dic("MERKLISTE GELÖSCHT");
          // remove this list as the active one
          await dispatch(ListActions.setActiveListIdWishlist(null));
        }
        if (listType === ListType.comparison) {
          text = dic("VERGLEICHSLISTE GELÖSCHT");
          // TODO: probably to setActiveListIdComparelist activeList to null
        }
        await dispatch(createPopMessage(PopMessageType.info, text));
      } catch (e) {
        throw e;
        // dispatch(createPopMessage(PopMessageType.warning, e.message));
      }
    };
  },

  /**
   * Updates name and or comment of a list
   * @param {string} listId
   * @param {string} name
   * @param {string} comment
   * @return {(dispatch) => Promise<void>}
   */
  updateList: function(listId: string, name: string, comment: string) {
    return async (dispatch, getState) => {
      const response = await COMPONA_WidgetServer_JSON_List.updateList(listId, name, comment);

      // Store in Redux
      const listData: ListVo = response.data;
      ListActions.storeList(dispatch, getState, listData);
    };
  },

  /**
   * Removes a product of the given listId
   * @param {string} listId
   * @param {string} objectId
   * @param {number} pos
   * @return {(dispatch) => Promise<void>}
   */
  removeProduct: function(listId: string, objectId: string, pos: number) {
    return async (dispatch, getState) => {
      const response = await COMPONA_WidgetServer_JSON_List.removeProduct(listId, objectId, pos);
      ListActions.storeList(dispatch, getState, response.data);
    };
  },

  /**
   * Removes all products from a list
   * @param {string} listId
   * @return {(dispatch) => Promise<void>}
   */
  removeProductsFromList: function(listId: string) {
    return async (dispatch, getState) => {
      const response = await COMPONA_WidgetServer_JSON_List.removeProductsFromList(listId);
      ListActions.storeList(dispatch, getState, response.data);
    };
  },
};

//
// Following section only contain short function names
//

export function setCurrentUserAsListOwner() {
  return ListActions.setCurrentUserAsListOwner();
}

export function initUserLists() {
  return ListActions.initUserLists();
}

export function resetCart(initState) {
  return ListActions.resetCart(initState);
}

export function resetWishlist(initState) {
  return ListActions.resetWishlist(initState);
}

// TODO: Will be needed in the future
export function resetComparisonList(initState) {
  return ListActions.resetComparisonList(initState);
}
