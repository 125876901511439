import { ManageActionTypes } from "./ActionTypes";
import COMPONA_WidgetServer_JSON_Order from "../../common/services/COMPONA_WidgetServer_JSON_Order";
import COMPONA_WidgetServer_JSON_User from "../../common/services/COMPONA_WidgetServer_JSON_User";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { PopMessageType } from "../../common/constants/Constants";
import { OrderActions } from "../../order/actions/orderActions";

export const ManageActions = {
  setManageOrdersData: function(data) {
    return { type: ManageActionTypes.SET_MANAGE_ORDERS_DATA, payload: { data } };
  },

  setManageUsersData: function(data) {
    return { type: ManageActionTypes.SET_MANAGE_USERS_DATA, payload: { data } };
  },

  getOrdersForManager: function() {
    return async (dispatch, getState) => {
      try {
        dispatch(ManageActions.setManageOrdersData({ isLoading: true }));
        const manageState = getState().manage;
        const mangeOrdersState = manageState.orders;
        const response = await COMPONA_WidgetServer_JSON_Order.getOrdersForManager(
          mangeOrdersState.offset,
          mangeOrdersState.limit,
        );

        if (response instanceof Error) {
          throw response;
        } else {
          await dispatch(ManageActions.setManageOrdersData({ isLoading: false }));
          await dispatch(ManageActions.setManageOrdersData({ data: response.data }));
        }
      } catch (e) {
        dispatch(ManageActions.setManageOrdersData({ isLoading: false }));
        throw e;
      }
    };
  },

  getUsersForManager: function() {
    return async (dispatch, getState) => {
      try {
        dispatch(ManageActions.setManageUsersData({ isLoading: true }));

        const response = await COMPONA_WidgetServer_JSON_User.getUsers();

        if (response instanceof Error) {
          throw response;
        } else {
          await dispatch(ManageActions.setManageUsersData({ isLoading: false }));
          await dispatch(ManageActions.setManageUsersData({ data: response.data }));
        }
      } catch (e) {
        dispatch(ManageActions.setManageOrdersData({ isLoading: false }));
        throw e;
      }
    };
  },

  deleteUserByEmail: function(email: string) {
    return async (dispatch, getState) => {
      try {
        dispatch(ManageActions.setManageUsersData({ isLoading: true }));

        const response = await COMPONA_WidgetServer_JSON_User.deleteUserByEmail(email);

        if (response instanceof Error) {
          throw response;
        } else {
          dispatch(createPopMessage(PopMessageType.info, `${response.data.messages.join("\n")}`));
          dispatch(ManageActions.getUsersForManager());
        }
      } catch (e) {
        dispatch(ManageActions.setManageOrdersData({ isLoading: false }));
        throw e;
      }
    };
  },

  getOrdersForManagerNext: function() {
    return async (dispatch, getState) => {
      const manageState = getState().manage;
      const mangeOrdersState = manageState.orders;
      const offset = mangeOrdersState.offset + mangeOrdersState.limit;
      await dispatch(ManageActions.setManageOrdersData({ offset }));
      await dispatch(ManageActions.getOrdersForManager());
    };
  },

  getOrdersForManagerPrevious: function() {
    return async (dispatch, getState) => {
      const manageState = getState().manage;
      const mangeOrdersState = manageState.orders;
      const offset = mangeOrdersState.offset - mangeOrdersState.limit;
      if (offset >= 0) {
        await dispatch(ManageActions.setManageOrdersData({ offset }));
        await dispatch(ManageActions.getOrdersForManager());
      }
    };
  },

  rescheduleOrderForErpOrderCreation: function(orderId: string) {
    return async (dispatch, getState) => {
      try {
        const response = await COMPONA_WidgetServer_JSON_Order.rescheduleOrderForErpOrderCreation(
          orderId,
        );

        if (response instanceof Error) {
          throw response;
        } else {
          if (response.messages && response.messages.length > 0) {
            dispatch(createPopMessage(PopMessageType.info, response.messages[0].message));
          }
          dispatch(ManageActions.getOrdersForManager());
          dispatch(OrderActions.getOrderData(orderId));
        }
      } catch (e) {
        throw e;
      }
    };
  },
};
