import * as React from "react";
import AutoCompleteDropDown from "../../common/components/input/AutoCompleteDropDown";
import RemoveCircle from "./RemoveCircle";
import SuggestionVo from "../vo/SuggestionVo";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { FilterTreeActions } from "../actions/treePanelActions";
import TreeItemVo from "../vo/TreeItemVo";

interface Props {
  level: number;
  title: string;

  removeAble: boolean;
  canHaveChild: boolean;

  treeItem: TreeItemVo;
}

class FilterTreeItem extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onItemSelect = this.onItemSelect.bind(this);
  }

  state = {
    autoValue: "",
  };

  onChange = e => {
    this.setState({ autoValue: e });
  };

  onItemSelect = id => {
    const suggestions: SuggestionVo[] = this.props.treeItem.suggestions;
    const activeSug: SuggestionVo | undefined = suggestions.find(
      suggestion => suggestion.id === id,
    );
    this.setState({ autoValue: "" });
    if (activeSug) {
      ElstrCore.store.dispatch(FilterTreeActions.setItemValue(id, true));
    }
  };

  onItemRemove = (e, id) => {
    ElstrCore.store.dispatch(FilterTreeActions.setItemValue(id, false));
  };

  getSuggestions(): SuggestionVo[] {
    const allSuggestions: SuggestionVo[] = this.props.treeItem.suggestions;

    const inputValue = this.state.autoValue.trim().toLowerCase();
    const inputLength = inputValue.length;

    // return inputLength === 0 ? [] : this.props.suggestions.filter(lang =>
    return inputLength === 0
      ? allSuggestions.filter(opt => opt.checked === false)
      : allSuggestions
          .filter(lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue)
          .filter(opt => opt.checked === false);
  }

  render() {
    const treeItem = this.props.treeItem;
    const data = treeItem.data;
    let cls = `treeview-${this.props.level}-level__item`;

    //cls += data ? " has-child " : "";
    if (data.length > 0) {
      cls += " has-child ";
    }

    return (
      <li className={cls}>
        {this.renderTitle()}
        {this.renderAutoComplete(treeItem)}
        {this.renderChildsContainer()}
      </li>
    );
  }

  renderChildsContainer() {
    const treeItem = this.props.treeItem;
    const data = treeItem.data;
    if (!data || data.length === 0) return null;

    return (
      <ul
        className={`treeview__${this.props.level + 1}-level`}
        key={`treeview__${this.props.level + 1}-level`}
      >
        {this.renderChilds(data)}
      </ul>
    );
  }

  renderChilds(treeItems: TreeItemVo[]) {
    return treeItems.map((treeItem, index) => {
      //const canHaveChild = (item.data.length > 0);
      const canHaveChild = treeItem.suggestions.length > 0;
      const removeAble = this.props.level > 0;

      const props = {
        title: treeItem.name,
        id: treeItem.id,
        key: `treeview__${this.props.level + 1}-${index}`,
        canHaveChild,
        removeAble,
        level: this.props.level + 1,
        treeItem,
      };

      return <FilterTreeItem {...props} />;
    });
  }

  renderTitle() {
    const treeItem = this.props.treeItem;
    if (this.props.removeAble) {
      return (
        <RemoveCircle label={this.props.title || ""} id={treeItem.id} onClick={this.onItemRemove} />
      );
    }

    return <span className={`treeview-${this.props.level}-level__title`}>{this.props.title}</span>;
  }

  renderAutoComplete(treeItem: TreeItemVo) {
    if (!this.props.canHaveChild) return null;

    const suggestions = this.getSuggestions();

    if (suggestions.length === 0 && this.state.autoValue === "") {
      // NOTE: display an empty AutoComplete if autoValue is not empty, otherwise the user gets stuck
      return null;
    }

    return (
      <AutoCompleteDropDown
        value={this.state.autoValue || ""}
        placeholder={treeItem.placeHolder || ""}
        suggestions={this.getSuggestions() || []}
        onChange={this.onChange}
        onItemSelected={this.onItemSelect}
      />
    );
  }
}

export default FilterTreeItem;
