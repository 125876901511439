import * as React from "react";
import { activeLang, setActiveLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { LayoutActions } from "../../../layout/actions/layoutActions";

interface Props {
  onClose: () => void;
}

class LangFlyout extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.changeLangClickHandler = this.changeLangClickHandler.bind(this);
  }

  changeLangClickHandler(e) {
    this.props.onClose();
    const newActiveLang = e.target.textContent.toLowerCase() ||  e.target.innerText.toLowerCase() ;
    setActiveLang(newActiveLang);
    ElstrCore.store.dispatch(LayoutActions.loadAllFacets(newActiveLang));
  }

  render() {
    const supportedLanguages = config("supportedLanguages");
    return (
      <div className="lang flyout flyout--lang" aria-hidden="false">
        <ul className="lang-nav" aria-hidden="false">
          {supportedLanguages.map(label => {
            return this.renderLangItem(label);
          })}
        </ul>
      </div>
    );
  }

  renderLangItem(label: string) {
    const cls = activeLang() === label ? "lang-nav__link link--active" : "lang-nav__link";

    const upperLabel = label.toUpperCase();
    return (
      <li key={label} className="lang-nav__item">
        <div onClick={this.changeLangClickHandler} className={cls}>
          {upperLabel}
        </div>
      </li>
    );
  }
}

export default LangFlyout;
