import * as React from "react";
import { Link } from "react-router-dom";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { CartState, CheckoutState, UserState } from "../../State";
import AddressCard from "../../common/components/input/AddressCard";
import { getCheckoutAddressFao, getCheckoutAddressLio } from "../../common/utils/checkoutUtils";
import RadioBoxItemsDkoAblnr from "./RadioBoxItemsDkoAblnr";
import RadioBoxItemsDkoLiefartCd from "./RadioBoxItemsDkoLiefartCd";
import { CheckoutActions } from "../actions/checkoutActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import CheckoutProductList from "./CheckoutProductList";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { v1 as uuidv1 } from "uuid";
import { priceCalculateShipping } from "../../common/utils/priceCalculations";
import { config } from "elstr-jslib-4/dist/ElstrConfig";

interface Props {
  user: UserState;
  cart: CartState;
  checkout: CheckoutState;
  shippingOptions: any[];
  shippingDestination: string;
  dkoLiefartCd: string;
  dkoAblnrOptions: any[] | null;
  dkoAblnr: string;
  dkoBestellBez: string;
  dstIsoCurrent: string;
  vatRate: number;
  cartSumInclVat: number;
}

class CheckoutOverview extends React.PureComponent<Props> {
  onOrderInputChange = e => {
    ElstrCore.store.dispatch(CheckoutActions.onOrderInputChange(e.target.name, e.target.value));
  };

  onOrderCheckboxChange = e => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ElstrCore.store.dispatch(CheckoutActions.onOrderInputChange(e.target.name, value));
  };

  onClickEditProducts = e => {
    e.preventDefault();
    ElstrCore.store.dispatch(LayoutActions.openCart(true));
  };

  render() {
    let agbConfirmed = false;
    if (
      this.props.checkout &&
      this.props.checkout.order &&
      this.props.checkout.order.agbConfirmed
    ) {
      agbConfirmed = this.props.checkout.order.agbConfirmed;
    }

    let shippingComment = "";
    if (
      this.props.checkout &&
      this.props.checkout.order &&
      this.props.checkout.order.shippingComment
    ) {
      shippingComment = this.props.checkout.order.shippingComment;
    }

    let accountNumber = "";
    if (
      this.props.checkout &&
      this.props.checkout.order &&
      this.props.checkout.order.accountNumber
    ) {
      accountNumber = this.props.checkout.order.accountNumber;
    }

    let eoriNumber = "";
    if (this.props.checkout && this.props.checkout.order && this.props.checkout.order.eoriNumber) {
      eoriNumber = this.props.checkout.order.eoriNumber;
    }

    const shippingCost = priceCalculateShipping(
      this.props.dkoLiefartCd,
      this.props.shippingDestination,
      this.props.user.dstIsoCurrent,
      this.props.cartSumInclVat,
    );
    const sum = this.props.cart.sum + shippingCost;

    const mwst = sum * (this.props.vatRate / 100);
    const total = sum + mwst;

    const uuid = uuidv1();

    return (
      <>
        <div className="content__2col editable content--step">
          <Link to="/shop/checkout/addresses" className="step__edit" />
          <div className="col">
            <h2 className="subtitle">{dic("LIEFERADRESSE")}</h2>
            <AddressCard
              address={getCheckoutAddressLio(this.props.checkout, this.props.user)}
              isEditable={false}
              isDisablable={false}
            />
            {this.props.checkout.asGuest && this.props.checkout.lioLandNotCh && (
              <p className="form__error-message">{dic("HINWEIS GAST LIEFERLAND SCHWEIZ")}</p>
            )}
            {this.props.cart.sum < parseFloat(config("minCartSumLioLandNotCH")) &&
              this.props.checkout.lioLandNotCh && (
                <p className="form__error-message">
                  {`${dic("HINWEIS MINDESTBESTELLWERT LIEFERLAND NICHT SCHWEIZ")}: ${parseInt(
                    config("minCartSumLioLandNotCH"),
                    10,
                  )} ${this.props.user.dstIsoCurrent}.`}
                </p>
              )}
            {this.props.cart.sum < parseFloat(config("minCartSumLioLandCH")) &&
              !this.props.checkout.lioLandNotCh && (
                <p className="form__error-message">
                  {`${dic("HINWEIS MINDESTBESTELLWERT LIEFERLAND SCHWEIZ")}: ${parseInt(
                    config("minCartSumLioLandCH"),
                    10,
                  )} ${this.props.user.dstIsoCurrent}.`}
                </p>
              )}
          </div>
          <div className="col">
            <h2 className="subtitle">{dic("RECHNUNGSADRESSE")}</h2>
            <AddressCard
              address={getCheckoutAddressFao(this.props.checkout, this.props.user)}
              isEditable={false}
              isDisablable={false}
            />
          </div>
        </div>
        <div className="content__2col editable content--step">
          <Link to="/shop/checkout/options" className="step__edit" />
          <div className="col">
            <h2 className="subtitle">{dic("VERSAND")}</h2>
            <div className="radio-box">
              <RadioBoxItemsDkoLiefartCd
                dkoLiefartCd={this.props.dkoLiefartCd}
                shippingOptions={this.props.shippingOptions}
                shippingDestination={this.props.shippingDestination}
                showOnlyChecked={true}
                dstIsoCurrent={this.props.user.dstIsoCurrent}
                cartSumInclVat={this.props.cartSumInclVat}
              />
            </div>
            {accountNumber.length > 0 && (
              <div className="form-input__wrapper">
                <label className="label" htmlFor={`${uuid}_accountNumber`}>
                  {dic("ACCOUNT NR")}
                </label>
                <input
                  className="input--text"
                  value={accountNumber}
                  type="text"
                  id={`${uuid}_accountNumber`}
                  name="accountNumber"
                  placeholder={`${dic("ACCOUNT NR")}...`}
                  aria-required={false}
                  readOnly={true}
                />
              </div>
            )}
            {eoriNumber.length > 0 && (
              <div className="form-input__wrapper">
                <label className="label" htmlFor={`${uuid}_eoriNumber`}>
                  {dic("EORI NUMMER")}
                </label>
                <input
                  className="input--text"
                  value={eoriNumber}
                  type="text"
                  id={`${uuid}_eoriNumber`}
                  name="eoriNumber"
                  placeholder={`${dic("EORI NUMMER")}...`}
                  aria-required={false}
                  readOnly={true}
                />
              </div>
            )}
          </div>
          <div className="col">
            <h2 className="subtitle">{dic("BEZAHLUNG")}</h2>
            <div className="radio-box">
              <RadioBoxItemsDkoAblnr
                dkoAblnr={this.props.dkoAblnr}
                dkoAblnrOptions={this.props.dkoAblnrOptions}
                showOnlyChecked={true}
              />
            </div>
          </div>
        </div>
        <div className="row editable content--step">
          <Link
            to={`/catalog/${activeLang()}/`}
            className="step__edit"
            onClick={this.onClickEditProducts}
          />
          <h2 className="subtitle">{dic("PRODUKTUEBERSICHT")}</h2>
          <CheckoutProductList user={this.props.user} products={this.props.cart.products} />
        </div>
        <div className="content__2col content--step">
          <div className="col">
            <div className="form-input__wrapper">
              <label className="label" htmlFor="dkoBestellBez">
                {dic("KUNDENEIGENE BESTELLNUMMER")}
              </label>
              <input
                className="input--text"
                value={this.props.dkoBestellBez}
                type="text"
                id="dkoBestellBez"
                name="dkoBestellBez"
                maxLength={30}
                placeholder={`${dic("KUNDENEIGENE BESTELLNUMMER")}...`}
                aria-required={false}
                onChange={this.onOrderInputChange}
              />
            </div>
            <div className="form-input__wrapper">
              <div className="label">{dic("VERSANDKOMMENTAR")}</div>
              <textarea
                rows={5}
                className="textarea"
                placeholder={`${dic("VERSANDKOMMENTAR")}...`}
                value={shippingComment}
                name="shippingComment"
                onChange={this.onOrderInputChange}
              />
            </div>
            <div className="form-input__wrapper form-input__wrapper--checkbox">
              <div className="form-row">
                <div
                  className={`checkbox ${this.props.checkout.formValidationErrors
                    .orderAgbConfirmed && "checkbox--error"}`}
                >
                  <input
                    className="input--checkbox"
                    id="agbConfirmed"
                    name="agbConfirmed"
                    type="checkbox"
                    aria-checked={false}
                    checked={agbConfirmed}
                    onChange={this.onOrderCheckboxChange}
                  />
                  <label htmlFor="agbConfirmed" className="checkbox__label">
                    <span className="checkbox__box" />
                    {dic("ICH HABE DIE")}{" "}
                    <Link to={`/cms/${activeLang()}/${dic("CMSURL_AGB")}`} className="text-link">
                      {dic("AGB")}
                    </Link>{" "}
                    {dic("GELESEN VERSTANDEN UND STIMME ZU")}
                  </label>
                  {this.props.checkout.formValidationErrors.orderAgbConfirmed && (
                    <p className="form__error-message">
                      {dic(this.props.checkout.formValidationErrors.orderAgbConfirmed)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <dl className="cart-footer__list">
              <dt className="cart-footer__title">{dic("SUMME")}</dt>
              <dd className="cart-footer__cont">{`${
                this.props.dstIsoCurrent
              } ${this.props.cart.sum.toFixed(4)}`}</dd>
              <dt className="cart-footer__title">{dic("VERSAND")}</dt>
              <dd className="cart-footer__cont">{`${
                this.props.dstIsoCurrent
              } ${shippingCost.toFixed(4)}`}</dd>
              <dt className="cart-footer__title price-subtotal">
                {dic("ZWISCHENSUMME OHNE MWST")}
              </dt>
              <dd className="cart-footer__cont price-subtotal">{`${
                this.props.dstIsoCurrent
              } ${sum.toFixed(4)}`}</dd>
              <dt className="cart-footer__title">{`${dic("MWST")} ${this.props.vatRate}%`}</dt>
              <dd className="cart-footer__cont">{`${this.props.dstIsoCurrent} ${mwst.toFixed(
                4,
              )}`}</dd>
              <dt className="cart-footer__title price-total">{dic("GESAMTBETRAG")}</dt>
              <dd className="cart-footer__cont price-total">
                {`${this.props.dstIsoCurrent} ${total.toFixed(4)}`}
              </dd>
            </dl>
          </div>
        </div>
      </>
    );
  }
}

export default CheckoutOverview;
