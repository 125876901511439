import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

declare var COMPONA_STATIC_CACHE;

/**
 *
 * @param {string} key
 * @param lang
 * @param string
 * @returns {string}
 */
export function getAttributeDescription(key: string, lang: string): string {
  let description = "";
  if (key) {
    if (
      COMPONA_STATIC_CACHE.attributes[key] &&
      COMPONA_STATIC_CACHE.attributes[key].description &&
      COMPONA_STATIC_CACHE.attributes[key].description[lang]
    ) {
      description = window.COMPONA_STATIC_CACHE.attributes[key].description[lang];
    } else if (
      COMPONA_STATIC_CACHE.attributes[key] &&
      COMPONA_STATIC_CACHE.attributes[key].description
    ) {
      description = window.COMPONA_STATIC_CACHE.attributes[key].description;
    } else {
      description = dic(key.toUpperCase());
    }

    if (COMPONA_STATIC_CACHE.attributes[key] && COMPONA_STATIC_CACHE.attributes[key].isRange) {
      if (description.indexOf(" min") > 0) {
        description = description.substring(0, description.indexOf(" min"));
      }
    }
  }
  return description;
}

/**
 *
 * @param {string} key
 * @param lang
 * @param string
 * @returns {string}
 */
export function getAttributeLongDescription(key: string, lang: string): string {
  if (key) {
    if (
      COMPONA_STATIC_CACHE.attributes[key] &&
      COMPONA_STATIC_CACHE.attributes[key].longDescription &&
      COMPONA_STATIC_CACHE.attributes[key].longDescription[lang]
    ) {
      return COMPONA_STATIC_CACHE.attributes[key].longDescription[lang];
    } else if (
      COMPONA_STATIC_CACHE.attributes[key] &&
      COMPONA_STATIC_CACHE.attributes[key].longDescription
    ) {
      return COMPONA_STATIC_CACHE.attributes[key].longDescription;
    } else {
      return key;
    }
  } else {
    return "";
  }
}
