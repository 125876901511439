import { CmsState } from "../../State";
import { CmsActionTypes } from "../actions/ActionTypes";

const cmsReducer = (state: CmsState, action) => {
  if (!state) return null;

  switch (action.type) {
    case CmsActionTypes.CMS_CONTENT_CHANGED:
      return { ...state, htmlText: action.htmlText, metaJson: action.metaJson };
  }

  return state;
};

export default cmsReducer;
