import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FoldingBoxState, RelatedTabState, SearchState, State, UserState } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { compose } from "redux";
import { ProductActions } from "../actions/productActions";
import ProductCard from "../components/ProductCard";
import ProductSectionList from "../components/ProductSectionList";
import ProductHeaderTags from "../components/ProductHeaderTags";
import ProductRelatedTabList from "../components/ProductRelatedTabList";
import ProductStructuredData from "../components/ProductStructuredData";
import SMTags from "../../common/components/metatags/SMTags";
import ProductVo from "../../common/vo/ProductVo";
import ProductTakeVo from "../../common/vo/ProductTakeVo";
import SearchBox from "../../common/components/input/SearchBox";
import { priceCalculateScaleArray } from "../../common/utils/priceCalculations";
import { ListActions } from "../../list/actions/listActions";
import { HomeSearchActions } from "../../home/actions/homeSearchActions";
import { LinkAlternateVo } from "../../common/vo/LinkAlternateVo";
import {
  productGetDescriptionString,
  productGetTitleString,
} from "../../common/utils/productUtils";
import { ProductTableColumnVo } from "../../common/components/table/ProductTable";
import {createImgUrl, createProductUrl} from "../../common/utils/createUrls";
import { config } from "elstr-jslib-4/dist/ElstrConfig";

interface ProductStateToProps {
  data: ProductVo | null;
  take: ProductTakeVo | null;
  linkAlternates: LinkAlternateVo[] | null;
  user: UserState;
  search: SearchState;
  foldingBoxes: FoldingBoxState[];
  relatedTabActiveIndex: number | null;
  relatedTabActiveIsLoaded: boolean;
  relatedTabs: RelatedTabState[];
  relatedProductsColumns: any[];
  searchOpen: boolean;
  scrollState: number;
}

interface ProductDispatchToProps {
  onMount: () => void;
  onObjectIdChanged: (objectID) => void;
  onTabIdChanged: (objectID, type) => void;
  onClickProductLink: (product: ProductVo) => void;
  onClickFoldingBoxTitle: (attributeSectionId: string) => void;
  onClickTakeChange: (direction: string) => void;
  onInputTakeChange: (quantityString: string) => void;
  addProduct: (listType, objectID, pos, quantity, comment) => void;
  addProductProductCard: (listType, objectID, pos, comment) => void;
  onClickShowResult: () => void;
  onClickSortLink: (column: ProductTableColumnVo, direction) => void;
}

interface Props extends ProductStateToProps, ProductDispatchToProps, RouteComponentProps<any> {
  match: any;
}

class Product extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.onClickTakeChange = this.onClickTakeChange.bind(this);
    this.onInputTakeChange = this.onInputTakeChange.bind(this);
  }

  componentDidMount() {
    this.props.onMount();
    this.props.onObjectIdChanged(this.props.match.params.objectID);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.objectID !== prevProps.match.params.objectID) {
      this.props.onObjectIdChanged(this.props.match.params.objectID);
    }
    if (this.props.match.params.lang && this.props.match.params.lang !== activeLang()) {
      // ElstrCore.store.dispatch(routerActions.replace(createProductUrl(this.props.data)));
    }
  }

  onClickTakeChange(direction) {
    this.props.onClickTakeChange(direction);
  }

  onClickLinkBack(e) {
    window.history.back();
    // this.props.history.goBack();
    e.preventDefault();
  }

  onInputTakeChange(quantityString) {
    this.props.onInputTakeChange(quantityString);
  }

  renderLinkBack() {
    if (this.props.history.length > 1) {
      return (
          <div className="main-catalog-header__title">
            <a href="/" onClick={this.onClickLinkBack} className="link--back">
              {dic("ZURUECK")}
            </a>
          </div>
      );
    }
    return (
        <div className="main-catalog-header__title">
          <Link to={`/catalog/${activeLang()}/`} className="link--back">
            {dic("KATALOG")}
          </Link>
        </div>
    );
  }

  render() {
    // console.log(this.props);
    const imgixUrl = config("imgixUrl");
    const smImage = config("smImage");
    let image =  imgixUrl + "/" + smImage;
    if (this.props.data === null) {
      let url = window.location.protocol + "//" + window.location.host + window.location.pathname;
      let title = dic("TITLE") + " – " + dic("TITLE_HOME_ADDON");
      let descr = dic("META DESCRIPTION");
      return (
          <main className="main main--catalog-detail" role="main">
            <header className="main-catalog__header">
              {this.renderLinkBack()}

              <SearchBox
                  search={this.props.search}
                  showProductSearchResult={this.props.searchOpen}
                  showSuggestionList={this.props.searchOpen}
                  onClickShowResult={this.props.onClickShowResult}
              />
            </header>
            <SMTags type="product" title={title} description={descr} url={url} image={image} />
            <div className="tab-content__item">
              <div className="loading" />
            </div>
          </main>
      );
    } else {
      let productHeaderTitle = this.props.data.ShortDescription;
      if (this.props.data.LongDescription) {
        productHeaderTitle += " " + this.props.data.LongDescription;
      }

      const title = productGetTitleString(this.props.data);
      const description = productGetDescriptionString(this.props.data);

      const priceScaleArray = priceCalculateScaleArray(this.props.data, this.props.user);

      let h1CssClass = "product-header__title";
      if (this.props.scrollState > 0) {
        h1CssClass = "product-header__title sticky";
      }

      // og
      if (this.props.data.elstrImage) {
        image = createImgUrl(this.props.data.elstrImage, "400", "400");
      }
      const url = window.location.protocol + "//" + window.location.host + createProductUrl(this.props.data, activeLang());

      if (this.props.linkAlternates !== null ) {
        return (
            <main className="main main--catalog-detail" role="main">
              <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                {this.props.linkAlternates.map(linkAlternate => {
                  return (
                      <link
                          key={`link_${linkAlternate.hreflang}`}
                          rel="alternate"
                          hrefLang={linkAlternate.hreflang}
                          href={linkAlternate.href}
                      />
                  );
                })}
                {this.props.linkAlternates.length > 0 && (
                    <link
                        key={`xlink_${this.props.linkAlternates[0].hreflang}`}
                        rel="alternate"
                        hrefLang="x-default"
                        href={this.props.linkAlternates[0].href}
                    />
                )}
              </Helmet>

              <SMTags type="product" title={title} description={description} url={url} image={image} />

              <header className="main-catalog__header">
                {this.renderLinkBack()}

                <SearchBox
                    search={this.props.search}
                    showProductSearchResult={this.props.searchOpen}
                    showSuggestionList={this.props.searchOpen}
                    onClickShowResult={this.props.onClickShowResult}
                />
              </header>

              <ProductStructuredData data={this.props.data} priceScaleArray={priceScaleArray} />

              <div className="product__header">
                <ProductHeaderTags data={this.props.data} />
                <h2 className="product-header__product-nr">
                  <dl className="product-nr">
                    <dt className="product-nr__title">
                      {dic("ART NR")}
                      {"\u00A0"}
                    </dt>
                    <dd className="product-nr__cont">
                      {this.props.data.objectID}
                      {"\u00A0"}
                    </dd>
                    <dt className="product-nr__title">
                      {dic("HERSTELLER NR")}
                      {"\u00A0"}
                    </dt>
                    <dd className="product-nr__cont">
                      <span>{this.props.data.hersteller_typennummer_formatiert}</span>
                      {"\u00A0"}
                      {this.props.data.hersteller_art_nr_unformatiert &&
                      this.props.data.hersteller_typennummer_unformatiert &&
                      this.props.data.hersteller_art_nr_unformatiert !==
                      this.props.data.hersteller_typennummer_unformatiert && (
                          <span>
                          {"\u00A0"}
                            {"/"}
                            {"\u00A0"}
                            {this.props.data.hersteller_art_nr_unformatiert}
                        </span>
                      )}
                    </dd>
                  </dl>
                </h2>
                <h1 className={h1CssClass}>{productHeaderTitle}</h1>
              </div>
              <div className="main-product__body" id="productDetailBody">
                <ProductCard
                    data={this.props.data}
                    take={this.props.take}
                    title={title}
                    priceScaleArray={priceScaleArray}
                    onClickTakeChange={this.onClickTakeChange}
                    onInputTakeChange={this.onInputTakeChange}
                    addProductProductCard={this.props.addProductProductCard}
                />

                <div className="content">
                  <h2 className="catalog__subtitle">{dic("TECHNISCHE DATEN")}</h2>
                  <ProductSectionList
                      data={this.props.data}
                      foldingBoxes={this.props.foldingBoxes}
                      onClickFoldingBoxTitle={this.props.onClickFoldingBoxTitle}
                  />
                </div>

                <ProductRelatedTabList
                    data={this.props.data}
                    user={this.props.user}
                    relatedTabActiveIndex={this.props.relatedTabActiveIndex}
                    relatedTabActiveIsLoaded={this.props.relatedTabActiveIsLoaded}
                    relatedTabs={this.props.relatedTabs}
                    onTabIdChanged={this.props.onTabIdChanged}
                    onClickProductLink={this.props.onClickProductLink}
                    relatedProductsColumns={this.props.relatedProductsColumns}
                    addProduct={this.props.addProduct}
                    onClickSortLink={this.props.onClickSortLink}
                    scrollState={this.props.scrollState}
                />
              </div>
            </main>
        );
      } else {
        console.log('this.props.linkAlternates is null');
        return null;
      }
    }
  }
}

const mapStateToProps = (state: State): ProductStateToProps => ({
  data: state.product.data,
  take: state.product.take,
  linkAlternates: state.product.linkAlternates,
  user: state.user,
  search: state.search,
  foldingBoxes: state.product.foldingBoxes,
  relatedTabActiveIndex: state.product.relatedTabActiveIndex,
  relatedTabActiveIsLoaded: state.product.relatedTabActiveIsLoaded,
  relatedTabs: state.product.relatedTabs,
  relatedProductsColumns: state.catalog.productList.columns,
  searchOpen: state.layout.searchOpen,
  scrollState: state.layout.scrollState,
});

const mapDispatchToProps = (dispatch): ProductDispatchToProps => ({
  onMount: () => {
    dispatch(LayoutActions.showFilterPanel(false));
    dispatch(LayoutActions.showHomeHeader(false));
  },
  onObjectIdChanged: objectID => dispatch(ProductActions.getProductData(objectID)),
  onTabIdChanged: (objectID, type) => dispatch(ProductActions.onTabIdChanged(objectID, type)),
  onClickProductLink: product => dispatch(ProductActions.setInitialData(product)),
  onClickFoldingBoxTitle: attributeSectionId =>
      dispatch(ProductActions.onClickFoldingBoxTitle(attributeSectionId)),
  onClickTakeChange: direction => dispatch(ProductActions.onClickTakeChange(direction)),
  onInputTakeChange: quantityString => dispatch(ProductActions.onInputTakeChange(quantityString)),
  addProduct: (listType, objectID, pos, quantity, comment) =>
      dispatch(ListActions.addProduct(listType, objectID, pos, quantity, comment)),
  addProductProductCard: (listType, objectID, pos, comment) =>
      dispatch(ListActions.addProductProductCard(listType, objectID, pos, comment)),
  onClickShowResult: () => dispatch(HomeSearchActions.showResultClicked()),
  onClickSortLink: (column, direction) => dispatch(ProductActions.setSorting(column, direction)),
});

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Product);
