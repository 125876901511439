import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import SearchItem from "./SearchItem";

interface Props {
  data: ProductVo[];
  onClickProductLink: (product: ProductVo) => void;
}

class SearchList extends React.PureComponent<Props> {
  render() {
    let searchListData: ProductVo[];
    if (this.props.data.length > 3) {
      searchListData = this.props.data.slice(0, 3);
    } else {
      searchListData = this.props.data;
    }
    return searchListData.map((item: ProductVo) => {
      return (
        <SearchItem
          data={item}
          key={item.objectID}
          onClickProductLink={this.props.onClickProductLink}
        />
      );
    });
  }
}

export default SearchList;
