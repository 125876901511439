import * as React from "react";
import { Link } from "react-router-dom";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  step: string;
}

class CheckoutSteps extends React.PureComponent<Props> {
  renderSteps(steps) {
    return steps.map((step, index) => {
      const clsStep = "step " + step.status;
      const key = `checkoutStep${step.textId}`;
      if (step.status === "done" && this.props.step !== "ordered") {
        return (
          <li key={key} className={clsStep}>
            <Link to={step.linkTo}>
              <span className="step__cont">
                {index + 1}. <span className="step__text">{dic(step.textId)}</span>
              </span>
            </Link>
          </li>
        );
      } else {
        return (
          <li key={key} className={clsStep}>
            <span className="step__cont">
              {index + 1}. <span className="step__text">{dic(step.textId)}</span>
            </span>
          </li>
        );
      }
    });
  }

  render() {
    const steps: any[] = [
      {
        status: "",
        textId: "ADRESSEN",
        linkTo: "/shop/checkout/addresses",
      },
      {
        status: "",
        textId: "VERSAND UND BEZAHLMOEGLICHKEITEN",
        linkTo: "/shop/checkout/options",
      },
      {
        status: "",
        textId: "BESTELLUEBERSICHT",
        linkTo: "/shop/checkout/overview",
      },
    ];

    if (this.props.step === "addresses") {
      steps[1].status = "todo";
      steps[2].status = "todo";
    }
    if (this.props.step === "options") {
      steps[0].status = "done";
      steps[2].status = "todo";
    }
    if (this.props.step === "overview") {
      steps[0].status = "done";
      steps[1].status = "done";
    }
    if (this.props.step === "ordered") {
      steps[0].status = "done";
      steps[1].status = "done";
      steps[2].status = "done";
    }

    return (
      <nav className="checkout-nav">
        <ul className="steps">{this.renderSteps(steps)}</ul>
      </nav>
    );
  }
}

export default CheckoutSteps;
