import ListVo, { ListProductVo } from "../../common/vo/ListVo";
import { ListActionTypes } from "../../list/actions/ActionTypes";
import { CartState } from "../../State";
import {
  priceCalculateForQuantity,
  priceCalculateScaleArray,
  priceCalculateShipping,
} from "../../common/utils/priceCalculations";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import {
  productGetTakeClick,
  productGetTakeInit,
  productGetTakeInput,
} from "../../common/utils/productUtils";

const cartReducer = (state: ListVo, action) => {
  if (!state) return null;

  switch (action.type) {
    case ListActionTypes.STORE_LIST_DATA_CART: {
      const payload: CartState = action.payload;

      // assign productTake
      payload.products.forEach((product: ListProductVo, i) => {
        if (product.data[activeLang()]) {
          const _product = { ...product };
          _product.take = productGetTakeInit(product.quantity, product.data[activeLang()]);
          const _products = [...payload.products];
          _products[i] = { ..._product };
          payload.products = [..._products];
        }
      });

      return { ...state, ...payload };
    }

    case ListActionTypes.RESET_CART: {
      const payload: CartState = action.payload;
      return { ...state, ...payload };
    }

    case ListActionTypes.UPDATE_COMMENT_CART_ITEM: {
      const index = action.payload.index;
      const comment = action.payload.comment;

      // create copies
      const products = [...state.products];
      const product = { ...products[index] };
      // update comment
      product.comment = comment;
      // assign new
      products[index] = product;

      return { ...state, products };
    }

    case ListActionTypes.TAKE_COUNT_CLICK_CHANGE_CART_ITEM: {
      const direction = action.payload.direction;
      const index = action.payload.index;

      // create copies
      const products = [...state.products];
      const product = { ...products[index] };
      const take = { ...product.take };
      // update take
      product.take = productGetTakeClick(take, product.data[activeLang()], direction);
      // update quantity
      if (product.take) product.quantity = product.take.quantity;

      // assign new
      products[index] = product;

      return { ...state, products };
    }

    case ListActionTypes.TAKE_COUNT_INPUT_CHANGE_CART_ITEM: {
      const quantity = action.payload.quantity;
      const index = action.payload.index;

      // create copies
      const products = [...state.products];
      const product = { ...products[index] };
      const take = { ...product.take };
      // update quantity
      product.quantity = quantity;
      // update take
      product.take = productGetTakeInput(take, product.data[activeLang()], quantity);

      // assign new
      products[index] = product;

      return { ...state, products };
    }

    case ListActionTypes.UPDATE_CART_FOOTER: {
      const user = action.payload.user;
      const cart = action.payload.cart;

      // calculate sum
      const footer = { sum: 0, shipping: 0, total: 0 };
      state.products.forEach((product: ListProductVo) => {
        if (product.take) {
          const priceScaleArray = priceCalculateScaleArray(product.data[activeLang()], user);
          const priceForQuantity: any = priceCalculateForQuantity(
            product.take.quantity,
            priceScaleArray,
          );
          footer.sum += Number(priceForQuantity.totalPriceForQuantity.toFixed(4));
        }
      });

      const cartSumInclVat = footer.sum * (1 + parseFloat(config("vatRate")) / 100);

      // calculate shipment
      footer.shipping = priceCalculateShipping(
        cart.dkoLiefartCd,
        "CH",
        user.dstIsoCurrent,
        cartSumInclVat,
      );

      // calculate total with MwsT
      footer.total = (footer.sum + footer.shipping) * (1 + parseFloat(config("vatRate")) / 100);

      return { ...state, ...footer };
    }
  }

  return state;
};

export default cartReducer;
