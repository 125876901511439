import * as React from "react";

interface Props {
}

class ComponaStructuredData extends React.PureComponent<Props> {
  render() {
      const structuredData: any = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Compona AG",
        "id": "https://www.compona.com/#organization",
        "url": "https://www.compona.com/",
        "sameAs": [
          "https://www.facebook.com/ComponaAG/",
          "https://ch.linkedin.com/company/compona-ag"
        ],
        "contactPoint": [{
          "@type": "ContactPoint",
          "telephone": "+41 44 956 21 21",
          "contactType": "customer service"
        },{
          "@type": "ContactPoint",
          "telephone": "0848 840 100",
          "contactType": "customer service",
          "areaServed": "CH"
        }],
        "logo": "https://www.compona.com/static/media/compona-logo.59786f6e.svg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Undermülistrasse 16",
          "addressLocality": "Fehraltorf",
          "postalCode": "8320",
          "addressCountry": "CH"
        }
      };
      return <script type="application/ld+json">{JSON.stringify(structuredData)}</script>;
  }
}

export default ComponaStructuredData;
