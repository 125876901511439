export interface PanelDataVO {
  id: string;
  title: string;
  collapsible: boolean;
  open: boolean;
  type: PanelItemRenderTypes;
  items: PanelItemVo[];
  value?: any;
  dataType?: any;
  description?: any;
  sortOrderDefault: number;
  unit: string;
  minValue?: any;
  maxValue?: any;
}

export interface PanelItemVo {
  id: string;
  name: string;
  count: number;
  checked: boolean;
}

export enum PanelItemRenderTypes {
  LIST = "LIST",
  SLIDER = "SLIDER",
  CHECKBOX = "CHECKBOX",
  AUTOCOMPLETE = "AUTOCOMPLETE",
}
