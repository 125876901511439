import { AccountState } from "../../State";
import { AccountActionTypes } from "../actions/ActionTypes";
// import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

const accountReducer = (state: AccountState, action) => {
  if (!state) return null;

  switch (action.type) {
    case AccountActionTypes.SET_ACCOUNT_DATA:
      // console.log(action.payload);
      return {
        ...state,
        ...action.payload.data,
      };

    case AccountActionTypes.SET_ACCOUNT_FORMADR_DATAVALUE:
      // console.log(action.payload);
      const accountFormAdr = { ...state.formAdr };
      accountFormAdr[action.payload.name] = action.payload.value;

      return {
        ...state,
        formAdr: accountFormAdr,
      };
  }

  return state;
};

export default accountReducer;
