import * as React from "react";
// import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { UserState } from "../../State";
import ProductVo from "../../common/vo/ProductVo";
import ProductListArticle from "../../common/components/list/ProductListArticle";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ProductActions } from "../../product/actions/productActions";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import ProductTakeVo from "../../common/vo/ProductTakeVo";
import {
  priceCalculateScaleArray,
  priceCalculateForQuantity,
} from "../../common/utils/priceCalculations";
import { ListProductVo } from "../../common/vo/ListVo";
import { getUnitDescription } from "../../common/utils/unitUtils";

interface Props {
  user: UserState;
  products: ListProductVo[];
}

// class OrderProductList is very similar to CheckoutProductList

class CheckoutProductList extends React.PureComponent<Props> {
  onClickProductLink = product => {
    ElstrCore.store.dispatch(ProductActions.setInitialData(product));
  };

  renderProductItems() {
    return this.props.products.map((product, index) => {
      if (product.take) {
        const productData: ProductVo = product.data[activeLang()];
        const productTake: ProductTakeVo = product.take;

        const priceForQuantity: any = priceCalculateForQuantity(
          productTake.quantity,
          priceCalculateScaleArray(productData, this.props.user),
        );

        return (
          <div className="list__row" key={`list_row_${productData.objectID}_${index}`}>
            <div className="list-row__product">
              <ProductListArticle
                data={productData}
                onClickProductLink={this.onClickProductLink}
                showHomepageFokusText={false}
                comment={product.comment}
              />
            </div>
            <div className="list-row__items">
              <div className="list-row__item">
                <span className="list__label">{dic("MENGE")}</span>
                <span className="list__text">{productTake.quantityString}</span>
              </div>

              <div className="list-row__item">
                <span className="list__label">{dic("PRICE")}</span>
                <span className="list__text">
                  {priceForQuantity.dstIso} {priceForQuantity.betrag.toFixed(4)} /{" "}
                  {getUnitDescription(productData.verkaufsEinheitNummer, activeLang())}
                </span>
              </div>
              <div className="list-row__item">
                <span className="list__label">{dic("GESAMTPREIS")}</span>
                <span className="list__text">
                  {priceForQuantity.dstIso} {priceForQuantity.totalPriceForQuantity.toFixed(4)}
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <div className="checkout-product-list">
        <h2 className="visuallyhidden">{dic("PRODUKTE")}</h2>
        <div className="list__header">
          <div className="list-header__product ">
            <span>{dic("PRODUKTE")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("MENGE")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("PRICE")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("GESAMTPREIS")}</span>
          </div>
        </div>
        {this.renderProductItems()}
      </div>
    );
  }
}

export default CheckoutProductList;
