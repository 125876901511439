import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  objectID: string;
  productRelationType: string;
  isActive: boolean;
  onTabIdChanged: (objectID, type) => void;
}

class ProductRelatedTabListTab extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onTabIdChangeHandler = this.onTabIdChangeHandler.bind(this);
  }

  onTabIdChangeHandler() {
    this.props.onTabIdChanged(this.props.objectID, this.props.productRelationType);
  }

  render() {
    let cls = "tab__item";
    if (this.props.isActive) {
      cls += " active";
    }
    return (
      <li className={cls} onClick={this.onTabIdChangeHandler}>
        {dic(this.props.productRelationType.toUpperCase())}
      </li>
    );
  }
}

export default ProductRelatedTabListTab;
