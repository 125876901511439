import { OrderDataVo } from "../../State";
import { OrderListProductVo } from "../vo/OrderVo";
import ProductVo from "../vo/ProductVo";
import TagManager from "react-gtm-module";

declare const dataLayer: any;

export const TagmanagerUtils = {
  pushEcommercePurchase: function(orderData: OrderDataVo) {
      const sumOfProductsPrices = orderData.sumOfProductsPrices;
      const sum = sumOfProductsPrices + orderData.shippingCost;
      const mwst = sum * (orderData.vatRate / 100);

      const products = orderData.listProducts.map((product: OrderListProductVo) => {
        const productData: ProductVo = product.baselineProductDataJson;
        return {
          name: productData.ShortDescription,
          id: productData.objectID,
          price: product.pricePerUnit.toFixed(4),
          brand: productData.herstellername,
          category: productData.funktion,
          quantity: product.quantity,
        };
      });

      const objectToPush = {
        event: "ecommerce",
        ecommerce: {
          currencyCode: orderData.dkoIsocode,
          purchase: {
            actionField: {
              id: orderData._id, // Transaction ID. Required for purchases and refunds.
              affiliation: "Webshop",
              revenue: orderData.totalPrice.toFixed(4), // Total transaction value (incl. tax and shipping)
              tax: mwst.toFixed(4),
              shipping: orderData.shippingCost.toFixed(4),
            },
            products,
          },
        },
      };

      dataLayer.push(objectToPush);
      return true;
  },

       init: function() {
        // permissions for analysing are set in https://tagmanager.google.com/ and not from the application side
        const tagManagerArgs = {
        gtmId: 'GTM-K65BGM3',
      };

      // Google Tag Manager
      TagManager.initialize(tagManagerArgs);

         // function gtag() {
         //   dataLayer.push(arguments)
         // }
         // gtag("consent", "default", {
         //   ad_personalization: "denied",
         //   ad_storage: "denied",
         //   ad_user_data: "denied",
         //   analytics_storage: "denied",
         //   functionality_storage: "denied",
         //   personalization_storage: "denied",
         //   security_storage: "granted",
         //   wait_for_update: 500
         // });
         // gtag("set", "ads_data_redaction", true);
         // gtag("set", "url_passthrough", true);


  }
};

