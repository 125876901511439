export enum ListActionTypes {
  STORE_LIST_DATA_CART = "STORE_LIST_DATA_CART",
  STORE_LIST_DATA_WISHLIST = "STORE_LIST_DATA_WISHLIST",
  STORE_LIST_DATA_COMPARISON = "STORE_LIST_DATA_COMPARISON",

  SET_ACTIVE_LIST_ID_WISHLIST = "SET_ACTIVE_LIST_ID_WISHLIST",

  UPDATE_COMMENT_CART_ITEM = "UPDATE_COMMENT_CART_ITEM",
  UPDATE_COMMENT_WISHLIST_ITEM = "UPDATE_COMMENT_WISHLIST_ITEM",
  UPDATE_CART_FOOTER = "UPDATE_CART_FOOTER",

  TAKE_COUNT_CLICK_CHANGE_CART_ITEM = "TAKE_COUNT_CLICK_CHANGE_CART_ITEM",
  TAKE_COUNT_INPUT_CHANGE_CART_ITEM = "TAKE_COUNT_INPUT_CHANGE_CART_ITEM",

  TAKE_COUNT_CLICK_CHANGE_WISHLIST_ITEM = "TAKE_COUNT_CLICK_CHANGE_WISHLIST_ITEM",
  TAKE_COUNT_INPUT_CHANGE_WISHLIST_ITEM = "TAKE_COUNT_INPUT_CHANGE_WISHLIST_ITEM",

  DELETE_LIST_BY_ID = "DELETE_LIST_BY_ID",

  RESET_CART = "RESET_CART",
  RESET_WISHLIST = "RESET_WISHLIST",
  RESET_COMPARISONLIST = "RESET_COMPARISONLIST",
}
