import * as React from "react";
import DragDropTagButton from "../button/DragDropTagButton";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { getAttributeDescription } from "../../../common/utils/attributeUtils";

interface Props {
  data: any[];
  onDragChange: (e) => void;
  onItemRemoveClick: (e) => void;
}

class DragDropTagList extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    this.props.onDragChange(result);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className="tag--drag"
              style={{
                padding: 0,
                display: "flex",
                overflow: "auto",
              }}
            >
              {this.renderItems()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  renderItems() {
    return this.props.data.map((item, index) => (
      <DragDropTagButton
        key={`${item.value}-${index}`}
        label={getAttributeDescription(item.dataAttributeKey, activeLang())}
        index={index}
        id={item.dataAttributeKey}
        onRemoveClick={this.props.onItemRemoveClick}
      />
    ));
  }
}

export default DragDropTagList;
