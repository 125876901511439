import * as React from "react";
import SuggestionVo from "../../../filterPanel/vo/SuggestionVo";
import Autosuggest from "react-autosuggest";

interface Props {
  value: string;
  placeholder: string;
  suggestions: SuggestionVo[];
  onChange: (e) => void;
  onItemSelected: (e) => void;
}

const getSuggestionValue = suggestion => {
  // NOTE: unfortunately, some suggestion lists have only a name, others have id and name
  if (suggestion.id !== undefined) {
    return suggestion.id;
  }
  return suggestion.name;
};

const renderSuggestion = suggestion => (
  <div
    className="autocomplete__option"
    style={{
      padding: "0.5rem 0.75rem",
      fontSize: "1.0625rem",
      fontFamily: "AvenirNextLTW01-Condens_721299, Arial, sans-serif",
    }}
    role="treeitem"
    aria-selected="false"
  >
    {suggestion.name}
    <span className="autocomplete__counter">{suggestion.count}</span>
  </div>
);

// TODO: Hover Item
class AutoCompleteDropDown extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);
  }

  suggestions: SuggestionVo[] = [];
  state: any = {
    focus: false,
  };

  onSuggestionsFetchRequested({ value, reason }) {
    if (reason === "escape-pressed") {
      this.setState({ focus: false });
    }
  }

  onSuggestionsClearRequested() {
    this.setState({ focus: false });
  }

  onSuggestionSelected(e, { suggestionValue }) {
    this.setState({ focus: false });
    this.props.onItemSelected(suggestionValue);
  }

  onChangeHandler(e, { newValue, method }) {
    console.log(newValue, method);
    this.props.onChange(e.target.value);
  }

  onFocusHandler() {
    this.setState({ focus: true });
  }

  onBlurHandler() {
    this.setState({ focus: false });
  }

  onClickHandler() {
    if (this.state.focus === false) {
      this.setState({ focus: true });
    }
    //else {
    //this.setState({ focus: false });
    //}
  }

  renderInputComponent(props) {
    return <input {...props} className="input--text" />;
  }

  render() {
    const inputProps: any = {
      placeholder: this.props.placeholder,
      value: this.props.value,
      onChange: this.onChangeHandler,
      onFocus: this.onFocusHandler,
      onBlur: this.onBlurHandler,
      onClick: this.onClickHandler,
    };

    return (
      <div className="autocomplete-block ">
        <div className="inputtext-block">
          <Autosuggest
            suggestions={this.props.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            inputProps={inputProps}
            focusInputOnSuggestionClick={false}
            alwaysRenderSuggestions={this.state.focus}
            theme={{
              suggestionsContainerOpen: {
                position: "absolute",
                overflow: "scroll",
                maxHeight: 260,
                marginTop: 1,
                backgroundColor: "#ffffff",
                width: "100%",
                zIndex: 1000,
              },
              containerOpen: { position: "relative" },
              suggestionHighlighted: {
                color: "#e32236",
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default AutoCompleteDropDown;
