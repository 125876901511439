import * as React from "react";
import { Helmet } from "react-helmet";
import { createImgUrl } from "../../common/utils/createUrls";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import SearchGroupVo from "../../common/vo/SearchGroupVo";
import { SearchState } from "../../State";
import {
  titleMetaStringPrefixForAnyFilter,
  titleStringMainForAnyFilter,
} from "../../common/utils/titleStringUtils";

interface Props {
  root: SearchGroupVo;
  current: SearchGroupVo;
  searchState: SearchState;
}

class ManufactureDetail extends React.PureComponent<Props> {
  render() {
    if (typeof this.props.root === "undefined") {
      return null;
    }

    const imgixUrl = config("imgixUrl");
    let rootImgSrc = imgixUrl + "/default.png";
    if (
      typeof this.props.root !== "undefined" &&
      typeof this.props.root.elstrFiles !== "undefined"
    ) {
      rootImgSrc = createImgUrl(this.props.root.elstrFiles[0], "", "", "0FFF", "png");
    }

    let title = titleMetaStringPrefixForAnyFilter(this.props.searchState);
    let headline = titleStringMainForAnyFilter(this.props.searchState);
    if (
      this.props.current.descShort &&
      this.props.current.descShort.length > 0 &&
      this.props.current.descShort !== this.props.root.descShort
    ) {
      title = `${this.props.root.descShort} ${title}`;
      headline = `${this.props.root.descShort} ${headline}`;
    }

    // betrifft herstellerlinie
    let lineImgSrc = "";
    if (
      typeof this.props.current !== "undefined" &&
      typeof this.props.current.elstrFiles !== "undefined"
    ) {
      lineImgSrc = createImgUrl(this.props.current.elstrFiles[0], "", "", "0FFF", "png");
    }

    if (
      typeof this.props.current !== "undefined" &&
      this.props.current.descShort !== "undefined" &&
      this.props.current.descShort &&
      this.props.current.descShort.length > 0 &&
      this.props.root.objectID !== "K10639"
    ) {
      return (
        <>
          <Helmet>
            <title>{`${title} ${dic("ONLINE KAUFEN")} | ${dic("TITLE")}`}</title>
            <meta name="description" content={this.props.current.descLong} />
          </Helmet>
          <h1 className="catalog__title">{headline}</h1>
          <div className="linecard">
            <div className="linecard__img-box">
              <img
                className="producer-teaser__img"
                src={rootImgSrc}
                alt={this.props.root.descShort}
                width="100%"
              />
              <img
                className="producer-teaser__img"
                src={lineImgSrc}
                alt={this.props.current.descShort}
                width="100%"
              />
            </div>

            <div className="linecard__text-block">
              <p className="linecard__text">{this.props.current.descLong}</p>
              <ul>
                {this.renderWebsite(this.props.current.herstellerwebsite)}
                {this.renderCatalog()}
              </ul>
            </div>
          </div>
        </>
      );
    } else {
      // betrifft hersteller
      if (this.props.root.objectID === "K10639") {
        // weitere hersteller
        title = this.props.current.descShort + " - " + dic("PRODUKTE") + " | " + dic("TITLE");
        headline = this.props.current.descShort;
        return (
          <>
            <Helmet>
              <title>{`${title} ${dic("PRODUKTE")} ${dic("ONLINE KAUFEN")} | ${dic(
                "TITLE",
              )}`}</title>
              <meta name="description" content={this.props.current.descLong} />
            </Helmet>
            <h1 className="catalog__title">{headline}</h1>
            <div className="linecard">
              <div className="linecard__img-box">
                <img
                  className="producer-teaser__img"
                  src={lineImgSrc}
                  alt={this.props.current.descShort}
                  width="100%"
                />
              </div>

              <div className="linecard__text-block">
                <p className="linecard__text">{this.props.current.descLong}</p>
                <ul>
                  {this.renderWebsite(this.props.current.herstellerwebsite)}
                  {this.renderCatalog()}
                </ul>
              </div>
            </div>
          </>
        );
      } else {
        // "normale" Hersteller
        return (
          <>
            <Helmet>
              <title>{`${title} ${dic("PRODUKTE")} ${dic("ONLINE KAUFEN")} | ${dic(
                "TITLE",
              )}`}</title>
              <meta name="description" content={this.props.root.descLong} />
            </Helmet>
            <h1 className="catalog__title">{headline}</h1>
            <div className="linecard">
              <div className="linecard__img-box">
                <img
                  className="producer-teaser__img"
                  src={rootImgSrc}
                  alt={this.props.root.descShort}
                  width="100%"
                />
              </div>

              <div className="linecard__text-block">
                <p className="linecard__text">{this.props.root.descLong}</p>
                <ul>
                  {this.renderWebsite(this.props.root.herstellerwebsite)}
                  {this.renderCatalog()}
                </ul>
              </div>
            </div>
          </>
        );
      }
    }
  }

  renderWebsite(website) {
    if (website !== "undefined" && website && website.length > 0) {
      return (
        <li>
          <a href={website} target="_blank" className="link--url" rel="noopener noreferrer">
            {dic("HERSTELLERWEBSITE")}
          </a>
        </li>
      );
    }
  }

  renderCatalog() {
    const imgixUrl = config("imgixUrl");
    if (!imgixUrl) {
      return null;
    }

    if (
      typeof this.props.current !== "undefined" &&
      this.props.current.descShort !== "undefined" &&
      this.props.current.descShort &&
      this.props.current.descShort.length > 0
    ) {
      // betrifft herstellerserie
      if (
        typeof this.props.current.elstrFiles !== "undefined" &&
        this.props.current.elstrFiles.length > 1
      ) {
        return this.props.current.elstrFiles.map((file, index) => {
          if (file.type !== "bild") {
            let divisor = 1000;
            let unit = "kB";
            if (file.sizeInBytes > 1000000) {
              divisor = 1000000;
              unit = "MB";
            }
            return (
              <li key={file.name}>
                <a
                  href={imgixUrl + `/${file.publicPath}/${file.name}`}
                  target="_blank"
                  className="link--download"
                  rel="noopener noreferrer"
                >
                  {dic(file.type.toUpperCase())}
                  <span className="link__file-info">
                    {` (${(file.sizeInBytes / divisor).toFixed(4)} ${unit})`}
                  </span>
                </a>
              </li>
            );
          }
          else {
            return undefined;
          }
        });
      }
    } else {
      // betrifft hersteller
      if (
        typeof this.props.root !== "undefined" &&
        typeof this.props.root.elstrFiles !== "undefined"
      ) {
        if (this.props.root.elstrFiles.length > 1) {
          return this.props.root.elstrFiles.map((file, index) => {
            if (file.type !== "bild") {
              let divisor = 1000;
              let unit = "kB";
              if (file.sizeInBytes > 1000000) {
                divisor = 1000000;
                unit = "MB";
              }
              return (
                <li key={file.name}>
                  <a
                    href={imgixUrl + `/${file.publicPath}/${file.name}`}
                    target="_blank"
                    className="link--download"
                    rel="noopener noreferrer"
                  >
                    {dic(file.type.toUpperCase())}
                    <span className="link__file-info">
                      {` (${(file.sizeInBytes / divisor).toFixed(4)} ${unit})`}
                    </span>
                  </a>
                </li>
              );
            }
            return undefined;
          });
        }
      }
    }
    return null;
  }
}

export default ManufactureDetail;
