import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  active?: boolean;
  moreText?: string;
  lessText?: string;
  onClick: (active: boolean) => void;
}

class MoreButton extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    active: false,
  };

  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    this.props.onClick(!this.props.active);
  }

  render() {
    const activeText = this.props.active
      ? this.props.lessText || dic("WENIGER ANZEIGEN")
      : this.props.moreText || dic("MEHR ANZEIGEN");
    const arrowCls = this.props.active ? "btn btn--arrow-up" : "btn btn--arrow-down";

    return (
      <button className={arrowCls} onClick={this.onClickHandler}>
        {activeText}
      </button>
    );
  }
}

export default MoreButton;
