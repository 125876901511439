import { OrderActionTypes } from "./ActionTypes";

import COMPONA_WidgetServer_JSON_Order from "../../common/services/COMPONA_WidgetServer_JSON_Order";

export const OrderActions = {
  setOrderData: function(data) {
    return { type: OrderActionTypes.SET_ORDER_DATA, payload: { data } };
  },

  getOrders: function() {
    return async (dispatch, getState) => {
      // Save the order
      try {
        dispatch(OrderActions.setOrderData({ isLoading: true }));

        const response = await COMPONA_WidgetServer_JSON_Order.getOrders();

        if (response instanceof Error) {
          throw response;
        } else {
          let newData = response.data;
          for (const key in newData) {
            let order = response.data[key];
            if (
                order.paymentStatus &&
                order.paymentStatus > 0 &&
                order.paymentStatus !== 30 &&
                order.paymentStatus !== 50 &&
                response.data.hasOwnProperty("_sign") === false
            ) {
              const orderId = order._id;
              const refno = orderId.toString();
              const signAmount = Math.round(order.totalPrice * 100);
              const amount = signAmount.toString();
              const currency = order.dkoIsocode;
              const signResponse = await COMPONA_WidgetServer_JSON_Order.signOrderString(
                  refno,
                  amount,
                  currency,
              );
              if (signResponse instanceof Error) {
                throw signResponse;
              } else {
                order._sign = signResponse.data.sign
              }
              newData[key] = order;
            }
          }
          await dispatch(OrderActions.setOrderData({ isLoading: false }));
          await dispatch(OrderActions.setOrderData({ orders: newData }));
        }
      } catch (e) {
        dispatch(OrderActions.setOrderData({ isLoading: false }));
        throw e;
      }
    };
  },

  getOrderData: function(orderId) {
    return async (dispatch, getState) => {
      // Save the order
      try {
        dispatch(OrderActions.setOrderData({ isLoading: true }));

        const response = await COMPONA_WidgetServer_JSON_Order.getOrderData(orderId);

        if (response instanceof Error) {
          throw response;
        } else {

          if (
              response.data.paymentStatus &&
              response.data.paymentStatus > 0 &&
              response.data.paymentStatus !== 30 &&
              response.data.paymentStatus !== 50  &&
              response.data.hasOwnProperty("_sign") === false
          ) {
            try {
              const refno = orderId.toString();
              const signAmount = Math.round(    response.data.totalPrice * 100  );
              const amount = signAmount.toString();
              const currency = response.data.dkoIsocode;
              const signResponse = await COMPONA_WidgetServer_JSON_Order.signOrderString(
                  refno,
                  amount,
                  currency,
              );
              if (signResponse instanceof Error) {
                throw signResponse;
              } else {
                response.data._sign = signResponse.data.sign
              }
            } catch (e) {
              dispatch(OrderActions.setOrderData({isLoading: false}));
              throw e;
            }
          }
          await dispatch(OrderActions.setOrderData({ isLoading: false }));
          await dispatch(OrderActions.setOrderData({ orders: [response.data] }));
        }
      } catch (e) {
        dispatch(OrderActions.setOrderData({ isLoading: false }));
        throw e;
      }
    };
  },
};
