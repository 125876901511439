import { AdrVo, CheckoutState, emptyAddress, UserState } from "../../State";

/**
 *
 * @param {CheckoutState} checkoutState
 * @param {UserState} userState
 * @returns {AdrVo}
 */
export function getCheckoutAddressLio(checkoutState: CheckoutState, userState: UserState): AdrVo {
  let inr = "0";
  if (checkoutState && checkoutState.order && checkoutState.order.dkoLioAdrinr) {
    inr = checkoutState.order.dkoLioAdrinr;
  }
  if (inr === "0") {
    return checkoutState.formLioAdr;
  } else {
    const address = userState.adr.find(adr => adr.inr === inr);
    if (address) {
      return address;
    } else {
      return emptyAddress;
    }
  }
}

/**
 *
 * @param {CheckoutState} checkoutState
 * @param {UserState} userState
 * @returns {AdrVo}
 */
export function getCheckoutAddressFao(checkoutState: CheckoutState, userState: UserState): AdrVo {
  let inr = "0";
  if (checkoutState && checkoutState.order && checkoutState.order.dkoFaoAdrinr) {
    inr = checkoutState.order.dkoFaoAdrinr;
  }
  if (inr === "0") {
    return checkoutState.formFaoAdr;
  } else {
    if (inr === "-1") {
      return getCheckoutAddressLio(checkoutState, userState);
    }
    const address = userState.adr.find(adr => adr.inr === inr);
    if (address) {
      return address;
    } else {
      return emptyAddress;
    }
  }
}
