import * as React from "react";
import { AdrVo } from "../../../State";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { v1 as uuidv1 } from "uuid";
import SelectBox from "./SelectBox";
import SelectBoxLaender from "./SelectBoxLaender";

interface Props {
  onSubmit: () => void;
  onReset?: () => void;
  onInputChange: (name: string, value: string) => void;
  formAdr: AdrVo;
  typeIsEditable?: boolean;
}

class AddressEdit extends React.PureComponent<Props> {
  onInputChange = e => {
    this.props.onInputChange(e.target.name, e.target.value);
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.onSubmit();
  };

  onReset = e => {
    e.preventDefault();
    if (this.props.onReset) {
      this.props.onReset();
    }
  };

  render() {
    const formId = uuidv1();

    return (
      <div className="address-box editing">
        <form className="form" onSubmit={this.onSubmit}>
          {this.props.typeIsEditable && (
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${formId}-type`}>
                {dic("TYP")} <span>*</span>
              </label>
              <div className="selectbox-block">
                <SelectBox
                  selections={[
                    { value: "", name: `${dic("WAEHLEN")}...` },
                    { value: "delivery", name: dic("LIEFERADRESSE") },
                    { value: "invoice", name: dic("RECHNUNGSADRESSE") },
                  ]}
                  id={`${formId}-type`}
                  name="type"
                  required={true}
                  selectedValue={this.props.formAdr.type}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
          )}
          <div className="form-input__wrapper">
            <label className="label" htmlFor={`${formId}-email`}>
              {dic("EMAIL")} <span>*</span>
            </label>
            <input
              className="input--text"
              type="email"
              id={`${formId}-email`}
              name="email"
              placeholder="user@domain.com"
              autoComplete="email"
              aria-required={true}
              required={true}
              value={this.props.formAdr.email}
              onChange={this.onInputChange}
            />
          </div>

          <div className="form-input__wrapper">
            <label className="label" htmlFor={`${formId}-zeile1`}>
              {dic("FIRMA")}
            </label>
            <input
                className="input--text"
                type="text"
                id={`${formId}-zeile1`}
                name="zeile1"
                placeholder={`${dic("FIRMA")}...`}
                maxLength={50}
                aria-required={false}
                value={this.props.formAdr.zeile1 ? this.props.formAdr.zeile1 : ""}
                onChange={this.onInputChange}
            />
          </div>

          <div className="form-row">
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${formId}-anrNr`}>
                {dic("ANREDE")}
              </label>
              <div className="selectbox-block">
                <SelectBox
                  selections={[
                    { value: "1", name: dic("HERR") },
                    { value: "2", name: dic("FRAU") },
                  ]}
                  id={`${formId}-anrNr`}
                  name="anrNr"
                  selectedValue={this.props.formAdr.anrNr ? this.props.formAdr.anrNr : "1"}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${formId}-vorname`}>
                {dic("VORNAME")} <span>*</span>
              </label>
              <input
                className="input--text"
                type="text"
                id={`${formId}-vorname`}
                name="vorname"
                placeholder={`${dic("VORNAME")}...`}
                maxLength={50}
                aria-required={true}
                required={true}
                value={this.props.formAdr.vorname ? this.props.formAdr.vorname : ""}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${formId}-name`}>
                {dic("NACHNAME")} <span>*</span>
              </label>
              <input
                className="input--text"
                type="text"
                id={`${formId}-name`}
                name="name"
                placeholder={`${dic("NACHNAME")}...`}
                maxLength={50}
                aria-required={true}
                required={true}
                value={this.props.formAdr.name ? this.props.formAdr.name : ""}
                onChange={this.onInputChange}
              />
            </div>
          </div>

          <div className="form-input__wrapper">
            <label className="label" htmlFor={`${formId}-zeile2`}>
              {dic("ADRESSE")} <span>*</span>
            </label>
            <input
              className="input--text"
              type="text"
              id={`${formId}-zeile2`}
              name="zeile2"
              placeholder={`${dic("ADRESSE")}...`}
              maxLength={50}
              aria-required={true}
              required={true}
              value={this.props.formAdr.zeile2 ? this.props.formAdr.zeile2 : ""}
              onChange={this.onInputChange}
            />
          </div>

          <div className="form-input__wrapper">
            <label className="label" htmlFor={`${formId}-zeile3`}>
              {dic("ADRESSZUSATZ")}
            </label>
            <input
              className="input--text"
              type="text"
              id={`${formId}-zeile3`}
              name="zeile3"
              placeholder={`${dic("ADRESSZUSATZ")}...`}
              maxLength={50}
              aria-required={false}
              value={this.props.formAdr.zeile3 ? this.props.formAdr.zeile3 : ""}
              onChange={this.onInputChange}
            />
          </div>

          <div className="form-row">
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${formId}-land`}>
                {dic("LAND")} <span>*</span>
              </label>
              <div className="selectbox-block">
                <SelectBoxLaender
                  id={`${formId}-land`}
                  name="land"
                  aria-required={true}
                  required={true}
                  selectedValue={this.props.formAdr.land ? this.props.formAdr.land : ""}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${formId}-plz`}>
                {dic("POSTLEITZAHL")} <span>*</span>
              </label>
              <input
                className="input--text"
                type="text"
                id={`${formId}-plz`}
                name="plz"
                placeholder={`${dic("POSTLEITZAHL")}...`}
                maxLength={10}
                value={this.props.formAdr.plz ? this.props.formAdr.plz : ""}
                onChange={this.onInputChange}
                aria-required={true}
                required={true}
              />
            </div>
            <div className="form-input__wrapper">
              <label className="label" htmlFor={`${formId}-ort`}>
                {dic("ORT")} <span>*</span>
              </label>
              <input
                className="input--text"
                type="text"
                id={`${formId}-ort`}
                name="ort"
                placeholder={`${dic("ORT")}...`}
                maxLength={50}
                value={this.props.formAdr.ort ? this.props.formAdr.ort : ""}
                onChange={this.onInputChange}
                aria-required={true}
                required={true}
              />
            </div>
          </div>

          <div className="form-input__wrapper">
            <label className="label" htmlFor={`${formId}-tel`}>
              {dic("TELEFON")}
            </label>
            <input
              className="input--text"
              type="text"
              id={`${formId}-tel`}
              name="tel"
              placeholder={`${dic("TELEFON")}...`}
              maxLength={50}
              aria-required={false}
              value={this.props.formAdr.tel ? this.props.formAdr.tel : ""}
              onChange={this.onInputChange}
            />
          </div>

          <div className="form-submit__wrapper form-row">
            {this.props.onReset && (
              <input
                type="reset"
                className="input--reset"
                value={dic("ZURUECK")}
                onClick={this.onReset}
              />
            )}
            <input type="submit" className="input--submit" value={dic("ADRESSE SPEICHERN")} />
          </div>
        </form>
      </div>
    );
  }
}

export default AddressEdit;
