import typeReducer from "./typeReducer";
import manufactureReducer from "./manufactureReducer";
import productListReducer from "./productListReducer";
import { combineReducers } from "redux";

export const catalogReducers = {
  catalog: combineReducers({
    type: typeReducer,
    manufacture: manufactureReducer,
    productList: productListReducer,
  }),
};
