import * as React from "react";

interface Props {
  label: string;
  id?: string;
  onClick: (e, id) => void;
}

class RemoveCircle extends React.PureComponent<Props> {
  onClickHandler = event => {
    this.props.onClick(this.props.label, this.props.id);
  };

  render() {
    return (
      <span className="tree__selected-item">
        <span className="remover--circle" aria-label="Remove" onClick={this.onClickHandler} />
        <div>{this.props.label}</div>
      </span>
    );
  }
}

export default RemoveCircle;
