import ListVo, { ListProductVo } from "../../common/vo/ListVo";
import { ListActionTypes } from "../../list/actions/ActionTypes";
import { WishlistState } from "../../State";
import {
  productGetTakeClick,
  productGetTakeInit,
  productGetTakeInput,
} from "../../common/utils/productUtils";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import produce, { Draft } from "immer";

const wishlistReducer = (state: WishlistState, action) => {
  if (!state) return null;

  return produce(state, (draft: Draft<WishlistState>) => {
    switch (action.type) {
      case ListActionTypes.STORE_LIST_DATA_WISHLIST: {
        const payload: ListVo = action.payload;

        const lists = draft.lists;

        // check if already exists for update or else push
        let exists = false;
        let indexOfExisting = 0;
        state.lists.forEach((list, i) => {
          if (list._id === payload._id) {
            exists = true;
            indexOfExisting = i;
          }
        });

        if (exists) {
          // update
          lists[indexOfExisting] = payload;
        } else {
          // add new one
          lists.push(payload);
        }

        // assign productTake
        lists.forEach((list: ListVo, j) => {
          list.products.forEach((product: ListProductVo, i) => {
            if (product.data[activeLang()]) {
              product.take = productGetTakeInit(product.quantity, product.data[activeLang()]);
              list.products[i] = product;
            }
          });
          draft.lists[j] = list;
        });

        break;
      }

      case ListActionTypes.DELETE_LIST_BY_ID: {
        const listId: string = action.payload.listId;

        // delete the list with the right listId
        draft.lists.forEach((list: ListVo, i) => {
          if (list._id === listId) draft.lists.splice(i, 1);
        });

        break;
      }

      case ListActionTypes.SET_ACTIVE_LIST_ID_WISHLIST: {
        draft.activeListId = action.payload.activeListId;

        break;
      }

      case ListActionTypes.RESET_WISHLIST: {
        const payload: WishlistState = action.payload;
        return { ...state, ...payload };
      }

      case ListActionTypes.UPDATE_COMMENT_WISHLIST_ITEM: {
        const { listId, comment, index } = action.payload;

        // find the the list and toggle editComment, reassign everything
        draft.lists.forEach((list: ListVo, i) => {
          if (list._id === listId) {
            // update comment
            draft.lists[i].products[index].comment = comment;
          }
        });

        break;
      }

      case ListActionTypes.TAKE_COUNT_CLICK_CHANGE_WISHLIST_ITEM: {
        const { listId, direction, index } = action.payload;

        // find the the list and toggle editComment, reassign everything
        draft.lists.forEach((list: ListVo, i) => {
          if (list._id === listId) {
            const product = draft.lists[i].products[index];
            const take = draft.lists[i].products[index].take;

            const newTake = productGetTakeClick(take, product.data[activeLang()], direction);
            draft.lists[i].products[index].take = newTake;

            // update quantity
            if (newTake) {
              draft.lists[i].products[index].quantity = product.take.quantity;
            }
          }
        });

        break;
      }

      case ListActionTypes.TAKE_COUNT_INPUT_CHANGE_WISHLIST_ITEM: {
        const { listId, quantity, index } = action.payload;

        // find the the list and toggle editComment, reassign everything
        draft.lists.forEach((list: ListVo, i) => {
          if (list._id === listId) {
            const product = draft.lists[i].products[index];
            const take = draft.lists[i].products[index].take;

            product.quantity = quantity;
            product.take = productGetTakeInput(take, product.data[activeLang()], quantity);

            draft.lists[i].products[index] = product;
          }
        });

        break;
      }
    }
  });
};

export default wishlistReducer;
