import * as React from "react";
import { Link } from "react-router-dom";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  onClick: () => void;
  href: string;
}

class NavLayer extends React.PureComponent<Props> {
  render() {
    return (
      <nav className="prinav-layer" role="navigation">
        <ul className="prinavs">
          <li className="prinav__item">
            <Link
              className={`prinav__link ${this.props.href.includes(`/catalog/`) && "link--active"}`}
              to={`/catalog/${activeLang()}/`}
              onClick={this.props.onClick}
            >
              {dic("KATALOG")}
            </Link>
          </li>
          <li className="prinav__item">
            <Link
              className={`prinav__link ${this.props.href.includes(
                `/cms/${activeLang()}/${dic("CMSURL_SERVICES")}`,
              ) && "link--active"}`}
              onClick={this.props.onClick}
              to={`/cms/${activeLang()}/${dic("CMSURL_SERVICES")}`}
            >
              {dic("SERVICES")}
            </Link>
          </li>
          <li className="prinav__item">
            <Link
              className={`prinav__link ${this.props.href.includes(
                `/cms/${activeLang()}/${dic("CMSURL_AKTUELLES")}`,
              ) && "link--active"}`}
              onClick={this.props.onClick}
              to={`/cms/${activeLang()}/${dic("CMSURL_AKTUELLES")}`}
            >
              {dic("AKTUELLES")}
            </Link>
          </li>
          <li className="prinav__item">
            <Link
              className={`prinav__link ${this.props.href.includes(
                `/cms/${activeLang()}/${dic("CMSURL_ABOUTUS")}`,
              ) && "link--active"}`}
              onClick={this.props.onClick}
              to={`/cms/${activeLang()}/${dic("CMSURL_ABOUTUS")}`}
            >
              {dic("UEBER UNS")}
            </Link>
          </li>
        </ul>
        <div className="prinav-layer__block">
          <ul className="subnav">
            <li className="subnav__item">
              <Link
                className="subnav__link"
                onClick={this.props.onClick}
                to={`/cms/${activeLang()}/${dic("CMSURL_KONTAKT")}`}
              >
                {dic("KONTAKT_NAV")}
              </Link>
            </li>
            <li className="subnav__item">
              <Link
                className="subnav__link"
                onClick={this.props.onClick}
                to={`/cms/${activeLang()}/${dic("CMSURL_FAQ")}`}
              >
                {dic("FAQ")}
              </Link>
            </li>
            <li className="subnav__item">
              <a
                className="subnav__link"
                onClick={this.props.onClick}
                target="_blank"
                rel="noopener noreferrer"
                href={dic("CMSURL_NEWSLETTER")}
              >
                {dic("NEWSLETTER")}
              </a>
            </li>
            <li className="subnav__item">
              <Link
                className="subnav__link"
                onClick={this.props.onClick}
                to={`/cms/${activeLang()}/${dic("CMSURL_JOBS")}`}
              >
                {dic("JOBS")}
              </Link>
            </li>
          </ul>

          <ul className="social-links">
            <li className="social-link__item">
              <a
                className="social-link__link"
                href="https://www.facebook.com/Compona-AG-176376555735516/"
              >
                <span className="sicon sicon__facebook social-link__icon" />
              </a>
            </li>
            <li className="social-link__item">
              <a className="social-link__link" href="https://twitter.com/ComponaAG">
                <span className="sicon sicon__twitter social-link__icon" />
              </a>
            </li>
            <li className="social-link__item">
              <a className="social-link__link" href="https://www.youtube.com/user/ComponaChannel">
                <span className="sicon sicon__youtube social-link__icon" />
              </a>
            </li>
            <li className="social-link__item">
              <a className="social-link__link" href="https://www.linkedin.com/company/8744345/">
                <span className="sicon sicon__linkedin social-link__icon" />
              </a>
            </li>
            <li className="social-link__item">
              <a className="social-link__link" href="https://www.xing.com/companies/componaag">
                <span className="sicon sicon__xing social-link__icon" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavLayer;
