import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { CheckoutActions } from "../actions/checkoutActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { v1 as uuidv1 } from "uuid";

interface Props {
  dkoAblnr: string;
  dkoAblnrOptions: any[] | null;
  showOnlyChecked: boolean;
}

class RadioBoxItemsDkoAblnr extends React.PureComponent<Props> {
  onOptionDkoAblnrChange = e => {
    ElstrCore.store.dispatch(CheckoutActions.onOptionDkoAblnrChange(e.target.value));
  };

  render() {
    if (Array.isArray(this.props.dkoAblnrOptions)) {
      return this.props.dkoAblnrOptions.map((option, index) => {
        const inputId = uuidv1();
        if (
          this.props.showOnlyChecked === false ||
          (this.props.showOnlyChecked === true && this.props.dkoAblnr === option)
        ) {
          return (
            <div
              key={`radio-box__item_${option}`}
              className={`radio-box__item ${this.props.dkoAblnr === option ? "checked" : ""}`}
            >
              <div className="radiobutton">
                <input
                  value={option}
                  checked={this.props.dkoAblnr === option ? true : false}
                  onChange={this.onOptionDkoAblnrChange}
                  className="input--radiobutton"
                  name="RadioBoxDkoAblnr"
                  type="radio"
                  aria-checked={false}
                  id={inputId}
                />
                <label htmlFor={inputId} className="radiobutton__label">
                  {dic(`ABLNR ${option} INFO`)}
                  <span className="sub-info">{dic(`ABLNR ${option} SUBINFO`)}</span>
                </label>
                <span className="box-radiobutton__right" />
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }
}

export default RadioBoxItemsDkoAblnr;
