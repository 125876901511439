import * as React from "react";
import { Link } from "react-router-dom";
import { getAttributeDescription } from "../../utils/attributeUtils";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { createGroupUrl } from "../../utils/createUrls";

declare var COMPONA_STATIC_CACHE;

interface Props {
  data?: any[];
}

class TagList extends React.PureComponent<Props> {
  renderTreeTag(objectId: string, suffix: string) {
    if (
      COMPONA_STATIC_CACHE.allClassifications &&
      COMPONA_STATIC_CACHE.allClassifications[activeLang()]
    ) {
      const allClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];
      const groupObject = allClassifications.find(
        classification => classification.objectID === objectId,
      );
      if (groupObject && groupObject.parentIds) {
        let breadcrumbDescShort = groupObject.breadcrumbDescShort;
        breadcrumbDescShort = breadcrumbDescShort.replace("funktion >", `${dic("FUNKTION")}: `);
        breadcrumbDescShort = breadcrumbDescShort.replace("hersteller >", `${dic("HERSTELLER")}: `);
        return (
          <Link to={createGroupUrl(groupObject, activeLang())} className="tag tag--tree">
            {breadcrumbDescShort+suffix}
          </Link>
        );
      }
    } else {
      return null;
    }
  }

  render() {
    return (
      <>
        <Link to={`/catalog/${activeLang()}/?steckertyp=M12`} className="tag">
          {`${getAttributeDescription("steckertyp", activeLang())}: M12`}
        </Link>

        <Link to={`/catalog/${activeLang()}/?steckverbinder_standard=D-Subminiatur`} className="tag">
          {`${getAttributeDescription("steckverbinder_standard", activeLang())}: D-Subminiatur`}
        </Link>

        {this.renderTreeTag("K20016","")}

        {this.renderTreeTag("K10184","")}

        <Link to={`/catalog/${activeLang()}/?steckertyp=M8`} className="tag">
          {`${getAttributeDescription("steckertyp", activeLang())}: M8`}
        </Link>

        {this.renderTreeTag("K10806"," (Tyco)")}

        {this.renderTreeTag("K10107","")}

        {this.renderTreeTag("K10847","")}

        {this.renderTreeTag("K10329","")}

      </>
    );
  }
}

export default TagList;
