import * as React from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { Link } from "react-router-dom";

interface Props {}

class PromoBanner extends React.PureComponent<Props> {

  render() {
    return (
      <div className="promo__banner">
        <ul className="promo-banner__list">
          <li className="promo-banner__item">
            <Link
              className="promo-banner__link"
              to={`/cms/${activeLang()}/${dic("CMSURL_VERSAND_BEZAHLUNG")}`}
            >
              <span className="sicon sicon__check" />
              {dic("KOSTENLOSER VERSAND")}
            </Link>
          </li>
          <li className="promo-banner__item">
            <Link className="promo-banner__link" to={`/catalog/${activeLang()}/`}>
              <span className="sicon sicon__check" />
              {dic("SUCHE PROMO")}
            </Link>
          </li>
          <li className="promo-banner__item">
            <Link className="promo-banner__link" to={`/cms/${activeLang()}/${dic("CMSURL_KONTAKT")}`}>
              <span className="sicon sicon__check" />
              {dic("KUNDENDIENST")}
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default PromoBanner;
