import { ManuFactureState } from "../../State";
import { CatalogActionsTypes } from "../actions/ActionTypes";
import { createImgUrl, createGroupUrl } from "../../common/utils/createUrls";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

function sortByOrderNo(items: any[]) {
  items.sort((a, b) => {
    if (Number(a.orderNo) > Number(b.orderNo)) {
      return 1;
    }
    if (Number(a.orderNo) < Number(b.orderNo)) {
      return -1;
    }
    return 0;
  });
}

const manufactureReducer = (state: ManuFactureState, action) => {
  if (!state) return null;

  switch (action.type) {
    case CatalogActionsTypes.CATALOG_MANUFACTURES_TILES:
      let group = {};
      if (action.data.hasOwnProperty("group")) {
        // changed by gvo on 10.07.2018: group = action.data.group;
        const manufacturer = action.data.group;
        group = {
          descShort: manufacturer.descShort,
          descLong: manufacturer.descLong,
          elstrFiles: manufacturer.elstrFiles,
          herstellerwebsite: manufacturer.herstellerwebsite,
        };
      }

      let manufacturer = {};
      if (action.data.hasOwnProperty("hersteller")) {
        manufacturer = action.data.hersteller;
      }

      let nData = [];
      const data = action.data.sub;
      if (data instanceof Object) {
        nData = data.map(item => {
          return {
            img: createImgUrl(item.elstrFiles[0], "", "", "0FFF", "png"),
            name: `${item.descShort}`,
            description: `${item.descLong} `,
            href: createGroupUrl(item, activeLang()),
            orderNo: item.orderNo,
          };
        });

        sortByOrderNo(nData);
      }
      return { ...state, data: nData, current: group, root: manufacturer };

    case CatalogActionsTypes.SHOW_ALL_MANUFACTURES:
      return { ...state, showAll: action.payLoad };
  }

  return state;
};

export default manufactureReducer;
