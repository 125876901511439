import ElstrIo from "elstr-jslib-4/dist/ElstrIo";

export default class COMPONA_WidgetServer_JSON_Order {
  static async createUpdateOrder(data: any, cartListId: string, orderId: string | null) {
    return ElstrIo.requestJsonRpc<any, any>(
      "COMPONA_WidgetServer_JSON_Order",
      "createUpdateOrder",
      {
        data,
        cartListId,
        orderId,
      },
    );
  }

  static async getOrderData(orderId: string) {
    return ElstrIo.requestJsonRpc<any, any>("COMPONA_WidgetServer_JSON_Order", "getOrderData", {
      orderId,
    });
  }

  static async getOrders() {
    return ElstrIo.requestJsonRpc<any, any>("COMPONA_WidgetServer_JSON_Order", "getOrders", {});
  }

  static async getOrdersForManager(offset: number, limit: number) {
    return ElstrIo.requestJsonRpc<any, any>(
      "COMPONA_WidgetServer_JSON_Order",
      "getOrdersForManager",
      {
        offset,
        limit,
      },
    );
  }

  static async rescheduleOrderForErpOrderCreation(orderId: string) {
    return ElstrIo.requestJsonRpc<any, any>(
      "COMPONA_WidgetServer_JSON_Order",
      "rescheduleOrderForErpOrderCreation",
      {
        orderId,
      },
    );
  }

  static async signOrderString(refno: number, amount: string, currency: string) {
    return ElstrIo.requestJsonRpc<any, any>(
      "COMPONA_WidgetServer_JSON_Order",
      "signOrderString",
      {
        refno,
        amount,
        currency
      }
    );
  }

}
