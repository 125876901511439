import { SearchState } from "../../State";
import { CATALOG_LAYOUTS } from "../constants/Constants";
import SearchGroupVo from "../vo/SearchGroupVo";

declare var COMPONA_STATIC_CACHE;
const queryString = require("query-string");
const URL = require("url-parse");

/**
 * Parse URL and find Layout by Classification ID and mapp to searchState
 * @param {string} url
 * @param {any[]} classifications
 * @returns {any | undefined}
 */
export function parseUrlToSearchState(
  url: string,
  classifications: any[],
): SearchGroupVo | undefined {
  // Parse URl "https://compona.ch/catalog/group/K10000"
  // Binder K10000
  // Subminiatur Steckverbinder K10043
  // Snap-in IP40 K10053

  const u = new URL(url);
  const h = u.pathname.split("/");

  // Path is single ID (Single Manufacture)
  if (h[2] === "group" && h[3]) {
    const t = classifications.find(item => item.objectID.toLowerCase() === h[3].toLowerCase());
    let layoutType;

    switch (t.parentIds.length) {
      case 0:
        if (t.classificationId === "hersteller") {
          layoutType = CATALOG_LAYOUTS.MANUFACTURE;
        } else {
          layoutType = CATALOG_LAYOUTS.FUNCTION;
        }

        break;
      case 1:
        if (t.classificationId === "hersteller") {
          layoutType = CATALOG_LAYOUTS.MANUFACTURE;
        } else {
          layoutType = CATALOG_LAYOUTS.FUNCTION;
        }
        break;
      case 2:
        if (t.classificationId === "hersteller") {
          layoutType = CATALOG_LAYOUTS.MANUFACTURE;
        } else {
          layoutType = CATALOG_LAYOUTS.DETAIL;
        }
        break;
      default:
        layoutType = CATALOG_LAYOUTS.ROOT;
    }

    return {
      classificationId: t.classificationId,
      objectID: t.objectID,
      descShort: t.descShort,
      descLong: t.longDesc,
      parentId: t.parentId,
      parentIds: t.parentIds,
      bredCrumbsShort: t.breadcrumbDescShort,
      bredCrumbsId: t.breadcrumbId,
      layoutType,
      elstrFiles: t.elstrFiles,
    };
  }

  return undefined;
}

export function parseHashUrl(
  search = window.location.search,
  treeRoots: any = [],
  allClassifications: any = [],
): any {
  let obj = queryString.parse(search);
  obj = { ...obj }; // convert objects without prototypes to objects with prototypes
  let query = obj.q;
  if (query) {
    query = decodeURIComponent(query);
  }
  delete obj.q;

  const trees: any = {};
  // addon by gvo
  // splitting of url-param with multiple values - quickfix
  for (const param in obj) {
    let paramValue = obj[param];
    paramValue = decodeURIComponent(paramValue);
    if (paramValue) {
      if (treeRoots.constructor === Array && treeRoots.includes(param)) {
        // if tree param
        trees[param] = {};
        let node;
        if (paramValue.indexOf("¦") > -1) {
          const newParam = paramValue.split("¦");
          newParam.forEach(item => {
            node = allClassifications.find(f => f.objectID === item);
            if (node.parentId === param) {
              // 1st level
              trees[param][item] = true;
            } else {
              // deeper level
              const parentIds = {};
              for (let i = 0; i < node.parentIds.length; ++i) {
                parentIds[node.parentIds[i]] = true;
              }
              trees[param][item] = parentIds;
            }
          });
        } else {
          node = allClassifications.find(f => f.objectID === paramValue);
          if (node.parentId === param) {
            // 1st level
            trees[param][paramValue] = true;
          } else {
            // deeper level
            const parentIds = {};
            for (let i = 0; i < node.parentIds.length; ++i) {
              parentIds[node.parentIds[i]] = true;
            }
            trees[param][paramValue] = parentIds;
          }
        }
        delete obj[param];
      } else {
        // added for google adwords additional parts in query
        if (COMPONA_STATIC_CACHE.attributes.hasOwnProperty(param)) {
          // if facet param
          if (paramValue.indexOf("¦") > -1) {
            const newParam = paramValue.split("¦");
            obj[param] = newParam;
          } else {
            if (typeof paramValue === "string" || paramValue instanceof String) {
              obj[param] = [paramValue];
            } else {
              // TODO "parseUrlToSearchState->parseHashUrl: TODO CHECK TYPE"
              // console.log("parseUrlToSearchState->parseHashUrl: TODO CHECK TYPE");
            }
          }
        } else {
          delete obj[param];
        }
      }
    }
  }

  /*
  try {
    for (const item in obj) {
      // gvo: split only if item is string
      if (typeof obj[item] === "string" || obj[item] instanceof String) {
        //obj[item] = obj[item].split("gaga");
        obj[item] = [obj[item]];
      }
    }
  } catch (e) {
    console.log("parseUrlToSearchState->parseHashUrl: TODO CHECK TYPE");
  }
  */

  return {
    query,
    filterSettings: obj,
    trees,
  };
}

export function searchQueryToUrl(search: SearchState, includeTree = true): string {
  const urlObj: any = {};

  if (search.query) urlObj.q = `${search.query}`;
  for (const item in search.filterSettings) {
    if (search.filterSettings[item].length > 0) {
      // changed by gvo
      // joining of search-param with multiple values - quickfix
      // orig: urlObj[item] = search.filterSettings[item];
      if (search.filterSettings[item] instanceof Array) {
        urlObj[item] = search.filterSettings[item].join("¦");
      } else {
        urlObj[item] = search.filterSettings[item];
      }
    }
  }

  if (includeTree) {
    for (const root in search.trees) {
      if (Object.keys(search.trees[root]).length > 0) {
        if (Object.keys(search.trees[root]).length > 1) {
          const arr: any = [];
          for (const item in search.trees[root]) {
            arr.push(item);
            urlObj[root] = arr.join("¦");
          }
        } else {
          for (const item in search.trees[root]) {
            urlObj[root] = item;
          }
        }
      }
    }
  }
  for (const param in urlObj) {
    const value = encodeURIComponent(urlObj[param]);
    urlObj[param] = value;
  }

  return "?" + queryString.stringify(urlObj);
}
