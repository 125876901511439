import * as React from "react";
import { Helmet } from "react-helmet";

interface Props {
  type: string;
  title: string;
  description: string;
  url: string;
  image?: string;
  siteName?: string;
}

class SMTags extends React.PureComponent<Props> {

  render() {
    let siteName = "Compona";
    if ( typeof this.props.siteName === "string" && this.props.siteName !== "" ) {
      siteName = this.props.siteName;
    }
    return (
      <Helmet>
        <meta property="og:site_name" content={siteName} />
        <meta property="og:type" content={this.props.type} />
        <meta property="og:title" content={this.props.title} />
        <meta property="og:description" content={this.props.description} />
        <meta property="og:url" content={this.props.url} />
        <meta property="og:image" content={this.props.image} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={this.props.title} />
        <meta name="twitter:description" content={this.props.description} />
        <meta name="twitter:image" content={this.props.image} />
      </Helmet>
    );
    // <meta name="twitter:site" content="@ComponaAG" />
  }
}
export default SMTags;
