import * as React from "react";
import { Link } from "react-router-dom";

import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { ShippingOptionVo } from "../../common/vo/ShippingOptionVo";

declare var COMPONA_STATIC_CACHE;

interface Props {
  sum: number;
  shipping: number;
  total: number;
  dkoLiefartCd: string;
  removeProductsFromList: (listId) => void;
  startCheckout: () => void;
  listId?: string;
  dstIsoCurrent: string;
}

class CartFooter extends React.PureComponent<Props> {
  removeProductsFromList = () => {
    if (this.props.listId) this.props.removeProductsFromList(this.props.listId);
  };

  startCheckout = e => {
    e.preventDefault();
    this.props.startCheckout();
  };

  render() {
    let shippingDescription = "";
    const shippingOption: ShippingOptionVo = window.COMPONA_STATIC_CACHE.lieferarten.find(
      shippingOption => shippingOption.liefartCd === this.props.dkoLiefartCd,
    );
    if (shippingOption && shippingOption.description) {
      shippingDescription = shippingOption.description[activeLang()];
    }

    return (
      <div className="cart__footer">
        <dl className="cart-footer__list">
          <dt className="cart-footer__title">{dic("SUMME")}</dt>
          <dd className="cart-footer__cont">
            {`${this.props.dstIsoCurrent} ${this.props.sum.toFixed(4)}`}
          </dd>
          <dt className="cart-footer__title">
            {dic("VERSAND")} {shippingDescription}{" "}
            <Link to={`/cms/${activeLang()}/${dic("CMSURL_VERSAND_BEZAHLUNG")}`}>
              <span className="tip-marker" />
            </Link>
          </dt>
          <dd className="cart-footer__cont">{`${
            this.props.dstIsoCurrent
          } ${this.props.shipping.toFixed(4)}`}</dd>
          <dt className="cart-footer__title price-total">{`${dic(
            "GESAMTBETRAG MIT MWST",
          )} (${config("vatRate")}%)`}</dt>
          <dd className="cart-footer__cont price-total">{`${
            this.props.dstIsoCurrent
          } ${this.props.total.toFixed(4)}`}</dd>
        </dl>
        <div className="cart-footer__submit">
          <input
            className="input--reset"
            type="reset"
            value={dic("WARENKORB LEEREN")}
            onClick={this.removeProductsFromList}
          />

          <input
            className="input--submit"
            type="submit"
            value={dic("ZUR KASSE")}
            onClick={this.startCheckout}
          />
        </div>
      </div>
    );
  }
}

export default CartFooter;
