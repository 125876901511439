import * as React from "react";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import SearchSuggestionVo from "../../vo/SearchSuggestionVo";
import { SearchActions } from "../../../search/actions/searchActions";

interface Props {
  suggestion: SearchSuggestionVo;
}

class SuggestionTag extends React.PureComponent<Props> {
  onClickLink = e => {
    ElstrCore.store.dispatch(SearchActions.onClickSuggestion(this.props.suggestion));
  };

  render() {
    return (
      <span className={"suggestion-tag"} onClick={this.onClickLink}>
        {this.props.suggestion.descriptionAndValueText}
      </span>
    );
  }
}

export default SuggestionTag;
