import { LayoutActions } from "../../layout/actions/layoutActions";
import { SearchActionTypes } from "../../search/actions/ActionTypes";
import { searchQueryToUrl, parseHashUrl } from "../../common/utils/parseHashUrl";
import { routerActions } from "connected-react-router";
import { SearchActions } from "../../search/actions/searchActions";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

export const HomeSearchActions = {
  /**
   * Parse Url string
   * @param location
   * @returns {(dispatch) => Promise<void>}
   */
  parseURL: function(location: Location) {
    return async dispatch => {
      const parsedUrl = parseHashUrl(location.search);
      if (parsedUrl.query) {
        dispatch(HomeSearchActions.searchChange(parsedUrl.query));
      }
    };
  },

  /**
   * Catalog clicked action
   * @returns {(dispatch, getState) => void}
   */
  catalogClicked: function() {
    return (dispatch, getState) => {
      dispatch(
        routerActions.push("/catalog/" + activeLang() + "/" + searchQueryToUrl(getState().search)),
      );
    };
  },

  /**
   *
   * @returns {(dispatch, getState) => void}
   */
  showResultClicked: function() {
    return (dispatch, getState) => {
      dispatch(LayoutActions.openSearch(false));
      dispatch(
        routerActions.push("/catalog/" + activeLang() + "/" + searchQueryToUrl(getState().search)),
      );
    };
  },

  /**
   * Clear search query
   * @returns {(dispatch) => void}
   */
  searchClear: function() {
    return (dispatch, getState) => {
      dispatch(LayoutActions.openSearch(false));
      dispatch(HomeSearchActions.clearDelayResult());
      dispatch(HomeSearchActions.clearSearchInput());
      dispatch(routerActions.push(searchQueryToUrl(getState().search)));
    };
  },

  /**
   * Handle Text search change
   * @returns {(dispatch) => void}
   */
  searchChange: function(query: string) {
    return (dispatch, getState) => {
      dispatch(HomeSearchActions.displaySearchValue(query));
      // dispatch(routerActions.push(searchQueryToUrl(getState().search)));

      if (query.length >= 2) {
        dispatch(SearchActions.changeResultByQuery());
        dispatch(LayoutActions.openSearch(true));
      } else {
        if (query.length === 0) dispatch(SearchActions.changeResultByQuery());
        dispatch(LayoutActions.openSearch(false));
        dispatch(HomeSearchActions.clearDelayResult());
      }
    };
  },

  /**
   *
   * @param query
   * @returns {{type: SearchActionTypes; query: any}}
   */
  displaySearchValue: function(query) {
    return { type: SearchActionTypes.SEARCH_TEXT_CHANGE, query };
  },

  /**
   *
   * @returns {{type: SearchActionTypes}}
   */
  clearSearchInput: function() {
    return { type: SearchActionTypes.SEARCH_TEXT_RESET };
  },

  /**
   *
   * @returns {(dispatch) => Promise<void>}
   */
  clearDelayResult: function() {
    return async dispatch => {
      setTimeout(() => {
        dispatch(HomeSearchActions.clearResult());
      }, 300);
    };
  },

  /**
   *
   * @returns {{type: SearchActionTypes}}
   */
  clearResult: function() {
    return { type: SearchActionTypes.CLEAR_SEARCH_RESULT };
  },
};
