import filterPanelReducer from "./filterPanelReducer";
import filterPanelTreeReducer from "./filterPanelTreeReducer";
import { combineReducers } from "redux";

export const filterPanelReducers = {
  productFilter: combineReducers({
    panelData: filterPanelReducer,
    treeData: filterPanelTreeReducer,
  }),
};
