import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import DragDropTagButtonInner from "./DragDropTagButtonInner";

interface Props {
  id: any;
  label: string;
  index: number;
  onRemoveClick: (e) => void;
}

class DragDropTagButton extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);
  }

  onRemoveClickHandler() {
    this.props.onRemoveClick(this.props.id);
  }

  render() {
    return (
      // @ts-ignore
      <Draggable key={this.props.id} draggableId={this.props.id} index={this.props.index}>
        {(provided, snapshot) => (
          <div style={{ flexShrink: 0 }}>
            <DragDropTagButtonInner
              provided={provided}
              onRemoveClickHandler={this.onRemoveClickHandler}
              index={this.props.index}
              label={this.props.label}
            />
          </div>
        )}
      </Draggable>
    );
  }
}

export default DragDropTagButton;
