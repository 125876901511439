import * as React from "react";
import { SearchState, State } from "../../State";
import { connect } from "react-redux";
import { HomeSearchActions } from "../actions/homeSearchActions";
import ProductVo from "../../common/vo/ProductVo";
import SearchInput from "../../common/components/input/SearchInput";
import SearchLayer from "../../common/components/list/SearchLayer";
import { HomeDataActions } from "../actions/homeDataActions";
import NewProductsList from "../components/NewProductsList";
import TagList from "../../common/components/list/TagList";
import SimpleButton, { SimpleButtonTypes } from "../../common/components/button/SimpleButton";
import { compose } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Teaser from "../components/Teaser";
import { Link } from "react-router-dom";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { ProductActions } from "../../product/actions/productActions";
import ComponaLogoTop from "../../layout/components/ComponaLogoTop";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { routerActions } from "connected-react-router";
import Helmet from "react-helmet";
import SMTags from "../../common/components/metatags/SMTags";

const smImage = config("smImage");

declare var ELSTR;

interface HomeStateToProps {
  // Search
  searchState: SearchState;
  newProducts: ProductVo[];
  searchOpen: boolean;
}

interface HomeDispatchToProps {
  onSearchValueChanged: (e: string) => void;
  onClickCatalog: () => void;
  onClickShowResult: () => void;
  getHomeData: () => void;
  onMount: () => void;
  onClickProductLink: (product: ProductVo) => void;
}

interface Props extends HomeStateToProps, HomeDispatchToProps, RouteComponentProps<any> {}

class Home extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    this.props.getHomeData();
    this.props.onMount();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.lang && this.props.match.params.lang !== activeLang()) {
      ElstrCore.store.dispatch(
          routerActions.replace(`/home/${activeLang()}/${window.location.search}`),
      );
    }
  }

  onSearchChange(e) {
    this.props.onSearchValueChanged(e.target.value);
  }

  render() {
    const imgixUrl = config("imgixUrl");
    const image = imgixUrl + "/" + smImage;
    return (
        <main className="main home__main" role="main">
          <Helmet>
            {config("supportedLanguages").map(lang => {
              return (
                  <link
                      key={`link_${lang}`}
                      rel="alternate"
                      hrefLang={lang}
                      href={`${window.location.protocol}//${window.location.host}/home/${lang}/`}
                  />
              );
            })}
          </Helmet>

          <SMTags type="website" title={`${dic("TITLE")} – ${dic("TITLE_HOME_ADDON")}`} description={dic("META DESCRIPTION")} url={`${window.location.protocol}//${window.location.host}/home/${activeLang()}/`} image={image} />

          <div className="home-main__logo">
            <ComponaLogoTop />
          </div>
          <div className="home__content">
            <h1 className="home__title">{dic("DIE PASSENDE LOESUNG FUER JEDE STECKVERBINDUNG")}</h1>
            {ELSTR.hasOwnProperty("applicationEnv") &&
            ELSTR.applicationEnv !== "production" && (
                <div className="content-alert-box">
                  <div className="content-alert-box__alert">
                    <span className="sicon sicon__warning" />
                  </div>
                  <div className="content-alert-box__content">
                    <p className="content-alert-box__text">
                      {`${dic("UMGEBUNG")}: ${ELSTR.applicationEnv.toUpperCase()}`}
                    </p>
                  </div>
                </div>
            )}
          </div>
          <div className="home__searchbox">
            <div className="home__content">
              <h2 className="subtitle">{`${dic("PRODUKT FINDEN")}:`}</h2>
              <div className="searchbox-wrap" role="search">
                <SearchInput
                    value={this.props.searchState.query || ""}
                    onChange={this.onSearchChange}
                    placeHolder={dic("NACH ARTIKELNUMMER HERSTELLER FUNKTION SPEZIFIKATIONEN")}
                    onSubmit={this.props.onClickShowResult}
                />
              </div>

              <SearchLayer
                  searchCount={this.props.searchState.searchCount}
                  searchResult={this.props.searchState.result}
                  suggestions={this.props.searchState.suggestions}
                  showProductSearchResult={this.props.searchOpen}
                  showSuggestionList={this.props.searchOpen}
                  onClickProductLink={this.props.onClickProductLink}
              />

              <div className="home-searchbox__tag-submit-wrap">
                <div className="home-searchbox__tags">
                  <h3 className="smltitle">{`${dic("MEIST GESUCHT")}:`}</h3>
                  <TagList />
                </div>
                <div className="home-searchbox__submit">
                  <SimpleButton
                      label={dic("ZUM KATALOG UND SHOP")}
                      type={SimpleButtonTypes.SUBMIT}
                      onClick={this.props.onClickCatalog}
                      onSubmit={this.props.onClickCatalog}
                  />
                </div>
              </div>
            </div>
          </div>

          <Teaser
              teasers={[
                {
                  imgUrl: `${imgixUrl}/cms/grunt/public/assets/img/home-teaser-01.jpg?auto=format&fm=jpg`,
                  imgUrlMbl: `${imgixUrl}/cms/grunt/public/assets/img/home-teaser-01-mbl.png?auto=format&fm=jpg`,
                  btnUrl: `/cms/${activeLang()}/${dic("CMSURL_SERVICES")}`,
                  btnLabel: "SERVICES",
                  title: "HOME SERVICES LEITSATZ",
                },
                {
                  imgUrl: `${imgixUrl}/cms/grunt/public/assets/img/home-teaser-02.jpg?auto=format&fm=jpg`,
                  imgUrlMbl: `${imgixUrl}/cms/grunt/public/assets/img/home-teaser-02-mbl.png?auto=format&fm=jpg`,
                  btnUrl: `/cms/${activeLang()}/${dic("CMSURL_AKTUELLES")}`,
                  btnLabel: "AKTUELLES",
                  title: "HOME AKTUELLES LEITSATZ",
                },
                {
                  imgUrl: `${imgixUrl}/cms/grunt/public/assets/img/home-teaser-03.jpg?auto=format&fm=jpg`,
                  imgUrlMbl: `${imgixUrl}/cms/grunt/public/assets/img/home-teaser-03-mbl.png?auto=format&fm=jpg`,
                  btnUrl: `/cms/${activeLang()}/${dic("CMSURL_ABOUTUS")}`,
                  btnLabel: "UEBER UNS",
                  title: "HOME UEBER UNS LEITSATZ",
                },
              ]}
          />

          <div className="home__content home__products">
            <h2 className="subtitle home__subtitle">{`${dic("DIE NEUSTEN PRODUKTE")}:`}</h2>

            <NewProductsList
                data={this.props.newProducts}
                onClickProductLink={this.props.onClickProductLink}
            />

            <div className="home__content-bottom-btn">
              <Link to={`/catalog/${activeLang()}/`} className="btn btn--arrow" title={dic("WEITERE PRODUKTE")}>
                {dic("WEITERE PRODUKTE")}
              </Link>
            </div>
          </div>
        </main>
    );
  }
}

const mapStateToProps = (state: State): HomeStateToProps => ({
  searchState: state.search,
  newProducts: state.home.newProducts,
  searchOpen: state.layout.searchOpen,
});

const mapDispatchToProps = (dispatch): HomeDispatchToProps => ({
  onSearchValueChanged: (value: string) => dispatch(HomeSearchActions.searchChange(value)),
  onClickCatalog: () => dispatch(HomeSearchActions.catalogClicked()),
  onClickShowResult: () => dispatch(HomeSearchActions.showResultClicked()),
  getHomeData: () => dispatch(HomeDataActions.getNewestProducts()),
  onClickProductLink: product => dispatch(ProductActions.setInitialData(product)),
  onMount: () => {
    dispatch(LayoutActions.showFilterPanel(false));
    dispatch(LayoutActions.showHomeHeader(true));
    dispatch(LayoutActions.openSearch(false));
    dispatch(HomeSearchActions.parseURL(window.location));
  },
});

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Home);

/*
<div className="home-searchbox__submit">
    <a href="catalog.html" className="btn btn--arrow">Zum Katalog & shop</a>
</div>
*/
