import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import Image from "../../common/components/media/Image";
import ProductTake, { ProductTakeType } from "../components/ProductTake";
import ProductPrice from "../components/ProductPrice";
import ProductTakeVo from "../../common/vo/ProductTakeVo";
import ProductSpecialAttributes from "./ProductSpecialAttributes";
import { getUnitDescription } from "../../common/utils/unitUtils";
import { ListType } from "../../common/constants/Constants";
import { productCanBeAddedToCart } from "../../common/utils/productUtils";
import ToolTip from "../../common/components/button/ToolTip";
import ProductCardFile from "./ProductCardFile";

interface Props {
  data: ProductVo;
  take: ProductTakeVo | null;
  title: string;
  priceScaleArray: any[];
  onClickTakeChange: (direction: string) => void;
  onInputTakeChange: (quantityString: string) => void;
  addProductProductCard: (listType, objectID, pos, comment) => void;
}

class ProductCard extends React.PureComponent<Props> {
  renderProductDownloads() {
    if (this.props.data.elstrFiles) {
      return this.props.data.elstrFiles.map((file, index) => {
        // show everything but pictures
        if (file.type !== "bild") {
          return <ProductCardFile file={file} key={index} />;
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }

  renderProductLink() {
    if (this.props.data.herstellerwebsite) {
      return (
        <li>
          <a href={this.props.data.herstellerwebsite} target="_blank"  rel="noopener noreferrer" className="link--url">
            {dic("HERSTELLERWEBSITE")}
          </a>
        </li>
      );
    } else {
      return null;
    }
  }

  scrollToRelatedTabsTitle(e) {
    e.preventDefault();

    const nodeRelatedTabsTitle = document.getElementById("relatedTabsTitle") || { offsetTop: 0 };
    const header = document.getElementById("header") || { clientHeight: 0 };

    // we will subtract the height of the header from the new scrolling position
    const scrollPosY = nodeRelatedTabsTitle.offsetTop - header.clientHeight;
    window.scrollTo(0, scrollPosY);
  }

  renderAndereVerpackungAnLager() {
    if (
      this.props.data.hasOwnProperty("andereverpackungInStock") &&
      this.props.data.andereverpackungInStock === true
    ) {
      return (
        <div onClick={this.scrollToRelatedTabsTitle} className="link--anchor-down">
          {dic("ANDERE VERPACKUNG AN LAGER")}
        </div>
      );
    } else {
      return null;
    }
  }

  renderAlternativproduktAnLager() {
    if (
      this.props.data.hasOwnProperty("alternativproduktInStock") &&
      this.props.data.alternativproduktInStock === true &&
      this.props.data.verfuegbareMenge === 0
    ) {
      return (
        <div onClick={this.scrollToRelatedTabsTitle} className="link--anchor-down">
          {dic("ALTERNATIVE ARTIKEL AN LAGER")}
        </div>
      );
    } else {
      return null;
    }
  }

  renderKommunikationText() {
    if (this.props.data.status && this.props.data.status === 105) {
      if (productCanBeAddedToCart(this.props.data)) {
        return null;
      } else {
        return <div className="row">{dic("FUER WEITERE INFORMATIONEN KONTAKT")}</div>;
      }
    }
    if (this.props.data.status && this.props.data.status === 400) {
      if (productCanBeAddedToCart(this.props.data)) {
        return null;
      } else {
        return <div className="row">{dic("FUER WEITERE INFORMATIONEN KONTAKT")}</div>;
      }
    }
    if (this.props.data.status && this.props.data.status > 650) {
      return (
        <div className="row">
          <strong>{dic("PRODUCT STATUS " + this.props.data.status)}</strong>
        </div>
      );
    }

    return null;
  }

  addProductToWishlist = () => {
    const listType = ListType.wishlist;
    const objectID = this.props.data.objectID;
    const pos = null;
    const comment = "";
    this.props.addProductProductCard(listType, objectID, pos, comment);
  };

  addProductToCart = () => {
    const listType = ListType.cart;
    const objectID = this.props.data.objectID;
    const pos = null;
    const comment = "";
    this.props.addProductProductCard(listType, objectID, pos, comment);
  };

  render() {
    if (
      this.props.data.status &&
      this.props.data.status < 700 &&
      this.props.data.mindestbestellmenge
    ) {
      const verkaufsEinheit: string = getUnitDescription(
        this.props.data.verkaufsEinheitNummer,
        activeLang(),
      );
      const verkaufsVerpackungsEinheit: string = getUnitDescription(
        this.props.data.verkaufsVerpackungsEinheitNummer,
        activeLang(),
      );

      return (
        <div className="product-card">
          <div className="product-card__left">
            <div className="product-card__img">
              <Image
                data={this.props.data.elstrImage}
                w={"400"}
                h={"400"}
                alt={this.props.title}
                title={this.props.title}
              />
              {this.props.data.elstrImage && (
                <span className="caption">{dic("ABBILDUNG AEHNLICH")}</span>
              )}
              <ToolTip
                id={`${this.props.data.objectID}-abbildung`}
                description={dic("ABBILDUNG AEHNLICH TOOLTIP")}
                placement="bottomLeft"
                overlayClassName="product-img-tooltip"
              />
            </div>
            <div className="product-card__content">
              <div className="row">
                <ProductSpecialAttributes data={this.props.data} />
                <ul>
                  {this.renderProductDownloads()}
                  {this.renderProductLink()}
                </ul>
                {this.props.data.elstrFilesMsg && this.props.data.elstrFilesMsg.length > 0 && (
                  <p className="form__error-message">{dic(this.props.data.elstrFilesMsg)}</p>
                )}
              </div>
              <div className="row">
                <dl className="product-info">
                  <dt className="product-info__title">{`${dic("VERFUEGBARKEIT")}:`}</dt>
                  <dd className="product-info__cont">
                    <em className={this.props.data.verfuegbareMenge > 0 ? "is-available" : ""}>
                      {`${this.props.data.verfuegbareMenge} ${dic("AUF LAGER")}  `}
                    </em>
                    <ToolTip
                      id={`${this.props.data.objectID}-lager`}
                      description={dic("VERFUEGBARKEIT LAGER HINT")}
                      placement="top"
                    />
                  </dd>
                  {this.props.data.hasOwnProperty("verfuegbareMengeTti") &&
                    this.props.data.verfuegbareMengeTti > 0 && (
                      <>
                        <dt className="product-info__title" />
                        <dd className="product-info__cont">
                          <em
                            className={this.props.data.verfuegbareMenge === 0 ? "is-available" : ""}
                          >
                            {`${this.props.data.verfuegbareMengeTti} ${dic("INNERT X TAGEN")}  `}
                          </em>
                          <ToolTip
                            id={`${this.props.data.objectID}-lagerTti`}
                            description={dic("WARE AB EXTERNEM LAGER HINT")}
                            placement="top"
                          />
                        </dd>
                      </>
                    )}

                  <dt className="product-info__title">{`${dic("MINDESTBESTELLMENGE")}:`}</dt>
                  <dd className="product-info__cont">
                    {this.props.data.mindestbestellmenge} {verkaufsEinheit}
                  </dd>
                  <dt className="product-info__title">{`${dic("AUSLIEFERZEIT")}:`}</dt>
                  <dd className="product-info__cont">
                    {this.props.data.lieferfrist} {dic("TAGE")}
                  </dd>
                  <dt className="product-info__title">{`${dic("VERPACKUNGSEINHEIT")}:`}</dt>
                  <dd className="product-info__cont">{verkaufsVerpackungsEinheit}</dd>
                </dl>
                {this.renderAndereVerpackungAnLager()}
                {this.renderAlternativproduktAnLager()}
              </div>
            </div>
          </div>
          <div className="product-card__right">
            {this.renderKommunikationText()}
            {productCanBeAddedToCart(this.props.data) && (
              <ProductPrice
                mengensprung={this.props.data.mengensprung || null}
                priceScaleArray={this.props.priceScaleArray}
              />
            )}
            {productCanBeAddedToCart(this.props.data) && this.props.take !== null && this.props.take && (
              <ProductTake
                take={this.props.take}
                priceScaleArray={this.props.priceScaleArray}
                onClickTakeChange={this.props.onClickTakeChange}
                onInputTakeChange={this.props.onInputTakeChange}
                type={ProductTakeType.product}
              />
            )}

            <div className="row row--submit">
              <div className="btn-wrap">
                {productCanBeAddedToCart(this.props.data) && (
                  <button className="btn btn--add-cart" onClick={this.addProductToCart}>
                    {dic("IN DEN WARENKORB")}
                  </button>
                )}
                <button className="btn btn--add-memo" onClick={this.addProductToWishlist}>
                  {dic("ZU MERKLISTE HINZUFUEGEN")}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="product-card">
          <div className="product-card__left">
            <div className="product-card__img">
              <Image data={this.props.data.elstrImage} w={"400"} h={"400"} />
            </div>
            <div className="product-card__content">
              <div className="row">
                <ProductSpecialAttributes data={this.props.data} />
                <ul>
                  {this.renderProductDownloads()}
                  {this.renderProductLink()}
                </ul>
              </div>
            </div>
          </div>
          <div className="product-card__right">
            {this.renderKommunikationText()}
            <div className="row row--submit">
              <div className="btn-wrap">
                <button className="btn btn--add-memo" onClick={this.addProductToWishlist}>
                  {dic("ZU MERKLISTE HINZUFUEGEN")}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ProductCard;
