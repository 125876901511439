export enum SearchActionTypes {
  FACETS_CHANGE = "FACETS_CHANGE",
  GROUP_CHANGE = "GROUP_CHANGE",
  SEARCH_TEXT_CHANGE = "SEARCH_TEXT_CHANGE",
  SEARCH_TEXT_RESET = "SEARCH_TEXT_RESET",
  SEARCH_URL_CHANGED = "SEARCH_URL_CHANGED",
  SHOW_SEARCH_RESULT = "SHOW_SEARCH_RESULT",
  CLEAR_SEARCH_RESULT = "CLEAR_SEARCH_RESULT",
  SET_SUGGESTIONS = "SET_SUGGESTIONS",
  SET_SHOW_ITEMS = "SET_SHOW_ITEMS",
  SET_SORTING = "SET_SORTING",
  RESET_SORTING = "RESET_SORTING",
}
