import { SearchState } from "../../State";
import { SearchActionTypes } from "../actions/ActionTypes";
import { CommonActionTypes } from "../../common/actions/CommonActionTypes";
import { parseHashUrl, parseUrlToSearchState } from "../../common/utils/parseHashUrl";
import SearchGroupVo from "../../common/vo/SearchGroupVo";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { LONG_DASH } from "../../common/utils/rangeUtils";

declare var COMPONA_STATIC_CACHE;

const searchReducer = (state: SearchState, action) => {
  if (!state) return null;

  const staticCacheAttributes = window.COMPONA_STATIC_CACHE.attributes;
  const treeRoots: any = [];
  for (const dataAttributeKey in staticCacheAttributes) {
    const attribute = staticCacheAttributes[dataAttributeKey];
    if (attribute.dataType === "tree") {
      treeRoots.push(attribute.dataAttributeKey);
    }
  }
  const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];

  switch (action.type) {
    case SearchActionTypes.SEARCH_URL_CHANGED:
      const group: SearchGroupVo | undefined = parseUrlToSearchState(
        action.payload.location.pathname,
        staticCacheClassifications,
      );

      const parsedUrl = parseHashUrl(
        action.payload.location.search,
        treeRoots,
        staticCacheClassifications,
      );

      let trs = {};
      if (group) {
        // trees[group.classificationId] = new Array(group.objectID);
        const objs = {};
        const parentIds = {};
        for (let i = 0; i < group.parentIds.length; ++i) {
          parentIds[group.parentIds[i]] = true;
        }
        objs[group.objectID] = parentIds;
        trs[group.classificationId] = objs;
      } else {
        trs = parsedUrl.trees;
      }

      return {
        ...state,
        group,
        filterSettings: parsedUrl.filterSettings,
        query: parsedUrl.query,
        trees: trs,
      };

    case CommonActionTypes.LOCATION_CHANGE:
      const grp: SearchGroupVo | undefined = parseUrlToSearchState(
        action.payload.location.pathname,
        staticCacheClassifications,
      );

      const parsedURL = parseHashUrl(
        action.payload.location.search,
        treeRoots,
        staticCacheClassifications,
      );

      let trees = {};
      if (grp) {
        // trees[group.classificationId] = new Array(group.objectID);
        const objs = {};
        const parentIds = {};
        for (let i = 0; i < grp.parentIds.length; ++i) {
          parentIds[grp.parentIds[i]] = true;
        }
        objs[grp.objectID] = parentIds;
        trees[grp.classificationId] = objs;
      } else {
        trees = parsedURL.trees;
      }

      return {
        ...state,
        filterSettings: parsedURL.filterSettings,
        query: parsedURL.query,
        group: grp,
        trees,
      };

    case SearchActionTypes.FACETS_CHANGE:
      const nFilterSettings: { [key: string]: string[] } = { ...state.filterSettings };
      const facetId: string = action.facetId; // NOTE: because facet types behave differently, this is sometimes an id, sometimes a value
      const valueId: string = action.valueId;

      if (action.add === false) {
        const facet = [...nFilterSettings[facetId]];

        facet.splice(
          facet.findIndex(el => el === valueId),
          1,
        ); // remove the value from the list

        if (!facet || facet.length === 0) {
          delete nFilterSettings[facetId];
        } else {
          nFilterSettings[facetId] = facet;
        }

        return { ...state, filterSettings: nFilterSettings };
      }

      const values = nFilterSettings[facetId];
      if (!values) {
        nFilterSettings[facetId] = [valueId];
      } else if (values[0].indexOf(LONG_DASH) >= 0) {
        // range slider, overwrite
        nFilterSettings[facetId] = [valueId];
      } else {
        // not range slider, add value
        const nValues = [...values];
        nValues.push(valueId);
        nFilterSettings[facetId] = nValues;
      }

      return { ...state, filterSettings: nFilterSettings };

    case SearchActionTypes.GROUP_CHANGE:
      const nTrees = { ...state.trees };
      let nGroup = { ...state.group };

      // delete from trees
      if (action.add === false) {
        let treeNode = action.node;
        if (typeof action.node === "string") {
          treeNode = staticCacheClassifications.find(classi => classi.objectID === action.node);
        } else {
          treeNode = action.node;
        }
        const crumbs = treeNode.breadcrumbId.split(" > ");
        // if current node is leave
        if (nTrees[crumbs[0]].hasOwnProperty(treeNode.objectID)) {
          const nSubTree = { ...nTrees[crumbs[0]] };
          // if current node has parent
          if (Object.keys(nSubTree[treeNode.objectID]).length > 0) {
            let nParents = nSubTree[treeNode.objectID];
            // if parent has other child
            let otherChildPresent = false;
            for (const key in nSubTree) {
              if (key !== treeNode.objectID && nSubTree[key].hasOwnProperty(treeNode.parentId)) {
                otherChildPresent = true;
              }
            }
            // if parent has no other child and is not root -> add parent to search
            if (otherChildPresent === false && treeNode.parentId !== crumbs[0]) {
              for (const parentKey in nParents) {
                if (nParents[parentKey] === treeNode.parentId) {
                  if (Object.keys(nParents).length > 1) {
                    delete nParents[parentKey];
                  } else {
                    nParents = [];
                  }
                }
              }
              nSubTree[treeNode.parentId] = nParents;
            }
          }
          // remove current from search
          delete nSubTree[treeNode.objectID];
          nTrees[crumbs[0]] = nSubTree;
          if (Object.keys(nTrees[crumbs[0]]).length === 0) {
            delete nTrees[crumbs[0]];
          }
        } else {
          const nSubTree = { ...nTrees[crumbs[0]] };
          // for each leave with the current node in parent ids we have to delete the leave
          for (const leaveKey in nSubTree) {
            if (nSubTree[leaveKey].hasOwnProperty(treeNode.objectID)) {
              delete nSubTree[leaveKey];
            }
          }
          // if no other leave with node parent in parent ids is present - set parent as leave
          let count = 0;
          for (const leaveKey in nSubTree) {
            if (nSubTree[leaveKey].hasOwnProperty(treeNode.parentId)) {
              count += 1;
            }
          }
          if (count === 0) {
            if (!nTrees.hasOwnProperty(treeNode.parentId)) {
              nSubTree[treeNode.parentId] = [];
            }
          }
          nTrees[crumbs[0]] = nSubTree;
        }

        let leaveCount = 0;
        let remainingLeaveRoot = "";
        for (const rootKey in nTrees) {
          if (Object.keys(nTrees[rootKey]).length === 0) {
            delete nTrees[rootKey];
          } else {
            leaveCount += Object.keys(nTrees[rootKey]).length;
            remainingLeaveRoot = rootKey;
          }
        }

        if (leaveCount === 1 && remainingLeaveRoot !== "") {
          const grpData = { classificationId: "", objectID: "" };
          grpData.classificationId = remainingLeaveRoot;
          for (const leaveKey in nTrees[remainingLeaveRoot]) {
            grpData.objectID = leaveKey;
          }
          nGroup = grpData;
        } else {
          nGroup = {};
        }
        return { ...state, group: nGroup, trees: nTrees };
      }

      // start of add case
      const parentIds = {};
      for (let i = 0; i < action.node.parentIds.length; ++i) {
        parentIds[action.node.parentIds[i]] = true;
      }
      // it is the first tree element (trees in state is empty)
      if (Object.keys(nTrees).length === 0 && nTrees.constructor === Object) {
        nGroup = action.node;
        const objs = {};
        objs[action.node.objectID] = parentIds;
        nTrees[action.node.classificationId] = objs;
      } else {
        const crumbs = action.node.breadcrumbId.split(" > ");
        // if tree root not in search so far
        if (!nTrees.hasOwnProperty(crumbs[0])) {
          const objs = {};
          objs[action.node.objectID] = parentIds;
          nTrees[action.node.classificationId] = objs;
        } else {
          // if parent of current node is in search
          if (nTrees[crumbs[0]].hasOwnProperty(action.node.parentId)) {
            // add new node to search
            const nSubTree = { ...nTrees[crumbs[0]] };
            nSubTree[action.node.objectID] = parentIds;
            // remove parent from search
            delete nSubTree[action.node.parentId];
            nTrees[crumbs[0]] = nSubTree;
          } else {
            const nSubTree = { ...nTrees[crumbs[0]] };
            nSubTree[action.node.objectID] = parentIds;
            nTrees[crumbs[0]] = nSubTree;
          }
        }
        let leaveCount = 0;
        for (const rootKey in nTrees) {
          leaveCount += Object.keys(nTrees[rootKey]).length;
        }
        if (leaveCount === 1) {
          nGroup = action.node;
        } else {
          nGroup = {};
        }
      }

      return { ...state, group: nGroup, trees: nTrees };

    case SearchActionTypes.SEARCH_TEXT_CHANGE:
      return { ...state, query: action.query };

    case SearchActionTypes.SEARCH_TEXT_RESET:
      return { ...state, query: "" };

    case SearchActionTypes.SHOW_SEARCH_RESULT:
      return { ...state, result: action.data.hits, searchCount: action.data.nbHits };

    case SearchActionTypes.CLEAR_SEARCH_RESULT:
      return { ...state, result: [], searchCount: 0 };

    case SearchActionTypes.SET_SUGGESTIONS:
      return { ...state, suggestions: action.suggestions.hits };

    case SearchActionTypes.SET_SHOW_ITEMS:
      return { ...state, showItems: action.hits };

    case SearchActionTypes.SET_SORTING:
      const sorting = { key: action.column.dataAttributeKey, direction: action.direction };
      return { ...state, sorting: sorting };

    case SearchActionTypes.RESET_SORTING:
      return { ...state, sorting: {} };
  }

  return state;
};

export default searchReducer;
