import { UserActionTypes } from "./ActionTypes";
import COMPONA_WidgetServer_JSON_User from "../../common/services/COMPONA_WidgetServer_JSON_User";
import {
  elstrUserLogin,
  elstrUserLogout,
} from "elstr-jslib-4/dist/actions/elstrUserActions";
import {
  setCurrentUserAsListOwner,
  initUserLists,
  resetCart,
  resetWishlist,
  ListActions,
} from "../../list/actions/listActions";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ListType, PopMessageType, SyncType } from "../../common/constants/Constants";
import { initStore, componaUserDefault } from "../../State";
import { CheckoutActions } from "../../checkout/actions/checkoutActions";
import { OrderActions } from "../../order/actions/orderActions";
import { LayoutActions } from "../../layout/actions/layoutActions";
import ElstrBaseApp from "elstr-jslib-4/dist/ElstrBaseApp";

export const UserActions = {
  //
  // Action Callers
  //

  setDstIsoCurrent: function(dstIso: String) {
    return { type: UserActionTypes.SET_USER_DSTISO_CURRENT, payload: { dstIso } };
  },

  setUserFlyoutLoginMsg: function(userFlyoutLoginMsg: String) {
    return { type: UserActionTypes.SET_USER_FLYOUT_LOGIN_MSG, payload: { userFlyoutLoginMsg } };
  },

  // TODO: Will be needed in the future
  getUserData: function(email: String) {
    return { type: UserActionTypes.GET_USER_DATA, payload: { email } };
  },

  setUserData: function(obj) {
    return { type: UserActionTypes.SET_USER_DATA, payload: obj };
  },

  //
  // Action Logic
  //

  /**
   * First use the Elstr login to auth
   * After the Elstr login we get the user data from Compona and store it
   *
   * @param {string} email
   * @param {string} password
   * @param {string} enterpriseApplication
   * @return {(dispatch, getState) => Promise<void>}
   */
  login: function(email: string, password: string, enterpriseApplication = "") {
    return async dispatch => {
      try {
        dispatch(UserActions.setUserData({ isLoading: true, hasInitLists: false }));
        const data = await dispatch(elstrUserLogin(email, password, enterpriseApplication));
        if (data instanceof Error) {
          // eslint-disable-next-line no-throw-literal
          throw {
            name: "loginFailed",
            message: "LOGIN NICHT ERFOLGREICH",
            displayMessage: "LOGIN NICHT ERFOLGREICH",
            data,
          };
        } else {
          await dispatch(setCurrentUserAsListOwner());
          UserActions.reloadOtherTabs();
          await dispatch(UserActions.initComponaUserAndLists());
          await dispatch(UserActions.initCheckoutOnLogin());
          dispatch(UserActions.setUserData({ isLoading: false }));
          dispatch(LayoutActions.openUser(false));
        }
      } catch (e) {
        dispatch(UserActions.setUserData({ isLoading: false }));
        if (e.hasOwnProperty("displayMessage")) {
          dispatch(createPopMessage(PopMessageType.warning, dic(e.displayMessage), 10000));
          dispatch(UserActions.setUserFlyoutLoginMsg(e.displayMessage));
        } else {
          dispatch(createPopMessage(PopMessageType.warning, dic(e.message), 10000));
        }
        throw e;
      }
    };
  },

  /**
   *
   * @param {string} email
   * @returns {(dispatch, getState) => Promise<void>}
   */
  forgotPassword: function(email: string) {
    return async (dispatch, getState) => {
      if (email.length === 0) {
        dispatch(createPopMessage(PopMessageType.warning, dic("EMAIL ZWINGEND"), 10000));
        dispatch(UserActions.setUserFlyoutLoginMsg("EMAIL ZWINGEND"));
        return;
      }
      try {
        dispatch(createPopMessage(PopMessageType.info, dic("ANTRAG GESENDET"), 10000));
        await COMPONA_WidgetServer_JSON_User.forgotPassword(email);
      } catch (e) {
        dispatch(createPopMessage(PopMessageType.warning, e.message, 10000));
        dispatch(UserActions.setUserFlyoutLoginMsg(e.message));
        throw e;
      }
    };
  },

  /**
   *
   * @param {string} email
   * @returns {(dispatch, getState) => Promise<void>}
   */
  sendVerifyAgain: function(email: string) {
    return async (dispatch, getState) => {
      if (email.length === 0) {
        dispatch(createPopMessage(PopMessageType.warning, dic("EMAIL ZWINGEND"), 10000));
        dispatch(UserActions.setUserFlyoutLoginMsg("EMAIL ZWINGEND"));
        return;
      }
      try {
        dispatch(createPopMessage(PopMessageType.info, dic("ANTRAG GESENDET"), 10000));
        await COMPONA_WidgetServer_JSON_User.reSendVerificationMail(email);
      } catch (e) {
        dispatch(createPopMessage(PopMessageType.warning, e.message, 10000));
        throw e;
      }
    };
  },

  /**
   * First logout of elstr user
   * After the Elstr logout we reset the compona user
   * @return {(dispatch) => Promise<void>}
   */
  logout: function() {
    return async dispatch => {
      await dispatch(elstrUserLogout());

      await dispatch(UserActions.setUserData(componaUserDefault));

      // Clear cart
      dispatch(resetCart(initStore.cart));
      ElstrBaseApp.ELS.replaceValue(ListType.cart, null);
      // Clear wishlist
      dispatch(resetWishlist(initStore.wishlist));
      ElstrBaseApp.ELS.replaceValue(ListType.wishlist, null);

      // TODO: Clear comparison
      // dispatch(resetComparisonList());
      // Storage.replaceValue(ListType.comparison, null);

      // Clear checkout state
      dispatch(CheckoutActions.resetCheckoutOrder());

      // Clear orders
      dispatch(OrderActions.setOrderData({ isLoading: false, orders: [] }));

      UserActions.reloadOtherTabs();

      dispatch(LayoutActions.openUser(false));
      await dispatch(createPopMessage(PopMessageType.info, dic("ABMELDEN ERFOLGREICH")));
    };
  },

  /**
   * Gets the Compona User Data and Lists and sets it in the store
   *
   * @return {(dispatch, getState) => Promise<void>}
   */
  initComponaUserAndLists: function() {
    return async (dispatch, getState) => {
      try {
        const email = getState().user.username;
        const componaUserData = await COMPONA_WidgetServer_JSON_User.getUserData(email);
        await dispatch(UserActions.setUserData(componaUserData.data));
        await dispatch(initUserLists());
        dispatch(UserActions.setUserData({ isInit: true, hasInitLists: true }));
        if (getState().checkout.isInit) {
          dispatch(CheckoutActions.initCheckoutOrder(getState().user, getState().cart));
        }
        dispatch(createPopMessage(PopMessageType.info, dic("ANMELDEN ERFOLGREICH")));
      } catch (e) {
        e.displayMessage = "FEHLER BENUTZERINITIALISIERUNG";
        throw e;
      }
    };
  },

  /**
   *
   * @returns {(dispatch, getState) => Promise<void>}
   */
  initCheckoutOnLogin: function() {
    return async (dispatch, getState) => {
      if (getState().checkout.isInit) {
        dispatch(CheckoutActions.initCheckoutOrder(getState().user, getState().cart));
      }
    };
  },

  onChangeDstIsoCurrent: function(dstIso) {
    return async (dispatch, getState) => {
      dispatch(UserActions.setDstIsoCurrent(dstIso));
      dispatch(ListActions.updateCartFooter(getState().user, getState().cart));
    };
  },

  /**
   * Whenever we want to force other tabs to reload themselves, we change they value of "reloadTab"
   * Which will inform the event listener to reload the tab
   */
  reloadOtherTabs: function() {
    localStorage.setItem(SyncType.reloadOtherTabs, String(Math.random()));
  }
}
