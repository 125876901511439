import { FilterPanelActionsTypes } from "./ActionTypes";
import { SearchActions } from "../../search/actions/searchActions";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { CatalogAlgoliaActions } from "../../catalog/actions/algoliaActions";

declare var COMPONA_STATIC_CACHE;

export const FilterTreeActions = {
  updateFilterTreeData: function(facets, attributes, classifi, searchData) {
    return {
      type: FilterPanelActionsTypes.CREATE_TREE_DATA,
      facets,
      attributes,
      classifi,
      searchData,
    };
  },

  setItemValue: function(facetId: string, add: boolean) {
    return async (dispatch, getState) => {
      const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];
      let treeNode = {};

      treeNode = staticCacheClassifications.find(classi => classi.objectID === facetId);

      if (add) {
        dispatch(SearchActions.addTreeSearch(treeNode));
      } else {
        dispatch(SearchActions.removeTreeSearch(treeNode));
      }
      dispatch(CatalogAlgoliaActions.getManufacturersAndFunctions());
    };
  },
};
