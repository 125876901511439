import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SignupState, State, UserState } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { compose } from "redux";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { SignupActions } from "../actions/signupActions";
import SimpleButton, { SimpleButtonTypes } from "../../common/components/button/SimpleButton";
import SignupResetPassword from "../components/SignupResetPassword";
import SelectBox from "../../common/components/input/SelectBox";
import SelectBoxLaender from "../../common/components/input/SelectBoxLaender";
import { UserActions } from "../../user/actions/userActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";

interface SignupStateToProps {}

interface SingupDispatchToProps {
  onMount: () => void;
  onSubmitSignup: () => void;
  onInputChange: (name: string, value: string) => void;
  onVerifySuccessful: () => void;
  onVerifyFailed: () => void;
}

interface Props extends SignupStateToProps, SingupDispatchToProps {
  user: UserState;
  signup: SignupState;
  match: any;
}

class Signup extends React.Component<Props> {
  componentDidMount() {
    this.props.onMount();

    if (
      this.props.match.params.status === "verifySuccessful" ||
      this.props.match.params.status === "verifyAccountEmailNewSuccessful"
    ) {
      this.props.onVerifySuccessful();
    }
    if (this.props.match.params.status === "verifyFailed") {
      this.props.onVerifyFailed();
    }
  }

  componentDidUpdate(prevProps) {}

  onInputChange = e => {
    this.props.onInputChange(e.target.name, e.target.value);
  };

  onSubmitSignup = e => {
    e.preventDefault();
    if (this.props.signup.isLoading === false) {
      this.props.onSubmitSignup();
    }
  };

  renderMessageSubmit() {
    if (this.props.signup.msg.length > 0) {
      return (
        <div className="pill-counter__notification">
          <p className="form__error-message" role="alert" id="error-textfield4" aria-hidden="false">
            {this.props.signup.msg}
          </p>
        </div>
      );
    }
  }

  onSignupNewsletterChange = e => {
    let { signupNewsletter } = this.props.signup;
    ElstrCore.store.dispatch(SignupActions.setSignupNewsletter(!signupNewsletter));
  };

  renderButtonSubmit() {
    if (this.props.signup.isLoading) {
      return (
        <div className="form-submit__wrapper form-row">
          <SimpleButton
            type={SimpleButtonTypes.SUBMIT}
            onClick={() => {}}
            onSubmit={() => {}}
            label={dic("KONTO WIRD ERSTELLT")}
          />
        </div>
      );
    } else {
      return (
        <div className="form-submit__wrapper form-row">
          <SimpleButton
            type={SimpleButtonTypes.SUBMIT}
            onClick={() => {}}
            onSubmit={this.onSubmitSignup}
            label={dic("KONTO ERSTELLEN")}
          />
        </div>
      );
    }
  }

  render() {
    if (this.props.match.params.status === "new") {
      return (
        <main className="main main--std" role="main">
          <div className="content">
            <form className="form" onSubmit={this.onSubmitSignup}>
              <h1 className="subtitle form-subtitle">{dic("AUF COMPONA REGISTRIEREN")}</h1>
              <div className="content__2col">
                <div className="col">
                  <div className="form-input__wrapper form-row">
                    <label className="label" htmlFor="email">
                      {dic("EMAIL")} <span>*</span>
                    </label>
                    <input
                      className="input--text"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="user@domain.com"
                      autoComplete="email"
                      aria-required={true}
                      required={true}
                      value={this.props.signup.data.email}
                      onChange={this.onInputChange}
                    />
                    {this.props.signup.userExistsAlready && (
                      <p
                        className="form__error-message"
                        role="alert"
                        id="error-textfield4"
                        aria-hidden="false"
                      >
                        {dic("USER_WITH_EMAIL_ALREADY_EXISTS").replace(
                          "%s",
                          this.props.signup.data.email,
                        )}
                      </p>
                    )}
                  </div>

                  <div className="form-row">
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="password">
                        {dic("PASSWORT")} <span>*</span>
                      </label>
                      <input
                        className="input--text"
                        type="password"
                        id="password"
                        name="password"
                        placeholder="******"
                        autoComplete="off"
                        aria-required={true}
                        required={true}
                        value={this.props.signup.data.password}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="passwordRepeated">
                        {dic("PASSWORT WIEDERHOLEN")} <span>*</span>
                      </label>
                      <input
                        className="input--text"
                        type="password"
                        id="passwordRepeated"
                        name="passwordRepeated"
                        placeholder="******"
                        autoComplete="off"
                        aria-required={true}
                        required={true}
                        value={this.props.signup.data.passwordRepeated}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="anrNr">
                        {dic("ANREDE")} <span>*</span>
                      </label>
                      <div className="selectbox-block">
                        <SelectBox
                          selections={[
                            { value: "1", name: dic("HERR") },
                            { value: "2", name: dic("FRAU") },
                          ]}
                          id="anrNr"
                          name="anrNr"
                          selectedValue={this.props.signup.data.anrNr}
                          onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="vorname">
                        {dic("VORNAME")} <span>*</span>
                      </label>
                      <input
                        className="input--text"
                        type="text"
                        id="vorname"
                        name="vorname"
                        placeholder={`${dic("VORNAME")}...`}
                        autoComplete="given-name"
                        maxLength={50}
                        aria-required={true}
                        required={true}
                        value={this.props.signup.data.vorname}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="name">
                        {dic("NACHNAME")} <span>*</span>
                      </label>
                      <input
                        className="input--text"
                        type="text"
                        id="name"
                        name="name"
                        placeholder={`${dic("NACHNAME")}...`}
                        autoComplete="family-name"
                        maxLength={50}
                        aria-required={true}
                        required={true}
                        value={this.props.signup.data.name}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-input__wrapper">
                    <label className="label" htmlFor="tel">
                      {dic("TELEFON")} <span>*</span>
                    </label>
                    <input
                      className="input--text"
                      type="text"
                      id="tel"
                      name="tel"
                      placeholder={`${dic("TELEFON")}...`}
                      autoComplete="tel"
                      maxLength={50}
                      aria-required={true}
                      required={true}
                      value={this.props.signup.data.tel}
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="zeile3">
                        {dic("FIRMA")}
                      </label>
                      <input
                        className="input--text"
                        type="text"
                        id="zeile3"
                        name="zeile3"
                        placeholder={`${dic("FIRMA")}...`}
                        autoComplete="organization"
                        maxLength={50}
                        value={this.props.signup.data.zeile3}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="dstDnr">
                        {dic("KUNDENNUMMER")}
                      </label>
                      <input
                        className="input--text"
                        type="text"
                        id="dstDnr"
                        name="dstDnr"
                        placeholder={`${dic("KUNDENNUMMER")}...`}
                        value={this.props.signup.data.dstDnr}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-input__wrapper">
                    <label className="label" htmlFor="zeile1">
                      {dic("ADRESSE")} <span>*</span>
                    </label>
                    <input
                      className="input--text"
                      type="text"
                      id="zeile1"
                      name="zeile1"
                      placeholder={`${dic("ADRESSE")}...`}
                      autoComplete="street-address"
                      maxLength={50}
                      aria-required={true}
                      required={true}
                      value={this.props.signup.data.zeile1}
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="form-input__wrapper">
                    <label className="label" htmlFor="zeile2">
                      {dic("ADRESSZUSATZ")}
                    </label>
                    <input
                      className="input--text"
                      type="text"
                      id="zeile2"
                      name="zeile2"
                      placeholder={`${dic("ADRESSZUSATZ")}...`}
                      maxLength={50}
                      aria-required="false"
                      value={this.props.signup.data.zeile2}
                      onChange={this.onInputChange}
                    />
                  </div>

                  <div className="form-row">
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="land">
                        {dic("LAND")} <span>*</span>
                      </label>
                      <div className="selectbox-block">
                        <SelectBoxLaender
                          id="land"
                          name="land"
                          aria-required={true}
                          required={true}
                          selectedValue={this.props.signup.data.land}
                          onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="plz">
                        {dic("POSTLEITZAHL")} <span>*</span>
                      </label>
                      <input
                        className="input--text"
                        type="text"
                        id="plz"
                        name="plz"
                        placeholder={`${dic("POSTLEITZAHL")}...`}
                        maxLength={10}
                        autoComplete="postal-code"
                        aria-required={true}
                        required={true}
                        value={this.props.signup.data.plz}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div className="form-input__wrapper">
                      <label className="label" htmlFor="ort">
                        {dic("ORT")} <span>*</span>
                      </label>
                      <input
                        className="input--text"
                        type="text"
                        id="ort"
                        name="ort"
                        placeholder={`${dic("ORT")}...`}
                        autoComplete="on"
                        maxLength={50}
                        aria-required={true}
                        required={true}
                        value={this.props.signup.data.ort}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-input__wrapper form-input__wrapper--checkbox">
                    <div className="form-row">
                      <div className="checkbox">
                        <input
                          className="input--checkbox"
                          id="signup-newsletter"
                          name="signupNewsletter"
                          type="checkbox"
                          aria-checked={this.props.signup.signupNewsletter}
                          checked={this.props.signup.signupNewsletter}
                          onChange={this.onSignupNewsletterChange}
                        />
                        <label htmlFor="signup-newsletter" className="checkbox__label">
                          <span className="checkbox__box" />
                          <strong>{dic("NEWSLETTER REGISTRIEREN")}</strong>
                        </label>
                      </div>
                    </div>
                  </div>

                  {this.renderButtonSubmit()}
                  {this.renderMessageSubmit()}
                </div>
                <div className="col" />
              </div>
            </form>
          </div>
        </main>
      );
    } else if (this.props.match.params.status === "resetPassword") {
      return (
        <SignupResetPassword
          token={this.props.match.params.token}
          isLoading={this.props.signup.isLoading}
        />
      );
    } else if (this.props.match.params.status === "verifySuccessful") {
      return (
        <main className="main main--std" role="main">
          <div className="content">
            <div className="content__2col">
              <div className="col">
                <h3 className="subtitle form-subtitle">{dic("VERIFIKATION ERFOLGREICH")}</h3>
                <p className="inst-text">{dic("VERIFIKATION ERFOLGREICH TEXT")} </p>
                <Link to="/catalog" className={"btn input"}>
                  {dic("ZUM KATALOG UND SHOP")}
                </Link>
              </div>
              <div className="col" />
            </div>
          </div>
        </main>
      );
    } else if (this.props.match.params.status === "verifyAccountEmailNewSuccessful") {
      return (
        <main className="main main--std" role="main">
          <div className="content">
            <div className="content__2col">
              <div className="col">
                <h3 className="subtitle form-subtitle">{dic("VERIFIKATION ERFOLGREICH")}</h3>
                <p className="inst-text">
                  {dic("VERIFIKATION ACCOUNT EMAIL NEU ERFOLGREICH TEXT")}{" "}
                </p>
                <Link to="/catalog" className={"btn input"}>
                  {dic("ZUM KATALOG UND SHOP")}
                </Link>
              </div>
              <div className="col" />
            </div>
          </div>
        </main>
      );
    } else if (this.props.match.params.status === "verifyFailed") {
      return (
        <main className="main main--std" role="main">
          <div className="content">
            <h3 className="subtitle form-subtitle">{dic("VERIFIKATION FEHLGESCHLAGEN")}</h3>
            <p className="inst-text" dangerouslySetInnerHTML={{ __html: dic("VERIFIKATION FEHLGESCHLAGEN TEXT")}} />
              <p>
                {" "}
            </p>
            <Link to="/catalog" className={"btn input"}>
             {dic("ZUM KATALOG UND SHOP")}
            </Link>
          </div>
        </main>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: State): SignupStateToProps => ({
  user: state.user,
  signup: state.signup,
});

const mapDispatchToProps = (dispatch): SingupDispatchToProps => ({
  onMount: () => {
    dispatch(LayoutActions.showFilterPanel(false));
    dispatch(LayoutActions.showHomeHeader(false));
  },
  onInputChange: (name, value) => dispatch(SignupActions.setSignupDataValue(name, value)),
  onSubmitSignup: () => dispatch(SignupActions.submitSignup()),
  onVerifySuccessful: async () => {
    await dispatch(UserActions.logout());
    await dispatch(LayoutActions.openUser(true));
  },
  onVerifyFailed: () => dispatch(LayoutActions.openUser(true)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Signup);
