import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { RelatedTabState, UserState } from "../../State";
import ProductRelatedTabListTab from "./ProductRelatedTabListTab";
import ProductTable, { ProductTableColumnVo } from "../../common/components/table/ProductTable";

interface Props {
  data: ProductVo | null;
  user: UserState;
  relatedTabActiveIndex: number | null;
  relatedTabActiveIsLoaded: boolean;
  relatedTabs: RelatedTabState[];
  onTabIdChanged: (objectID, type) => void;
  onClickProductLink: (product: ProductVo) => void;
  relatedProductsColumns: any[];
  addProduct: (listType, objectID, pos, quantity, comment) => void;
  onClickSortLink: (column: ProductTableColumnVo, direction) => void;
  scrollState: number;
}

class ProductRelatedTabList extends React.PureComponent<Props> {
  private readonly resultListInnerwrap: any;

  constructor(props) {
    super(props);
    this.resultListInnerwrap = React.createRef();
    this.onClickScroll = this.onClickScroll.bind(this);
  }

  onClickScroll(direction) {
    const scrollLeft = this.resultListInnerwrap.current.scrollLeft;
    const pxAmount = 200;
    if (direction === "right") {
      this.resultListInnerwrap.current.scrollLeft = scrollLeft + pxAmount;
    }
    if (direction === "left") {
      this.resultListInnerwrap.current.scrollLeft = scrollLeft - pxAmount;
    }
  }

  renderTabItems(relatedTabs) {
    return relatedTabs.map((tabItem, index) => {
      if (this.props.data !== null) {
        return (
          <ProductRelatedTabListTab
            key={`${this.props.data.objectID}-${tabItem.productRelationType}-${index}`}
            objectID={this.props.data.objectID}
            productRelationType={tabItem.productRelationType}
            isActive={this.props.relatedTabActiveIndex === index}
            onTabIdChanged={this.props.onTabIdChanged}
          />
        );
      }
      return null;
    });
  }

  renderTabContentItems(relatedTabs) {
    if (this.props.relatedTabActiveIndex !== null) {
      if (this.props.relatedTabs[this.props.relatedTabActiveIndex].isLoaded) {
        return (
          <div className="tab-content__item">
            <div className="result-list-outerwrap">
              <div className="result-list-innerwrap" ref={this.resultListInnerwrap}>
                <div className="result-list">
                  <h2 className="visuallyhidden">Ergebnis Liste</h2>
                  <ProductTable
                    columns={this.props.relatedProductsColumns}
                    products={
                      this.props.relatedTabs[this.props.relatedTabActiveIndex].relatedProducts
                    }
                    sorting={this.props.relatedTabs[this.props.relatedTabActiveIndex].sorting}
                    onClickProductLink={this.props.onClickProductLink}
                    user={this.props.user}
                    addProduct={this.props.addProduct}
                    onClickScroll={this.onClickScroll}
                    onClickSortLink={this.props.onClickSortLink}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="tab-content__item">
            <div className="loading" />
          </div>
        );
      }
    }
  }

  render() {
    let HeaderCssClass = "related-product";
    if (this.props.scrollState > 1) {
      HeaderCssClass = "related-product sticky";
    }

    if (this.props.relatedTabs.length > 0 && this.props.relatedTabActiveIndex !== null) {
      return (
        <>
          <div id="relatedTabsTitle" className="catalog__subtitle">
            {dic("WEITERE UND VERWANDTE PRODUKTE")}
          </div>
          <div className={HeaderCssClass}>
            <div className="tab-header">
              <nav className="tab-nav">
                <ul className="tab">{this.renderTabItems(this.props.relatedTabs)}</ul>
              </nav>
            </div>
            <div className="related-product__body" id="relatedProductBody">
              <div className="tab-content">
                {this.renderTabContentItems(this.props.relatedTabs)}
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
}

export default ProductRelatedTabList;
