import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ManageActions } from "../actions/manageActions";
import { ManageUsersData, ManageUsersState } from "../../State";
import UsersData from "./UsersData";
import create from "../report/Users";

interface Props {
  manageUsers: ManageUsersState;
}

class ManageUsers extends React.PureComponent<Props> {
  private readonly searchUser: any;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.searchUser = React.createRef();
  }

  componentDidMount() {
    ElstrCore.store.dispatch(ManageActions.getUsersForManager());
  }

  componentWillUnmount() {
    // Reset when leaving Tab
    ElstrCore.store.dispatch(
      ManageActions.setManageUsersData({
        data: [],
      }),
    );
  }

  onSubmitFormOpenOrderId = e => {
    e.preventDefault();
    this.forceUpdate();
  };

  /**
   * filters ManageUsersData[] according to specified user fields and addresses
   * returns a list of filtered ManageUsersData[]
   */
  getFilteredManageUsersData = () => {
    const { manageUsers } = this.props;

    return manageUsers.data.filter(user => {
      // during mounting
      if (!this.searchUser.current) {
        return true;
      }

      const filterValue = this.searchUser.current.value;

      // when input is empty
      if (filterValue === "") {
        return true;
      }

      if (user.email && user.email.includes(filterValue)) {
        return true;
      }

      if (user.dstDnr && user.dstDnr.includes(filterValue)) {
        return true;
      }

      if (user._id && user._id.includes(filterValue)) {
        return true;
      }

      return this.filterAddress(user);
    });
  };

  /**
   * returns true when searchUser filterValue is inside the specified address attributes
   * @param user
   */
  filterAddress = (user: ManageUsersData) => {
    const filterValue = this.searchUser.current.value;
    return user.adr.some(adr => {
      if (adr.inr && adr.inr.includes(filterValue)) {
        return true;
      }
      if (adr.name && adr.name.includes(filterValue)) {
        return true;
      }
      if (adr.vorname && adr.vorname.includes(filterValue)) {
        return true;
      }
      if (adr.ort && adr.ort.includes(filterValue)) {
        return true;
      }
      if (adr.tel && adr.tel.includes(filterValue)) {
        return true;
      }
      if (adr.email && adr.email.includes(filterValue)) {
        return true;
      }
      return false;
    });
  };

  render() {
    const limit = 100;
    const filteredManageUsersData: ManageUsersData[] = this.getFilteredManageUsersData().slice(
      0,
      limit,
    );

    return (
      <>
        <form onSubmit={this.onSubmitFormOpenOrderId}>
          <div className="form-row">
            <div className="form-input__wrapper">
              <label className="label" htmlFor="textfield3">
                {dic("TEXT ZUR SUCHE VON BENUTZERN EINGEBEN")}
              </label>
              <input
                className="input--text"
                ref={this.searchUser}
                type="text"
                id="searchUser"
                name="searchUser"
                placeholder=""
                required={false}
                aria-required={true}
              />
            </div>
            <div className="form-input__wrapper" />
          </div>
          <div className="wrapper form-row">
            <input type="submit" className="input--submit" value={dic("SUCHEN")} />
          </div>
        </form>

        {this.props.manageUsers.isLoading ? (
          <div className="loading" />
        ) : (
          <>
            <div className="form-submit__wrapper form-row">
              <button
                className="btn input--reset"
                onClick={() => {
                  create();
                }}
              >
                {dic("REPORT")}
              </button>
            </div>
            <UsersData manageUsersData={filteredManageUsersData} />
          </>
        )}
      </>
    );
  }
}

export default ManageUsers;
