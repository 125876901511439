import * as React from "react";

interface Props {
  id: string;
  label: string;
  onClick?: (e) => void;
  onRemoveClick?: (e) => void;
}

class TagButton extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onRemoveClickHandler = this.onRemoveClickHandler.bind(this);
  }

  onRemoveClickHandler() {
    if (this.props.onRemoveClick) this.props.onRemoveClick(this.props.id);
  }

  render() {
    return (
      <p className="catalog-tag" onClick={this.props.onClick}>
        {this.props.label}
        <span className="tag-remover" aria-label="Remove" onClick={this.onRemoveClickHandler} />
      </p>
    );
  }
}

export default TagButton;
