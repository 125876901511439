import * as React from "react";
import { Link } from "react-router-dom";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  onClick?: (e) => void;
}

class ComponaLogoTop extends React.Component<Props> {
  render() {
    return (
      <>
        <div className="visuallyhidden">Company Logo</div>
        <Link to={`/home/${activeLang()}/`}>
          <img
            className="logo__img"
            src={require("./../../common/assets/img/layout/compona-logo.svg")}
            alt="Compona Logo"
            onClick={this.props.onClick}
          />
        </Link>
      </>
    );
  }
}

export default ComponaLogoTop;
