import { HomeActionsTypes } from "./HomeActionsTypes";
import AlgoliaServices from "../../common/services/AlgoliaServices";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

export const HomeDataActions = {
  /**
   * Get Newest Products for Home include Debounce
   * @returns {any}
   */
  getNewestProducts: function() {
    const thunk: any = async dispatch => {
      const algolia = await AlgoliaServices.getNewProducts(activeLang());
      dispatch(HomeDataActions.displayNewestProducts(algolia.hits));
    };

    thunk.meta = {
      debounce: {
        time: 100,
        key: "getNewestProducts",
      },
    };

    return thunk;
  },

  /**
   * Internal function call the Reducer
   * @param data
   * @returns {{type: HomeActionsTypes; data: any}}
   */
  displayNewestProducts: function(data) {
    return { type: HomeActionsTypes.SHOW_NEW_PRODUCTS, data };
  },
};
