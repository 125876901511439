import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CmsState, State } from "../../State";
import { compose } from "redux";
import { CmsActions } from "../actions/cmsActions";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { v1 as uuidv1 } from "uuid";
import SMTags from "../../common/components/metatags/SMTags";

const smImage = config("smImage");

declare const $;
declare const COMPONA_CMS_JS;

interface CmsStateToProps {
  cmsState: CmsState;
  routerParams: any;
}

interface CmsDispatchToProps {
  onMount: () => void;
  getContent: (url) => void;
}

interface Props extends CmsStateToProps, CmsDispatchToProps, RouteComponentProps<any> {
  data: {};
}

class Cms extends React.Component<Props> {
  private cmsRoot = "";
  private scriptId = uuidv1();

  constructor(props) {
    super(props);
    if (config("cmsRoot")) {
      this.cmsRoot = config("cmsRoot");
    }
  }

  componentDidMount() {
    this.props.onMount();
    this.props.getContent(`${this.cmsRoot}${this.props.routerParams.location.pathname}`);

    if (
        this.props.match.params.lang &&
        this.props.match.params.lang !== activeLang() &&
        this.props.cmsState.metaJson &&
        this.props.cmsState.metaJson.url &&
        this.props.cmsState.metaJson.url[activeLang()]
    ) {
      const urlOfLang = this.props.cmsState.metaJson.url[activeLang()];
      window.location.href = urlOfLang;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.routerParams.location.pathname !== prevProps.routerParams.location.pathname) {
      this.props.getContent(`${this.cmsRoot}${this.props.routerParams.location.pathname}`);
    }

    if (
        this.props.cmsState !== prevProps.cmsState ||
        this.props.routerParams.location.hash !== prevProps.routerParams.location.hash
    ) {
      // Used to apply js after the rendering
      setTimeout(
          function() {
            if (typeof $ === "function" && typeof COMPONA_CMS_JS === "function") {
              COMPONA_CMS_JS();
              if (window.location.hash.length > 0) {
                const $target = $(window.location.hash);
                $("html, body")
                    .stop()
                    .animate(
                        {
                          scrollTop: $target.offset().top - 58,
                        },
                        1000,
                        "swing",
                    );
              }
              // console.log("COMPONA_CMS_JS is executed");
            } else {
              // console.log("COMPONA_CMS_JS not ready to execute");
            }
          },
          500,
      );
    }
  }

  render() {
    let url = "";
    if ( this.props.cmsState.metaJson.url !== undefined ) {
      url = this.props.cmsState.metaJson.url[activeLang()];
    }
    const imgixUrl = config("imgixUrl");
    const image = imgixUrl + "/" + smImage;

    return (
        <>
          <Helmet>
            <script
                src={`https://www.compona.com/cms/assets/js/lib/jquery-3.2.1.js?react=1&scriptid=${this.scriptId}`}
                type="text/javascript"
            />
            <script
                src={`https://www.compona.com/cms/assets/js/init/scripts.js?react=1&scriptid=${this.scriptId}`}
                type="text/javascript"
            />
            {this.props.cmsState.metaJson.title && (
                <title>{this.props.cmsState.metaJson.title}</title>
            )}
            {this.props.cmsState.metaJson.description && (
                <meta name="description" content={this.props.cmsState.metaJson.description} />
            )}
            {this.props.cmsState.metaJson.keywords && (
                <meta name="keywords" content={this.props.cmsState.metaJson.keywords} />
            )}
            {this.props.cmsState.metaJson.url &&
            Object.keys(this.props.cmsState.metaJson.url).map(lang => {
              return (
                  <link
                      key={`link_alternate_lang_${lang}`}
                      rel="alternate"
                      hrefLang={lang}
                      href={this.props.cmsState.metaJson.url[lang]}
                  />
              );
            })}
          </Helmet>

          <SMTags type="website" title={this.props.cmsState.metaJson.title} description={this.props.cmsState.metaJson.description} url={url} image={image} />

          <div dangerouslySetInnerHTML={{ __html: this.props.cmsState.htmlText }} />
        </>
    );
  }
}

const mapStateToProps = (state: State): CmsStateToProps => ({
  cmsState: state.cms,
  routerParams: state.router,
});

const mapDispatchToProps = (dispatch): CmsDispatchToProps => ({
  onMount: () => {
    dispatch(LayoutActions.showHomeHeader(false));
    dispatch(LayoutActions.showFilterPanel(false));
  },
  getContent: url => dispatch(CmsActions.getContent(url)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Cms);
