import React, { Component } from "react";
import { WHITE_SPACE } from "../../common/utils/constants";

interface Props {
  className: string
  disabled: boolean
  checked: boolean
  name:string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string
  label:string
}

class CheckBoxCookieBanner extends Component<Props> {
  render() {
    return (
      <div className="checkbox checkbox-vertical-spacing">
      <label className="checkbox__label" htmlFor={this.props.id}>{this.props.label}</label>{WHITE_SPACE}
        <input
          className={this.props.className}
          id={this.props.id}
          name={this.props.name}
          type="checkbox"
          disabled={this.props.disabled}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default CheckBoxCookieBanner;
