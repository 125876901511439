import { SearchState } from "../../State";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { getUnitSymbol } from "./unitUtils";
import { getAttributeDescription } from "./attributeUtils";

declare var COMPONA_STATIC_CACHE;

function getTitleStringTreeParts(
  staticCacheClassifications,
  searchState: SearchState,
  key,
  topDown = true,
) {
  if (searchState.trees.hasOwnProperty(key) && typeof searchState.trees[key] !== "undefined") {
    return Object.keys(searchState.trees[key]).map(item => {
      const classifi = staticCacheClassifications.find(node => node.objectID === item);
      if (classifi.parentIds.length > 1) {
        const classifiParent = staticCacheClassifications.find(
          node => node.objectID === classifi.parentId,
        );
        if (topDown) {
          return `${classifiParent.descShort} > ${classifi.descShort}`;
        } else {
          return `${classifi.descShort} < ${classifiParent.descShort}`;
        }
      }
      return classifi.descShort;
    });
  }
  return [];
}

function getTitleStringFilterParts(searchState: SearchState) {
  // Similar logic in SearchBox.tsx
  return Object.keys(searchState.filterSettings).map(facetKey => {
    if (COMPONA_STATIC_CACHE.attributes.hasOwnProperty(facetKey)) {
      const desc = getAttributeDescription(facetKey, activeLang());
      const dataType = window.COMPONA_STATIC_CACHE.attributes[facetKey].dataType;

      let unit = "";
      if (COMPONA_STATIC_CACHE.attributes[facetKey].unitOfMeasureId !== "") {
        unit = getUnitSymbol(
          COMPONA_STATIC_CACHE.attributes[facetKey].unitOfMeasureId,
          activeLang(),
        );
      }

      let concatenatedValues = "";
      const facetValues = searchState.filterSettings[facetKey];
      if (dataType === "boolean") {
        if (facetValues instanceof Array) {
          if (facetValues.find(prop => prop === "0")) {
            concatenatedValues += " " + dic("NEIN");
          }
          if (facetValues.find(prop => prop === "1")) {
            if (concatenatedValues !== "") {
              concatenatedValues += ",";
            }
            concatenatedValues += " " + dic("JA");
          }
        }
      } else {
        const values = facetValues;
        concatenatedValues = `${values.join(` ${unit} ${dic("ODER")} `)} ${unit}`;
      }
      return `${desc} ${concatenatedValues}`;
    } else {
      return "";
    }
  });
}

function filterTreeIsset(searchState: SearchState) {
  if (searchState.hasOwnProperty("trees")) {
    if (
      (searchState.trees.hasOwnProperty("hersteller") &&
        typeof searchState.trees.hersteller !== "undefined") ||
      (searchState.trees.hasOwnProperty("funktion") &&
        typeof searchState.trees.funktion !== "undefined")
    ) {
      return true;
    }
  }
  return false;
}

function filterIsset(searchState: SearchState) {
  if (
    searchState.hasOwnProperty("filterSettings") &&
    Object.keys(searchState.filterSettings).length > 0
  ) {
    return true;
  }
  return false;
}

function queryIsset(searchState: SearchState) {
  if (searchState.query && searchState.query.length > 0) {
    return true;
  }
  return false;
}

/**
 *
 * @param searchState
 */
export function titleStringPrefixForAnyFilter(searchState: SearchState): string {
  if (filterTreeIsset(searchState) && !filterIsset(searchState) && !queryIsset(searchState)) {
    return "";
  }
  if (!filterTreeIsset(searchState) && filterIsset(searchState) && !queryIsset(searchState)) {
    return `${dic("PRODUKTE")} ${dic("MIT")} `;
  }
  if (!filterTreeIsset(searchState) && !filterIsset(searchState) && queryIsset(searchState)) {
    return `${dic("PRODUKTE")} ${dic("GESUCHT NACH")} `;
  }
  return "";
}

/**
 *
 * @param searchState
 */
export function titleStringMainForAnyFilter(searchState: SearchState): string {
  const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];
  let titleStringMain = "";

  const titleStringHerstellerParts: string[] = getTitleStringTreeParts(
    staticCacheClassifications,
    searchState,
    "hersteller",
    true,
  );
  const titleStringFunktionParts: string[] = getTitleStringTreeParts(
    staticCacheClassifications,
    searchState,
    "funktion",
    true,
  );
  const titleStringTreeParts = titleStringHerstellerParts.concat(titleStringFunktionParts);
  titleStringMain += `${titleStringTreeParts.join(` ${dic("UND")} `)}`;

  if (filterTreeIsset(searchState) && !filterIsset(searchState) && !queryIsset(searchState)) {
    return titleStringMain;
  }

  if (titleStringMain.length > 0 || (filterIsset(searchState) || !queryIsset(searchState))) {
    if (titleStringMain.length > 0) {
      titleStringMain += ` `;
    }
    titleStringMain += `${dic("PRODUKTE")}`;
  }

  const titleStringFilterParts = getTitleStringFilterParts(searchState);
  if (!filterTreeIsset(searchState) && filterIsset(searchState) && !queryIsset(searchState)) {
    return `${titleStringFilterParts.join(`, `)}`;
  } else if (filterIsset(searchState)) {
    if (titleStringFilterParts.length > 0) {
      titleStringMain += ` ${dic("MIT")} ${titleStringFilterParts.join(`${dic("UND")} `)}`;
    }
  }

  if (!filterTreeIsset(searchState) && !filterIsset(searchState) && queryIsset(searchState)) {
    return `'${searchState.query}'`;
  } else if (queryIsset(searchState)) {
    titleStringMain += ` ${dic("GESUCHT NACH")} '${searchState.query}'`;
  }

  return titleStringMain;
}

/**
 *
 * @param searchState
 */
export function titleStringPostfixForAnyFilter(searchState: SearchState): string {
  if (filterTreeIsset(searchState) && !filterIsset(searchState) && !queryIsset(searchState)) {
    return ` ${dic("PRODUKTE")}`;
  }
  if (!filterTreeIsset(searchState) && filterIsset(searchState) && !queryIsset(searchState)) {
    return "";
  }
  if (!filterTreeIsset(searchState) && !filterIsset(searchState) && queryIsset(searchState)) {
    return "";
  }
  return "";
}

/**
 *
 * @param searchState
 */
export function titleMetaStringPrefixForAnyFilter(searchState: SearchState): string {
  if (
    searchState.hasOwnProperty("trees") &&
    searchState.hasOwnProperty("filterSettings") &&
    Object.keys(searchState.filterSettings).length < 4
  ) {
    const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];

    const titleStringHerstellerParts: string[] = getTitleStringTreeParts(
      staticCacheClassifications,
      searchState,
      "hersteller",
      false,
    );
    const titleStringFunktionParts: string[] = getTitleStringTreeParts(
      staticCacheClassifications,
      searchState,
      "funktion",
      false,
    );
    const titleStringTreeParts = titleStringHerstellerParts.concat(titleStringFunktionParts);

    const titleStringFilterParts = getTitleStringFilterParts(searchState);

    let titleStringForAnyFilter = "";

    if (searchState.query && searchState.query.length > 0) {
      titleStringForAnyFilter = `${searchState.query}`;
    }
    if (titleStringForAnyFilter !== "" && titleStringTreeParts.length > 0) {
      titleStringForAnyFilter += `, `;
    }
    titleStringForAnyFilter += `${titleStringTreeParts.join(`, `)}`;

    if (titleStringForAnyFilter !== "" && titleStringFilterParts.length > 0) {
      titleStringForAnyFilter += `, `;
    }
    titleStringForAnyFilter += `${titleStringFilterParts.join(`, `)}`;

    if (titleStringForAnyFilter.length > 0) {
      titleStringForAnyFilter += ` `;
    }

    return titleStringForAnyFilter;
  } else {
    return "";
  }
}
