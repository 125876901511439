import TreeItemVo from "./filterPanel/vo/TreeItemVo";

export const TestDataGenerator = {
  filterSingleTestData: function(): { [key: string]: TreeItemVo | undefined } {
    return {
      hersteller: {
        id: "hersteller",
        name: "Hersteller",
        placeHolder: "Serie auswählen...",
        canHaveChild: false,
        count: 0,
        suggestions: [],
        data: [],
      },
      funktion: {
        id: "funktion",
        name: "Funktionen",
        placeHolder: "Funktionstyp auswählen...",
        canHaveChild: false,
        count: 0,
        suggestions: [],
        data: [],
      },
    };
  }
}
