import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import { createProductUrl, createImgUrl } from "../../common/utils/createUrls";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  data: ProductVo;
  priceScaleArray: any[];
}

class ProductStructuredData extends React.PureComponent<Props> {

  structuredOfferData(priceScaleItem, verfuegbareMenge) {
    // Preise sind 90 Tage gültig
    let priceValidUntilDate = new Date();
    priceValidUntilDate.setDate(priceValidUntilDate.getDate() + 90);
    return {
      "@type": "Offer",
      id: window.location.href+"#offer",
      url: window.location.href,
      price: priceScaleItem.betrag,
      priceCurrency: priceScaleItem.dstIso,
      priceValidUntil: priceValidUntilDate.toISOString().split('T')[0],
      eligibleQuantity: {
        "@type": "QuantitativeValue",
        minValue: priceScaleItem.mengeAb,
        unitText: priceScaleItem.verkaufsEinheit,
      },
      availability: ((verfuegbareMenge > 0) ? 'http://schema.org/InStock' : 'http://schema.org/BackOrder'),
      itemCondition: "http://schema.org/NewCondition",
    };
  }

  render() {
    if (this.props.data) {
      const structuredData: any = {
        "@context": "https://schema.org",
        "@type": "Product",
        identifier: this.props.data.objectID,
        url: "https://compona.com" + createProductUrl(this.props.data, activeLang()),
      };
      structuredData.name = "";
      if (this.props.data.herstellername) {
        structuredData.name += this.props.data.herstellername;
      }
      if (this.props.data.hersteller_typennummer_formatiert) {
        structuredData.name += " " + this.props.data.hersteller_typennummer_formatiert;
      }
      if (this.props.data.ShortDescription) {
        structuredData.name += " " + this.props.data.ShortDescription;
      }
      if (this.props.data.LongDescription) {
        structuredData.name += " " + this.props.data.LongDescription;
      }
      structuredData.description = "";
      if (this.props.data.ShortDescription) {
        structuredData.description += this.props.data.ShortDescription;
      }
      if (this.props.data.LongDescription) {
        structuredData.description += " " + this.props.data.LongDescription;
      }

      if (this.props.data.gtin) {
        structuredData.gtin = this.props.data.gtin;
      }

      if (this.props.data.hersteller_art_nr_unformatiert) {
        structuredData.mpn = this.props.data.hersteller_art_nr_unformatiert;
      } else {
        structuredData.mpn = this.props.data.objectID;
      }
      structuredData.sku = this.props.data.objectID;
      if (this.props.data.herstellername) {
        structuredData.brand = {
          "@type": "Brand",
          name: this.props.data.herstellername,
        };
      }
      if (this.props.data.herstellername) {
        structuredData.manufacturer = {
          "@type": "Organization",
          name: this.props.data.herstellername,
        };
      }
      if (this.props.data.elstrImage) {
        structuredData.image = createImgUrl(this.props.data.elstrImage, "400", "400");
      }
      const priceScaleArray = this.props.priceScaleArray;
      const priceScaleArrayLength = priceScaleArray.length;
      if (priceScaleArrayLength > 0) {
        const verfuegbareMenge = this.props.data.verfuegbareMenge;
        if(priceScaleArrayLength === 1){
          structuredData.offers = this.structuredOfferData(priceScaleArray[0],verfuegbareMenge);
        } else {
              let lowestPriceScale = priceScaleArray[0];
          let higestPriceScale = priceScaleArray[0];

          for (let i = 0; i < priceScaleArrayLength; i++) {
            if (priceScaleArray[i].betrag < lowestPriceScale.betrag) {
              lowestPriceScale = priceScaleArray[i];
            }
            if (priceScaleArray[i].betrag > higestPriceScale.betrag) {
              higestPriceScale = priceScaleArray[i];
            }
          }

          structuredData.offers = {
            "@type": "AggregateOffer",
            lowPrice: lowestPriceScale.betrag,
            highPrice: higestPriceScale.betrag,
            priceCurrency: lowestPriceScale.dstIso,
            offerCount: priceScaleArrayLength,
            availability: ((verfuegbareMenge > 0) ? 'http://schema.org/InStock' : 'http://schema.org/BackOrder'),
            itemCondition: "http://schema.org/NewCondition",
            offers: priceScaleArray.map((priceScaleItem,index)=>{
              return this.structuredOfferData(priceScaleItem,verfuegbareMenge);
            }),
          };
        }

      } else {
        structuredData.offers = {
          "@type": "Offer",
          id: window.location.href+"#offer",
          url: window.location.href,
          price: dic("PRICE_ON_DEMAND"),
        };
      }
      return <script type="application/ld+json">{JSON.stringify(structuredData)}</script>;
    } else {
      return null;
    }
  }
}

export default ProductStructuredData;
