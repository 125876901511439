import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ManageUsersData } from "../../State";

interface Props {
  user: ManageUsersData;
}

export const keysToDisplay: string[] = [
  "_id",
  "email",
  "dstDnr",
  "_insertDate",
  "isVerified",
  "isCompona",
  "isAssignedErp",
  "isManager",
  "dstZlgkond",
  "dabKondgrpnr",
  "dstIso",
];

class UserDataTable extends React.PureComponent<Props> {
  render() {
    const { user } = this.props;
    return keysToDisplay.map((key, i) => {
      return (
        <div key={i} className="list__row">
          <div className="list-row__item">
            <span className="list__label">Key</span>
            <span className="list__text">
              <span>{dic(key.toUpperCase())}</span>
            </span>
          </div>
          <div className="list-row__item">
            <span className="list__label">Value</span>
            <span className="list__text">
              <span>{user.hasOwnProperty(key) ? String(user[key]) : ""}</span>
            </span>
          </div>
        </div>
      );
    });
  }
}

export default UserDataTable;
