import { CheckoutActionTypes } from "./ActionTypes";
import { routerActions } from "connected-react-router";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { ConsentValues, PopMessageType } from "../../common/constants/Constants";
import { ListActions } from "../../list/actions/listActions";
import COMPONA_WidgetServer_JSON_Order from "../../common/services/COMPONA_WidgetServer_JSON_Order";
import { priceCalculateShipping, priceGetVatRate } from "../../common/utils/priceCalculations";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import * as Sentry from "@sentry/browser";
import { createDatatransUrl } from "../../common/utils/createUrls";
import { AdrVo } from "../../State";
import { ShippingOptionVo } from "../../common/vo/ShippingOptionVo";
import { TagmanagerUtils } from "../../common/utils/tagmanagerUtils";
import ElstrIo from "elstr-jslib-4/dist/ElstrIo";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { UserActions } from "../../user/actions/userActions";

declare var COMPONA_STATIC_CACHE;

export const CheckoutActions = {
  setCheckoutData: function(data) {
    return { type: CheckoutActionTypes.SET_CHECKOUT_DATA, payload: { data } };
  },

  setCheckoutOrderDataValue: function(name, value) {
    return { type: CheckoutActionTypes.SET_CHECKOUT_ORDER_DATAVALUE, payload: { name, value } };
  },

  resetCheckoutOrder: function() {
    return { type: CheckoutActionTypes.RESET_CHECKOUT_ORDER, payload: {} };
  },

  initCheckoutOrder: function(user, cart) {
    return { type: CheckoutActionTypes.INIT_CHECKOUT_ORDER, payload: { user, cart } };
  },

  setCheckoutFormLioAdrDataValue: function(name, value) {
    return {
      type: CheckoutActionTypes.SET_CHECKOUT_FORMLIOADR_DATAVALUE,
      payload: { name, value },
    };
  },

  setCheckoutOrderShippingDestination: function(shippingDestination, isAssignedErp: boolean) {
    return {
      type: CheckoutActionTypes.SET_CHECKOUT_ORDER_SHIPPINGDESTINATION,
      payload: { shippingDestination, isAssignedErp },
    };
  },

  setCheckoutFormFaoAdrDataValue: function(name, value) {
    return {
      type: CheckoutActionTypes.SET_CHECKOUT_FORMFAOADR_DATAVALUE,
      payload: { name, value },
    };
  },

  setCheckoutFormvalidationError: function(key, translationKey) {
    return {
      type: CheckoutActionTypes.SET_CHECKOUT_FORMVALIDATION_ERROR,
      payload: { key, translationKey },
    };
  },

  resetCheckoutFormvalidationError: function(key) {
    return {
      type: CheckoutActionTypes.RESET_CHECKOUT_FORMVALIDATION_ERROR,
      payload: { key },
    };
  },

  /**
   *
   * @returns {(dispatch, getState) => Promise<void>}
   */
  startCheckout: function() {
    return async (dispatch, getState) => {
      const userState = getState().user;
      const cartState = getState().cart;
      dispatch(LayoutActions.openCart(false));
      dispatch(CheckoutActions.initCheckoutOrder(userState, cartState));
      dispatch(routerActions.push("/shop/checkout/addresses"));
    };
  },

  /**
   *
   * @returns {(dispatch, getState) => Promise<void>}
   */
  initCheckoutIfNotInitialised: function() {
    return async (dispatch, getState) => {
      const checkoutState = getState().checkout;
      if (!checkoutState.isInit) {
        dispatch(CheckoutActions.initCheckoutOrder(getState().user, getState().cart));
      }
    };
  },

  /**
   *
   * @returns {(dispatch, getState) => void}
   */
  proceedCheckoutAsGuest: function() {
    return (dispatch, getState) => {
      dispatch(CheckoutActions.setCheckoutData({ asGuest: true }));
    };
  },

  /**
   *
   * @param name
   * @param value
   * @returns {(dispatch, getState) => Promise<void>}
   */
  onOrderInputChange: function(name, value) {
    return async (dispatch, getState) => {
      const checkoutState = getState().checkout;
      const userState = getState().user;
      dispatch(CheckoutActions.setCheckoutOrderDataValue(name, value));
      if (name === "dkoLioAdrinr") {
        if (value === "0" && checkoutState.formLioAdr) {
          dispatch(
            CheckoutActions.setCheckoutOrderShippingDestination(
              checkoutState.formLioAdr.land,
              userState.isAssignedErp,
            ),
          );
        } else {
          const addresses = userState.adr.filter(address => address.inr === value);
          if (Array.isArray(addresses) && addresses.length > 0) {
            dispatch(
              CheckoutActions.setCheckoutOrderShippingDestination(
                addresses[0].land,
                userState.isAssignedErp,
              ),
            );
          }
        }
        if (value === "0") {
          dispatch(CheckoutActions.setCheckoutData({ editLioAdr: true }));
        } else {
          dispatch(CheckoutActions.setCheckoutData({ editLioAdr: false }));
        }
      }
      if (name === "dkoFaoAdrinr") {
        if (value === "0") {
          dispatch(CheckoutActions.setCheckoutData({ editFaoAdr: true }));
        } else {
          dispatch(CheckoutActions.setCheckoutData({ editFaoAdr: false }));
        }
      }
      if (name === "agbConfirmed") {
        dispatch(CheckoutActions.resetCheckoutFormvalidationError("orderAgbConfirmed"));
      }
      if (name === "dkoLiefartCd") {
        dispatch(CheckoutActions.resetCheckoutFormvalidationError("radioBoxItemsDkoLiefartCd"));
      }
      if (name === "accountNumber") {
        dispatch(CheckoutActions.resetCheckoutFormvalidationError("orderAccountNumber"));
      }
      if (name === "eoriNumber") {
        dispatch(CheckoutActions.resetCheckoutFormvalidationError("orderEoriNumber"));
      }

      dispatch(CheckoutActions.updateFaoLandNotCh());
      dispatch(CheckoutActions.validateDstIsoCurrentFaoLandNotCh());
    };
  },

  onSaveFormLioAdr: function() {
    return async (dispatch, getState) => {
      const checkoutState = getState().checkout;
      const userState = getState().user;
      dispatch(
        CheckoutActions.setCheckoutOrderShippingDestination(
          checkoutState.formLioAdr.land,
          userState.isAssignedErp,
        ),
      );

      dispatch(CheckoutActions.updateFaoLandNotCh());
      dispatch(CheckoutActions.validateDstIsoCurrentFaoLandNotCh());
    };
  },

  onSaveFormFaoAdr: function() {
    return async (dispatch, getState) => {

      dispatch(CheckoutActions.updateFaoLandNotCh());
      dispatch(CheckoutActions.validateDstIsoCurrentFaoLandNotCh());
    };
  },

  /**
   *
   * @param name
   * @param value
   * @returns {(dispatch, getState) => Promise<void>}
   */
  onInputChangeLio: function(name, value) {
    return async (dispatch, getState) => {
      const checkoutState = getState().checkout;
      const userState = getState().user;
      dispatch(CheckoutActions.setCheckoutFormLioAdrDataValue(name, value));
      if (checkoutState.order && checkoutState.order.dkoLioAdrinr === "0" && name === "land") {
        dispatch(
          CheckoutActions.setCheckoutOrderShippingDestination(value, userState.isAssignedErp),
        );
      }
    };
  },

  /**
   *
   * @param value
   * @returns {(dispatch, getState) => void}
   */
  onOptionDkoLiefartCdChange: function(value) {
    return (dispatch, getState) => {
      dispatch(CheckoutActions.setCheckoutOrderDataValue("dkoLiefartCd", value));
      dispatch(CheckoutActions.resetCheckoutFormvalidationError("radioBoxItemsDkoLiefartCd"));
      dispatch(CheckoutActions.resetCheckoutFormvalidationError("orderAccountNumber"));
      dispatch(CheckoutActions.resetCheckoutFormvalidationError("orderEoriNumber"));

      const shippingOption: ShippingOptionVo = window.COMPONA_STATIC_CACHE.lieferarten.find(
        shippingOption => shippingOption.liefartCd === value,
      );
      if (shippingOption) {
        if (shippingOption.mAccountNumber === false) {
          dispatch(CheckoutActions.setCheckoutOrderDataValue("accountNumber", null));
        }
        if (shippingOption.mEoriNumber === false) {
          dispatch(CheckoutActions.setCheckoutOrderDataValue("eoriNumber", null));
        }
      }
    };
  },

  /**
   *
   * @param value
   * @returns {(dispatch, getState) => void}
   */
  onOptionDkoAblnrChange: function(value) {
    return (dispatch, getState) => {
      dispatch(CheckoutActions.setCheckoutOrderDataValue("dkoAblnr", value));
    };
  },

  /**
   *
   * @param e
   * @returns {(dispatch, getState) => undefined}
   */
  validateProceedToOptions: function(e) {
    return (dispatch, getState) => {
      const checkoutState = getState().checkout;
      if (checkoutState.editLioAdr || checkoutState.editFaoAdr) {
        dispatch(
          createPopMessage(PopMessageType.warning, dic("ADRESSEN NICHT GESPEICHERT"), 10000),
        );
        e.preventDefault();
        return false;
      }
      if (
        !checkoutState.order.shippingDestination ||
        checkoutState.order.shippingDestination.length === 0
      ) {
        dispatch(createPopMessage(PopMessageType.warning, dic("LIEFERLAND ZWINGEND"), 10000));
        e.preventDefault();
        return false;
      } else {
        if (
          !COMPONA_STATIC_CACHE.laender.find(
            land => land.key === checkoutState.order.shippingDestination,
          )
        ) {
          dispatch(createPopMessage(PopMessageType.warning, dic("LIEFERLAND UNGUELTIG"), 10000));
          e.preventDefault();
          return false;
        }
      }

      dispatch(CheckoutActions.updateFaoLandNotCh());
      dispatch(CheckoutActions.validateDstIsoCurrentFaoLandNotCh());

      return true;
    };
  },

  updateFaoLandNotCh: function() {
    return (dispatch, getState) => {
      const checkoutState = getState().checkout;
      const userState = getState().user;

      let faoLand = "";
      const dkoFaoAdrinr = checkoutState.order['dkoFaoAdrinr'];
      const dkoLioAdrinr = checkoutState.order['dkoLioAdrinr'];
      if (dkoFaoAdrinr === "-1") {
        // Rechnungsadresse ist gleich Lieferadresse
        if (dkoLioAdrinr === "0") {
          // Lieferadresse ist neu
          faoLand = checkoutState.formLioAdr.land;
        } else {
          const lioAdr = userState.adr.find(adr => adr.inr === dkoLioAdrinr);
          if(lioAdr){
            faoLand = lioAdr.land;
          }
        }
      } else {
        if (dkoFaoAdrinr === "0") {
          // Rechnungsadresse ist neu
          faoLand = checkoutState.formFaoAdr.land;
        } else {
          const faoAdr = userState.adr.find(adr => adr.inr === dkoFaoAdrinr);
          if(faoAdr){
            faoLand = faoAdr.land;
          }
        }
      }
      if(faoLand !== "CH" && faoLand !== "LI"){
        dispatch(CheckoutActions.setCheckoutData({ faoLandNotCh: true }));
      } else {
        dispatch(CheckoutActions.setCheckoutData({ faoLandNotCh: false }));
      }
    };
  },

  validateDstIsoCurrentFaoLandNotCh: function() {
    return (dispatch, getState) => {
      const checkoutState = getState().checkout;
      const userState = getState().user;
      const supportedCurrencies = config("dstIsoAvailable");
      if(checkoutState.faoLandNotCh){
        //console.log(supportedCurrencies);
        supportedCurrencies.forEach(dstIso => {
          if(dstIso.value ===userState.dstIsoCurrent){
            if(!dstIso.faoLandNotChAllowed){
              const dstIsoDefaultFaoLandNotCh = config("dstIsoDefaultFaoLandNotCh");
              dispatch(UserActions.onChangeDstIsoCurrent(dstIsoDefaultFaoLandNotCh));
              dispatch(createPopMessage(PopMessageType.info, dic("LIEFERUNG AUSSERHALB CH NUR EUR UND USD"), 10000));
            }
          }
        });
      }
    };
  },

  /**
   *
   * @param e
   * @returns {(dispatch, getState) => undefined}
   */
  validateProceedToOverview: function(e) {
    return (dispatch, getState) => {
      const checkoutState = getState().checkout;
      if (!checkoutState.order.dkoLiefartCd) {
        dispatch(createPopMessage(PopMessageType.warning, dic("VERSAND ZWINGEND"), 10000));
        dispatch(
          CheckoutActions.setCheckoutFormvalidationError(
            "radioBoxItemsDkoLiefartCd",
            "VERSAND ZWINGEND",
          ),
        );
        e.preventDefault();
        return false;
      }

      const shippingOption: ShippingOptionVo = window.COMPONA_STATIC_CACHE.lieferarten.find(
        shippingOption => shippingOption.liefartCd === checkoutState.order.dkoLiefartCd,
      );
      let mEoriNumber = false;
      if (shippingOption) {
        mEoriNumber = shippingOption.mEoriNumber;
      }

      if (
        mEoriNumber &&
        (!checkoutState.order.eoriNumber || checkoutState.order.eoriNumber.length === 0)
      ) {
        dispatch(createPopMessage(PopMessageType.warning, dic("EORI ZWINGEND"), 10000));
        dispatch(
          CheckoutActions.setCheckoutFormvalidationError("orderEoriNumber", "EORI ZWINGEND"),
        );
        e.preventDefault();
        return false;
      }
      if (
        shippingOption.mAccountNumber &&
        (!checkoutState.order.accountNumber || checkoutState.order.accountNumber.length === 0)
      ) {
        dispatch(createPopMessage(PopMessageType.warning, dic("ACCOUNT NR ZWINGEND"), 10000));
        dispatch(
          CheckoutActions.setCheckoutFormvalidationError(
            "orderAccountNumber",
            "ACCOUNT NR ZWINGEND",
          ),
        );
        e.preventDefault();
        return false;
      }
      return true;
    };
  },

  /**
   *
   * @param e
   * @returns {(dispatch, getState) => Promise<undefined>}
   */
  validateProceedToOrderedAndProcessOrder: function(e) {
    return async (dispatch, getState) => {
      const checkoutState = getState().checkout;
      const userState = getState().user;
      const cartState = getState().cart;
      const layoutState = getState().layout;

      // No automatic proceed to next step anyway
      e.preventDefault();

      // Validate the previous steps again
      if (dispatch(CheckoutActions.validateProceedToOptions(e)) === false) {
        return false;
      }
      if (dispatch(CheckoutActions.validateProceedToOverview(e)) === false) {
        return false;
      }

      if (!checkoutState.order.agbConfirmed) {
        dispatch(createPopMessage(PopMessageType.warning, dic("AGB ZWINGEND"), 10000));
        dispatch(
          CheckoutActions.setCheckoutFormvalidationError("orderAgbConfirmed", "AGB ZWINGEND"),
        );
        return false;
      }

      // Save the order
      try {
        dispatch(createPopMessage(PopMessageType.info, dic("SPEICHERN DER BESTELLUNG"), 10000));
        dispatch(CheckoutActions.setCheckoutData({ isLoading: true }));

        let deliveryAddress: AdrVo | null = null;
        let billingAddress: AdrVo | null = null;

        const dkoLioAdrinr = checkoutState.order.dkoLioAdrinr;
        if (dkoLioAdrinr === "0") {
          deliveryAddress = checkoutState.formLioAdr;
        }

        let dkoFaoAdrinr = checkoutState.order.dkoFaoAdrinr;
        if (dkoFaoAdrinr === "-1") {
          dkoFaoAdrinr = dkoLioAdrinr;
          if (dkoLioAdrinr === "0") {
            billingAddress = deliveryAddress;
          }
        } else if (dkoFaoAdrinr === "0") {
          billingAddress = checkoutState.formFaoAdr;
        }
        const vatRate = priceGetVatRate(checkoutState.order.shippingDestination);
        const cartSumInclVat = cartState.sum + cartState.sum * (vatRate / 100);
        const shippingCost = priceCalculateShipping(
          checkoutState.order.dkoLiefartCd,
          checkoutState.order.shippingDestination,
          userState.dstIsoCurrent,
          cartSumInclVat,
        );
        const sum = cartState.sum + shippingCost;
        const mwst = sum * (vatRate / 100);
        const totalPrice = parseFloat((sum + mwst).toFixed(4));
        const requestData = {
          comment: "",
          shippingInParts: checkoutState.order.shippingInParts,
          shippingComment: checkoutState.order.shippingComment,
          eoriNumber: checkoutState.order.eoriNumber,
          agbConfirmed: checkoutState.order.agbConfirmed,
          dkoAblnr: checkoutState.order.dkoAblnr,
          dkoIsocode: userState.dstIsoCurrent,
          dkoBestellBez: checkoutState.order.dkoBestellBez,
          dkoLiefartCd: checkoutState.order.dkoLiefartCd,
          accountNumber: checkoutState.order.accountNumber,
          dkoLioAdrinr,
          dkoFaoAdrinr,
          shippingCost,
          vatRate,
          totalPrice,
          deliveryAddress,
          billingAddress,
        };

        const response = await COMPONA_WidgetServer_JSON_Order.createUpdateOrder(
          requestData,
          checkoutState.order.listId,
          null,
        );

        if (response instanceof Error) {
          throw response;
        } else {
          ElstrIo.throwErrorIfResponseContainsErrorMessage(response);
        }

        const orderId = response.data._id;

        await dispatch(CheckoutActions.setCheckoutData({ isLoading: false }));

        // Reset the order if order creation was successful
        await dispatch(CheckoutActions.resetCheckoutOrder());
        await dispatch(ListActions.removeProductsFromList(checkoutState.order.listId));

        // Set the checkout data for the google tag manager
        if(layoutState.cookiesAccepted === ConsentValues.Y){
          TagmanagerUtils.pushEcommercePurchase(response.data);
        }

        // sign order string
        const refno = orderId.toString();
        const signAmount = Math.round(    response.data.totalPrice * 100  );
        const amount = signAmount.toString();
        const currency = response.data.dkoIsocode;
        const signResponse = await COMPONA_WidgetServer_JSON_Order.signOrderString(
            refno,
            amount,
            currency,
        );
        if (signResponse instanceof Error) {
          throw signResponse;
        } else {
          ElstrIo.throwErrorIfResponseContainsErrorMessage(signResponse);
        }

        // Start payment for credit card process 410
        if (checkoutState.order.dkoAblnr === "410") {
          // redirect to datatrans, dkoAblnr = 410 is Credit Card
          const paymentUrl = createDatatransUrl(
              orderId,
              amount,
              response.data.dkoIsocode,
              signResponse.data.sign,
          );
          window.location.href = paymentUrl;
          return;
        } else {
          dispatch(routerActions.push(`/shop/checkout/ordered/${orderId}`));

          return;
        }
      } catch (e) {
        dispatch(CheckoutActions.setCheckoutData({ isLoading: false }));
        dispatch(
          createPopMessage(
            PopMessageType.warning,
            dic("FEHLER BEIM SPEICHERN DER BESTELLUNG"),
            10000,
          ),
        );
        Sentry.captureException(e);
        throw e;
      }
    };
  },
};
