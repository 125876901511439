import * as React from "react";
import CatalogSubtitle from "../components/CatalogSubtitle";
//import MoreButton from "../../common/components/button/MoreButton";
import { Link } from "react-router-dom";
import ManufactureVo from "../../common/vo/ManufactureVo";
import TypeItem from "../components/TypeItem";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  showAllItems: boolean;
  showLessLink?: boolean;

  data: ManufactureVo[];
}

class Type extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showAllItems: false,
    showLessLink: true,
  };

  render() {
    return (
      <>
        <CatalogSubtitle label={dic("FUNKTION")} count={this.props.data.length} />

        <div className="grids filterteaser--product">{this.renderItems()}</div>

        {this.renderMoreBox()}
      </>
    );
  }

  renderMoreBox() {
    if (this.props.data.length <= 6) return null;

    if (this.props.showAllItems) {
      if (this.props.showLessLink) {
        return (
          <div className="grey-box">
            <Link className="btn btn--arrow" to={`/catalog/${activeLang()}`}>{`${dic(
              "WENIGER ANZEIGEN",
            )}`}</Link>
          </div>
        );
      } else {
        return;
      }
    } else {
      return (
        <div className="grey-box">
          <Link className="btn btn--arrow" to={`/catalog/${activeLang()}/f`}>{`${dic(
            "MEHR ANZEIGEN",
          )}`}</Link>
        </div>
      );
    }
  }

  renderItems() {
    let count = 0;

    if (this.props.data instanceof Object) {
      if (Object.keys(this.props.data).length > 0) {
        return this.props.data.map((item: ManufactureVo, index) => {
          count++;

          if (!this.props.showAllItems && count > 6) return false;
          return (
            <TypeItem
              name={item.name}
              description={item.description}
              img={item.img}
              href={item.href}
              key={item.name + index}
            />
          );
        });
      }
    }
  }
}

export default Type;
