import { SignupState } from "../../State";
import { SignupActionTypes } from "../actions/ActionTypes";
import SignupVo from "../../common/vo/SignupVo";
// import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

const signupReducer = (state: SignupState, action) => {
  if (!state) return null;

  switch (action.type) {
    case SignupActionTypes.SET_SIGNUP_DATAVALUE:
      // console.log(action.payload);
      const signupData: SignupVo = { ...state.data };
      signupData[action.payload.name] = action.payload.value;

      return {
        ...state,
        data: signupData,
      };

    case SignupActionTypes.SET_SIGNUP_ISLOADING:
      // console.log(action.payload);
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case SignupActionTypes.SET_SIGNUP_USER_EXISTS_ALREADY:
      // console.log(action.payload);
      return {
        ...state,
        userExistsAlready: action.payload.userExistsAlready,
      };

    case SignupActionTypes.SET_SIGNUP_MSG:
      // console.log(action.payload);
      return {
        ...state,
        msg: action.payload.msg,
      };

    case SignupActionTypes.SET_SIGNUP_NEWSLETTER:
      return {
        ...state,
        signupNewsletter: action.payload.signupNewsletter,
      };
  }

  return state;
};

export default signupReducer;
