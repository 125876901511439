import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import {
  default as SimpleButton,
  SimpleButtonTypes,
} from "../../common/components/button/SimpleButton";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { SignupActions } from "../actions/signupActions";

interface Props {
  token: string;
  isLoading: boolean;
}

class SignupResetPassword extends React.PureComponent<Props> {
  private readonly password: any;
  private readonly passwordRepeated: any;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element

    this.password = React.createRef();
    this.passwordRepeated = React.createRef();

    this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
  }

  onSubmitResetPassword(e) {
    e.preventDefault();
    const password = this.password.current.value;
    const passwordRepeated = this.passwordRepeated.current.value;
    ElstrCore.store.dispatch(
      SignupActions.resetPassword(this.props.token, password, passwordRepeated),
    );
    this.password.current.value = "";
    this.passwordRepeated.current.value = "";
  }

  renderButtonSubmit() {
    if (this.props.isLoading) {
      return (
        <div className="form-submit__wrapper form-row">
          <SimpleButton
            type={SimpleButtonTypes.SUBMIT}
            onClick={() => {}}
            onSubmit={() => {}}
            label={dic("PASSWORT WIRD GEAENDERT")}
          />
        </div>
      );
    } else {
      return (
        <div className="form-submit__wrapper form-row">
          <SimpleButton
            type={SimpleButtonTypes.SUBMIT}
            onClick={() => {}}
            onSubmit={this.onSubmitResetPassword}
            label={dic("PASSWORT AENDERN")}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <main className="main main--std" role="main">
        <div className="content">
          <form className="form" onSubmit={this.onSubmitResetPassword}>
            <h1 className="subtitle form-subtitle">{dic("PASSWORT ZURUECKSETZEN")}</h1>
            <div className="content__2col">
              <div className="col">
                <div className="form-row">
                  <div className="form-input__wrapper">
                    <label className="label" htmlFor="password">
                      {dic("PASSWORT")} <span>*</span>
                    </label>
                    <input
                      ref={this.password}
                      className="input--text"
                      type="password"
                      id="password"
                      name="password"
                      placeholder="******"
                      autoComplete="off"
                      aria-required={true}
                      required={true}
                    />
                  </div>
                  <div className="form-input__wrapper">
                    <label className="label" htmlFor="passwordRepeated">
                      {dic("PASSWORT WIEDERHOLEN")} <span>*</span>
                    </label>
                    <input
                      ref={this.passwordRepeated}
                      className="input--text"
                      type="password"
                      id="passwordRepeated"
                      name="passwordRepeated"
                      placeholder="******"
                      autoComplete="off"
                      aria-required={true}
                      required={true}
                    />
                  </div>
                </div>
                {this.renderButtonSubmit()}
              </div>
              <div className="col" />
            </div>
          </form>
        </div>
      </main>
    );
  }
}

export default SignupResetPassword;
