import * as React from "react";
import FilterPanelBlock from "./FilterPanelBlock";
import { PanelDataVO } from "../../common/vo/PanelDataVo";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { getAttributeDescription } from "../../common/utils/attributeUtils";

interface Props {
  data: PanelDataVO[] | null;
  search: any;
}

class FilterPanelBlockList extends React.PureComponent<Props> {
  render() {
    if (!this.props.data) return null;

    return this.props.data.map((panelData, index) => {
      return (
        <FilterPanelBlock
          panelData={panelData}
          description={getAttributeDescription(panelData.id, activeLang())}
          key={`panel${index}`}
          search={this.props.search}
        />
      );
    });
  }
}

export default FilterPanelBlockList;
