import ElstrIo from "elstr-jslib-4/dist/ElstrIo";
import { ListType } from "../constants/Constants";

const className = "COMPONA_WidgetServer_JSON_List";

export default class COMPONA_WidgetServer_JSON_List {
  static async createList(name: string, listType: ListType, comment = "") {
    return ElstrIo.requestJsonRpc<any, any>(className, "createList", {
      name,
      listType,
      comment,
    });
  }

  static async updateList(listId: string, name: string, comment: string) {
    return ElstrIo.requestJsonRpc<any, any>(className, "updateList", {
      listId,
      name,
      comment,
    });
  }

  static async deleteList(listId: string) {
    return ElstrIo.requestJsonRpc<any, any>(className, "deleteList", {
      listId,
    });
  }

  static async setCurrentUserAsListOwner(listIds: string[]) {
    return ElstrIo.requestJsonRpc<any, any>(className, "setCurrentUserAsListOwner", {
      listIds,
    });
  }

  static async getListData(listId: string) {
    return ElstrIo.requestJsonRpc<any, any>(className, "getListData", {
      listId,
    });
  }

  static async getLists(email: string) {
    return ElstrIo.requestJsonRpc<any, any>(className, "getLists", {
      email,
    });
  }

  static async updateProduct(
    listId: string,
    objectID: string,
    pos: number,
    quantity: number,
    comment: string,
  ) {
    return ElstrIo.requestJsonRpc<any, any>(className, "updateProduct", {
      listId,
      objectID,
      pos,
      quantity,
      comment,
    });
  }

  static async removeProduct(listId: string, objectID: string, pos: number) {
    return ElstrIo.requestJsonRpc<any, any>(className, "removeProduct", {
      listId,
      objectID,
      pos,
    });
  }

  static async moveProduct(listId: string, objectID: string, pos: number, posNew: number) {
    return ElstrIo.requestJsonRpc<any, any>(className, "moveProduct", {
      listId,
      objectID,
      pos,
      posNew,
    });
  }

  /**
   *
   * @param {string} listId
   * @param {string} objectID
   * @param {number | null} pos = null the BE will put item at the end
   * @param {number} quantity
   * @param {string} comment
   * @return {Promise<any>}
   */
  static async addProduct(
    listId: string,
    objectID: string,
    pos: number | null,
    quantity: number,
    comment = "",
  ) {
    return ElstrIo.requestJsonRpc<any, any>(className, "addProduct", {
      listId,
      objectID,
      pos,
      quantity,
      comment,
    });
  }

  /**
   * Adds products from given list to cart
   * @param {string} listId
   * @param {string} cartListId
   * @return {Promise<any>}
   */
  static async addProductsFromListToCart(listId: string, cartListId: string) {
    return ElstrIo.requestJsonRpc<any, any>(className, "addProductsFromListToCart", {
      listId,
      cartListId,
    });
  }

  /**
   * Removes all products from a list
   * @param {string} listId
   * @return {Promise<any>}
   */
  static async removeProductsFromList(listId: string) {
    return ElstrIo.requestJsonRpc<any, any>(className, "removeProductsFromList", {
      listId,
    });
  }
}
