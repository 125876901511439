import * as React from "react";

import { connect } from "react-redux";
import { State } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import FilterPanelBlockList from "../components/FilterPanelBlockList";
import { PanelDataVO } from "../../common/vo/PanelDataVo";
import { FilterPanelActions } from "../actions/filterPanelActions";
import FilterTree from "../components/FilterTree";
//import { SearchActions } from "../../search/actions/searchActions";
//import addTreeGroup = SearchActions.addTreeGroup;
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";

interface FilterStateToProps {
  filterPanelData: PanelDataVO[] | null;
  filterTreeData: any;
  open: boolean;
  visible: boolean;
  search: any;
}

interface FilterDispatchToProps {
  onMount: () => void;
}

interface Props extends FilterStateToProps, FilterDispatchToProps {}

class FilterPanel extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onFilterClickHandler = this.onFilterClickHandler.bind(this);
  }

  onFilterClickHandler() {
    ElstrCore.store.dispatch(LayoutActions.openFilter(!this.props.open));
  }

  // <FilterTree data={this.props.filterTreeData}/>
  render() {
    if (!this.props.visible) return null;

    const label = this.props.open ? dic("FILTER AUSBLENDEN") : dic("FILTER EINBLENDEN");
    const filterpanelIconClassname = this.props.open
      ? dic("filterpanel-closer")
      : dic("filterpanel-opener");

    return (
      <>
        <div className={filterpanelIconClassname} onClick={this.onFilterClickHandler}>
          {label}
        </div>
        <aside className="filterpanel" role="contentinfo">
          <FilterTree data={this.props.filterTreeData} />

          <FilterPanelBlockList data={this.props.filterPanelData} search={this.props.search} />
        </aside>
      </>
    );
  }
}

const mapStateToProps = (state: State): FilterStateToProps => ({
  filterTreeData: state.productFilter.treeData,
  filterPanelData: state.productFilter.panelData,
  open: state.layout.filterOpen,
  visible: state.layout.filterPanelVisible,
  search: state.search,
});

const mapDispatchToProps = (dispatch): FilterDispatchToProps => ({
  onMount: () => dispatch(FilterPanelActions.loadFacets()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
