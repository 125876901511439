import * as React from "react";
import { OrderState } from "../../State";
import OrderList from "../../order/components/OrderList";
import { OrderActions } from "../../order/actions/orderActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";

interface Props {
  order: OrderState;
}

class AccountOrders extends React.PureComponent<Props> {
  componentDidMount() {
    ElstrCore.store.dispatch(OrderActions.getOrders());
  }

  render() {
    return <OrderList orders={this.props.order.orders} isLoading={this.props.order.isLoading} />;
  }
}

export default AccountOrders;
