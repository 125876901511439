export enum CatalogActionsTypes {
  SHOW_ALL_MANUFACTURES = "CATALOG_SHOW_ALL_MANUFACTURES",
  SHOW_ALL_TYPES = "CATALOG_SHOW_ALL_TYPES",
  TOGGLE_PRODUCT_VIEW_SETTINGS_BOX = "TOGGLE_PRODUCT_VIEW_SETTINGS_BOX",

  // FILTER
  REMOVE_VIEW_SETTINGS_COLUMN = "REMOVE_VIEW_SETTINGS_COLUMN",
  CHANGE_DRAG_DROP_HANDLER = "CHANGE_DRAG_DROP_HANDLER",
  SAVE_VIEW_SETTINGS_COLUMNS = "SAVE_VIEW_SETTINGS_COLUMNS",
  RESET_VIEW_SETTINGS_COLUMNS = "RESET_VIEW_SETTINGS_COLUMNS",

  // NEW COL AUTO SUGGSESTION
  NEW_COL_AUTO_CHANGE = "NEW_COL_AUTO_CHANGE",
  NEW_COL_AUTO_SELECTED = "NEW_COL_AUTO_SELECTED",

  // CATALOG update tiles
  CATALOG_FUNCTION_TILES = "CATALOG_FUNCTION_TILES",
  CATALOG_MANUFACTURES_TILES = "CATALOG_MANUFACTURES_TILES",

  // Update Product
  UPDATE_PRODUCT_DATA = "UPDATE_PRODUCT_DATA",
}
