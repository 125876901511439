export const LONG_DASH = "—";

export interface SimpleRangeVo {
  minValue: string | undefined;
  maxValue: string | undefined;
}

/**
 *
 * @param {number} minValue, minimum value for current constraint
 * @param {number} maxValue, maximum value for current constraint
 * @param {number} minMinValue, global minimum
 * @param {number} maxMaxValue, global maximum
 * @returns {string}
 */
export function rangeToString(
  minValue: number,
  maxValue: number,
  minMinValue: number,
  maxMaxValue: number,
): string {
  let rangeString = LONG_DASH; // default: nothing to filter

  if (minValue > minMinValue) {
    rangeString = String(minValue) + rangeString;
  }
  if (maxValue < maxMaxValue) {
    rangeString = rangeString + String(maxValue);
  }
  return rangeString;
}

/**
 *
 * @param {string} currentRange
 * @param lang
 * @param string
 * @returns {string}
 */
export function stringToRange(currentRange: string): SimpleRangeVo {
  let minValue: string | undefined = undefined;
  let maxValue: string | undefined = undefined;

  const longDash = "—";
  if (currentRange.indexOf(longDash) >= 0) {
    // special case range slider values
    const rangeLimits = currentRange.split(longDash);
    if (rangeLimits[0] !== "") {
      minValue = rangeLimits[0];
    }
    if (rangeLimits[1] !== "") {
      maxValue = rangeLimits[1];
    }
  }
  return { minValue, maxValue };
}
