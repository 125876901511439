import * as React from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import SearchInput from "./SearchInput";
import SearchLayer from "../list/SearchLayer";
import { SearchState } from "../../../State";
import { SearchActions } from "../../../search/actions/searchActions";
import { ProductActions } from "../../../product/actions/productActions";
import TagButton from "../button/TagButton";
import { getUnitSymbol } from "../../utils/unitUtils";
import { getAttributeDescription } from "../../utils/attributeUtils";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";

declare var COMPONA_STATIC_CACHE;

interface Props {
  onClickShowResult: () => void;
  showProductSearchResult: boolean;
  showSuggestionList: boolean;
  search: SearchState;
}

class SearchBox extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onTextChange = this.onTextChange.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onTagRemove = this.onTagRemove.bind(this);
    this.onTreeTagRemove = this.onTreeTagRemove.bind(this);
    this.onClickProductLink = this.onClickProductLink.bind(this);
  }

  onTextChange(result) {
    ElstrCore.store.dispatch(SearchActions.addTextSearchQuery(result.target.value));
  }

  onSearchClear() {
    ElstrCore.store.dispatch(SearchActions.resetSearch());
    ElstrCore.store.dispatch(SearchActions.resetSorting());
  }

  onTagRemove(facetKey) {
    const facetValues = this.props.search.filterSettings[facetKey];
    const lastValue = facetValues[facetValues.length - 1];
    //ElstrCore.store.dispatch(SearchActions.removeFacetSearch(facetKey, lastValue));
    ElstrCore.store.dispatch(SearchActions.setFacetSearch(facetKey, lastValue, false));
  }

  onTreeTagRemove(result) {
    ElstrCore.store.dispatch(SearchActions.removeTreeSearch(result));
  }

  onClickProductLink(product) {
    ElstrCore.store.dispatch(ProductActions.setInitialData(product));
  }

  render() {
    let tagLength = 0;
    if (this.props.search.filterSettings) {
      tagLength += Object.keys(this.props.search.filterSettings).length;
    }
    if (this.props.search.trees) {
      tagLength += Object.keys(this.props.search.trees).length;
    }

    return (
      <div className="catalog__searchbox" role="search">
        <div className="catalog-searchbox__header">
          {tagLength > 0 && <span className="close--circle" onClick={this.onSearchClear} />}
          <div>
            {this.renderTreeTagButtons("hersteller")}
            {this.renderTreeTagButtons("funktion")}
            {this.renderTagButtons()}
          </div>
          <SearchInput
            value={this.props.search.query || ""}
            onChange={this.onTextChange}
            placeHolder={`${dic("PRODUKT FINDEN")}...`}
            onSubmit={this.props.onClickShowResult}
          />
        </div>

        <SearchLayer
          searchCount={this.props.search.searchCount}
          searchResult={this.props.search.result}
          suggestions={this.props.search.suggestions}
          showProductSearchResult={this.props.showProductSearchResult}
          showSuggestionList={this.props.showSuggestionList}
          onClickProductLink={this.onClickProductLink}
        />
      </div>
    );
  }

  renderTagButtons() {
    // Similar logic in titleStringUtils.tsx
    return Object.keys(this.props.search.filterSettings).map(facetKey => {
      if (COMPONA_STATIC_CACHE.attributes.hasOwnProperty(facetKey)) {
        const desc = getAttributeDescription(facetKey, activeLang());
        const dataType = window.COMPONA_STATIC_CACHE.attributes[facetKey].dataType;

        let unit = "";
        if (COMPONA_STATIC_CACHE.attributes[facetKey].unitOfMeasureId !== "") {
          unit = getUnitSymbol(
            COMPONA_STATIC_CACHE.attributes[facetKey].unitOfMeasureId,
            activeLang(),
          );
        }

        let concatenatedValues = "";
        const facetValues = this.props.search.filterSettings[facetKey];
        if (dataType === "boolean") {
          if (facetValues instanceof Array) {
            if (facetValues.find(prop => prop === "0")) {
              concatenatedValues += " " + dic("NEIN");
            }
            if (facetValues.find(prop => prop === "1")) {
              if (concatenatedValues !== "") {
                concatenatedValues += ",";
              }
              concatenatedValues += " " + dic("JA");
            }
          }
        } else {
          const values = facetValues;
          concatenatedValues = values.join(" " + unit + ", ") + " " + unit;
        }
        return (
          <TagButton
            id={facetKey}
            label={desc + ":" + concatenatedValues}
            key={facetKey}
            onRemoveClick={this.onTagRemove}
          />
        );
      } else {
        return null;
      }
    });
  }

  renderTreeTagButtons(treeKey: string) {
    const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];

    if (typeof this.props.search.trees[treeKey] !== "undefined") {
      return Object.keys(this.props.search.trees[treeKey]).map(item => {
        const classifi = staticCacheClassifications.find(node => node.objectID === item);
        const pos = classifi.breadcrumbDescShort.indexOf(" >");
        const label =
          dic(classifi.breadcrumbDescShort.substr(0, pos).toUpperCase()) +
          classifi.breadcrumbDescShort.substr(pos);

        return (
          <TagButton id={item} label={label} key={item} onRemoveClick={this.onTreeTagRemove} />
        );
      });
    }
    return;
  }
}

export default SearchBox;
