import { AccountActionTypes } from "./ActionTypes";
import COMPONA_WidgetServer_JSON_User from "../../common/services/COMPONA_WidgetServer_JSON_User";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { PopMessageType } from "../../common/constants/Constants";
import { UserActions } from "../../user/actions/userActions";

export const AccountActions = {
  setAccountData: function(data) {
    return { type: AccountActionTypes.SET_ACCOUNT_DATA, payload: { data } };
  },

  setAccountFormAdrDataValue: function(name, value) {
    return { type: AccountActionTypes.SET_ACCOUNT_FORMADR_DATAVALUE, payload: { name, value } };
  },

  addOrUpdateAddress: function() {
    return async (dispatch, getState) => {
      const accountState = getState().account;
      const userState = getState().user;

      // Save the address
      try {
        const response = await COMPONA_WidgetServer_JSON_User.addOrUpdateAddress(
          userState.email,
          accountState.formAdr,
          accountState.formAdr.type,
        );

        if (response instanceof Error) {
          throw response;
        } else {
          const componaUserData = await COMPONA_WidgetServer_JSON_User.getUserData(
            userState.username,
          );
          await dispatch(UserActions.setUserData(componaUserData.data));
        }
      } catch (e) {
        console.log(e.message);
        dispatch(createPopMessage(PopMessageType.warning, e.message, 10000));
      }
    };
  },

  disableAddress: function(inr) {
    return async (dispatch, getState) => {
      const userState = getState().user;
      const adrToDisable = userState.adr.find(adr => adr.inr === inr);

      // Disable the address
      try {
        const response = await COMPONA_WidgetServer_JSON_User.disableAddress(
          userState.email,
          adrToDisable,
        );

        if (response instanceof Error) {
          throw response;
        } else {
          const componaUserData = await COMPONA_WidgetServer_JSON_User.getUserData(
            userState.username,
          );
          await dispatch(UserActions.setUserData(componaUserData.data));
        }
      } catch (e) {
        console.log(e.message);
        dispatch(createPopMessage(PopMessageType.warning, e.message, 10000));
      }
    };
  },

  changePassword: function(password, passwordNew, passwordNewRepeated) {
    // console.log("changePassword");
    return async (dispatch, getState) => {
      if (passwordNew === passwordNewRepeated) {
        try {
          dispatch(createPopMessage(PopMessageType.info, dic("ANTRAG GESENDET"), 10000));
          const response = await COMPONA_WidgetServer_JSON_User.updateUser(
            getState().user.username,
            "",
            password,
            passwordNew,
          );
          if (response.data) {
            dispatch(createPopMessage(PopMessageType.info, dic("PASSWORT GEAENDERT"), 10000));
          }
        } catch (e) {
          console.log(e.message);
          dispatch(createPopMessage(PopMessageType.warning, e.message, 10000));
        }
      } else {
        dispatch(
          createPopMessage(PopMessageType.warning, dic("PASSWORT NICHT KORREKT WIEDERHOLT"), 10000),
        );
      }
    };
  },

  changeAccountEmail: function(accountEmail) {
    // console.log("accountEmail");
    return async (dispatch, getState) => {
      try {
        dispatch(createPopMessage(PopMessageType.info, dic("ANTRAG GESENDET"), 10000));
        const response = await COMPONA_WidgetServer_JSON_User.updateUser(
          getState().user.username,
          accountEmail,
          "",
          "",
        );
        if (response.data) {
          dispatch(
            createPopMessage(PopMessageType.info, dic("EMAIL ZUR VERFIKATION GESENDET"), 10000),
          );
        }
      } catch (e) {
        console.log(e.message);
        dispatch(createPopMessage(PopMessageType.warning, e.message, 10000));
      }
    };
  },
}
