import ProductVo from "./common/vo/ProductVo";
import ProductTakeVo from "./common/vo/ProductTakeVo";
import ManufactureVo from "./common/vo/ManufactureVo";
import { TestDataGenerator } from "./TestDataGenerator";
import { PanelDataVO } from "./common/vo/PanelDataVo";
import SearchGroupVo from "./common/vo/SearchGroupVo";
import SearchSuggestionVo from "./common/vo/SearchSuggestionVo";
import AttributeVo from "./common/vo/AttributeVo";
import ElstrUserVo from "elstr-jslib-4/dist/vo/ElstrUserVo";
import { ElstrColumnsVo } from "elstr-jslib-4/dist/vo/ElstrDbVo";
import ListVo from "./common/vo/ListVo";
import { ConsentValues, Sicon } from "./common/constants/Constants";
import SignupVo from "./common/vo/SignupVo";
import OrderVo, { OrderListProductVo } from "./common/vo/OrderVo";
import { LinkAlternateVo } from "./common/vo/LinkAlternateVo";
import ElstrBaseApp from "elstr-jslib-4/dist/ElstrBaseApp";
import TransactionLogsVo from "./common/vo/TransactionLogsVo";

declare var COMPONA_STATIC_CACHE;

function getFilter() {
  const localColumnsViewSettings: any = ElstrBaseApp.ELS.getValue("columnsViewSettings");

  const staticCacheAttributes = window.COMPONA_STATIC_CACHE.attributes;
  const columns: any[] = [];
  for (const dataAttributeKey in staticCacheAttributes) {
    const attribute = staticCacheAttributes[dataAttributeKey];
    if (attribute.listDisplayAllowed && attribute.listDisplayDefault) {
      columns.push(attribute);
    }
  }

  try {
    if (localColumnsViewSettings) return localColumnsViewSettings;
  } catch (e) {
    console.error("columnsViewSettings data corrupt");
    return columns;
  }

  return columns;
}

function getCookiesAccepted() {
  const localAcceptCookies:ConsentValues | null = ElstrBaseApp.ELS.getValue("acceptCookies");

  if(localAcceptCookies === ConsentValues.Y || localAcceptCookies === ConsentValues.N){
    return localAcceptCookies;
  }

  return null;
}

function getColumnsAvailable() {
  const staticCacheAttributes = window.COMPONA_STATIC_CACHE.attributes;
  const columnsAvailable: any[] = [];

  for (const dataAttributeKey in staticCacheAttributes) {
    const attribute = staticCacheAttributes[dataAttributeKey];
    if (attribute.listDisplayAllowed) {
      columnsAvailable.push(attribute);
    }
  }

  return columnsAvailable;
}

function isMobile():boolean {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  ) < 1024;
}

/**
 * Empty for production but dummy messages for dev
 * @return {any}
 */
function getPopMessages() {
  // Currently disabled but should be kept in case of debugging
  return [];
  /*
  if (process.env.NODE_ENV !== "production") {
    return [
      {
        text: "Initial Text - Closes Second",
        level: "alert",
        sicon: Sicon.chat,
        removedAfterMs: 6000,
        randNum: Math.random(),
      },

      {
        text: "Initial Text - Closes Fourth",
        level: "alert",
        sicon: Sicon.quality,
        removedAfterMs: 11000,
        randNum: Math.random(),
      },
      {
        text: "Some Wishlist item has been added - Closes First",
        level: "wishlist",
        sicon: Sicon.wishlist,
        removedAfterMs: 3000,
        randNum: Math.random(),
      },
      {
        text: "Close This here",
        level: "alert",
        sicon: Sicon.angle,
        removedAfterMs: 0,
        randNum: Math.random(),
      },
      {
        text: "Initial Text - Closes Third",
        level: "alert",
        sicon: Sicon.connector,
        removedAfterMs: 9000,
        randNum: Math.random(),
      },
      {
        text: "Initial Text - Closes Third",
        level: "alert",
        sicon: Sicon.connector,
        removedAfterMs: 9000,
        randNum: Math.random(),
      },
    ];
  } else {
    return [];
  }
  */
}

function getFoldingBoxesAvailable() {
  const staticCacheAttributeSections: any[] = window.COMPONA_STATIC_CACHE.attributeSections;
  const staticCacheAttributes = window.COMPONA_STATIC_CACHE.attributes;
  let foldingBoxesAvailable: FoldingBoxState[] = [];

  if (staticCacheAttributeSections) {
    foldingBoxesAvailable = staticCacheAttributeSections.map(
      (attributeSection, attributeSectionIndex) => {
        const foldingBoxAttributes: any[] = [];
        for (const dataAttributeKey in staticCacheAttributes) {
          const attribute = staticCacheAttributes[dataAttributeKey];
          if (attribute.sectionId) {
            if (attribute.sectionId === attributeSection.attributeSectionId) {
              attribute.isVisible = false;
              foldingBoxAttributes.push(attribute);
            }
          }
        }
        return {
          attributeSectionId: attributeSection.attributeSectionId,
          name: attributeSection.name,
          isVisible: false,
          isExpanded: true,
          attributes: foldingBoxAttributes,
        };
      },
    );
  }
  return foldingBoxesAvailable;
}

export const emptyAddress = {
  inr: "0",
  anrNr: "1",
  subjekttyp: "1",
  name: "",
  vorname: "",
  zeile1: "",
  zeile2: "",
  zeile3: "",
  zeile4: "",
  zeile5: "",
  land: "",
  plz: "",
  ort: "",
  tel: "",
  tel2: "",
  email: "",
  sprache: "",
  type: "",
};

export const componaUserDefault = {
  isVerified: false,
  isCompona: false,
  isAssignedErp: false,
  isManager: false,
  isInit: false,
  dstZlgkond: "16",
  dabKondgrpnr: "1",
  dstIso: "CHF",
  dstIsoCurrent: "CHF",
  dstDnr: null,
  adrEorinr: null,
  userFlyoutLoginMsg: "",
  adr: [],
};

export const initStore: State = {
  router: undefined,

  algoliaData: {
    allFacets: {},
  },

  search: {
    query: "",
    filterSettings: {},
    group: undefined,
    trees: null,
    suggestions: [],
    searchCount: 0,
    result: [],
    mostSearched: [],
    showItems: 100,
    sorting: { key: undefined, direction: undefined },
  },

  layout: {
    facets: {},
    filterOpen: !isMobile(),
    filterPanelVisible: false,
    cartOpen: false,
    cartExplicitlyClosed: false,
    searchOpen: false,
    langOpen: false,
    currencyOpen: false,
    menuOpen: false,
    userOpen: false,
    wishlistOpen: false,
    homeHeader: false,
    scrollState: 0,
    cookiesAccepted: getCookiesAccepted(),
  },

  productFilter: {
    panelData: null,
    treeData: TestDataGenerator.filterSingleTestData(),
  },

  cart: {
    products: [],
    sum: 0,
    shipping: 0,
    total: 0,
    dkoLiefartCd: "21",
    _id: "",
  },

  wishlist: {
    lists: [],
    activeListId: null,
  },

  home: {
    newProducts: [],
  },

  catalog: {
    manufacture: {
      show: true,
      data: [],
      current: [],
      root: [],
    },

    type: {
      show: true,
      data: [],
      current: [],
      root: [],
    },

    productList: {
      viewSettingsOpen: false,
      columnsViewSettings: getFilter(),
      columns: getFilter(),
      columnsAvaible: getColumnsAvailable(),
      newAutoValue: "",
    },
  },

  product: {
    data: null,
    take: null,
    linkAlternates: [],
    foldingBoxes: getFoldingBoxesAvailable(),
    relatedTabActiveIndex: null,
    relatedTabActiveIsLoaded: false,
    relatedTabs: [],
  },

  cms: {
    htmlText: "",
    metaJson: {},
  },

  signup: {
    isLoading: false,
    msg: "",
    userExistsAlready: false,
    signupNewsletter: false,
    data: {
      email: "",
      password: "",
      passwordRepeated: "",
      anrNr: "1",
      vorname: "",
      name: "",
      tel: "",
      zeile1: "",
      zeile2: "",
      zeile3: "",
      dstDnr: "",
      plz: "",
      ort: "",
      land: "",
    },
  },

  account: {
    editAdr: null,
    formAdr: { ...emptyAddress },
  },

  checkout: {
    isInit: false,
    isLoading: false,
    asGuest: false,
    order: null,
    formLioAdr: {
      ...emptyAddress,
      type: "delivery",
    },
    editLioAdr: false,
    formFaoAdr: {
      ...emptyAddress,
      type: "invoice",
    },
    editFaoAdr: false,
    dkoAblnrOptions: null,
    formValidationErrors: {},
    lioLandNotCh: false,
    faoLandNotCh: false,
  },

  order: {
    isLoading: false,
    orders: [],
  },

  user: {
    username: "anonymous",
    isAuth: false,
    isAdmin: false,
    resourcesAllowed: [],
    enterpriseApplicationData: [],
    clientIp: "",
    memberOf: [],

    isLoading: false,
    hasInitLists: false,
    ...componaUserDefault,
  },

  manage: {
    orders: {
      isLoading: false,
      limit: 100,
      offset: 0,
      data: [],
    },
    users: {
      isLoading: false,
      data: [],
    },
  },

  popMessage: getPopMessages() || [],
};

export interface State {
  router: any;

  algoliaData: AlgoliaState;

  // Search state
  search: SearchState;

  // Layout State
  layout: LayoutState;

  // Cart
  cart: CartState;

  // WishlistState
  wishlist: WishlistState;

  // FilterPanel
  productFilter: ProductFilterState;

  // HOME State
  home: HomeState;

  // CATALOG State
  catalog: CatalogState;

  // PRODUCT Detail State
  product: ProductState;

  // CMS CONTENT state
  cms: CmsState;

  signup: SignupState;

  account: AccountState;

  checkout: CheckoutState;

  order: OrderState;

  // USERSTATE extends ElstrUserVo
  user: UserState;

  manage: ManageState;

  // PopMessages State
  popMessage: PopMessageVo[];
}

export interface AlgoliaState {
  allFacets: any;
}

export interface SearchState {
  query: string;
  filterSettings: { [key: string]: string[] }; // key identifies a classification, array contains a list of values, NOTE: values are used for Algolia facet filters AND Algolia numeric filters, depending on attribute type
  group: SearchGroupVo | undefined;
  trees: any | null;
  suggestions: SearchSuggestionVo[];
  searchCount: number;
  result: ProductVo[];
  mostSearched: string[];
  showItems: number;
  sorting: {};
}

export interface CartState extends ListVo {
  sum: number;
  shipping: number;
  total: number;
  dkoLiefartCd: string;
}

export interface WishlistState {
  lists: ListVo[];
  activeListId: null; // will have either an list id from the DB or null. null indicates no list is currently open or active. in case a user adds a new item to the list, activeListId will switch to last wishlist element, add there the product and set the last wishlist element as active
}

export interface ComparisonState {
  lists: ListVo[];
  activeListId: null; // will have either an list id from the DB or null. null indicates no list is currently open or active. in case a user adds a new item to the list, activeListId will switch to last wishlist element, add there the product and set the last wishlist element as active
}

export interface ProductFilterState {
  panelData: PanelDataVO[] | null;
  treeData: any;
}

export interface HomeState {
  newProducts: ProductVo[];
}

export interface CatalogState {
  manufacture: ManuFactureState;
  type: TypeState;
  productList: ProductListState;
}

export interface ProductState {
  data: ProductVo | null;
  take: ProductTakeVo | null;
  linkAlternates: LinkAlternateVo[];
  foldingBoxes: FoldingBoxState[];
  relatedTabActiveIndex: number | null;
  relatedTabActiveIsLoaded: boolean;
  relatedTabs: RelatedTabState[];
}

export interface ManuFactureState {
  data: ManufactureVo[];
  show: boolean;
  current: any;
  root: any;
}

export interface TypeState {
  data: ManufactureVo[];
  show: boolean;
  current: any;
  root: any;
}

export interface ProductListState {
  viewSettingsOpen: boolean;

  columnsViewSettings: AttributeVo[];
  columns: AttributeVo[];

  columnsAvaible: AttributeVo[];
  newAutoValue: string;
}

export interface LayoutState {
  // Facets Data
  facets: any;

  // Container
  filterOpen: boolean;
  searchOpen: boolean;

  filterPanelVisible: boolean;

  // Header
  homeHeader: boolean;
  wishlistOpen: boolean;
  cartOpen: boolean;
  cartExplicitlyClosed: boolean;
  menuOpen: boolean;
  userOpen: boolean;
  langOpen: boolean;
  currencyOpen: boolean;
  scrollState: number;
  cookiesAccepted: ConsentValues | null;
}

export interface SignupState {
  isLoading: boolean;
  msg: string;
  userExistsAlready: boolean;
  signupNewsletter: boolean;
  data: SignupVo;
}

export interface AccountState {
  editAdr: string | null;
  formAdr: AdrVo;
}

export interface CheckoutState {
  isInit: boolean;
  isLoading: boolean;
  asGuest: boolean;
  order: OrderVo | null;
  formLioAdr: AdrVo;
  editLioAdr: boolean;
  formFaoAdr: AdrVo;
  editFaoAdr: boolean;
  dkoAblnrOptions: string[] | null;
  formValidationErrors: any;
  lioLandNotCh: boolean;
  faoLandNotCh: boolean;
}

export interface OrderDataVo extends OrderVo {
  _id: string;
  _insertDate: string;
  _insertUser: string;
  _sign?:string;
  dkoAblnr: string;
  dkoBestellDatum: string;
  status: number;
  dkoAufNr: string;
  accountNumber: string;
  eoriNumber: string;
  dkoLiefartCd: string;
  paymentStatus: number;
  sumOfProductsPrices: number;
  shippingCost: number;
  vatRate: number;
  totalPrice: number;
  deliveryAddress: AdrVo;
  billingAddress: AdrVo;
  listProducts: OrderListProductVo[];
  erpTransactionLogs?: TransactionLogsVo[];
  retryErpOrderCreation: number;
  erpValidationFailed: number;
  erpValidationFailedUpdatedAt: string | null;
}

export interface OrderState {
  isLoading: boolean;
  orders: OrderDataVo[];
}

export interface ManageState {
  orders: ManageOrdersState;
  users: ManageUsersState;
}

export interface ManageOrdersState {
  isLoading: boolean;
  limit: number;
  offset: number;
  data: OrderDataVo[];
}

export interface ManageUsersState {
  isLoading: boolean;
  data: ManageUsersData[];
}

export interface ManageUsersData extends ElstrColumnsVo {
  email: string;
  isVerified: boolean;
  isCompona: boolean;
  isAssignedErp: boolean;
  isManager: boolean;
  dstZlgkond: string;
  dabKondgrpnr: string;
  dstIso: string;
  dstDnr: string;
  adrEorinr: string;
  adr: AdrVo[];
}

export interface CmsState {
  htmlText: string;
  metaJson: any;
}

export interface FoldingBoxState {
  attributeSectionId: string;
  name: string;
  isVisible: boolean;
  isExpanded: boolean;
  attributes: AttributeVo[];
}

export interface RelatedTabState {
  productRelationType: string;
  isLoaded: boolean;
  relatedProducts: ProductVo[];
  sorting: { key: undefined; direction: undefined };
}

export interface UserState extends ElstrUserVo {
  isLoading: boolean;
  isVerified: boolean;
  isCompona: boolean;
  isAssignedErp: boolean;
  isManager: boolean;
  isInit: boolean;
  hasInitLists?: boolean;
  dstZlgkond: string;
  dabKondgrpnr: string;
  dstIso: string;
  dstIsoCurrent: string;
  dstDnr: string | null;
  adrEorinr: string | null;
  adr: AdrVo[];
  userFlyoutLoginMsg: string;
}

export interface AdrVo extends ElstrColumnsVo {
  inr: string | null;
  anrNr: string | null;
  subjekttyp: string;
  name: string | null;
  vorname: string | null;
  zeile1: string | null;
  zeile2: string | null;
  zeile3: string | null;
  zeile4: string | null;
  zeile5: string | null;
  land: string;
  plz: string | null;
  ort: string | null;
  tel: string | null;
  tel2: string | null;
  email: string;
  sprache: string;
  type: string;
}

export interface PopMessageVo {
  textBold: string;
  text?: string;
  sicon: Sicon;
  level: string;
  clickAction?: any; // the pop message can have a click action like "undo removing an item"
  linktext?: string;
  href?: string;
  isCmsNotice?: boolean;
  removedAfterMs: number; // when 0 the class "inline-close--circle" appears and needs manual close to remove
  randNum?: number; // used for react to identify the MessageItem and not to abort it's removedAterMs sequence
}
