import * as React from "react";

interface Props {
  provided: any; // any used instead of DraggableProvided to fix Typescript error
  label: string;
  index: number;
  onRemoveClickHandler: (e) => void;
}

class DragDropTagButtonInner extends React.PureComponent<Props> {

  render() {
    return (
      <div
        ref={this.props.provided.innerRef}
        {...this.props.provided.draggableProps}
        {...this.props.provided.dragHandleProps}
      >
        <div className="tag-drag__item">
          <span className="tag-drag__dragger">
            <span className="tag-drag__order">{this.props.index + 1}.</span> {this.props.label}
          </span>
          <span
            className="tag-remover"
            aria-label="Remove"
            onClick={this.props.onRemoveClickHandler}
          />
        </div>
      </div>
    );
  }
}

export default DragDropTagButtonInner;
