import * as React from "react";
import classNames from "classnames";
import { PanelDataVO, PanelItemRenderTypes } from "../../common/vo/PanelDataVo";
import RangeSlider from "../../common/components/input/RangeSlider";
import CheckBox from "../../common/components/input/CheckBox";
import AutoCompleteTagList from "../../common/components/list/AutoCompleteTagList";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { getAttributeLongDescription } from "../../common/utils/attributeUtils";
import ToolTip from "../../common/components/button/ToolTip";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { FilterPanelActions } from "../actions/filterPanelActions";
import {
  LONG_DASH,
  rangeToString,
  stringToRange,
  SimpleRangeVo,
} from "../../common/utils/rangeUtils";

interface Props {
  panelData: PanelDataVO;
  description?: any;
  search: any;
}

class FilterPanelBlock extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onTitleClick = this.onTitleClick.bind(this);
    this.onItemSelected = this.onItemSelected.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
  }

  filterIsActive = false;

  onTitleClick() {
    const open = !this.props.panelData.open;
    const item = this.props.panelData.title;
    ElstrCore.store.dispatch(FilterPanelActions.toggleFilterTitleBlock(open, item));
  }

  // NOTE: for parameter list see remarks in AutoCompleteTagList
  onItemSelected(isSelected: boolean, obj: any) {
    const id = this.props.panelData.id;
    const valueId = obj.id; // comming from AutoCompleteTagList
    ElstrCore.store.dispatch(FilterPanelActions.setItemValue(id, valueId, !isSelected));
  }

  onRangeChange(
    minValue: number,
    maxValue: number,
    minMinValue: number,
    maxMaxValue: number,
    changeType: string,
  ) {
    //console.log("onRangeChange", minValue, maxValue, minMinValue, maxMaxValue, changeType);

    const rangeString = rangeToString(minValue, maxValue, minMinValue, maxMaxValue);

    let add = true;
    if (rangeString === LONG_DASH) {
      add = false;
    }

    ElstrCore.store.dispatch(
      FilterPanelActions.setItemValue(this.props.panelData.id, rangeString, add),
    );
  }

  render() {
    const panelData = this.props.panelData;
    const items = panelData.items;
    if (!items) return null;

    // check if criterias of facet have any results - if NO do not render the facet
    let facetCriteriaResultCount = 0;
    items.map((item, index) => {
      facetCriteriaResultCount += item.count;
      return null;
    });
    // check if at least one criteria is checked
    const checked = items.filter(function(criteria) {
      return criteria.checked === true;
    });
    // check if slider criteria is set
    let facetCriteriaSliderState = false;
    if (this.props.panelData.type === PanelItemRenderTypes.SLIDER) {
      if (panelData.id in this.props.search.filterSettings) {
        facetCriteriaSliderState = true;
      }
    }
    if (facetCriteriaResultCount === 0 && checked.length < 1 && !facetCriteriaSliderState) {
      return null;
    }

    // check if any child of current facet is selected / active in filter
    if (panelData.id in this.props.search.filterSettings) {
      this.filterIsActive = true;
      // panelData.open = true; -> ! DID A STATE MUTATION
      // this.overrideOpen is not the correct way. Should have been done in the reducer that initializes the filter state and on filter close
    } else {
      this.filterIsActive = false;
    }

    const cls = classNames({
      filterpanel__block: true,
      collapsible: panelData.collapsible,
      opened: panelData.open || this.filterIsActive,
    });

    return (
      <div className={cls}>
        <div className="filterpanel__subtitle" onClick={this.onTitleClick}>
          <span className="panel-block-filter">{panelData.title}</span>
          <ToolTip
            id={panelData.id}
            description={getAttributeLongDescription(panelData.id, activeLang())}
            overlayClassName="filterpanel-tooltip"
          />
        </div>
        {this.renderItems()}
      </div>
    );
  }

  renderItems() {
    const panelData = this.props.panelData;
    const items = panelData.items;
    if (!items) return null;
    if (!panelData.open && !this.filterIsActive) return null;

    switch (panelData.type) {
      case PanelItemRenderTypes.AUTOCOMPLETE:
        return (
          <AutoCompleteTagList
            id={panelData.id}
            suggestions={items}
            search={this.props.search.filterSettings}
            filterIsActive={this.filterIsActive}
            placeHolder={dic("WAEHLEN")}
            unit={panelData.unit}
            onItemSelected={this.onItemSelected}
          />
        );
      case PanelItemRenderTypes.SLIDER:
        const values = this.props.search.filterSettings[panelData.id];
        let range: SimpleRangeVo = { minValue: undefined, maxValue: undefined };
        if (values && values[0]) {
          range = stringToRange(values[0]);
        }
        return (
          <RangeSlider
            id={panelData.id}
            marks={items}
            unit={panelData.unit}
            minValue={range.minValue}
            maxValue={range.maxValue}
            onRangeChange={this.onRangeChange}
          />
        );
      default:
        return items.map((item, index) => {
          return this.renderItem(item, index);
        });
    }
  }

  renderItem(item, index) {
    const panelData = this.props.panelData;
    let isBoolean = false;
    if (panelData.dataType === "boolean") {
      isBoolean = true;
    }

    return React.createElement(getItemRenderer(panelData.type), {
      ...item,
      key: `${item.title}-${index}`,
      filterIsActive: this.filterIsActive,
      isBoolean,
      unit: panelData.unit,
      onChange: this.onItemSelected,
    });
  }
}

function getItemRenderer(type: PanelItemRenderTypes) {
  switch (type) {
    default:
      return CheckBox;
  }
}

export default FilterPanelBlock;
