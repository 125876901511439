import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { OrderState, UserState } from "../../State";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { OrderActions } from "../../order/actions/orderActions";
import OrderList from "../../order/components/OrderList";

interface Props {
  user: UserState;
  order: OrderState;
  orderId: string | null;
}

class CheckoutOrdered extends React.PureComponent<Props> {
  componentDidMount() {
    ElstrCore.store.dispatch(OrderActions.getOrderData(this.props.orderId));
  }

  componentDidUpdate(prevProps) {}

  render() {
    const orderData = this.props.order.orders.find(order => {
      return order._id === this.props.orderId;
    });

    if (orderData) {
      return (
        <>
          <h2 className="subtitle">{dic("BESTEN DANK FUER IHRE BESTELLUNG")}</h2>

          <OrderList orders={[orderData]} isLoading={this.props.order.isLoading} />
        </>
      );
    }
    return (
      <>
        <h2 className="subtitle">{dic("BESTEN DANK FUER IHRE BESTELLUNG")}</h2>
        <div className="loading" />
      </>
    );
  }
}

export default CheckoutOrdered;
