import * as React from "react";
import { ListProductDataVo, ListProductVo } from "../../common/vo/ListVo";
import Image from "../../common/components/media/Image";
import { createProductUrl } from "../../common/utils/createUrls";
import { Link } from "react-router-dom";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ListType } from "../../common/constants/Constants";
import ProductTake, { ProductTakeType } from "../../product/components/ProductTake";
import { productCanBeAddedToCart } from "../../common/utils/productUtils";

interface Props {
  listId: string | undefined;
  index: number;
  itemType: ListType;
  product: ListProductVo;
  addProductFromListToCart?: (listType, objectID, pos, comment, product, take) => void;
  updateProduct: (listId, objectID, pos, quantity, comment) => void;
  onInputTakeChangeListItem: (quantity, listId, index) => void;
  onBlurInputTakeListItem: (product, take, listId, objectID, pos, comment) => void;
  onClickTakeChangeListItem: (direction, listId, objectID, pos, comment, index) => void;
  updateCommentCartItem?: (comment, index) => void;
  updateCommentWishlistItem?: (listId, comment, index) => void;
  removeProduct: (listId, objectId, pos) => void;
  priceScaleArray: any[];
}

class ListItem extends React.PureComponent<Props, any> {
  state = {
    edit: false,
  };

  removeProduct = e => {
    e.preventDefault();
    this.props.removeProduct(
      this.props.listId,
      this.props.product.objectID,
      this.props.product.pos,
    );
  };

  _handleCommentChange = (newComment: string) => {
    if (ListType.cart === this.props.itemType && this.props.updateCommentCartItem) {
      this.props.updateCommentCartItem(newComment, this.props.index);
    }
    if (ListType.wishlist === this.props.itemType && this.props.updateCommentWishlistItem) {
      this.props.updateCommentWishlistItem(this.props.listId, newComment, this.props.index);
    }
  };

  handleCommentChange = e => {
    e.preventDefault();
    // we pass the text from e.target.value
    this._handleCommentChange(e.target.value);
  };

  deleteComment = e => {
    e.preventDefault();
    // we reset with an empty string
    this._handleCommentChange("");
    // we also update immediately the DB
    this.updateComment("");
  };

  toggleEditingComment = e => {
    e.preventDefault();
    this.setState(prevState => {
      return { edit: !prevState.edit };
    });
  };

  updateComment = (newComment: string) => {
    const listId = this.props.listId;
    const objectID = this.props.product.objectID;
    const pos = this.props.product.pos;
    const quantity = this.props.product.quantity;

    this.props.updateProduct(listId, objectID, pos, quantity, newComment);
  };

  saveComment = e => {
    e.preventDefault();
    const listId = this.props.listId;
    const objectID = this.props.product.objectID;
    const pos = this.props.product.pos;
    const quantity = this.props.product.quantity;
    const comment = this.props.product.comment;

    this.props.updateProduct(listId, objectID, pos, quantity, comment);
    setTimeout(() => {
      this.toggleEditingComment(e);
    }, 300);
  };

  previewComment = () => {
    // under these conditions we will render a preview of the comment
    // this also controls the appearance of certain icons (save/edit)
    return !this.state.edit && this.props.product.comment ? "has-comment" : "";
  };

  editingStyle = () => {
    return this.state.edit ? "editing" : "";
  };

  render() {
    const product = this.props.product;
    const productData: ListProductDataVo = product.data[activeLang()];

    return (
      <article className={`product ${this.editingStyle()}`}>
        <Link to={createProductUrl(productData, activeLang())}>
          <Image className="product__img" data={productData.elstrImage} w={"64"} h={"64"} />
        </Link>

        <Link to={createProductUrl(productData, activeLang())} className="product__link">
          <h3 className="product__title">{productData.ShortDescription}</h3>
        </Link>
        <dl className="product-nr">
          <dt className="product-nr__title">{dic("ART NR")}</dt>
          <dd className="product-nr__cont">{` ${product.objectID}`}</dd>
        </dl>
        {productCanBeAddedToCart(this.props.product.data[activeLang()]) &&
          this.props.product.take && (
            <ProductTake
              take={this.props.product.take}
              priceScaleArray={this.props.priceScaleArray}
              onInputTakeChangeListItem={this.props.onInputTakeChangeListItem}
              onClickTakeChangeListItem={this.props.onClickTakeChangeListItem}
              onBlurInputTakeListItem={this.props.onBlurInputTakeListItem}
              type={
                this.props.itemType === ListType.cart
                  ? ProductTakeType.cart
                  : ProductTakeType.wishlist
              }
              product={this.props.product}
              index={this.props.index}
              listId={this.props.listId}
            />
          )}
        <div className="product__actions">
          <div className="product__icon-delete" onClick={this.removeProduct} />
          {this.renderAddComment()}
          {productCanBeAddedToCart(this.props.product.data[activeLang()]) && this.renderAddCart()}
        </div>
        <div className={`product__comment ${this.previewComment()}`}>
          <h3 className="title">{dic("KOMMENTAR")}</h3>
          {this.renderCommentText()}
          <div className="product-comment__actions">
            {this.renderSave()}
            <div className="product__icon-delete" onClick={this.deleteComment} />
            {this.renderEdit()}
          </div>
        </div>
      </article>
    );
  }

  addProductFromListToCart = e => {
    e.preventDefault();
    const listType = ListType.cart;
    const objectID = this.props.product.objectID;
    const pos = null;
    const comment = this.props.product.comment;
    const product = this.props.product;
    const take = this.props.product.take;

    if (this.props.addProductFromListToCart) {
      this.props.addProductFromListToCart(listType, objectID, pos, comment, product, take);
    }
  };

  renderCommentText() {
    // if we're not editing and there is comment available on the list, we show that
    // otherwhise the textarea will be rendered
    return !this.state.edit && this.props.product.comment ? (
      <div className="product-comment__post">{`${this.props.product.comment}`}</div>
    ) : (
      <textarea
        rows={3}
        className="textarea"
        placeholder={dic("KUNDENARTIKELNUMMER ABTEILUNG KOMMENTAR")}
        value={`${this.props.product.comment}`}
        onChange={this.handleCommentChange}
      />
    );
  }

  renderAddCart() {
    // won't be rendered in cart, since the item is already in the cart, or when no take is defined
    if (this.props.itemType === ListType.cart || !this.props.product.take) return null;

    return <div className="product__icon-add-cart" onClick={this.addProductFromListToCart} />;
  }

  renderAddComment() {
    // specified to only show when item has no comment
    return !this.props.product.comment ? (
      <div className="product__icon-comment" onClick={this.toggleEditingComment} />
    ) : null;
  }

  renderSave() {
    return !this.previewComment() ? (
      <div className="product__icon-save" onClick={this.saveComment} />
    ) : null;
  }

  renderEdit() {
    return this.previewComment() ? (
      <div className="product__icon-edit" onClick={this.toggleEditingComment} />
    ) : null;
  }
}

export default ListItem;
