import * as React from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { CheckoutActions } from "../actions/checkoutActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { priceCalculateShipping } from "../../common/utils/priceCalculations";
import { v1 as uuidv1 } from "uuid";

interface Props {
  dkoLiefartCd: string;
  shippingDestination: string;
  shippingOptions: any[];
  showOnlyChecked: boolean;
  dstIsoCurrent: string;
  cartSumInclVat: number;
}

class RadioBoxItemsDkoLiefartCd extends React.PureComponent<Props> {
  onOptionDkoLiefartCdChange = e => {
    ElstrCore.store.dispatch(CheckoutActions.onOptionDkoLiefartCdChange(e.target.value));
  };

  render() {
    if (Array.isArray(this.props.shippingOptions)) {
      return this.props.shippingOptions.map((option, index) => {
        const inputId = uuidv1();
        const shippingCost = priceCalculateShipping(
          option.liefartCd,
          this.props.shippingDestination,
          this.props.dstIsoCurrent,
          this.props.cartSumInclVat,
        );
        if (shippingCost > 0 && option.freeShippingFrom[this.props.dstIsoCurrent] !== null) {
        }
        if (
          this.props.showOnlyChecked === false ||
          (this.props.showOnlyChecked === true && this.props.dkoLiefartCd === option.liefartCd)
        ) {
          return (
            <div
              key={`radio-box__item_${option.liefartCd}`}
              className={`radio-box__item ${
                this.props.dkoLiefartCd === option.liefartCd ? "checked" : ""
              }`}
            >
              <div className="radiobutton">
                <input
                  value={option.liefartCd}
                  checked={this.props.dkoLiefartCd === option.liefartCd ? true : false}
                  onChange={this.onOptionDkoLiefartCdChange}
                  className="input--radiobutton"
                  name="RadioBoxDkoLiefartCd"
                  type="radio"
                  aria-checked={this.props.dkoLiefartCd === option.liefartCd ? true : false}
                  id={inputId}
                />
                <label htmlFor={inputId} className="radiobutton__label">
                  {option.description[activeLang()]}
                  <span className="sub-info">{option.addDescription[activeLang()]}</span>
                  {shippingCost > 0 &&
                    option.hasOwnProperty("freeShippingFrom") &&
                    option.freeShippingFrom[this.props.dstIsoCurrent] !== null && (
                      <span className="sub-info">
                        {dic("VERSANDKOSTENFREI AB")} {this.props.dstIsoCurrent}{" "}
                        {option.freeShippingFrom[this.props.dstIsoCurrent]}
                      </span>
                    )}
                </label>
                <span className="box-radiobutton__right">{`${
                  this.props.dstIsoCurrent
                } ${shippingCost.toFixed(4)}`}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    }
  }
}

export default RadioBoxItemsDkoLiefartCd;
