import { CmsActionTypes } from "./ActionTypes";
// import { routerActions } from "connected-react-router";

export const CmsActions = {
  cmsContentChanged: function(metaJson, htmlText) {
    return { type: CmsActionTypes.CMS_CONTENT_CHANGED, metaJson, htmlText };
  },

  getAllFetches: async function(requests) {
    try {
      const data = await Promise.all(
        requests.map(request =>
          fetch(request.url, {
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, same-origin
          }).then(response => {
            if (request.type === "text") {
              return response.text();
            }
            if (request.type === "json") {
              return response.json();
            }
          }),
        ),
      );
      return data;
    } catch (error) {
      console.log(error);

      throw error;
    }
  },

  getContent: function(url) {
    return async (dispatch, getState) => {
      if (url.includes("/cms/")) {
        const requests: any[] = [];
        requests.push({ url: `${url}?react=1&react_meta=1`, type: "json" });
        requests.push({ url: `${url}?react=1`, type: "text" });
        const responses = await CmsActions.getAllFetches(requests);
        dispatch(CmsActions.cmsContentChanged(responses[0], responses[1]));
      }
    };
  },
};
