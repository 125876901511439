import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ManageState, State, UserState } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { compose } from "redux";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { routerActions } from "connected-react-router";
import ManageOrders from "../components/ManageOrders";
import ManageUsers from "../components/ManageUsers";
import Helmet from "react-helmet";

interface ManageStateToProps {}

interface ManageDispatchToProps {
  onMount: () => void;
}

interface Props extends ManageStateToProps, ManageDispatchToProps {
  user: UserState;
  manage: ManageState;
  match: any;
}

class Manage extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.onMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.isAuth === false && this.props.user.isAuth === true) {
      // handle tab specific onLogin event
    }
  }

  onClickTabOrders(e) {
    ElstrCore.store.dispatch(routerActions.push(`/manage/orders/`));
  }

  onClickTabUsers = () => {
    ElstrCore.store.dispatch(routerActions.push(`/manage/users/`));
  };

  render() {
    if (this.props.user.isAuth && this.props.user.isManager) {
      return (
        <main className="main main--std" role="main">
          <Helmet>
            <script
              src="https://unpkg.com/xlsx@0.14.0/dist/xlsx.full.min.js"
              type="text/javascript"
            />
          </Helmet>
          <div className="content">
            <h1 className="content__page-title">{dic("MANAGE")}</h1>
            <div className="tab-header">
              <nav className="tab-nav">
                <ul className="tab">
                  <li
                    onClick={this.onClickTabOrders}
                    className={`tab__item ${
                      this.props.match.params.tab === "orders" ? "active" : ""
                    }`}
                  >
                    {dic("BESTELLUNGEN")}
                  </li>
                  <li
                    onClick={this.onClickTabUsers}
                    className={`tab__item ${
                      this.props.match.params.tab === "users" ? "active" : ""
                    }`}
                  >
                    {dic("BENUTZER")}
                  </li>
                </ul>
              </nav>
            </div>
            {this.props.match.params.tab === "orders" && (
              <ManageOrders manageOrders={this.props.manage.orders} />
            )}
            {this.props.match.params.tab === "users" && (
              <ManageUsers manageUsers={this.props.manage.users} />
            )}
          </div>
        </main>
      );
    }
    return (
      <main className="main main--checkout" role="main">
        <div className="content">
          <h1 className="content__page-title">{dic("KEIN ZUGRIFF")}</h1>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: State): ManageStateToProps => ({
  user: state.user,
  manage: state.manage,
});

const mapDispatchToProps = (dispatch): ManageDispatchToProps => ({
  onMount: () => {
    dispatch(LayoutActions.showFilterPanel(false));
    dispatch(LayoutActions.showHomeHeader(false));
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Manage);
