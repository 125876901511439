import * as React from "react";
import SuggestionTag from "../button/SuggestionTag";
import SearchSuggestionVo from "../../vo/SearchSuggestionVo";

interface Props {
  suggestions: SearchSuggestionVo[];
}

class SuggestionList extends React.PureComponent<Props> {
  render() {
    return this.props.suggestions.map((suggestion: SearchSuggestionVo, index) => {
      return <SuggestionTag suggestion={suggestion} key={`suggestion_tag_${index}`} />;
    });
  }
}

export default SuggestionList;
