import * as React from "react";
import { Helmet } from "react-helmet";
import { Route, withRouter, RouteComponentProps } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import NavLayer from "../../common/components/flyout/NavLayer";
import WishlistFlyout from "../../wishlist/containers/WishlistFlyout";
import LangFlyout from "../../common/components/flyout/LangFlyout";
import UserFlyout from "../../common/components/flyout/UserFlyout";
import CartFlyout from "../../cart/containers/CartFlyout";
import Home from "../../home/containers/Home";
import Catalog from "../../catalog/containers/Catalog";
import { connect } from "react-redux";
import { compose } from "redux";
import { LayoutState, State, UserState, CheckoutState } from "../../State";
import classNames from "classnames";
import Product from "../../product/containers/Product";
import Signup from "../../signup/containers/Signup";
import Account from "../../account/containers/Account";
import Checkout from "../../checkout/containers/Checkout";
import Order from "../../order/containers/Order";
import Cms from "../../cms/containers/Cms";
import Manage from "../../manage/containers/Manage";
import { LayoutActions } from "../actions/layoutActions";
import { UserActions } from "../../user/actions/userActions";
import FilterPanel from "../../filterPanel/containers/FilterPanel";
import { FilterPanelActions } from "../../filterPanel/actions/filterPanelActions";
import ScrollToTop from "../../common/components/router/ScrollToTop";
import PopMessage from "../../popMessage/containers/PopMessage";
import { ListActions } from "../../list/actions/listActions";
import { PopMessageType, SyncType } from "../../common/constants/Constants";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import CurrencyFlyout from "../../common/components/flyout/CurrencyFlyout";
import CookieBannerSwitch from "../../cookieBanner/components/CookieBannerSwitch";
import PromoBanner from "../components/PromoBanner";
import NoticeVo from "../../common/vo/NoticeVo";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import {
  createPopMessage,
  hashCode,
  getCmsNoticesClosed,
} from "../../popMessage/actions/popMessageActions";

require("./../../common/assets/css/styles.css");
require("./../../common/assets/css/app_styles.css");

interface LayoutDispatchToProps {
  onLangClose: () => void;
  onCurrencyClose: () => void;
  onCartCloseExpicitly: () => void;
  onUserClose: () => void;
  login: (email: string, password: string) => void;
  forgotPassword: (email: string) => void;
  sendVerifyAgain: (email: string) => void;
  logout: () => void;
  onMenuClick: () => void;
  onPropsChange: () => void;
  updateListDataFromOtherTab: () => void;
  deleteListById: (listId) => void;
  setScrollState: (scrollState) => void;
}

interface LayoutStateToProps {
  data: LayoutState;
  user: UserState;
  checkout: CheckoutState;
}

interface Props extends LayoutDispatchToProps, LayoutStateToProps, RouteComponentProps<any> {}

class Layout extends React.Component<Props> {
  // TODO Performance: "onPropsChange" kann evtl. später entfernt werden
  componentDidMount() {
    this.props.onPropsChange();
    window.onpopstate = item => {
      this.props.onPropsChange();
    };

    this.setEventListeners();
    this.addNoticesFromCms();
  }

  setEventListeners = () => {
    /* Set the Event Listener for Reloading / Syncing the Tabs */
    window.addEventListener("storage", e => {
      // prevent doing anything when the oldValue equals the newValue solved
      // an infinity loop problem with IE11
      if (e.oldValue !== e.newValue) {
        if (e.key === SyncType.reloadOtherTabs) {
          window.location.reload();
        } else if (e.key === SyncType.updateListDataFromOtherTab) {
          this.props.updateListDataFromOtherTab();
        } else if (e.key === SyncType.deleteListId) {
          this.props.deleteListById(e.newValue);
        }
      }
    });

    /* Set the Event Listener for the Scrolling State */
    window.addEventListener("scroll", e => {
      // write scroll state into storage
      // scrollState = 0: no stickyness
      // scrollState = 1: h1 is sticky
      // scrollState = 2: h1 and additional lists header is sticky

      // Get the offset Position
      // Notice: Browser can have difference
      // src of following code: http://stackoverflow.com/questions/2717252/document-body-scrolltop-is-always-0-in-ie-even-when-scrolling/11102215#11102215
      let scrollPos =
        typeof window.pageYOffset !== "undefined"
          ? window.pageYOffset
          : document.documentElement.scrollTop
          ? document.documentElement.scrollTop
          : document.body.scrollTop
          ? document.body.scrollTop
          : 0; // fallback if it's not ie11

      let scrollState = 0;

      // nur auf product Detail view

      // H1 Element
      const elementH1 = window.document.getElementById("productDetailBody");
      if (elementH1) {
        if (scrollPos > 120) {
          scrollState = 1;
        }
      }

      // Related Products Header
      const elementRelatedBody = window.document.getElementById("relatedProductBody");
      if (elementRelatedBody) {
        const elementRelatedBodyRect = elementRelatedBody.getBoundingClientRect();
        const absPos = scrollPos + elementRelatedBodyRect.top;
        // console.log("absPos: " + absPos);
        // console.log("scrollPos: " + scrollPos);
        if (scrollPos > absPos - 280) {
          scrollState = 2;
        }
      }

      if (this.props.data.scrollState !== scrollState) {
        this.props.setScrollState(scrollState);
      }
    });
  };

  addNoticesFromCms = () => {
    if (
      window.COMPONA_STATIC_CACHE.hasOwnProperty("notices") &&
      window.COMPONA_STATIC_CACHE.notices.hasOwnProperty(activeLang()) &&
      Array.isArray(window.COMPONA_STATIC_CACHE.notices[activeLang()])
    ) {
      const cmsNoticesClosed: string[] = getCmsNoticesClosed();

      window.COMPONA_STATIC_CACHE.notices[activeLang()].forEach((notice: NoticeVo, index) => {
        if (
          notice.hasOwnProperty("visible") &&
          notice.visible === "1" &&
          notice.hasOwnProperty("label") &&
          notice.label.length > 0
        ) {
          let linktext = "";
          if (notice.hasOwnProperty("linktext") && notice.linktext.length > 0) {
            linktext = notice.linktext;
          }
          let href = "";
          if (notice.hasOwnProperty("href") && notice.href.length > 0) {
            href = notice.href;
          }
          let headline = "";
          if (notice.hasOwnProperty("headline") && notice.headline.length > 0) {
            headline = notice.headline;
          }

          const ms = 1500 + 500 * index;
          if (cmsNoticesClosed.indexOf(hashCode(notice.label)) === -1) {
            setTimeout(() => {
              ElstrCore.store.dispatch(
                // @ts-ignore
                createPopMessage(
                  PopMessageType.cmsNotice,
                  headline,
                  0,
                  notice.label,
                  linktext,
                  href,
                ),
              );
            }, ms);
          }
        }
      });
    }
  };

  render() {
    const cls = classNames({
      container: true,
      "filterpanel-opened": this.props.data.filterOpen,
      "search-layer-opened": this.props.data.searchOpen,
      "cart-opened": this.props.data.cartOpen,
      "user-opened": this.props.data.userOpen,
      "lang-opened": this.props.data.langOpen,
      "currency-opened": this.props.data.currencyOpen,
      "wishlist-opened": this.props.data.wishlistOpen,
      "menu-opened": this.props.data.menuOpen,
    });

    // Add and remove classes on body element for placing chat icon and widget.
    if (this.props.data.cartOpen) {
      document.body.classList.add("cart-opened");
    } else {
      document.body.classList.remove("cart-opened");
    }
    if (this.props.data.wishlistOpen) {
      document.body.classList.add("wishlist-opened");
    } else {
      document.body.classList.remove("wishlist-opened");
    }

    return (
      <ScrollToTop>
        <Helmet>
          <title>
            {dic("TITLE")} – {dic("TITLE_HOME_ADDON")}
          </title>
          <meta name="description" content={dic("META DESCRIPTION")} />
          <meta name="keywords" content={dic("META KEYWORDS")} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className={cls}>
          <PromoBanner />
          <Header />
          <NavLayer onClick={this.props.onMenuClick} href={window.location.href} />
          <CartFlyout onClose={this.props.onCartCloseExpicitly} />
          <WishlistFlyout />
          <LangFlyout onClose={this.props.onLangClose} />
          <CurrencyFlyout
            onClose={this.props.onCurrencyClose}
            dstIsoCurrent={this.props.user.dstIsoCurrent}
            faoLandNotCh={this.props.checkout.faoLandNotCh}
          />
          <UserFlyout
            onClose={this.props.onUserClose}
            login={this.props.login}
            forgotPassword={this.props.forgotPassword}
            sendVerifyAgain={this.props.sendVerifyAgain}
            logout={this.props.logout}
            history={this.props.history}
            user={this.props.user}
          />
          <FilterPanel />

          <Route exact path="/" component={Home as any} />
          <Route path="/home/:lang?" component={Home as any} />
          <Route exact path="/catalog/:lang?/:showAll?" component={Catalog as any} />
          <Route
            exact
            path="/catalog/group/:objectID/:lang?/:routeAddition?"
            component={Catalog as any}
          />
          <Route path="/product/:objectID/:lang?/:routeAddition?" component={Product as any} />
          <Route path="/signup/:status/:token?" component={Signup as any} />
          <Route path="/account/:tab/:username" component={Account as any} />
          <Route path="/shop/checkout/:step/:orderId?" component={Checkout as any} />
          <Route path="/shop/order/:orderId" component={Order as any} />
          <Route path="/cms/:lang/:path?" component={Cms as any} />
          <Route path="/manage/:tab/:_id?" component={Manage as any} />

          <Footer />
          <PopMessage />
        </div>
        <CookieBannerSwitch isAccepted={this.props.data.cookiesAccepted} />
      </ScrollToTop>
    );
  }
}

const mapStateToProps = (state: State): LayoutStateToProps => ({
  data: state.layout,
  user: state.user,
  checkout: state.checkout,
});

const mapDispatchToProps = (dispatch): LayoutDispatchToProps => ({
  onLangClose: () => dispatch(LayoutActions.openLang(false)),
  onCurrencyClose: () => dispatch(LayoutActions.openCurrency(false)),
  onCartCloseExpicitly: () => dispatch(LayoutActions.onCartCloseExpicitly()),
  onUserClose: () => dispatch(LayoutActions.openUser(false)),
  login: (email, password) => dispatch(UserActions.login(email, password)),
  forgotPassword: email => dispatch(UserActions.forgotPassword(email)),
  sendVerifyAgain: email => dispatch(UserActions.sendVerifyAgain(email)),
  logout: () => dispatch(UserActions.logout()),
  onMenuClick: () => dispatch(LayoutActions.openMenu(false)),
  onPropsChange: () => dispatch(FilterPanelActions.loadFacets()),
  updateListDataFromOtherTab: () => dispatch(ListActions.updateListDataFromOtherTab()),
  deleteListById: listId => dispatch(ListActions.deleteListById(listId)),
  setScrollState: scrollState => dispatch(LayoutActions.setScrollState(scrollState)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(Layout));
