import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { AccountState, AdrVo, emptyAddress, UserState } from "../../State";
import AddressCard from "../../common/components/input/AddressCard";
import AddressEdit from "../../common/components/input/AddressEdit";
import SimpleButton, { SimpleButtonTypes } from "../../common/components/button/SimpleButton";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { AccountActions } from "../actions/accountActions";

interface Props {
  user: UserState;
  account: AccountState;
}

class AccountSettings extends React.PureComponent<Props> {
  private readonly password: any;
  private readonly passwordNew: any;
  private readonly passwordNewRepeated: any;
  private readonly accountEmailNew: any;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element

    this.password = React.createRef();
    this.passwordNew = React.createRef();
    this.passwordNewRepeated = React.createRef();
    this.accountEmailNew = React.createRef();

    this.onSubmitChangePassword = this.onSubmitChangePassword.bind(this);
    this.onSubmitChangeAccountEmail = this.onSubmitChangeAccountEmail.bind(this);
  }

  onSubmitChangePassword(e) {
    e.preventDefault();
    const password = this.password.current.value;
    const passwordNew = this.passwordNew.current.value;
    const passwordNewRepeated = this.passwordNewRepeated.current.value;
    ElstrCore.store.dispatch(
      AccountActions.changePassword(password, passwordNew, passwordNewRepeated),
    );
    this.password.current.value = "";
    this.passwordNew.current.value = "";
    this.passwordNewRepeated.current.value = "";
  }

  onSubmitChangeAccountEmail(e) {
    e.preventDefault();
    const accountEmailNew = this.accountEmailNew.current.value;

    ElstrCore.store.dispatch(AccountActions.changeAccountEmail(accountEmailNew));
    this.accountEmailNew.current.value = "";
  }

  onClickAddAddress = e => {
    ElstrCore.store.dispatch(AccountActions.setAccountData({ editAdr: "0" }));
    ElstrCore.store.dispatch(AccountActions.setAccountData({ formAdr: emptyAddress }));
  };
  onClickEditAddress = inr => {
    ElstrCore.store.dispatch(AccountActions.setAccountData({ editAdr: inr }));
    const address = this.props.user.adr.find(address => address.inr === inr);
    if (address) {
      ElstrCore.store.dispatch(AccountActions.setAccountData({ formAdr: address }));
    }
  };
  onClickDisable = inr => {
    ElstrCore.store.dispatch(AccountActions.disableAddress(inr));
  };

  onSubmitAddressEdit() {
    ElstrCore.store.dispatch(AccountActions.addOrUpdateAddress());
    ElstrCore.store.dispatch(AccountActions.setAccountData({ editAdr: null }));
  }
  onResetAddressEdit() {
    ElstrCore.store.dispatch(AccountActions.setAccountData({ editAdr: null }));
    ElstrCore.store.dispatch(AccountActions.setAccountData({ formAdr: emptyAddress }));
  }
  onAddressInputChange = (name: string, value: string) => {
    ElstrCore.store.dispatch(AccountActions.setAccountFormAdrDataValue(name, value));
  };

  sortAddressesByType(a: AdrVo, b: AdrVo) {
    // reverse order: 1. org, 2. invoice, 3. delivery
    return b.type.localeCompare(a.type);
  }

  render() {
    const addresses = this.props.user.adr;
    addresses.sort(this.sortAddressesByType);

    return (
      <div className="content__2col">
        <div className="col">
          <div className={`row`}>
            <h2 className="subtitle">{dic("PASSWORT AENDERN")}</h2>
            <form className="form" onSubmit={this.onSubmitChangePassword}>
              <div className="form-row">
                <div className="form-input__wrapper">
                  <label className="label" htmlFor="password">
                    {dic("PASSWORT")} {dic("BISHER")} *
                  </label>
                  <input
                    ref={this.password}
                    className="input--text"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="******"
                    autoComplete="off"
                    aria-required={true}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-input__wrapper">
                  <label className="label" htmlFor="password">
                    {dic("PASSWORT")} *
                  </label>
                  <input
                    ref={this.passwordNew}
                    className="input--text"
                    type="password"
                    id="passwordNew"
                    name="passwordNew"
                    placeholder="******"
                    autoComplete="off"
                    aria-required={true}
                    required={true}
                  />
                </div>
                <div className="form-input__wrapper">
                  <label className="label" htmlFor="passwordRepeated">
                    {dic("PASSWORT WIEDERHOLEN")} *
                  </label>
                  <input
                    ref={this.passwordNewRepeated}
                    className="input--text"
                    type="password"
                    id="passwordNewRepeated"
                    name="passwordNewRepeated"
                    placeholder="******"
                    autoComplete="off"
                    aria-required={true}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-row">
                <SimpleButton
                  type={SimpleButtonTypes.SUBMIT}
                  onClick={() => {}}
                  onSubmit={() => {}}
                  label={dic("PASSWORT AENDERN")}
                />
              </div>
            </form>
          </div>
          <div style={{ margin: "40px 0" }} />
          <div className={`row content--step`}>
            <h2 className="subtitle">{dic("EMAIL DES KONTOS AENDERN")}</h2>
            <form className="form" onSubmit={this.onSubmitChangeAccountEmail}>
              <div className="form-row">
                <div className="form-input__wrapper">
                  <label className="label" htmlFor="accountEmail">
                    {dic("EMAIL")} *
                  </label>
                  <input
                    ref={this.accountEmailNew}
                    className="input--text"
                    type="email"
                    id="accountEmail"
                    name="accountEmail"
                    placeholder="user@domain.com"
                    autoComplete="off"
                    aria-required={true}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-row">
                <SimpleButton
                  type={SimpleButtonTypes.SUBMIT}
                  onClick={() => {}}
                  onSubmit={() => {}}
                  label={dic("EMAIL DES KONTOS AENDERN")}
                />
              </div>
              <div className="form-row">
                <p>
                  <br />
                  {dic("HINWEIS EMAIL MUSS BESTAETIGT WERDEN")}
                </p>
              </div>
            </form>
          </div>
        </div>

        <div className="col">
          <h2 className="subtitle">{dic("ADRESSEN")}</h2>
          {addresses.map((address, index) => {
            if (address.type !== "account") {
              if (
                this.props.account.editAdr !== null &&
                this.props.account.editAdr === address.inr
              ) {
                return (
                  <AddressEdit
                    key={`address_edit_withInr_${address.inr}_${index}`}
                    formAdr={this.props.account.formAdr}
                    onSubmit={this.onSubmitAddressEdit}
                    onReset={this.onResetAddressEdit}
                    onInputChange={this.onAddressInputChange}
                  />
                );
              } else {
                return (
                  <AddressCard
                    key={`address_card_${index}`}
                    address={address}
                    isEditable={address.type === "org" ? false : true}
                    isDisablable={address.type === "org" ? false : true}
                    onClickEdit={this.onClickEditAddress}
                    onClickDisable={this.onClickDisable}
                  />
                );
              }
            }
            return null;
          })}

          {this.props.account.editAdr === "0" && (
            <AddressEdit
              key={`address_edit_withNoInr`}
              onSubmit={this.onSubmitAddressEdit}
              onReset={this.onResetAddressEdit}
              onInputChange={this.onAddressInputChange}
              formAdr={this.props.account.formAdr}
              typeIsEditable={true}
            />
          )}
          <SimpleButton
            type={SimpleButtonTypes.SUBMIT}
            onClick={this.onClickAddAddress}
            onSubmit={this.onClickAddAddress}
            label={dic("NEUE ADRESSE")}
          />
          <p>
            <br />
            {dic("HAUTPADRESSE AENDERN ADRESSE LOESCHEN")}
          </p>
        </div>
      </div>
    );
  }
}

export default AccountSettings;
