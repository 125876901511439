import AlgoliaServices from "../../common/services/AlgoliaServices";
import { FilterPanelActionsTypes } from "./ActionTypes";
import { SearchActions } from "../../search/actions/searchActions";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { CatalogAlgoliaActions } from "../../catalog/actions/algoliaActions";
import { SearchState } from "../../State";
import AttributeVo from "../../common/vo/AttributeVo";
import * as algoliasearch from "algoliasearch";
import { FilterTreeActions } from "./treePanelActions";

declare var COMPONA_STATIC_CACHE;

export const FilterPanelActions = {
  _loadFacets: function() {
    return async (dispatch, getState) => {
      const searchQuery: SearchState = getState().search;
      const attributes: AttributeVo[] = window.COMPONA_STATIC_CACHE.attributes;
      const allClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];

      const allFacets: any[] = getState().layout.facets;
      const algoliaResponse: algoliasearch.Response = await AlgoliaServices.getProductsByFacetsTreesAndQuery(
        activeLang(),
        getState().search,
      );
      dispatch(SearchActions.showSearch(algoliaResponse));
      // dispatch(SearchActions.showSearch(algoliaResponse));
      // dispatch(ProductActions.updateProductData(result.hits, result.nbHits));

      if (searchQuery.query && searchQuery.query.length >= 2) {
        const suggestions: any = await AlgoliaServices.getSuggestions(
          searchQuery.query,
          activeLang(),
        );
        dispatch(SearchActions.showSuggestions(suggestions));
      }

      // Adding result counts with current tree filter inactive
      const algoliaResponseForTrees = algoliaResponse;
      for (const tree in getState().search.trees) {
        for (const key in getState().search.trees[tree]) {
          const algoliaTreeResponse: algoliasearch.Response = await AlgoliaServices.getProductsByFacetsTreesAndQuery(
            activeLang(),
            getState().search,
            key,
          );
          if (
            algoliaResponseForTrees.hasOwnProperty("facets") &&
            algoliaTreeResponse.hasOwnProperty("facets")
          ) {
            if (
              algoliaResponseForTrees.facets!.hasOwnProperty(tree) &&
              algoliaTreeResponse.facets!.hasOwnProperty(tree)
            ) {
              algoliaResponseForTrees.facets![tree] = algoliaTreeResponse.facets![tree];
            }
          }
        }
      }

      dispatch(
        FilterTreeActions.updateFilterTreeData(
          algoliaResponseForTrees.facets,
          attributes,
          allClassifications,
          searchQuery,
        ),
      );

      // Adding result counts with current panel block filter inactive
      const algoliaResponseForBlocks = algoliaResponse;
      for (const key in getState().search.filterSettings) {
        // do not care about sliders - continue on sliders

        if (
          attributes.hasOwnProperty(key) &&
          (attributes[key].isRange === true ||
            ((attributes[key].dataType === "integer" || attributes[key].dataType === "float") &&
              Object.keys(allFacets[key]).length > 7))
        ) {
          continue;
        }

        const algoliaBlockResponse: algoliasearch.Response = await AlgoliaServices.getProductsByFacetsTreesAndQuery(
          activeLang(),
          getState().search,
          key,
        );
        if (
          algoliaResponseForBlocks.hasOwnProperty("facets") &&
          algoliaBlockResponse.hasOwnProperty("facets")
        ) {
          if (
            algoliaResponseForBlocks.facets!.hasOwnProperty(key) &&
            algoliaBlockResponse.facets!.hasOwnProperty(key)
          ) {
            algoliaResponseForBlocks.facets![key] = algoliaBlockResponse.facets![key];
          }
        }
      }
      dispatch(
        FilterPanelActions.createFilterPanelDataOptions(
          algoliaResponseForBlocks.facets,
          attributes,
          searchQuery,
          allFacets,
        ),
      );
    };
  },

  loadFacets: function() {
    const thunk: any = async dispatch => {
      dispatch(FilterPanelActions._loadFacets());
    };

    thunk.meta = {
      debounce: {
        time: 50,
        key: "loadFacets",
      },
    };

    return thunk;
  },

  createFilterPanelDataOptions: function(
    facets,
    attributes: AttributeVo[],
    searchQuery: SearchState,
    allFacets: any[],
  ) {
    return {
      type: FilterPanelActionsTypes.FILTER_LOAD_FACETS,
      facets,
      attributes,
      searchQuery,
      allFacets,
    };
  },

  toggleFilterTitleBlock: function(open: boolean, item: any) {
    const data = { open, item };
    return { type: FilterPanelActionsTypes.TOGGLE_FILTER_BLOCK, data };
  },

  setItemValue: function(facetId: string, valueId: string, add: boolean) {
    return async (dispatch, getState) => {
      dispatch(SearchActions.setFacetSearch(facetId, valueId, add));
      // dispatch(loadFacets()); // postpone this call until url change is detected, otherwise we would invoke two parallel Algolia queries
      dispatch(CatalogAlgoliaActions.getManufacturersAndFunctions());
    };
  },
};
