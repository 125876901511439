import * as React from "react";
import AutoCompleteDropDown from "../../common/components/input/AutoCompleteDropDown";
import DragDropTagList from "../../common/components/list/DragDropTagList";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { getAttributeDescription } from "../../common/utils/attributeUtils";

interface Props {
  productCount?: number;
  data: any[];
  titlePrefix: string;
  titleMain: string;
  titlePostfix: string;
  titleIsH1: boolean;

  onDragChange: (e) => void;

  open: boolean;
  onOpenClick: (active: boolean) => void;

  onSaveClick: () => void;
  onResetClick: () => void;
  onItemRemoveClick: (e: string) => void;

  newColAutoValue: string;
  newColAutoPlaceholder: string;
  productListColumnsAvailable: any[];
  onNewColAutoChange: (e: string) => void;
  onNewItemSelected: (e: string) => void;
}

class ProductViewSettingsBox extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onOpenClickHandler = this.onOpenClickHandler.bind(this);
  }

  onOpenClickHandler() {
    this.props.onOpenClick(!this.props.open);
  }

  getSuggestions() {
    const suggestions: any[] = [];
    const newColAutoValueLower = this.props.newColAutoValue.toLowerCase();

    for (const attribute of this.props.productListColumnsAvailable) {
      const name = getAttributeDescription(attribute.dataAttributeKey, activeLang());
      const nameLower = name.toLowerCase();
      if (newColAutoValueLower) {
        if (nameLower.startsWith(newColAutoValueLower)) {
          suggestions.push({
            id: attribute.dataAttributeKey,
            name,
          });
        }
      } else {
        suggestions.push({
          id: attribute.dataAttributeKey,
          name,
        });
      }
    }

    suggestions.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return suggestions;
  }

  render() {
    const opened: string = this.props.open ? "opened" : "";
    const suggestions: any[] = this.getSuggestions();

    return (
      <>
        {this.props.titleIsH1 && (
          <div className="catalog__subtitle">
            {`${this.props.titlePrefix}`}
            <h1 className="catalog-subtitle__title">{`${this.props.titleMain}`}</h1>
            {`${this.props.titlePostfix}`}
            <span className="title__counter"> ({this.props.productCount})</span>
          </div>
        )}
        {!this.props.titleIsH1 && (
          <h2 className="catalog__subtitle">
            {`${this.props.titlePrefix}${this.props.titleMain}${this.props.titlePostfix}`}
            <span className="title__counter"> ({this.props.productCount})</span>
          </h2>
        )}

        <div className={`folding-box result-setting-box ${opened}`}>
          <div className="folding-box__titlebar" onClick={this.onOpenClickHandler}>
            {dic("DARSTELLUNGSEINSTELLUNGEN")}
            <span className="folding-box__arrow" aria-hidden="true" />
          </div>
          <div className="folding-box__content" aria-expanded="false" role="region">
            <div className="smltitle">{dic("SPALTEN HINZUFUEGEN")}</div>

            <AutoCompleteDropDown
              value={this.props.newColAutoValue}
              placeholder={this.props.newColAutoPlaceholder}
              suggestions={suggestions}
              onChange={this.props.onNewColAutoChange}
              onItemSelected={this.props.onNewItemSelected}
            />

            <div className="smltitle">{dic("SPALTENORDNUNG")}</div>

            <DragDropTagList
              data={this.props.data}
              onDragChange={this.props.onDragChange}
              onItemRemoveClick={this.props.onItemRemoveClick}
            />

            <div className="folding-box__submit">
              <input
                className="input--reset"
                type="reset"
                value={dic("ZURUECKSETZEN")}
                onClick={this.props.onResetClick}
              />
              <input
                className="input--submit"
                type="submit"
                value={dic("SPEICHERN")}
                onClick={this.props.onSaveClick}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductViewSettingsBox;
