export enum CheckoutActionTypes {
  SET_CHECKOUT_DATA = "SET_CHECKOUT_DATA",
  INIT_CHECKOUT_ORDER = "INIT_CHECKOUT_ORDER",
  RESET_CHECKOUT_ORDER = "RESET_CHECKOUT_ORDER",
  SET_CHECKOUT_ORDER_DATAVALUE = "SET_CHECKOUT_ORDER_DATAVALUE",
  SET_CHECKOUT_ORDER_SHIPPINGDESTINATION = "SET_CHECKOUT_ORDER_SHIPPINGDESTINATION",
  SET_CHECKOUT_FORMLIOADR_DATAVALUE = "SET_CHECKOUT_FORMLIOADR_DATAVALUE",
  SET_CHECKOUT_FORMFAOADR_DATAVALUE = "SET_CHECKOUT_FORMFAOADR_DATAVALUE",
  SET_CHECKOUT_FORMVALIDATION_ERROR = "SET_CHECKOUT_FORMVALIDATION_ERROR",
  RESET_CHECKOUT_FORMVALIDATION_ERROR = "RESET_CHECKOUT_FORMVALIDATION_ERROR",
}
