import { PopMessageVo } from "../../State";
import { PopMessageActionsTypes } from "../actions/ActionTypes";

const popMessageReducer = (state: PopMessageVo[], action) => {
  if (!state) return null;

  switch (action.type) {
    case PopMessageActionsTypes.ADD_POP_MESSAGE: {
      const newState = [...state];
      // unshift puts the new message at the top
      newState.unshift(action.payload.popMessage);
      return newState;
    }

    case PopMessageActionsTypes.REMOVE_POP_MESSAGE: {
      const newState = [...state];
      newState.splice(action.payload.index, 1);
      return newState;
    }
  }

  return state;
};

export default popMessageReducer;
