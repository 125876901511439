import * as React from "react";
import ProductTableHeader from "./ProductTableHeader";
import ProductTableRow from "./ProductTableRow";
import ProductVo from "../../vo/ProductVo";
import { UserState } from "../../../State";
import { priceCalculateScaleArray } from "../../utils/priceCalculations";

interface Props {
  children?: any;
  columns: ProductTableColumnVo[];
  products: ProductVo[];
  sorting: { key?: string; direction?: string };
  onClickProductLink: (product: ProductVo) => void;
  user: UserState;
  addProduct: (listType, objectID, pos, quantity, comment) => void;
  onClickScroll: (direction) => void;
  onClickSortLink: (column: ProductTableColumnVo, direction) => void;
}

class ProductTable extends React.PureComponent<Props> {
  render() {
    return (
      <div>
        <ProductTableHeader
          data={this.props.columns}
          sorting={this.props.sorting}
          onClickSortLink={this.props.onClickSortLink}
        />
        {this.props.products.map((item, index) => {
          return (
            <ProductTableRow
              key={`${item.objectID}-${index}`}
              data={item}
              priceScaleArray={priceCalculateScaleArray(item, this.props.user)}
              columns={this.props.columns}
              onClickProductLink={this.props.onClickProductLink}
              addProduct={this.props.addProduct}
              onClickScroll={this.props.onClickScroll}
            />
          );
        })}
      </div>
    );
  }
}

export default ProductTable;

export interface ProductTableColumnVo {
  description: string;
  dataAttributeKey: string;
  dataType: string;
  isRange: boolean;
  sorted: number;
}
