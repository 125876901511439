import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ElstrFileVo from "elstr-jslib-4/dist/vo/ElstrFileVo";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ProductActions } from "../actions/productActions";

interface Props {
  file: ElstrFileVo;
}

class ProductCardFile extends React.PureComponent<Props> {
  onClickFile = e => {
    ElstrCore.store.dispatch(ProductActions.onClickProductFile(e, this.props.file.type));
  };

  render() {
    let divisor = 1000;
    let unit = "kB";
    if (this.props.file.sizeInBytes > 1000000) {
      divisor = 1000000;
      unit = "MB";
    }
    return (
      <li>
        <a
          href={`/${this.props.file.publicPath}/${this.props.file.name}`}
          target="_blank"
          className="link--download"
          onClick={this.onClickFile}
          rel="noopener noreferrer"
        >
          {dic(this.props.file.type.toUpperCase())}
          <span className="link__file-info">
            {` (`}
            {this.props.file.hasOwnProperty("lang") &&
              this.props.file.lang &&
              this.props.file.lang.length > 0 &&
              `${this.props.file.lang.toUpperCase()},`}
            {` ${(this.props.file.sizeInBytes / divisor).toFixed(4)} ${unit})`}
          </span>
        </a>
      </li>
    );
  }
}

export default ProductCardFile;
