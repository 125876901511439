import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { priceCalculateForQuantity } from "../../common/utils/priceCalculations";
import { ListProductVo } from "../../common/vo/ListVo";
import ProductTakeVo from "../../common/vo/ProductTakeVo";
import { KeyEvent } from "../../common/constants/Constants";

export enum ProductTakeType {
  product = "product",
  wishlist = "wishlist",
  cart = "cart",
}

interface Props {
  take: ProductTakeVo;
  priceScaleArray: any[];
  onClickTakeChange?: (direction: string) => void;
  onInputTakeChange?: (quantityString: string) => void;
  onInputTakeChangeListItem?: (quantity, listId, index) => void;
  onBlurInputTakeListItem?: (product, take, listId, objectID, pos, comment) => void;
  onClickTakeChangeListItem?: (quantity, listId, objectID, pos, comment, index) => void;
  type: ProductTakeType;
  listId?: string | undefined;
  product?: ListProductVo | undefined;
  index?: number;
}

class ProductTake extends React.PureComponent<Props> {
  onClickHandlerListItem = direction => {
    if (this.props.product && this.props.onClickTakeChangeListItem) {
      const listId = this.props.listId;
      const objectID = this.props.product.objectID;
      const pos = this.props.product.pos;
      const comment = this.props.product.comment;
      const index = this.props.index;
      this.props.onClickTakeChangeListItem(direction, listId, objectID, pos, comment, index);
    }
  };

  onClickMinusHandler = () => {
    const direction = "minus";
    if (this.props.type === ProductTakeType.product && this.props.onClickTakeChange) {
      this.props.onClickTakeChange(direction);
    }
    if (this.props.type === ProductTakeType.cart || this.props.type === ProductTakeType.wishlist) {
      this.onClickHandlerListItem(direction);
    }
  };

  onClickPlusHandler = () => {
    const direction = "plus";
    if (this.props.type === ProductTakeType.product && this.props.onClickTakeChange) {
      this.props.onClickTakeChange(direction);
    }
    if (this.props.type === ProductTakeType.cart || this.props.type === ProductTakeType.wishlist) {
      this.onClickHandlerListItem(direction);
    }
  };

  onChangeQuantityInput = e => {
    if (this.props.type === ProductTakeType.product && this.props.onInputTakeChange) {
      this.props.onInputTakeChange(e.target.value);
    }
    if (
      (this.props.type === ProductTakeType.cart || this.props.type === ProductTakeType.wishlist) &&
      this.props.product &&
      this.props.onInputTakeChangeListItem
    ) {
      const quantity = e.target.value;
      const listId = this.props.listId;
      const index = this.props.index;
      this.props.onInputTakeChangeListItem(quantity, listId, index);
    }
  };

  handleOnBlur = e => {
    // TODO maybe handle enter for mobile
    if (
      (this.props.type === ProductTakeType.cart || this.props.type === ProductTakeType.wishlist) &&
      this.props.product &&
      this.props.onBlurInputTakeListItem
    ) {
      const product = this.props.product;
      const take = this.props.take;
      const listId = this.props.listId;
      const objectID = this.props.product.objectID;
      const pos = this.props.product.pos;
      const comment = this.props.product.comment;
      this.props.onBlurInputTakeListItem(product, take, listId, objectID, pos, comment);
    }
  };

  handleOnKeyDown = e => {
    if (e.keyCode === KeyEvent.DOM_VK_RETURN) {
      // https://github.com/Microsoft/TypeScript/issues/5901
      // used to hide the keyboard for mobile devices
      (document.activeElement as HTMLElement).blur();
    }
  };

  render() {
    if (!this.props.take) return null;

    const priceForQuantity: any = priceCalculateForQuantity(
      this.props.take.quantity,
      this.props.priceScaleArray,
    );

    let inputClassName = "pill-counter__input";
    if (this.props.take.msg.length > 0) {
      inputClassName += " form-error";
    }
    return (
      <div className="row row--total">
        <div className="pill-counter-wrap">
          <div className="pill-counter">
            <button className="pill-counter__left" title="-" onClick={this.onClickMinusHandler}>
              -{this.props.take.minusCounter}
            </button>
            <input
              className={inputClassName}
              type="text"
              value={this.props.take.quantityString}
              onChange={this.onChangeQuantityInput}
              onKeyDown={this.handleOnKeyDown}
              onBlur={this.handleOnBlur}
            />
            <span className="pill-counter__unit">{this.props.take.verkaufsEinheit}</span>
            <button className="pill-counter__right" title="+" onClick={this.onClickPlusHandler}>
              +{this.props.take.plusCounter}
            </button>
          </div>
          {this.props.take.msg.length > 0 && (
            <div className="pill-counter__notification">
              {dic(this.props.take.msg)} {dic("ANNAHME")}
              {": "}
              {this.props.take.quantity.toFixed(4)}
            </div>
          )}
        </div>
        {this.props.type !== ProductTakeType.wishlist && (
          <div className="price-total-wrap">
            <p className="price-total">
              {dic("TOTAL")}
              {": "}
              <strong>
                {priceForQuantity.dstIso} {priceForQuantity.totalPriceForQuantity.toFixed(4)}
              </strong>
            </p>
            {this.props.type !== ProductTakeType.cart && (
              <p className="price-vat">{dic("PREIS EXCL MWST VERSANDKOSTEN")}</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ProductTake;
