import { SearchActionTypes } from "./ActionTypes";
import { routerActions } from "connected-react-router";
import { searchQueryToUrl } from "../../common/utils/parseHashUrl";
import { FilterPanelActions } from "../../filterPanel/actions/filterPanelActions";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { CatalogAlgoliaActions } from "../../catalog/actions/algoliaActions";
import { createPopMessage } from "../../popMessage/actions/popMessageActions";
import { PopMessageType } from "../../common/constants/Constants";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import SearchSuggestionVo from "../../common/vo/SearchSuggestionVo";

declare var COMPONA_STATIC_CACHE;

export const SearchActions = {
  /**
   * Parse Url string
   * @param location (http://compona.com/group/K10000/#q=test&cta=0&cta=1
   * @returns {(dispatch, getState) => Promise<void>}
   */
  parseURL: function(location) {
    return async (dispatch, getState) => {
      const staticCacheClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];
      dispatch(SearchActions.urlChanged(location, staticCacheClassifications));
      dispatch(FilterPanelActions.loadFacets());
      // added because catalog was not updated on second level click on function
      dispatch(CatalogAlgoliaActions.getManufacturersAndFunctions());
    };
  },

  /**
   * Internal function dispatch SEARCH_URL_CHANGED Reducer
   * @param location
   * @param allClassifications
   * @returns {{type: SearchActionTypes; payload: {location: any; allClassifications: any}}}
   */
  urlChanged: function(location, allClassifications) {
    return {
      type: SearchActionTypes.SEARCH_URL_CHANGED,
      payload: { location, allClassifications },
    };
  },

  /**
   * Remove Facet from search
   * @param {string} facetId
   * @returns {(dispatch, getState) => Promise<void>}
   */
  setFacetSearch: function(facetId: string, valueId: string, add: boolean) {
    return async (dispatch, getState) => {
      dispatch(SearchActions.setShowItems(100));
      dispatch(SearchActions.facetSearch(facetId, valueId, add));
      dispatch(routerActions.push(searchQueryToUrl(getState().search)));
      // dispatch(FilterPanelActions.loadFacets()); // postpone this call until url change is detected, otherwise we would invoke two parallel Algolia queries
    };
  },

  /**
   * Internal function dispatch FACETS_CHANGE Reducer
   * @param data
   * @returns {{type: SearchActionTypes}}
   */
  facetSearch: function(facetId: string, valueId: string, add: boolean) {
    return { type: SearchActionTypes.FACETS_CHANGE, facetId, valueId, add };
  },

  /**
   * Add Text Search Query (Value change direct)
   * @param data
   * @returns {{type: SearchActionTypes}}
   */
  addTextSearchQuery: function(query: string) {
    return async dispatch => {
      dispatch(SearchActions.setShowItems(100));
      dispatch(SearchActions.searchQuery(query));

      if (query.length >= 2) {
        dispatch(SearchActions.changeResultByQuery());
        dispatch(LayoutActions.openSearch(true));
      } else {
        if (query.length === 0) dispatch(SearchActions.changeResultByQuery());
        dispatch(LayoutActions.openSearch(false));
      }
    };
  },

  changeResultByQuery: function() {
    const thunk: any = async (dispatch, getState) => {
      dispatch(routerActions.replace(searchQueryToUrl(getState().search)));
      // dispatch(FilterPanelActions.loadFacets()); // postpone this call until url change is detected, otherwise we would invoke two parallel Algolia queries
    };

    thunk.meta = {
      debounce: {
        time: 500,
        key: "changeResultByQuery",
      },
    };

    return thunk;
  },

  /**
   * Internal function dispatch SEARCH_TEXT_CHANGE Reducer
   * @param {string} query
   * @returns {{type: SearchActionTypes; query: string}}
   */
  searchQuery: function(query: string) {
    return { type: SearchActionTypes.SEARCH_TEXT_CHANGE, query };
  },

  showSearch: function(data) {
    return { type: SearchActionTypes.SHOW_SEARCH_RESULT, data };
  },

  showSuggestions: function(suggestions) {
    return { type: SearchActionTypes.SET_SUGGESTIONS, suggestions };
  },

  /**
   * Reset search
   * @returns {(dispatch, getState) => Promise<void>}
   */
  resetSearch: function() {
    return async (dispatch, getState) => {
      dispatch(LayoutActions.openSearch(false));
      dispatch(SearchActions.clearDelayResult());
      dispatch(routerActions.push(`/catalog/${activeLang()}/`));
    };
  },

  /**
   * Add Tree node to search
   * @param node
   * @returns {(dispatch, getState) => Promise<void>}
   */
  addTreeSearch: function(node) {
    return async (dispatch, getState) => {
      dispatch(SearchActions.setShowItems(100));
      dispatch(SearchActions.treeSearch({ node, add: true }));

      const group = getState().search.group;

      if (group.constructor === Object && Object.keys(group).length > 0) {
        dispatch(
          routerActions.push(
            "/catalog/group/" + node.objectID + searchQueryToUrl(getState().search, true),
          ),
        );
      } else {
        dispatch(
          routerActions.push(
            "/catalog/" + activeLang() + "/" + searchQueryToUrl(getState().search),
          ),
        );
      }

      // dispatch(FilterTreeActions.createFilterTreeData());
      // dispatch(FilterPanelActions.loadFacets()); // postpone this call until url change is detected, otherwise we would invoke two parallel Algolia queries
    };
  },

  /**
   * Remove Tree node from search
   * @param node
   * @returns {(dispatch, getState) => Promise<void>}
   */
  removeTreeSearch: function(node) {
    return async (dispatch, getState) => {
      dispatch(SearchActions.setShowItems(100));
      dispatch(SearchActions.treeSearch({ node, add: false }));

      const group = getState().search.group;

      if (group.constructor === Object && Object.keys(group).length > 0) {
        dispatch(
          routerActions.push(
            "/catalog/group/" + group.objectID + searchQueryToUrl(getState().search),
          ),
        );
      } else {
        dispatch(
          routerActions.push(
            "/catalog/" + activeLang() + "/" + searchQueryToUrl(getState().search),
          ),
        );
      }

      // dispatch(FilterTreeActions.createFilterTreeData());
      // dispatch(FilterPanelActions.loadFacets()); // postpone this call until url change is detected, otherwise we would invoke two parallel Algolia queries
    };
  },

  /**
   * Internal function dispatch FACETS_CHANGE Reducer
   * @param data
   * @returns {{type: SearchActionTypes}}
   */
  treeSearch: function(data) {
    return { type: SearchActionTypes.GROUP_CHANGE, ...data };
  },

  /**
   * Set Result List Items to show
   * @param hits
   * @returns {(dispatch, getState) => Promise<void>}
   */
  setResultListItems: function(hits2Show) {
    return async (dispatch, getState) => {
      dispatch(SearchActions.setShowItems(hits2Show));
      const availableHits = getState().search.searchCount;
      if (hits2Show < availableHits) {
        dispatch(
          createPopMessage(PopMessageType.info, dic("EINSCHRAENKUNG ANZAHL RESULTATE"), 5000),
        );
      }
      dispatch(FilterPanelActions.loadFacets());
    };
  },

  /**
   * Set Result List Items
   * @param hits
   * @returns {{type: SearchActionTypes}}
   */
  setShowItems: function(hits) {
    return { type: SearchActionTypes.SET_SHOW_ITEMS, hits };
  },

  /**
   * Set List Sorting Column
   * @param column
   * @param direction
   * @returns {(dispatch, getState) => Promise<void>}
   */
  setSorting: function(column, direction) {
    return async (dispatch, getState) => {
      if (column.isRange === true) {
        dispatch(createPopMessage(PopMessageType.info, dic("RANGE SPALTE NICHT SORTIERBAR"), 5000));
      } else {
        dispatch(SearchActions.setSortingSettins(column, direction));
        dispatch(FilterPanelActions.loadFacets());
      }
    };
  },

  /**
   * Set Sorting Settings in State
   * @param hits
   * @returns {{type: SearchActionTypes}}
   */
  setSortingSettins: function(column, direction) {
    return { type: SearchActionTypes.SET_SORTING, column, direction };
  },

  /**
   * Reset List Sorting Column
   * @returns {(dispatch, getState) => Promise<void>}
   */
  resetSorting: function() {
    return async (dispatch, getState) => {
      dispatch(SearchActions.resetSortingSettins());
      dispatch(FilterPanelActions.loadFacets());
    };
  },

  /**
   * Set Sorting Settings in State
   * @param hits
   * @returns {{type: SearchActionTypes}}
   */
  resetSortingSettins: function() {
    return { type: SearchActionTypes.RESET_SORTING };
  },

  clearDelayResult: function() {
    return async dispatch => {
      setTimeout(() => {
        dispatch(SearchActions.clearResult());
      }, 300);
    };
  },

  clearResult: function() {
    return { type: SearchActionTypes.CLEAR_SEARCH_RESULT };
  },

  onClickSuggestion: function(suggestion: SearchSuggestionVo) {
    return async (dispatch, getState) => {
      const routerState = getState().router;
      dispatch(LayoutActions.openSearch(false));
      dispatch(SearchActions.searchQuery(""));

      if (COMPONA_STATIC_CACHE.attributes[suggestion.dataAttributeKey]) {
        const attribute = window.COMPONA_STATIC_CACHE.attributes[suggestion.dataAttributeKey];
        if (attribute.dataType && attribute.dataType === "tree") {
          dispatch(routerActions.push(`/catalog/group/${suggestion.value}/`));
        } else {
          if (routerState.location.pathname.startsWith("/catalog") === false) {
            dispatch(routerActions.push(`/catalog/${activeLang()}/`));
          }
          dispatch(
            SearchActions.setFacetSearch(suggestion.dataAttributeKey, suggestion.value, true),
          );
          dispatch(FilterPanelActions.loadFacets());
        }
      }
    };
  },
};
