import * as React from "react";
import TeaserVo from "../../common/vo/TeaserVo";
import { Link } from "react-router-dom";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props extends TeaserVo {}

class TeaserItem extends React.PureComponent<Props> {
  render() {
    return (
      <Link to={this.props.btnUrl} className="home-teaser-text-layer__item">
        <span
          className="home-teaser__img--mobile"
          style={{ backgroundImage: `url(${this.props.imgUrlMbl})` }}
          aria-hidden="true"
        />
        <h3 className="home-teaser__title">{dic(this.props.title)}</h3>
        <button className="btn btn--arrow" title={dic(this.props.btnLabel)}>{dic(this.props.btnLabel)}</button>
      </Link>
    );
  }
}

export default TeaserItem;
