import * as React from "react";
import { ProductTableColumnVo } from "./ProductTable";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { getAttributeDescription } from "../../../common/utils/attributeUtils";

interface Props {
  key: string;
  item: ProductTableColumnVo;
  index: number;
  sorting: { key?: string; direction?: string };
  onClickSortLink: (column: ProductTableColumnVo, direction?: string) => void;
}

class ProductTableHeaderColumn extends React.PureComponent<Props> {
  onClickSortAsc = e => {
    this.props.onClickSortLink(this.props.item, "asc");
  };
  onClickSortDesc = e => {
    this.props.onClickSortLink(this.props.item, "desc");
  };
  onClickUnsort = e => {
    this.props.onClickSortLink(this.props.item);
  };

  render() {
    if (
      this.props.sorting.key === this.props.item.dataAttributeKey &&
      this.props.sorting.direction
    ) {
      if (this.props.sorting.direction === "asc") {
        return (
          <div
            className="list-header__item sorted"
            key={`${this.props.item.dataAttributeKey}-${this.props.index}`}
            onClick={this.onClickSortDesc}
          >
            <span style={{ display: "inline-block" }}>
              <span className="sicon sicon__arrow-up" />{" "}
              {getAttributeDescription(this.props.item.dataAttributeKey, activeLang())}
            </span>
          </div>
        );
      } else if (this.props.sorting.direction === "desc") {
        return (
          <div
            className="list-header__item sorted"
            key={`${this.props.item.dataAttributeKey}-${this.props.index}`}
            onClick={this.onClickUnsort}
          >
            <span style={{ display: "inline-block" }}>
              <span className="sicon sicon__arrow-down" />{" "}
              {getAttributeDescription(this.props.item.dataAttributeKey, activeLang())}
            </span>
          </div>
        );
      }
    } else {
      return (
        <div
          className="list-header__item"
          key={`${this.props.item.dataAttributeKey}-${this.props.index}`}
          onClick={this.onClickSortAsc}
        >
          <span style={{ display: "inline-block" }}>
            {getAttributeDescription(this.props.item.dataAttributeKey, activeLang())}
          </span>
        </div>
      );
    }
    /*
    switch (this.props.item.sorted) {
      case 1:
        return (
          <div
            className="list-header__item sorted"
            key={`${this.props.item.dataAttributeKey}-${this.props.index}`}
            onClick={this.onClickUnsort}
          >
            <span className="sicon sicon__arrow-down" />{" "}
            <span style={{ display: "inline-block" }}>
              {getAttributeDescription(this.props.item.dataAttributeKey, activeLang())}
            </span>
          </div>
        );
      case 2:
        return (
          <div
            className="list-header__item sorted"
            key={`${this.props.item.dataAttributeKey}-${this.props.index}`}
            onClick={this.onClickSortDesc}
          >
            <span className="sicon sicon__arrow-up" />{" "}
            <span style={{ display: "inline-block" }}>
              {getAttributeDescription(this.props.item.dataAttributeKey, activeLang())}
            </span>
          </div>
        );
    }

    return (
      <div
        className="list-header__item"
        key={`${this.props.item.dataAttributeKey}-${this.props.index}`}
        onClick={this.onClickSortAsc}
      >
        <span style={{ display: "inline-block" }}>
          {getAttributeDescription(this.props.item.dataAttributeKey, activeLang())}
        </span>
      </div>
    );
    */
  }
}

export default ProductTableHeaderColumn;
