import { PopMessageActionsTypes } from "./ActionTypes";
import { PopMessageVo } from "../../State";
import { PopMessageLevel as level, PopMessageType, Sicon } from "../../common/constants/Constants";
import ElstrBaseApp from "elstr-jslib-4/dist/ElstrBaseApp";

export const PopMessageActions = {
  addPopMessageItem: function(popMessage: PopMessageVo) {
    return { type: PopMessageActionsTypes.ADD_POP_MESSAGE, payload: { popMessage } };
  },

  removePopMessageItem_thunk: function(index: number) {
    return { type: PopMessageActionsTypes.REMOVE_POP_MESSAGE, payload: { index } };
  },

  removePopMessageItem: function(index: number) {
    return async (dispatch, getState) => {
      const popMessageState = getState().popMessage;
      if (popMessageState[index].isCmsNotice === true) {
        unshiftCmsNoticesClosed(popMessageState[index].text);
      }
      dispatch(PopMessageActions.removePopMessageItem_thunk(index));
    };
  },
};

/**
 * Creates a set of different prepared popMessages like info or warning and also dispatches the according action
 *
 * @param messageType
 * @param text
 * @param removedAfterMs if 0, must be closed manually
 */
export function createPopMessage(
  messageType: PopMessageType,
  textBold: string,
  removedAfterMs = 5000,
  text = "",
  linktext = "",
  href = "",
) {
  let popMessage: PopMessageVo;

  switch (messageType) {
    case PopMessageType.info: {
      popMessage = new PopMesageInstance({
        textBold,
        text,
        sicon: Sicon.info,
        level: level.wishlist,
        removedAfterMs,
      });
      break;
    }

    case PopMessageType.warning: {
      popMessage = new PopMesageInstance({
        textBold,
        text,
        sicon: Sicon.warning,
        level: level.alert,
        removedAfterMs,
      });
      break;
    }

    case PopMessageType.addWishlist: {
      popMessage = new PopMesageInstance({
        textBold,
        text,
        sicon: Sicon.wishlist,
        level: level.wishlist,
        removedAfterMs,
      });
      break;
    }

    case PopMessageType.addCart: {
      popMessage = new PopMesageInstance({
        textBold,
        text,
        sicon: Sicon.cart,
        level: level.alert,
        removedAfterMs,
      });
      break;
    }

    case PopMessageType.cmsNotice: {
      popMessage = new PopMesageInstance({
        textBold,
        text,
        sicon: Sicon.warning,
        level: level.alert,
        removedAfterMs: 0,
        linktext,
        href,
        isCmsNotice: true,
      });
      break;
    }
  }

  return async dispatch => {
    dispatch(PopMessageActions.addPopMessageItem(popMessage));
  };
}

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 * @param s
 */
export function hashCode(s: string) {
  let h;
  for (let i = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
}

export function getCmsNoticesClosed() {
  const cmsNoticesClosed: string[] = ElstrBaseApp.ELS.getValue("cmsNoticesClosed");
  try {
    if (cmsNoticesClosed && Array.isArray(cmsNoticesClosed)) {
      return cmsNoticesClosed;
    } else {
      return [];
    }
  } catch (e) {
    console.error("cmsNoticesClosed data corrupt");
    return [];
  }
}

export function unshiftCmsNoticesClosed(label: string) {
  const cmsNoticesClosed = getCmsNoticesClosed();
  cmsNoticesClosed.unshift(hashCode(label));
  ElstrBaseApp.ELS.replaceValue("cmsNoticesClosed", cmsNoticesClosed);
}

class PopMesageInstance implements PopMessageVo {
  public textBold;
  public text;
  public sicon;
  public level;
  public clickAction;
  public linktext;
  public href;
  public isCmsNotice;
  public removedAfterMs;
  public randNum;

  constructor(popMessage: PopMessageVo) {
    this.textBold = popMessage.textBold;
    this.text = popMessage.text;
    this.sicon = popMessage.sicon;
    this.level = popMessage.level;
    this.removedAfterMs = popMessage.removedAfterMs;
    this.clickAction = popMessage.clickAction;
    this.linktext = popMessage.linktext;
    this.href = popMessage.href;
    this.isCmsNotice = popMessage.isCmsNotice;
    this.randNum = Math.random();
  }
}
