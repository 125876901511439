import * as React from "react";
import { Link } from "react-router-dom";
import ProductVo from "../../common/vo/ProductVo";
import { getFullText } from "../../common/utils/textAttributeDataType";
import { getAttributeDescription } from "../../common/utils/attributeUtils";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

declare var COMPONA_STATIC_CACHE;

interface Props {
  data: ProductVo;
}

class ProductSpecialAttributes extends React.PureComponent<Props> {
  renderSpecialAttributes() {
    if (this.props.data.elstrFiles) {
      const attributes = window.COMPONA_STATIC_CACHE.attributes;

      return Object.keys(attributes).map((dataAttributeKey, index) => {
        // Add new attribute to attributes. Like specialAttributeClassname
        // If specialAttributeClassname is not null ->
        if (
          (dataAttributeKey === "ausrichtung" ||
            dataAttributeKey === "geschlecht" ||
            dataAttributeKey === "betriebstemperatur" ||
            dataAttributeKey === "schutzklasse" ||
            dataAttributeKey === "kabelabgang" ||
            dataAttributeKey === "nennspannung" ||
            dataAttributeKey === "nennstrom" ||
            dataAttributeKey === "kategorie" ||
            dataAttributeKey === "rastermass" ||
            dataAttributeKey === "schirmung" ||
            dataAttributeKey === "verriegelungsgewinde" ||
            dataAttributeKey === "polzahl") &&
          this.props.data[dataAttributeKey]
        ) {
          let historyReplace = false;
          let linkTo = window.location.pathname;
          const linkfilter = `?${dataAttributeKey}=${encodeURIComponent(
            this.props.data[dataAttributeKey],
          )}`;
          if (attributes[dataAttributeKey].isFilterable) {
            historyReplace = true;
            linkTo = `/catalog/${activeLang()}/${linkfilter}`;
          }
          return (
            <li key={`specialAttribute_${dataAttributeKey}`} className="filter-icon__item">
              <Link to={linkTo} replace={historyReplace}>
                <span className={`sicon sicon__${dataAttributeKey}`} />
                <span className="filter-icon-item__text">
                  {getFullText(this.props.data, attributes[dataAttributeKey])}
                </span>
                <span className="tooltip">
                  {getAttributeDescription(dataAttributeKey, activeLang())}
                </span>
              </Link>
            </li>
          );
        }
        else {
          return undefined;
        }
      });
    } else {
      return null;
    }
  }

  render() {
    return <ul className="filter-icon">{this.renderSpecialAttributes()}</ul>;
  }
}

export default ProductSpecialAttributes;
