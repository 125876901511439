import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { CartState, CheckoutState, UserState } from "../../State";
import { CheckoutActions } from "../actions/checkoutActions";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import CheckoutAddress from "./CheckoutAddress";
import { config } from "elstr-jslib-4/dist/ElstrConfig";

interface Props {
  user: UserState;
  cart: CartState;
  checkout: CheckoutState;
}

class CheckoutAddresses extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onEditLio = this.onEditLio.bind(this);
    this.onEditFao = this.onEditFao.bind(this);
  }

  onOrderInputChange = e => {
    ElstrCore.store.dispatch(CheckoutActions.onOrderInputChange(e.target.name, e.target.value));
    // this.props.onInputChange(e.target.name, e.target.value);
  };

  renderAddressOptions(key) {
    let addresses: any[] = [];

    if (Array.isArray(this.props.user.adr)) {
      addresses = addresses.concat(this.props.user.adr.filter(address => address.type === "org"));
    }

    if (key === "dkoLioAdrinr") {
      if (Array.isArray(this.props.user.adr)) {
        addresses = addresses.concat(
          this.props.user.adr.filter(address => address.type === "delivery"),
        );
      }
    }
    if (key === "dkoFaoAdrinr") {
      if (Array.isArray(this.props.user.adr)) {
        addresses = addresses.concat(
          this.props.user.adr.filter(address => address.type === "invoice"),
        );
      }
      addresses.push({
        inr: "-1",
        name: dic("LIEFERADRESSE"),
      });
    }
    addresses.push({
      inr: "0",
      name: dic("NEUE ADRESSE"),
    });

    return addresses.map((address, index) => {
      let optionString = "";
      const optionStringParts: string[] = [];
      if (address.type && address.type === "org") {
        optionString += `${dic("HAUPTADRESSE")}: `;
      }
      if (address.name && address.name.length) {
        optionStringParts.push(address.name);
      }
      if (address.vorname && address.vorname.length) {
        optionStringParts.push(address.vorname);
      }
      if (address.zeile1 && address.zeile1.length) {
        optionStringParts.push(address.zeile1);
      }
      if (address.zeile2 && address.zeile2.length) {
        optionStringParts.push(address.zeile2);
      }
      if (address.zeile3 && address.zeile3.length) {
        optionStringParts.push(address.zeile3);
      }
      if (address.zeile4 && address.zeile4.length) {
        optionStringParts.push(address.zeile4);
      }
      if (address.land && address.land.length) {
        optionStringParts.push(address.land);
      }
      if (address.plz && address.plz.length) {
        optionStringParts.push(address.plz);
      }
      if (address.ort && address.ort.length) {
        optionStringParts.push(address.ort);
      }
      optionString += optionStringParts.join(", ");
      return (
        <option
          key={`selectbox__option_${key}_${address.inr}`}
          className="selectbox__placeholder selectbox__option"
          value={address.inr}
        >
          {optionString}
        </option>
      );
    });
  }

  onInputChangeLio(name, value) {
    ElstrCore.store.dispatch(CheckoutActions.onInputChangeLio(name, value));
  }
  onInputChangeFao(name, value) {
    ElstrCore.store.dispatch(CheckoutActions.setCheckoutFormFaoAdrDataValue(name, value));
  }

  onEditLio() {
    ElstrCore.store.dispatch(CheckoutActions.setCheckoutData({ editLioAdr: true }));
  }
  onEditFao() {
    ElstrCore.store.dispatch(CheckoutActions.setCheckoutData({ editFaoAdr: true }));
  }

  onSubmitLio() {
    // Speichern der Adresse
    ElstrCore.store.dispatch(CheckoutActions.setCheckoutData({ editLioAdr: false }));
    ElstrCore.store.dispatch(CheckoutActions.onSaveFormLioAdr());
  }

  onSubmitFao() {
    // Speichern der Adresse
    ElstrCore.store.dispatch(CheckoutActions.setCheckoutData({ editFaoAdr: false }));
    ElstrCore.store.dispatch(CheckoutActions.onSaveFormFaoAdr());
  }

  // Render Lieferadresse
  renderlio(dkoLioAdrinr) {
    return (
      <CheckoutAddress
        inr={dkoLioAdrinr}
        user={this.props.user}
        formAdr={this.props.checkout.formLioAdr}
        onInputChange={this.onInputChangeLio}
        onSubmit={this.onSubmitLio}
        edit={this.props.checkout.editLioAdr}
        onClickEdit={this.onEditLio}
      />
    );
  }

  // Render Rechnungsadresse
  renderFao(dkoLioAdrinr, dkoFaoAdrinr) {
    if (dkoFaoAdrinr === "-1") {
      // Rechnungsadresse ist gleich Lieferadresse
      if (dkoLioAdrinr === "0") {
        // Lieferadresse ist neu
        return (
          <CheckoutAddress
            inr={dkoFaoAdrinr}
            user={this.props.user}
            formAdr={this.props.checkout.formLioAdr}
            onInputChange={this.onInputChangeLio}
            onSubmit={this.onSubmitLio}
            edit={this.props.checkout.editLioAdr}
            onClickEdit={this.onEditLio}
          />
        );
      } else {
        return (
          <CheckoutAddress
            inr={dkoLioAdrinr}
            user={this.props.user}
            formAdr={this.props.checkout.formLioAdr}
            onInputChange={this.onInputChangeLio}
            onSubmit={this.onSubmitLio}
            edit={false}
            onClickEdit={this.onEditLio}
          />
        );
      }
    } else {
      return (
        <CheckoutAddress
          inr={dkoFaoAdrinr}
          user={this.props.user}
          formAdr={this.props.checkout.formFaoAdr}
          onInputChange={this.onInputChangeFao}
          onSubmit={this.onSubmitFao}
          edit={this.props.checkout.editFaoAdr}
          onClickEdit={this.onEditFao}
        />
      );
    }
  }

  renderEditHint(inr) {
    if (inr === "0" || inr === "-1") {
      return null;
    }
    const address = this.props.user.adr.find(adr => adr.inr === inr);
    if (address && address.type !== "org") {
      return <div className={`text-condenced`}>{dic("ADRESSE IM ACCOUNT EDITIEREN")}</div>;
    }
    return null;
  }

  render() {
    let dkoLioAdrinr = "0";
    if (this.props.checkout.order && this.props.checkout.order.dkoLioAdrinr) {
      dkoLioAdrinr = this.props.checkout.order.dkoLioAdrinr;
    }
    let dkoFaoAdrinr = "0";
    if (this.props.checkout.order && this.props.checkout.order.dkoFaoAdrinr) {
      dkoFaoAdrinr = this.props.checkout.order.dkoFaoAdrinr;
    }

    return (
      <div className="content__2col">
        <div className="col">
          <h2 className="subtitle">{dic("LIEFERADRESSE")}</h2>
          <div className="selectbox-block">
            <select
              className="selectbox"
              name="dkoLioAdrinr"
              value={dkoLioAdrinr}
              onChange={this.onOrderInputChange}
            >
              {this.renderAddressOptions("dkoLioAdrinr")}
            </select>
          </div>
          {this.renderlio(dkoLioAdrinr)}
          {this.renderEditHint(dkoLioAdrinr)}
          {this.props.checkout.lioLandNotCh && (
            <div className={`text-condenced`}>{dic("HINWEIS EORI ERFORDERLICH")}</div>
          )}
          {this.props.checkout.asGuest && this.props.checkout.lioLandNotCh && (
            <p className="form__error-message">{dic("HINWEIS GAST LIEFERLAND SCHWEIZ")}</p>
          )}
          {this.props.cart.sum < parseFloat(config("minCartSumLioLandNotCH")) &&
            this.props.checkout.lioLandNotCh && (
              <p className="form__error-message">
                {`${dic("HINWEIS MINDESTBESTELLWERT LIEFERLAND NICHT SCHWEIZ")}: ${parseInt(
                  config("minCartSumLioLandNotCH"),
                  10,
                )} ${this.props.user.dstIsoCurrent}.`}
              </p>
            )}
          {this.props.cart.sum < parseFloat(config("minCartSumLioLandCH")) &&
            !this.props.checkout.lioLandNotCh && (
              <p className="form__error-message">
                {`${dic("HINWEIS MINDESTBESTELLWERT LIEFERLAND SCHWEIZ")}: ${parseInt(
                  config("minCartSumLioLandCH"),
                  10,
                )} ${this.props.user.dstIsoCurrent}.`}
              </p>
            )}
        </div>
        <div className="col">
          <h2 className="subtitle">{dic("RECHNUNGSADRESSE")}</h2>
          <div className="selectbox-block">
            <select
              className="selectbox"
              name="dkoFaoAdrinr"
              value={dkoFaoAdrinr}
              onChange={this.onOrderInputChange}
            >
              {this.renderAddressOptions("dkoFaoAdrinr")}
            </select>
          </div>
          {this.renderFao(dkoLioAdrinr, dkoFaoAdrinr)}
          {this.renderEditHint(dkoFaoAdrinr)}
        </div>
      </div>
    );
  }
}

export default CheckoutAddresses;
