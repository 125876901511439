import * as React from "react";

interface Props {
  name: string;
  id: string;
  count: any;
  key: string;
  unit: any;
  onClick: (e, active) => void;
}

class TagListRemoveAbleItem extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
  }

  onRemoveHandler(e) {
    this.props.onClick(this.props.id, true);
  }

  render() {
    return (
      <div className="filterpanel__selected-item" key={`${this.props.name}-${this.props.count}`}>
        <span className="remover--circle" aria-label="Remove" onClick={this.onRemoveHandler} />
        {this.props.name}
        <span className="filterpanel__counter">{this.props.count}</span>
      </div>
    );
  }
}

export default TagListRemoveAbleItem;
