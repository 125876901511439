import * as React from "react";
import TeaserItem from "./TeaserItem";
import TeaserVo from "../../common/vo/TeaserVo";

interface Props {
  teasers: TeaserVo[];
}

class Teaser extends React.PureComponent<Props> {
  renderTeaserItems() {
    let count = 0;
    return this.props.teasers.map((item: TeaserVo) => {
      count++;
      return <TeaserItem {...item} key={"TeaserItem" + count} />;
    });
  }

  renderTeaserPictures() {
    let count = 0;

    return this.props.teasers.map((item: TeaserVo) => {
      count++;
      return (
        <div
          className="home-teaser-img-layer__item"
          key={"TeaserPIC" + count}
          style={{ backgroundImage: `url(${item.imgUrl})` }}
        />
      );
    });
  }

  render() {
    return (
      <div className="home__teaser" role="navigation">
        <h2 className="visuallyhidden">Firmen Teaser Navigation</h2>
        <div className="home-teaser__text-layer">{this.renderTeaserItems()}</div>
        <div className="home-teaser__bg-layer" aria-hidden="true" />
        <div className="home-teaser__img-layer" aria-hidden="true">
          {this.renderTeaserPictures()}
        </div>
      </div>
    );
  }
}

export default Teaser;
