import * as React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

interface Props {
  id: string;
  marks: any[];
  unit?: any;
  minValue: any;
  maxValue: any;
  onRangeChange: (minValue, maxValue, minMinValue, maxMaxValue, changeType) => void;
}

class RangeSlider extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.onMinChange = this.onMinChange.bind(this);
    this.onMaxChange = this.onMaxChange.bind(this);
    this.minMinValue = Number(this.props.marks[0].id);
    this.maxMaxValue = Number(this.props.marks[this.props.marks.length - 1].id);
  }

  minMinValue?: any;
  maxMaxValue?: any;

  onSliderChange(values) {
    this.props.onRangeChange(values[0], values[1], this.minMinValue, this.maxMaxValue, "slider");
  }

  onMinChange(e) {
    this.props.onRangeChange(
      e.target.value,
      this.props.maxValue,
      this.minMinValue,
      this.maxMaxValue,
      "min",
    );
  }

  onMaxChange(e) {
    this.props.onRangeChange(
      this.props.minValue,
      e.target.value,
      this.minMinValue,
      this.maxMaxValue,
      "max",
    );
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  render() {
    const createSliderWithTooltip = Slider.createSliderWithTooltip;
    const Range = createSliderWithTooltip(Slider.Range);
    const Handle = Slider.Handle;

    let minRangeValueText = this.props.minValue;
    if (!minRangeValueText) {
      minRangeValueText = this.minMinValue;
    }
    let minRangeValue = Number(minRangeValueText);
    if (!minRangeValue || !this.isNumeric(minRangeValue)) {
      minRangeValue = this.minMinValue;
    }
    let maxRangeValueText = this.props.maxValue;
    if (!maxRangeValueText) {
      maxRangeValueText = this.maxMaxValue;
    }
    let maxRangeValue = Number(maxRangeValueText);
    if (!maxRangeValue || !this.isNumeric(maxRangeValue)) {
      maxRangeValue = this.maxMaxValue;
    }

    const handle = props => {
      const { value, dragging, index, ...restProps } = props;

      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={value}
          visible={dragging}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </Tooltip>
      );
    };

    //const wrapperStyle = { width: 400, margin: 50 };
    const marks = {};
    for (let i = 0; i < this.props.marks.length; i++) {
      if (i === 0 || i === this.props.marks.length - 1) {
        marks[this.props.marks[i].id] = this.props.marks[i].id + " " + this.props.unit;
      }
    }
    const value = [minRangeValue, maxRangeValue];

    //const step = Math.round(10 * (this.maxMaxValue - this.minMinValue)) / 100;
    var step = 0.1;
    if (Number.isInteger(this.maxMaxValue) && Number.isInteger(this.minMinValue)) {
      step = 1;
    }

    return (
      <>
        <div className="range-slider-box">
          <div className="range-slider-box__label">
            <Range
              //dots
              min={this.minMinValue}
              max={this.maxMaxValue}
              defaultValue={value}
              tipFormatter={value => `${value} ${this.props.unit}`}
              tipProps={{ overlayClassName: "rangeSliderStyle" }}
              step={step}
              marks={marks}
              handle={handle}
              onAfterChange={this.onSliderChange}
            />
          </div>
        </div>
        <div className="range-slider-input-wrap">
          <input
            type="text"
            value={`${minRangeValueText}`}
            className="input--text"
            onChange={this.onMinChange}
          />
          <span className="range-slider-input-separator">—</span>
          <input
            type="text"
            value={`${maxRangeValueText}`}
            className="input--text"
            onChange={this.onMaxChange}
          />
        </div>
      </>
    );
  }

  renderHandle() {
    return (
      <>
        <span className="range-slider__handle handle--left" style={{ left: "10%" }} />
        <span className="range-slider__handle handle--right" style={{ right: "50%" }} />
      </>
    );
  }
}

export default RangeSlider;
