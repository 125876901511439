import * as React from "react";
import TagListRemoveAbleItem from "../button/TagListRemoveAbleItem";
import { SearchState } from "../../../State";
import { isObject } from "util";

interface Props {
  parent: string;
  data?: any[];
  search?: SearchState;
  unit: any;
  onRemove: (e, active) => void;
}

class TagListRemoveAble extends React.PureComponent<Props> {

  render() {
    if (!this.props.data) return null;

    var checkedSubs: any = [];
    if (typeof this.props.search !== "undefined") {
      if (this.props.search.hasOwnProperty(this.props.parent)) {
        for (var key in this.props.search[this.props.parent]) {
          const value = this.props.search[this.props.parent][key];
          var sub = this.props.data.find(item => item.id === value);
          checkedSubs.push(sub);
        }
      }
    }

    return checkedSubs.map((item, index) => {
      if (isObject(item) && item.checked === true) {
        return (
          <TagListRemoveAbleItem
            name={item.name}
            id={item.id}
            count={item.count}
            key={`removableItem${index}`}
            unit={this.props.unit}
            onClick={this.props.onRemove}
          />
        );
      }
      return null;
    });
  }
}

export default TagListRemoveAble;
