import { CatalogActionsTypes } from "./ActionTypes";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { SearchActions } from "../../search/actions/searchActions";
import ElstrBaseApp from "elstr-jslib-4/dist/ElstrBaseApp";

export const CatalogActions = {
  showAllManufactures: function(active: boolean) {
    return { type: CatalogActionsTypes.SHOW_ALL_MANUFACTURES, payLoad: active };
  },

  showAllTypes: function(active: boolean) {
    return { type: CatalogActionsTypes.SHOW_ALL_TYPES, payLoad: active };
  },

  toggleProductViewSettingsBox: function(active: boolean) {
    return { type: CatalogActionsTypes.TOGGLE_PRODUCT_VIEW_SETTINGS_BOX, payLoad: active };
  },

  changeDragAndDropData: function(result) {
    return { type: CatalogActionsTypes.CHANGE_DRAG_DROP_HANDLER, payLoad: result };
  },

  removeViewSettingsColumn: function(value: string) {
    return { type: CatalogActionsTypes.REMOVE_VIEW_SETTINGS_COLUMN, payLoad: value };
  },

  newColAutoChange: function(value: string) {
    return { type: CatalogActionsTypes.NEW_COL_AUTO_CHANGE, payLoad: value };
  },

  newColAutoSelected: function(value: string) {
    return { type: CatalogActionsTypes.NEW_COL_AUTO_SELECTED, payLoad: value };
  },

  /**
   *
   * @returns {(dispatch, getState) => void}
   */
  showResultClicked: function() {
    return (dispatch, getState) => {
      dispatch(LayoutActions.openSearch(false));
    };
  },

  saveViewSettingsColumns: function() {
    return (dispatch, getState) => {
      dispatch(CatalogActions.saveViewSettingsColumns_thunk());
      ElstrBaseApp.ELS.replaceValue(
        "columnsViewSettings",
        getState().catalog.productList.columnsViewSettings,
      );
      dispatch(CatalogActions.toggleProductViewSettingsBox(false));
    };
  },

  saveViewSettingsColumns_thunk: function() {
    return { type: CatalogActionsTypes.SAVE_VIEW_SETTINGS_COLUMNS };
  },

  resetViewSettingsColumns: function() {
    return (dispatch, getState) => {
      dispatch(CatalogActions.resetViewSettingsColumns_thunk());
      dispatch(CatalogActions.toggleProductViewSettingsBox(false));
      dispatch(SearchActions.resetSorting());
    };
  },

  resetViewSettingsColumns_thunk: function() {
    return { type: CatalogActionsTypes.RESET_VIEW_SETTINGS_COLUMNS };
  },

  /*
export function getProductInfos(url: string) {
  return { type: CatalogActionsTypes.RESET_VIEW_SETTINGS_COLUMNS };
}


  export function getGroupData (objectID) {
      return {};
  }
  */
};
