import ElstrIo from "elstr-jslib-4/dist/ElstrIo";

const className = "COMPONA_WidgetServer_JSON_User";

export default class COMPONA_WidgetServer_JSON_User {
  static async createUser(
    email: string,
    password: string,
    dstDnr: string,
    adr: object,
    signupLang: string,
    signupNewsletter: boolean,
  ): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "createUser", {
      email,
      password,
      dstDnr,
      adr,
      signupLang,
      signupNewsletter,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async updateUser(
    email: string,
    emailNew: string,
    password: string,
    passwordNew: string,
  ): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "updateUser", {
      email,
      emailNew,
      password,
      passwordNew,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async getUserData(email: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "getUserData", {
      email,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async getUsers(): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "getUsers", {}).catch((e: Error) => {
      throw e;
    });
  }

  static async addOrUpdateAddress(email: string, adr: object, type: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "addOrUpdateAddress", {
      email,
      adr,
      type,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async disableAddress(email: string, adr: object): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "disableAddress", {
      email,
      adr,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async reSendVerificationMail(email: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "reSendVerificationMail", {
      email,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async forgotPassword(email: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "forgotPassword", {
      email,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async resetPassword(token: string, password: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "resetPassword", {
      token,
      password,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async getIfUserExists(email: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "getIfUserExists", {
      email,
    }).catch((e: Error) => {
      throw e;
    });
  }

  static async deleteUserByEmail(email: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(className, "deleteUserByEmail", {
      email,
    }).catch((e: Error) => {
      throw e;
    });
  }
}

export async function getUserData(email: string) {
  return COMPONA_WidgetServer_JSON_User.getUserData(email);
}
