import { ProductFilterState } from "../../State";
import { FilterPanelActionsTypes } from "../actions/ActionTypes";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import SuggestionVo from "../vo/SuggestionVo";
import { createImgUrlFromClassifcation } from "../../common/utils/createUrls";

const filterPanelTreeReducer = (state: ProductFilterState, action) => {
  if (!state) return null;

  switch (action.type) {
    /**
     * Build Treedata
     */
    case FilterPanelActionsTypes.CREATE_TREE_DATA:
      const attributes = action.attributes;
      const classifications = action.classifi;
      const facets = action.facets;

      const searchTrees = action.searchData.trees;

      const treeRoots: any = [];
      for (const dataAttributeKey in attributes) {
        const attribute = attributes[dataAttributeKey];
        if (attribute.dataType === "tree") {
          treeRoots.push(attribute.dataAttributeKey);
        }
      }

      // Create Trees
      const trees: any = [];
      for (const treeRoot in treeRoots) {
        trees[treeRoots[treeRoot]] = createTreeItem(
          treeRoots[treeRoot],
          dic(treeRoots[treeRoot]),
          `${dic("WAEHLEN")} ...`,
          treeRoots[treeRoot],
          classifications,
          treeRoots,
          searchTrees,
          facets,
        );
      }

      return { ...state, ...trees };
  }

  return state;
};

function createTreeItem(
  id: string,
  name: string,
  placeHolder: string,
  suggestionSearchString: string,
  allClass: any,
  treeRoots: any,
  searchTrees: any,
  facets: any,
) {
  var isInSearch = false;
  if (searchTrees) {
    treeRoots.forEach(root => {
      if (typeof searchTrees[root] != "undefined") {
        if (searchTrees[root].hasOwnProperty(id)) {
          isInSearch = true;
        }
        // look up in parent ids
        Object.keys(searchTrees[root]).forEach(function(objectKey, index) {
          if (searchTrees[root][objectKey].hasOwnProperty(id)) {
            isInSearch = true;
          }
        });
      }
    });
  }
  if (!isInSearch && treeRoots.indexOf(id) === -1) return;

  const suggestionArray = createSuggestion(suggestionSearchString, allClass, searchTrees, facets);
  const suggestions = suggestionArray[0];
  const count = suggestionArray[1];

  return {
    id,
    name,
    placeHolder,
    suggestions,
    canHaveChild: false,
    count,
    data: createDataBasedOnSuggestions(suggestions, allClass, treeRoots, searchTrees, facets),
  };
}

function createDataBasedOnSuggestions(
  suggestions: SuggestionVo[],
  allClass,
  treeRoots,
  searchQuery,
  facets,
): any[] {
  const data: any = [];
  suggestions.forEach(item => {
    const t = createTreeItem(
      item.id,
      item.name,
      `${dic("WAEHLEN")} ...`,
      item.id,
      allClass,
      treeRoots,
      searchQuery,
      facets,
    );
    if (t) data.push(t);
  });
  return data;
}

function sortByOrderNo(items: any[]) {
  items.sort((a, b) => {
    if (Number(a.orderNo) > Number(b.orderNo)) {
      return 1;
    }
    if (Number(a.orderNo) < Number(b.orderNo)) {
      return -1;
    }
    return 0;
  });
}

function createSuggestion(query: string, allClassifications, searchTrees, facets): any[] {
  const suggestions: SuggestionVo[] = [];
  var parentCount = 0;
  allClassifications.forEach(classification => {
    if (classification.parentId === query) {
      var isChecked = false;
      var count = 0;
      if (searchTrees) {
        if (typeof searchTrees[classification.classificationId] != "undefined") {
          if (
            searchTrees[classification.classificationId].hasOwnProperty(classification.objectID)
          ) {
            isChecked = true;
          }
          // look up in parent ids
          Object.keys(searchTrees[classification.classificationId]).forEach(function(objectKey, index) {
            if (
              searchTrees[classification.classificationId][objectKey].hasOwnProperty(
                classification.objectID,
              )
            ) {
              isChecked = true;
            }
          });
        }
      }
      if (typeof searchTrees[classification.classificationId] != "undefined") {
        if (typeof facets[classification.classificationId] != "undefined") {
          if (facets[classification.classificationId].hasOwnProperty(classification.objectID)) {
            count = facets[classification.classificationId][classification.objectID];
            parentCount = parentCount + count;
          }
        }
      }

      if (count === 0) {
        suggestions.push({
          id: classification.objectID,
          name: classification.descShort,
          checked: isChecked,
          orderNo: Number(classification.orderNo),
          img: createImgUrlFromClassifcation(classification),
          href: `/catalog/group/${classification.objectID}/`,
        });
      } else {
        suggestions.push({
          id: classification.objectID,
          name: classification.descShort,
          checked: isChecked,
          count,
          orderNo: Number(classification.orderNo),
          img: createImgUrlFromClassifcation(classification),
          href: `/catalog/group/${classification.objectID}/`,
        });
      }
    }
  });

  sortByOrderNo(suggestions);
  return [suggestions, parentCount];
}

export default filterPanelTreeReducer;
