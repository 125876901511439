import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  name: string;
  id: string;
  count: number;
  checked: boolean;
  isBoolean?: boolean;
  unit: string;

  filterIsActive?: boolean;

  onClick?: (e: boolean) => void;
  onChange?: (e: boolean, itemProps: Props) => void;
}

class CheckBox extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    // gvo
    // if (!this.props.count && !this.props.checked) return null;
    if (this.props.onClick) {
      this.props.onClick(this.props.checked);
    }

    if (this.props.onChange) {
      this.props.onChange(this.props.checked, this.props);
    }

    return null;
  }

  render() {
    const st: any = {};
    if (!this.props.count && !this.props.checked) {
      st.opacity = 0.5;
    }

    if (this.props.filterIsActive || this.props.count > 0) {
      return (
        <div style={st} className="checkbox" onClick={this.onClickHandler}>
          <input
            className="input--checkbox"
            type="checkbox"
            onChange={() => null}
            aria-checked="false"
            checked={this.props.checked}
          />
          {this.renderName()}
          {this.renderCount()}
        </div>
      );
    } else {
      return (
        <div style={st} className="checkbox inactive">
          <input
            className="input--checkbox"
            type="checkbox"
            aria-checked="false"
            checked={this.props.checked}
          />
          {this.renderName()}
          {this.renderCount()}
        </div>
      );
    }
  }

  renderName() {
    if (!this.props.name) return undefined;

    let label = this.props.name;
    if (this.props.isBoolean) {
      if (this.props.name === "0") {
        label = dic("NEIN");
      } else {
        label = dic("JA");
      }
    }

    return (
      <label className="filterpanel__label checkbox__label">
        <span className="checkbox__box" />
        {label}
      </label>
    );
  }

  renderCount() {
    // if (!this.props.count) return undefined;
    return <span className="filterpanel__counter">{this.props.count}</span>;
  }
}

export default CheckBox;
