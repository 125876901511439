import { ManageState } from "../../State";
import { ManageActionTypes } from "../actions/ActionTypes";

const manageReducer = (state: ManageState, action) => {
  if (!state) return null;

  switch (action.type) {
    case ManageActionTypes.SET_MANAGE_ORDERS_DATA:
      // console.log(action.payload);

      return {
        ...state,
        orders: { ...state.orders, ...action.payload.data },
      };

    case ManageActionTypes.SET_MANAGE_USERS_DATA:
      return {
        ...state,
        users: { ...state.users, ...action.payload.data },
      };
  }

  return state;
};

export default manageReducer;
