import { ProductState, RelatedTabState } from "../../State";
import { ProductActionTypes } from "../actions/ActionTypes";
import ProductVo from "../../common/vo/ProductVo";
import {
  productGetTakeClick,
  productGetTakeInit,
  productGetTakeInput,
} from "../../common/utils/productUtils";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { LinkAlternateVo } from "../../common/vo/LinkAlternateVo";
import { createProductUrl } from "../../common/utils/createUrls";
import { isArray } from "util";
import { createDraft, Draft, finishDraft } from "immer";

declare var COMPONA_STATIC_CACHE;

const productReducer = (state: ProductState, action) => {
  if (!state) return null;

  let relatedTabs: RelatedTabState[] = [];
  let relatedTabActiveIndex: number | null = null;
  let relatedTabActiveIsLoaded = false;
  let foldingBoxes;
  const take = state.take;
  const data = state.data;
  const supportedLanguages = config("supportedLanguages");

  switch (action.type) {
    case ProductActionTypes.SET_PRODUCT_DATA:
      const productData: ProductVo = { ...action.payload.productData };

      // Calculate state for foldingBoxes
      // const foldingBoxes = state.foldingBoxes;
      foldingBoxes = state.foldingBoxes.map((foldingBox, foldingBoxIndex) => {
        let foldingBoxIsVisible = false;
        const attributes = foldingBox.attributes.map((attribute, attributeIndex) => {
          const dataAttibuteKey = attribute.dataAttributeKey;
          if (
            productData.hasOwnProperty(dataAttibuteKey) &&
            COMPONA_STATIC_CACHE.attributes[dataAttibuteKey] &&
            COMPONA_STATIC_CACHE.attributes[dataAttibuteKey].detailsDisplayAllowed
          ) {
            foldingBoxIsVisible = true;
            return { ...attribute, isVisible: true };
          } else {
            return { ...attribute, isVisible: false };
          }
        });
        return { ...foldingBox, attributes, isVisible: foldingBoxIsVisible };
      });

      // Calculate state for relatedTabs
      relatedTabs = [];
      if (productData.productRelationTypes) {
        productData.productRelationTypes.map((type: string, index) => {
          relatedTabActiveIndex = 0;
          relatedTabActiveIsLoaded = false;
          relatedTabs.push({
            productRelationType: type,
            isLoaded: false,
            relatedProducts: [],
            sorting: { key: undefined, direction: undefined },
          });
          return null;
        });
      } else {
        relatedTabActiveIndex = null;
      }

      return {
        ...state,
        data: productData,
        take: productGetTakeInit(productData.mindestbestellmenge, productData),
        foldingBoxes,
        relatedTabActiveIndex,
        relatedTabActiveIsLoaded,
        relatedTabs,
      };

    case ProductActionTypes.SET_RELATED_TAB_DATA:
      relatedTabs = state.relatedTabs.map(tab => {
        if (tab.productRelationType === action.payload.productRelationType) {
          return {
            ...tab,
            isLoaded: true,
            relatedProducts: action.payload.data,
            sorting: { key: undefined, direction: undefined },
          };
        }
        return {
          ...tab,
          isLoaded: false,
          relatedProducts: [],
          sorting: { key: undefined, direction: undefined },
        };
      });
      return { ...state, relatedTabs };

    case ProductActionTypes.SET_RELATED_TAB_ACTIVE_INDEX:
      relatedTabActiveIndex = 0;
      for (let i = 0; i < state.relatedTabs.length; i++) {
        if (state.relatedTabs[i].productRelationType === action.payload.productRelationType) {
          relatedTabActiveIndex = i;
        }
      }

      return { ...state, relatedTabActiveIndex };

    case ProductActionTypes.OPEN_CLOSE_FOLDING_BOX:
      foldingBoxes = state.foldingBoxes.map((foldingBox, foldingBoxIndex) => {
        if (foldingBox.attributeSectionId === action.payload.attributeSectionId) {
          return { ...foldingBox, isExpanded: !foldingBox.isExpanded };
        } else {
          return foldingBox;
        }
      });
      return { ...state, foldingBoxes };

    case ProductActionTypes.TAKE_COUNT_CLICK_CHANGE:
      if (take && data) {
        const productTake = productGetTakeClick(take, data, action.payload.direction);
        return { ...state, take: productTake };
      } else {
        return state;
      }

    case ProductActionTypes.TAKE_COUNT_INPUT_CHANGE:
      if (take && data) {
        const productTake = productGetTakeInput(take, data, action.payload.quantityString);
        return { ...state, take: productTake };
      } else {
        return state;
      }

    case ProductActionTypes.SET_PRODUCT_LINK_ALTERNATES:
      const linkAlternates: LinkAlternateVo[] = supportedLanguages.map(lang => {
        return {
          hreflang: lang,
          href: `${window.location.protocol}//${window.location.host}${createProductUrl(
            action.payload.data[lang],
            lang,
          )}`,
        };
      });
      return { ...state, linkAlternates };

    case ProductActionTypes.SET_PRODUCT_FILES_MSG:
      const dataWithMsg = { ...data, elstrFilesMsg: action.payload.msg };
      return { ...state, data: dataWithMsg };

    case ProductActionTypes.SET_RELATED_SORTING:
      const columnKey = action.column.dataAttributeKey;
      const sortConf = { key: columnKey, direction: action.direction };
      const prodState: Draft<ProductState> = createDraft(state);
      const tabs: RelatedTabState[] = [];
      for (const index in prodState.relatedTabs) {
        const relatedTab = { ...prodState.relatedTabs[index] };
        if (parseInt(index) === prodState.relatedTabActiveIndex) {
          relatedTab.sorting = { ...sortConf };
          let relProds = [...prodState.relatedTabs[index].relatedProducts].sort((a, b) =>
            sortAlphaNum(a, b, columnKey, action.direction),
          );
          if (action.direction === undefined || action.direction === "") {
            relProds = [...prodState.relatedTabs[index].relatedProducts].sort((a, b) =>
              sortAlphaNum(a, b, "objectID", action.direction),
            );
          }
          relatedTab.relatedProducts = relProds;
        }
        tabs[index] = relatedTab;
      }
      prodState.relatedTabs = tabs;
      const newProdState = finishDraft(prodState);
      const tmpProdState = JSON.parse(JSON.stringify(newProdState));
      return tmpProdState;
  }

  return state;
};

function sortAlphaNum(a, b, colKey, dir) {
  let valA = "";
  let valB = "";
  if (a[colKey] !== undefined) valA = a[colKey];
  if (b[colKey] !== undefined) valB = b[colKey];

  if (isArray(a[colKey]) && a[colKey][0] !== undefined) {
    valA = a[colKey][0];
  }
  if (isArray(b[colKey]) && b[colKey][0] !== undefined) {
    valB = b[colKey][0];
  }
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;
  const aInt = parseInt(valA, 10);
  const bInt = parseInt(valB, 10);
  if (dir === "asc") {
    if (isNaN(aInt) && isNaN(bInt)) {
      const aA = valA.replace(reA, "");
      const bA = valB.replace(reA, "");
      if (aA === bA) {
        const aN = parseInt(valA.replace(reN, ""), 10);
        const bN = parseInt(valB.replace(reN, ""), 10);
        // alert("in if " + aN + " : " + bN);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    } else if (isNaN(aInt)) {
      // A is not an Int
      return 1; // to make alphanumeric sort first return 1 here
    } else if (isNaN(bInt)) {
      // B is not an Int
      return -1; // to make alphanumeric sort first return -1 here
    } else if (aInt === bInt) {
      return 0;
    } else {
      return aInt > bInt ? 1 : -1;
    }
  } else {
    if (isNaN(aInt) && isNaN(bInt)) {
      const aA = valA.replace(reA, "");
      const bA = valB.replace(reA, "");
      if (aA === bA) {
        const aN = parseInt(valA.replace(reN, ""), 10);
        const bN = parseInt(valB.replace(reN, ""), 10);
        // alert("in if " + aN + " : " + bN);
        return aN === bN ? 0 : aN > bN ? -1 : 1;
      } else {
        return aA > bA ? -1 : 1;
      }
    } else if (isNaN(aInt)) {
      // A is not an Int
      return -1; // to make alphanumeric sort first return 1 here
    } else if (isNaN(bInt)) {
      // B is not an Int
      return 1; // to make alphanumeric sort first return -1 here
    } else if (aInt === bInt) {
      return 0;
    } else {
      return aInt > bInt ? -1 : 1;
    }
  }
}

export default productReducer;
