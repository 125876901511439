import * as React from "react";
import ProductVo from "../../vo/ProductVo";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { Link } from "react-router-dom";
import Image from "../media/Image";
import { createProductUrl } from "../../utils/createUrls";
import { v1 as uuidv1 } from "uuid";
import { productGetTitleString } from "../../utils/productUtils";

interface Props {
  data: ProductVo;
  onClickProductLink: (product: ProductVo) => void;
  showHomepageFokusText: boolean;
  comment?: string;
}

class ProductListArticle extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    this.props.onClickProductLink(this.props.data);
    return null;
  }

  render() {
    return (
      <article className="product" key={`article_product_${uuidv1()}`}>
        {this.props.showHomepageFokusText && (
          <div className="product__flag">{this.props.data.homepage_fokus_text}</div>
        )}

        <div className="product__img">
          <Link onClick={this.onClickHandler} to={createProductUrl(this.props.data, activeLang())}>
            <Image
              data={this.props.data.elstrImage}
              w={"88"}
              h={"88"}
              title={productGetTitleString(this.props.data)}
              alt={productGetTitleString(this.props.data)}
            />
          </Link>
        </div>
        <Link
          onClick={this.onClickHandler}
          to={createProductUrl(this.props.data, activeLang())}
          className="product__link"
        >
          <h3 className="product__title">{this.props.data.ShortDescription}</h3>
        </Link>
        <dl className="product-nr">
          <dt className="product-nr__title">{dic("ART NR")}</dt>
          <dd className="product-nr__cont"> {this.props.data.objectID}</dd>
          <dt className="product-nr__title">{dic("HERSTELLER TYPEN NR")}</dt>
          <dd className="product-nr__cont">{this.props.data.hersteller_typennummer_formatiert}</dd>
        </dl>
        {this.props.comment &&
          this.props.comment.length > 0 && (
            <>
              <p className="product__comment-label">{dic("KOMMENTAR")}</p>
              <p className="product__comment-text">{this.props.comment}</p>
            </>
          )}
      </article>
    );
  }
}

export default ProductListArticle;
