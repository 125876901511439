import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { OrderDataVo } from "../../State";

interface Props {
  order: OrderDataVo;
}

class OrderStatus extends React.PureComponent<Props> {
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    let orderPaymentStatusStyle = {};
    if (
      this.props.order.paymentStatus &&
      this.props.order.paymentStatus > 0 &&
      this.props.order.paymentStatus < 50
    ) {
      orderPaymentStatusStyle = {
        color: "#e32236",
        fontWeight: "bold",
      };
    }
    return (
      <>
        <span>{dic(`ORDER STATUS ${this.props.order.status}`)}</span>
        <br />
        {this.props.order.paymentStatus &&
          this.props.order.paymentStatus > 0 && (
            <span style={orderPaymentStatusStyle}>
              {dic(`ORDER PAYMENTSTATUS ${this.props.order.paymentStatus}`)}
            </span>
          )}
      </>
    );
  }
}

export default OrderStatus;
