import * as React from "react";
import { Helmet } from "react-helmet";
import SearchGroupVo from "../../common/vo/SearchGroupVo";
import {
  titleMetaStringPrefixForAnyFilter,
  titleStringMainForAnyFilter,
} from "../../common/utils/titleStringUtils";
import { SearchState } from "../../State";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  current: SearchGroupVo;
  searchState: SearchState;
}

class TypeDetail extends React.PureComponent<Props> {
  render() {
    if (typeof this.props.current === "undefined") {
      return null;
    }

    const title = titleMetaStringPrefixForAnyFilter(this.props.searchState);

    return (
      <>
        <Helmet>
          <title>{`${title} ${dic("ONLINE KAUFEN")} | ${dic("TITLE")}`}</title>
          <meta name="description" content={this.props.current.descLong} />
        </Helmet>
        <h1 className="catalog__title">{titleStringMainForAnyFilter(this.props.searchState)}</h1>
        <p className="catalog__lead">{this.props.current.descLong}</p>
      </>
    );
  }
}

export default TypeDetail;
