import * as React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

interface Props {
  id: string;
  description: string;
  placement?: string;
  overlayClassName?: string;
  onClick?: (e) => void;
}

class ToolTip extends React.PureComponent<Props> {

  render() {
    if (this.props.description === "") {
      return null;
    }
    let placement = "topLeft";
    if (this.props.placement && this.props.placement !== "") {
      placement = this.props.placement;
    }
    if (typeof this.props.description !== "string") {
      return null;
    }
    return (
      <Tooltip
        placement={placement}
        trigger="hover"
        id={this.props.id}
        overlay={this.props.description}
        overlayClassName={this.props.overlayClassName}
      >
        <div className="tip-marker" aria-describedby={this.props.id} />
      </Tooltip>
    );
  }
}

export default ToolTip;
