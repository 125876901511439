import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import ProductListArticle from "../../common/components/list/ProductListArticle";
import { v1 as uuidv1 } from "uuid";

interface Props {
  data: ProductVo[];
  onClickProductLink: (product: ProductVo) => void;
}

class NewProductsList extends React.PureComponent<Props> {
  render() {
    return this.props.data.map((product: ProductVo) => {
      return (
        <ProductListArticle
          key={uuidv1()}
          data={product}
          onClickProductLink={this.props.onClickProductLink}
          showHomepageFokusText={true}
        />
      );
    });
  }
}

export default NewProductsList;
