import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  mengensprung: number | null;
  priceScaleArray: any[];
}

class ProductPrice extends React.PureComponent<Props> {
  renderPriceInfo() {
    // Show special info only if mengensprung is not 1
    if (
      this.props.mengensprung === 1 ||
      this.props.mengensprung === 0 ||
      this.props.mengensprung === null
    ) {
      return <div className="price-info" />;
    } else {
      return (
        <div className="price-info">
          <strong>{`${dic("ACHTUNG")}:`}</strong>
          {dic("NICHT IN 1ER SCHRITTEN")}
        </div>
      );
    }
  }

  renderPriceScale() {
    const Fragment = React.Fragment;
    return this.props.priceScaleArray.map((price, index) => {
      return (
        <Fragment key={`price-${index}`}>
          <dt className="price-unit__title">
            {dic("AB")} {price.mengeAb} {price.verkaufsEinheit}
          </dt>
          <dd className="price-unit__cont">
            {price.dstIso} {price.betrag.toFixed(4)} {dic("PRO")} {price.verkaufsEinheit}
          </dd>
        </Fragment>
      );
    });
  }

  render() {
    return (
      <div className="row row--price">
        {this.renderPriceInfo()}
        <dl className="price-unit">{this.renderPriceScale()}</dl>
      </div>
    );
  }
}

export default ProductPrice;
