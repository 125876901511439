import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import { Link } from "react-router-dom";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import Image from "../../common/components/media/Image";
import { createProductUrl } from "../../common/utils/createUrls";

interface Props {
  data: ProductVo;
  onClickProductLink: (product: ProductVo) => void;
}

class SearchItem extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    this.props.onClickProductLink(this.props.data);
    return null;
  }

  render() {
    return (
      <article className="product">
        <div className="product__img">
          <Link onClick={this.onClickHandler} to={createProductUrl(this.props.data, activeLang())}>
            <Image data={this.props.data.elstrImage} w={"88"} h={"88"} />
          </Link>
        </div>
        <Link
          onClick={this.onClickHandler}
          to={createProductUrl(this.props.data, activeLang())}
          className="product__link"
        >
          <h3 className="product__title">{this.props.data.ShortDescription}</h3>
        </Link>
        <dl className="product-nr">
          <dt className="product-nr__title">{dic("ART NR")}</dt>
          <dd className="product-nr__cont"> {this.props.data.objectID}</dd>
          <dt className="product-nr__title">{dic("HERSTELLER TYPEN NR")}</dt>
          <dd className="product-nr__cont">{this.props.data.hersteller_typennummer_formatiert}</dd>
        </dl>

        {this.props.data.status && this.props.data.status <= 650 && (
          <p className="product__stock">
            {dic("Noch")} {this.props.data.verfuegbareMenge} {dic("auf Lager")}
          </p>
        )}
      </article>
    );
  }
}

export default SearchItem;
