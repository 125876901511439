import * as React from "react";
import { LayoutState, UserState, State } from "../../State";
import { connect } from "react-redux";
import { LayoutActions } from "../actions/layoutActions";
import { SearchActions } from "../../search/actions/searchActions";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import ComponaLogoTop from "../components/ComponaLogoTop";
import ComponaStructuredData from "../components/ComponaStructuredData";
import WebsiteStructuredData from "../components/WebsiteStructuredData";

interface StateProps {
  data: LayoutState;
  user: UserState;
  numOfCartItems: number;
  numOfWishlists: number;
}

interface DispatchProps {
  onWishlistClick: (value) => void;
  onCartClick: (value) => void;
  onUserClick: (value) => void;
  onLangClick: (value) => void;
  onCurrencyClick: (value) => void;
  onMenuClick: () => void;
  onLogoClick: () => void;
}

interface Props extends StateProps, DispatchProps {}

interface UserIconProps {
  onUserClickHandler: () => void;
  isInit: boolean;
}

class UserIcon extends React.PureComponent<UserIconProps> {
  render() {
    const cls = this.props.isInit ? "user-logged" : "";
    return (
      <li
        className={`meta-nav__item meta-nav--user user--logged ${cls}`}
        onClick={this.props.onUserClickHandler}
      >
        <span className="sicon sicon__user" />
        <span className="user-status" aria-hidden="true" />
      </li>
    );
  }
}

class Header extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onWishListClickHandler = this.onWishListClickHandler.bind(this);
    this.onCartClickHandler = this.onCartClickHandler.bind(this);
    this.onUserClickHandler = this.onUserClickHandler.bind(this);
    this.onLangClickHandler = this.onLangClickHandler.bind(this);
    this.onCurrencyClickHandler = this.onCurrencyClickHandler.bind(this);
    this.onLogoClickHandler = this.onLogoClickHandler.bind(this);
  }

  onWishListClickHandler() {
    this.props.onWishlistClick(!this.props.data.wishlistOpen);
  }

  onCartClickHandler() {
    this.props.onCartClick(!this.props.data.cartOpen);
  }

  onUserClickHandler() {
    this.props.onUserClick(!this.props.data.userOpen);
  }

  onLangClickHandler() {
    this.props.onLangClick(!this.props.data.langOpen);
  }

  onCurrencyClickHandler() {
    this.props.onCurrencyClick(!this.props.data.currencyOpen);
  }

  onLogoClickHandler() {
    this.props.onLogoClick();
  }

  render() {
    let cls = "header";
    if (this.props.data.homeHeader) cls += " header--home";

    return (
      <header className={cls} role="banner" id="header">
        <div className="visuallyhidden">Header</div>
        <div className="header__logo">
          <ComponaLogoTop onClick={this.onLogoClickHandler} />
        </div>

        <WebsiteStructuredData />
        <ComponaStructuredData />

        <nav className="header__nav" role="navigation">
          <div className="visuallyhidden">Header Meta Navigation</div>
          <ul className="meta-nav">
            <li className="meta-nav__item not-empty" onClick={this.onWishListClickHandler}>
              <span className="sicon sicon__header-wishlist" />
              <span className="meta-nav__counter" aria-hidden="true">
                {this.props.numOfWishlists}
              </span>
            </li>
            <li
              className="meta-nav__item meta-nav--cart not-empty"
              onClick={this.onCartClickHandler}
            >
              <span className="sicon sicon__cart" />
              <span className="meta-nav__counter" aria-hidden="true">
                {this.props.numOfCartItems}
              </span>
            </li>
            <UserIcon
              isInit={this.props.user.isInit}
              onUserClickHandler={this.onUserClickHandler}
            />
            <li className="meta-nav__item" onClick={this.onLangClickHandler}>
              <span className="lang-nav--curent">{activeLang().toUpperCase()}</span>
            </li>
            <li className="meta-nav__item" onClick={this.onCurrencyClickHandler}>
              <span className="currency-nav--curent">{this.props.user.dstIsoCurrent}</span>
            </li>
          </ul>

          <div className="header-nav__hamburger" onClick={this.props.onMenuClick}>
            <div className="hamburger">
              <span className="hamburger__bar" />
              <span className="hamburger__bar" />
              <span className="hamburger__bar" />
            </div>
            <span className="hamburger__counter" aria-hidden="true">
              3{/* TODO: Where is this number used? */}
            </span>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state: State): StateProps => ({
  data: state.layout,
  user: state.user,
  numOfCartItems: state.cart.products.length,
  numOfWishlists: state.wishlist.lists.length,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onWishlistClick: (value = true) => dispatch(LayoutActions.openWhishlist(value)),
  onCartClick: (value = true) => dispatch(LayoutActions.openCart(value)),
  onUserClick: (value = true) => dispatch(LayoutActions.openUser(value)),
  onLangClick: (value = true) => dispatch(LayoutActions.openLang(value)),
  onCurrencyClick: (value = true) => dispatch(LayoutActions.openCurrency(value)),
  onMenuClick: (value = true) => dispatch(LayoutActions.openMenu(value)),
  onLogoClick: () => dispatch(SearchActions.resetSorting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
