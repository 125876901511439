import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import WishlistCreateList from "../components/WishlistCreateList";
import Wishlist from "../components/Wishlist";
import { State, UserState } from "../../State";
import ListVo from "../../common/vo/ListVo";
import { connect } from "react-redux";
import { ListActions } from "../../list/actions/listActions";
import { LayoutActions } from "../../layout/actions/layoutActions";

interface WishlistStateToProps {
  lists: ListVo[];
  user: UserState;
  activeListId: null | string;
}

interface WishlistDispatchToProps {
  addProductFromListToCart: (listType, objectID, pos, comment, product, take) => void;
  addProductsFromListToCart: (listId) => void;
  removeProduct: (listId, objectId, pos) => void;
  removeProductsFromList: (listId) => void;
  onInputTakeChangeWishlist: (quantity, listId, index) => void;
  onClickTakeChangeWishlist: (direction, listId, objectID, pos, comment, index) => void;
  onBlurInputTakeWishlist: (quantity, listId, objectID, pos, comment, index) => void;
  updateProduct: (listId, objectID, pos, quantity, comment) => void;
  createList: (name, listType, comment) => void;
  updateList: (name, listType, comment) => void;
  updateCommentWishlistItem: (listId, comment, index) => void;
  deleteList: (listId, type) => void;
  setActiveListIdWishlist: (listId) => void;
  onWishlistClose: () => void;
}

interface Props extends WishlistStateToProps, WishlistDispatchToProps {}

class WishlistFlyout extends React.PureComponent<Props, any> {
  constructor(props) {
    super(props);
    this.createList = this.createList.bind(this);
  }

  createList() {
    this.props.createList(dic("NEUE MERKLISTE"), "wishlist", "");
  }

  render() {
    return (
      <div className="wishlist flyout flyout--wishlist" aria-hidden="true">
        <div className="wishlist__header">
          <div className="wishlist__title">
            {dic("MERKLISTEN")}{" "}
            <span className="title__counter">{`(${this.props.lists.length})`}</span>
          </div>{" "}
          <span className="close--circle" onClick={this.props.onWishlistClose} />
        </div>
        <div className="wishlist__body">
          {this.renderWishlists()}
          <WishlistCreateList createList={this.props.createList} />
        </div>

        <div className="wishlist__footer">
          <input
            className="input--submit"
            type="submit"
            value={dic("NEUE MERKLISTE")}
            onClick={this.createList}
          />
        </div>
      </div>
    );
  }

  renderWishlists() {
    if (this.props.lists.length === 0) return null;

    return this.props.lists.map(wishlist => {
      return (
        <Wishlist
          key={wishlist._id}
          wishlist={wishlist}
          activeListId={this.props.activeListId}
          addProductFromListToCart={this.props.addProductFromListToCart}
          addProductsFromListToCart={this.props.addProductsFromListToCart}
          removeProduct={this.props.removeProduct}
          removeProductsFromList={this.props.removeProductsFromList}
          onInputTakeChangeWishlist={this.props.onInputTakeChangeWishlist}
          onBlurInputTakeWishlist={this.props.onBlurInputTakeWishlist}
          onClickTakeChangeWishlist={this.props.onClickTakeChangeWishlist}
          updateProduct={this.props.updateProduct}
          updateList={this.props.updateList}
          updateCommentWishlistItem={this.props.updateCommentWishlistItem}
          deleteList={this.props.deleteList}
          setActiveListId={this.props.setActiveListIdWishlist}
          user={this.props.user}
        />
      );
    });
  }
}

const mapStateToProps = (state: State): WishlistStateToProps => ({
  lists: state.wishlist.lists,
  user: state.user,
  activeListId: state.wishlist.activeListId,
});

const mapDispatchToProps = (dispatch): WishlistDispatchToProps => ({
  addProductFromListToCart: (listType, objectID, pos, comment, product, take) =>
    dispatch(ListActions.addProductFromListToCart(listType, objectID, pos, comment, product, take)),
  addProductsFromListToCart: listId => dispatch(ListActions.addProductsFromListToCart(listId)),
  removeProduct: (listId, objectId, pos) =>
    dispatch(ListActions.removeProduct(listId, objectId, pos)),
  removeProductsFromList: listId => dispatch(ListActions.removeProductsFromList(listId)),
  onClickTakeChangeWishlist: (direction, listId, objectID, pos, comment, index) =>
    dispatch(
      ListActions.onClickTakeChangeWishlist(direction, listId, objectID, pos, comment, index),
    ),
  onInputTakeChangeWishlist: (quantity, listId, index) =>
    dispatch(ListActions.onInputTakeChangeWishlist(quantity, listId, index)),
  onBlurInputTakeWishlist: (quantity, listId, objectID, pos, comment, index) =>
    dispatch(ListActions.onBlurInputTakeWishlist(quantity, listId, objectID, pos, comment, index)),
  updateProduct: (listId, objectID, pos, quantity, comment) =>
    dispatch(ListActions.updateProduct(listId, objectID, pos, quantity, comment)),
  createList: (name, listType, comment) =>
    dispatch(ListActions.createList(name, listType, comment)),
  updateList: (listId, name, comment) => dispatch(ListActions.updateList(listId, name, comment)),
  updateCommentWishlistItem: (listId, comment, index) =>
    dispatch(ListActions.updateCommentWishlistItem(listId, comment, index)),
  deleteList: (listId, type) => dispatch(ListActions.deleteList(listId, type)),
  setActiveListIdWishlist: listId => dispatch(ListActions.setActiveListIdWishlist(listId)),
  onWishlistClose: () => dispatch(LayoutActions.openWhishlist(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WishlistFlyout);
