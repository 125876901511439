import * as React from "react";

interface Props {
  label?: string;
  className?: string;
  type: SimpleButtonTypes;
  onClick: (e) => void;
  onSubmit: (e) => void;
}

class SimpleButton extends React.PureComponent<Props> {
  render() {
    let className = `input--${this.props.type}`;
    if (this.props.className && this.props.className.length > 0) {
      className = this.props.className;
    }
    return (
      <input
        className={className}
        type={this.props.type}
        value={this.props.label}
        onClick={this.props.onClick}
        onSubmit={this.props.onSubmit}
      />
    );
  }
}

export enum SimpleButtonTypes {
  RESET = "reset",
  SUBMIT = "submit",
}

export default SimpleButton;
