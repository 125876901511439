import * as React from "react";
import { Link } from "react-router-dom";

interface Props {
  name: string;
  description: string;
  img: any;
  href: any;
}

class ManufactureItem extends React.PureComponent<Props> {
  render() {
    let description = this.props.description;
    if (description.length > 100) {
      description = `${description.substring(0, 90)}...`;
    }
    return (
      <article className="grid__item">
        <Link to={this.props.href} className="grid-item__link">
          <div className="grid-item__img-box">
            <img
              className="grid-item__img"
              src={this.props.img}
              alt={this.props.name}
              title={this.props.name}
            />
          </div>
          <div className="grid-item__text-block">
            <h3 className="grid-item__title">{this.props.name}</h3>
            <p className="grid-item__text">{description}</p>
          </div>
        </Link>
      </article>
    );
  }
}

export default ManufactureItem;
