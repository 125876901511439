import * as React from "react";
import FilterTreeItem from "./FilterTreeItem";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import TreeItemVo from "../vo/TreeItemVo";
import { PanelDataVO } from "../../common/vo/PanelDataVo";

interface Props {
  data: PanelDataVO[];
}

class FilterTree extends React.PureComponent<Props> {
  render() {
    return (
      <div className="filterpanel__block filterpanel__block--treeview">
        <ul className="treeview__1-level ">
          {this.renderFilterTreeItem("hersteller")}
          {this.renderFilterTreeItem("funktion")}
        </ul>
      </div>
    );
  }

  renderFilterTreeItem(treeKey: string) {
    const treeItem: TreeItemVo | undefined = this.props.data[treeKey];
    if (!treeItem) {
      return;
    }
    const title = dic(this.props.data[treeKey].name.toUpperCase());
    return (
      <FilterTreeItem
        level={1}
        title={title}
        canHaveChild={true}
        removeAble={false}
        treeItem={treeItem}
      />
    );
  }
}

export default FilterTree;
