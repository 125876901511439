import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import { FoldingBoxState } from "../../State";
import ProductSection from "./ProductSection";

interface Props {
  data: ProductVo;
  foldingBoxes: FoldingBoxState[];
  onClickFoldingBoxTitle: (attributeSectionId: string) => void;
}

class ProductSectionList extends React.PureComponent<Props> {
  render() {
    return this.props.foldingBoxes.map((foldingBox, index) => {
      if (foldingBox.isVisible) {
        return (
          <ProductSection
            data={this.props.data}
            foldingBox={foldingBox}
            onClickFoldingBoxTitle={this.props.onClickFoldingBoxTitle}
            key={`${foldingBox.attributeSectionId}-${index}`}
          />
        );
      } else {
        return null;
      }
    });
  }
}

export default ProductSectionList;
