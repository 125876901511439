import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { OrderState, State, UserState } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { compose } from "redux";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { OrderActions } from "../actions/orderActions";
import { v1 as uuidv1 } from "uuid";
import AddressCard from "../../common/components/input/AddressCard";
import RadioBoxItemsDkoAblnr from "../../checkout/components/RadioBoxItemsDkoAblnr";
import { Link } from "react-router-dom";
import OrderProductList from "../components/OrderProductList";
import OrderStatus from "../components/OrderStatus";
import { createDatatransUrl } from "../../common/utils/createUrls";
import { ShippingOptionVo } from "../../common/vo/ShippingOptionVo";
import TransactionLogs from "../../common/components/list/TransactionLogs";
import OrderManageFunctions from "../components/OrderManageFunctions";

declare var COMPONA_STATIC_CACHE;
// import { OrderActions } from "../actions/orderActions";

interface OrderStateToProps {}

interface OrderDispatchToProps {
  onMount: () => void;
}

interface Props extends OrderStateToProps, OrderDispatchToProps {
  user: UserState;
  order: OrderState;
  match: any;
}

class Order extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.onMount();

    const orderId = this.props.match.params.orderId;
    const orderData = this.props.order.orders.find(order => order._id === orderId);
    if (!orderData) {
      ElstrCore.store.dispatch(OrderActions.getOrderData(orderId));
    }

    if (this.props.user.isAuth === false) {
      ElstrCore.store.dispatch(LayoutActions.openUser(true));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.isAuth === false && this.props.user.isAuth === true) {
      const orderId = this.props.match.params.orderId;
      ElstrCore.store.dispatch(OrderActions.getOrderData(orderId));
    }
  }

  renderPayNow(orderData) {
    if (
      orderData.paymentStatus &&
      orderData.paymentStatus > 0 &&
      orderData.paymentStatus !== 30 &&
      orderData.paymentStatus !== 50
    ) {
      const orderId = this.props.match.params.orderId;
      if ( orderData.hasOwnProperty("_sign") === false ) {
        // if order not payed and sign not given - relaod order data
        ElstrCore.store.dispatch(OrderActions.getOrderData(orderId));
      }
      const signAmount = Math.round(    orderData.totalPrice * 100  );
      const amount = signAmount.toString();
      return (
        <div className="row">
          <form className="float--right">
            <a
              href={createDatatransUrl(orderData._id, amount, orderData.dkoIsocode, orderData._sign)}
              className={"btn input"}
            >
              {dic("BEZAHLEN")}
            </a>
          </form>
        </div>
      );
    }
    return null;
  }

  render() {
    const orderId = this.props.match.params.orderId;
    const orderData = this.props.order.orders.find(order => order._id === orderId);

    const uuid = uuidv1();

    if (orderData) {
      const dkoLioAdr = orderData.deliveryAddress;
      const dkoFaoAdr = orderData.billingAddress;
      const sumOfProductsPrices = orderData.sumOfProductsPrices;
      const sum = sumOfProductsPrices + orderData.shippingCost;
      const mwst = sum * (orderData.vatRate / 100);

      const shippingOption: ShippingOptionVo = window.COMPONA_STATIC_CACHE.lieferarten.find(
        lieferart => lieferart.liefartCd === orderData.dkoLiefartCd,
      );

      return (
        <main className="main main--std" role="main">
          <div className="content">
            <h1 className="content__page-title">
              {dic("BESTELLUNG")}{" "}
              {orderData.dkoAufNr ? `#${orderData.dkoAufNr}` : `*${orderData._id}`}
            </h1>

            <div className="content__2col content--step">
              <div className="col">
                <h2 className="subtitle">{dic("LIEFERADRESSE")}</h2>
                <AddressCard address={dkoLioAdr} isEditable={false} isDisablable={false} />
              </div>
              <div className="col">
                <h2 className="subtitle">{dic("RECHNUNGSADRESSE")}</h2>
                <AddressCard address={dkoFaoAdr} isEditable={false} isDisablable={false} />
              </div>
            </div>

            <div className="content__2col content--step">
              <div className="col">
                <h2 className="subtitle">{dic("VERSAND")}</h2>
                <div className="radio-box">
                  <div className={`radio-box__item checked`}>
                    <div className="radiobutton">
                      <input
                        value={orderData.dkoLiefartCd}
                        checked={true}
                        className="input--radiobutton"
                        name="RadioBoxDkoLiefartCd"
                        type="radio"
                        aria-checked={true}
                        id={`input_dkoLiefartCd_${uuid}`}
                        readOnly={true}
                      />

                      <label htmlFor={`input_dkoLiefartCd_${uuid}`} className="radiobutton__label">
                        {shippingOption.description[activeLang()]}
                        <span className="sub-info">
                          {shippingOption.addDescription[activeLang()]}
                        </span>
                      </label>
                      <span className="box-radiobutton__right">{`${
                        orderData.dkoIsocode
                      } ${orderData.shippingCost.toFixed(4)}`}</span>
                    </div>
                  </div>
                </div>
                {orderData.accountNumber && orderData.accountNumber.length > 0 && (
                  <div className="form-input__wrapper">
                    <label className="label" htmlFor={`${uuid}_accountNumber`}>
                      {dic("ACCOUNT NR")}
                    </label>
                    <input
                      className="input--text"
                      value={orderData.accountNumber}
                      type="text"
                      id={`${uuid}_accountNumber`}
                      name="accountNumber"
                      placeholder={`${dic("ACCOUNT NR")}...`}
                      aria-required={true}
                      readOnly={true}
                    />
                  </div>
                )}
                {orderData.eoriNumber && orderData.eoriNumber.length > 0 && (
                  <div className="form-input__wrapper">
                    <label className="label" htmlFor={`${uuid}_eoriNumber`}>
                      {dic("EORI NUMMER")}
                    </label>
                    <input
                      className="input--text"
                      value={orderData.eoriNumber}
                      type="text"
                      id={`${uuid}_eoriNumber`}
                      name="eoriNumber"
                      placeholder={`${dic("EORI NUMMER")}...`}
                      aria-required={true}
                      readOnly={true}
                    />
                  </div>
                )}
              </div>
              <div className="col">
                <h2 className="subtitle">{dic("BEZAHLUNG")}</h2>
                <div className="radio-box">
                  <RadioBoxItemsDkoAblnr
                    dkoAblnr={orderData.dkoAblnr}
                    dkoAblnrOptions={["400", "410", "420"]}
                    showOnlyChecked={true}
                  />
                </div>
              </div>
            </div>

            <div className="row  content--step">
              <h2 className="subtitle">{dic("PRODUKTUEBERSICHT")}</h2>
              <OrderProductList
                listProducts={orderData.listProducts}
                dkoIsocode={orderData.dkoIsocode}
              />
            </div>

            <div className="content__2col content--step">
              <div className="col">
                <div className="form-input__wrapper">
                  <label className="label" htmlFor="dkoBestellBez">
                    {dic("KUNDENEIGENE BESTELLNUMMER")}
                  </label>
                  <input
                    className="input--text"
                    value={orderData.dkoBestellBez}
                    type="text"
                    id="dkoBestellBez"
                    name="dkoBestellBez"
                    placeholder={`${dic("KUNDENEIGENE BESTELLNUMMER")}...`}
                    aria-required={true}
                    readOnly={true}
                  />
                </div>
                <div className="form-input__wrapper">
                  <div className="label">{dic("VERSANDKOMMENTAR")}</div>
                  <textarea
                    rows={5}
                    className="textarea"
                    placeholder={`${dic("VERSANDKOMMENTAR")}...`}
                    value={orderData.shippingComment}
                    name="shippingComment"
                    readOnly={true}
                  />
                </div>
                <div className="form-input__wrapper form-input__wrapper--checkbox">
                  <div className="form-row">
                    <div className="checkbox">
                      <input
                        className="input--checkbox"
                        id="agbConfirmed"
                        name="agbConfirmed"
                        type="checkbox"
                        aria-checked="false"
                        checked={true}
                        readOnly={true}
                      />
                      <label htmlFor="agbConfirmed" className="checkbox__label">
                        <span className="checkbox__box" />
                        {dic("ICH HABE DIE")}{" "}
                        <Link
                          to={`/cms/${activeLang()}/${dic("CMSURL_AGB")}`}
                          className="text-link"
                        >
                          {dic("AGB")}
                        </Link>{" "}
                        {dic("GELESEN VERSTANDEN UND STIMME ZU")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <dl className="cart-footer__list">
                  <dt className="cart-footer__title">{dic("SUMME")}</dt>
                  <dd className="cart-footer__cont">{`${
                    orderData.dkoIsocode
                  } ${sumOfProductsPrices.toFixed(4)}`}</dd>
                  <dt className="cart-footer__title">{dic("VERSAND")}</dt>
                  <dd className="cart-footer__cont">{`${
                    orderData.dkoIsocode
                  } ${orderData.shippingCost.toFixed(4)}`}</dd>
                  <dt className="cart-footer__title price-subtotal">
                    {dic("ZWISCHENSUMME OHNE MWST")}
                  </dt>
                  <dd className="cart-footer__cont price-subtotal">{`${
                    orderData.dkoIsocode
                  } ${sum.toFixed(4)}`}</dd>
                  <dt className="cart-footer__title">{`${dic("MWST")} ${orderData.vatRate}%`}</dt>
                  <dd className="cart-footer__cont">{`${orderData.dkoIsocode} ${mwst.toFixed(
                    4,
                  )}`}</dd>
                  <dt className="cart-footer__title price-total">{dic("GESAMTBETRAG")}</dt>
                  <dd className="cart-footer__cont price-total">
                    {`${orderData.dkoIsocode} ${orderData.totalPrice.toFixed(4)}`}
                  </dd>
                </dl>

                <dl className="cart-footer__list">
                  <dt className="cart-footer__title">{dic("BESTELLUNG")}</dt>
                  <dd className="cart-footer__cont">
                    {orderData.dkoAufNr ? `#${orderData.dkoAufNr}` : `*${orderData._id}`}
                  </dd>
                  <dt className="cart-footer__title">{dic("DATUM")}</dt>
                  <dd className="cart-footer__cont">
                    {new Intl.DateTimeFormat().format(new Date(orderData.dkoBestellDatum))}
                  </dd>
                  <dt className="cart-footer__title">{dic("STATUS")}</dt>
                  <dd className="cart-footer__cont">
                    <OrderStatus order={orderData} />
                  </dd>
                </dl>

                {this.renderPayNow(orderData)}
              </div>
            </div>

            {this.props.user.isAuth &&
              this.props.user.isManager &&
              orderData.hasOwnProperty("erpTransactionLogs") &&
              orderData.erpTransactionLogs && (
                <TransactionLogs transactionLogs={orderData.erpTransactionLogs} />
              )}
            {this.props.user.isAuth && this.props.user.isManager && !orderData.dkoAufNr && (
              <OrderManageFunctions orderData={orderData} />
            )}
          </div>
        </main>
      );
    }
    return (
      <main className="main main--checkout" role="main">
        <div className="content">
          <h1 className="content__page-title">{dic("KEIN ZUGRIFF")}</h1>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: State): OrderStateToProps => ({
  user: state.user,
  order: state.order,
});

const mapDispatchToProps = (dispatch): OrderDispatchToProps => ({
  onMount: () => {
    dispatch(LayoutActions.showFilterPanel(false));
    dispatch(LayoutActions.showHomeHeader(false));
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Order);
