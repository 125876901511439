import * as React from "react";
import { ManageOrdersState } from "../../State";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ManageActions } from "../actions/manageActions";
import OrderList from "../../order/components/OrderList";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { routerActions } from "connected-react-router";
/* eslint-disable jsx-a11y/anchor-is-valid */

interface Props {
  manageOrders: ManageOrdersState;
}

class ManageOrders extends React.PureComponent<Props> {
  private readonly oderIdToOpen: any;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.oderIdToOpen = React.createRef();

    this.onSubmitFormOpenOrderId = this.onSubmitFormOpenOrderId.bind(this);
  }

  componentDidMount() {
    const manageOrdersData = this.props.manageOrders.data;

    if (!Array.isArray(manageOrdersData) || manageOrdersData.length === 0) {
      ElstrCore.store.dispatch(ManageActions.getOrdersForManager());
    }
  }

  componentDidUpdate(prevProps) {}

  onClickReload = e => {
    e.preventDefault();
    ElstrCore.store.dispatch(ManageActions.getOrdersForManager());
  };

  onClickNext = e => {
    e.preventDefault();
    ElstrCore.store.dispatch(ManageActions.getOrdersForManagerNext());
  };

  onClickPrevious = e => {
    e.preventDefault();
    ElstrCore.store.dispatch(ManageActions.getOrdersForManagerPrevious());
  };

  onSubmitFormOpenOrderId(e) {
    e.preventDefault();
    const oderIdToOpen = this.oderIdToOpen.current.value;
    console.log(oderIdToOpen);
    ElstrCore.store.dispatch(routerActions.push(`/shop/order/${oderIdToOpen}`));
  }

  render() {
    return (
      <div>
        <OrderList
          orders={this.props.manageOrders.data}
          isLoading={this.props.manageOrders.isLoading}
          showAllIds={true}
        />
        <div className="form-submit__wrapper form-row">
          <a href="#" className="btn input--reset" onClick={this.onClickReload}>
            {dic("LISTE NEU LADEN")}
          </a>
          {this.props.manageOrders.offset > 0 && (
            <a href="#" className="btn input--submit" onClick={this.onClickPrevious}>
              {`${dic("BESTELLUNGEN")} ${this.props.manageOrders.offset -
                this.props.manageOrders.limit}-${this.props.manageOrders.offset}`}
            </a>
          )}
          {this.props.manageOrders.data.length === this.props.manageOrders.limit && (
            <a href="#" className="btn input--submit" onClick={this.onClickNext}>
              {`${dic("BESTELLUNGEN")} ${this.props.manageOrders.offset +
                this.props.manageOrders.limit}-...`}
            </a>
          )}
        </div>

        <form onSubmit={this.onSubmitFormOpenOrderId}>
          <div className="form-row">
            <div className="form-input__wrapper">
              <label className="label" htmlFor="textfield3">
                Bestellung mit Id oder Referenz Nr. direkt öffnen
              </label>
              <input
                className="input--text"
                ref={this.oderIdToOpen}
                type="text"
                id="openOrderId"
                name="openOrderId"
                placeholder="Id oder Referenz Nr. der Bestellung (Bsp. 0be61d61e87e72f120474e4a1541cd2e)"
                required={true}
                aria-required={true}
              />
            </div>
            <div className="form-input__wrapper" />
          </div>
          <div className="wrapper form-row">
            <input type="submit" className="input--submit" value={dic("OEFFNEN")} />
          </div>
        </form>
      </div>
    );
  }
}

export default ManageOrders;
