import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import SimpleButton, { SimpleButtonTypes } from "../button/SimpleButton";

interface Props {
  placeHolder?: string;
  value: string;
  onChange: (e: any) => void;
  onSubmit: () => void;
  onBlur?: (e: any) => void;
}

export default class SearchInput extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit();
  }

  render() {
    const buttonLabel = dic("ERGEBNIS ANZEIGEN");
    return (
      <form>
        <input
          id="search-input"
          placeholder={this.props.placeHolder}
          type="text"
          className="input--search"
          role="searchbox"
          onFocus={this.props.onChange}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          value={this.props.value}
        />
        <SimpleButton
          className="btn"
          type={SimpleButtonTypes.SUBMIT}
          onClick={this.onSubmit}
          onSubmit={this.onSubmit}
          label={buttonLabel}
        />
      </form>
    );
  }
}
