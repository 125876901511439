import { TypeState } from "../../State";
import { CatalogActionsTypes } from "../actions/ActionTypes";
import { createImgUrl, createGroupUrl } from "../../common/utils/createUrls";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

function sortByOrderNo(items: any[]) {
  items.sort((a, b) => {
    if (Number(a.orderNo) > Number(b.orderNo)) {
      return 1;
    }
    if (Number(a.orderNo) < Number(b.orderNo)) {
      return -1;
    }
    return 0;
  });
}

const typeReducer = (state: TypeState, action) => {
  if (!state) return null;

  switch (action.type) {
    case CatalogActionsTypes.CATALOG_FUNCTION_TILES:
      var group = {};
      if (action.data.hasOwnProperty("funktion")) {
        // changed by gvo on 10.07.2018: group = action.data.funktion;
        const funktion = action.data.funktion;
        if ( funktion.elstrFiles === undefined ) {
          group = { descShort: funktion.descShort, descLong: funktion.descLong, img: "" };
        } else {
          group = {
            descShort: funktion.descShort,
            descLong: funktion.descLong,
            img: createImgUrl(funktion.elstrFiles[0], "992", "564", "0FFF", "png"),
          };
        }
      }

      var newData = [];
      let data = action.data.sub;
      if (data instanceof Object) {
        newData = data.map(item => {
          if ( item.elstrFiles === undefined ) {
            return null;
          }
          return {
            img: createImgUrl(item.elstrFiles[0], "992", "564", "0FFF", "png"),
            name: item.descShort,
            description: item.descLong,
            href: createGroupUrl(item, activeLang()),
            orderNo: item.orderNo,
          };
        });
      }
      sortByOrderNo(newData);

      return { ...state, data: newData, current: group };

    case CatalogActionsTypes.SHOW_ALL_TYPES:
      return { ...state, showAll: action.payLoad };
  }

  return state;
};

export default typeReducer;
