import { LayoutState } from "../../State";
import { LayoutActionsTypes } from "../actions/ActionTypes";

const layoutReducer = (state: LayoutState, action) => {
  if (!state) return null;

  // const reset = { langOpen: false, userOpen: false, wishlistOpen: false, cartOpen: false };
  const resetLeft: any = {};
  const resetRight = {
    wishlistOpen: false,
    cartOpen: false,
    userOpen: false,
    langOpen: false,
    currencyOpen: false,
    menuOpen: false,
  };

  switch (action.type) {
    case LayoutActionsTypes.LAYOUT_LOAD_FACETS:
      const facets = action.facets.facets;
      return { ...state, facets };
    case LayoutActionsTypes.LAYOUT_HOME_HEADER:
      return { ...state, ...resetLeft, homeHeader: action.open };
    case LayoutActionsTypes.LAYOUT_FILTER_SHOW:
      return { ...state, ...resetLeft, filterPanelVisible: action.open };
    case LayoutActionsTypes.LAYOUT_FILTER_OPEN:
      return { ...state, ...resetLeft, filterOpen: action.open };
    case LayoutActionsTypes.LAYOUT_SEARCH_OPEN:
      return { ...state, ...resetLeft, searchOpen: action.open };
    case LayoutActionsTypes.LAYOUT_WISHLIST_OPEN:
      return { ...state, ...resetRight, wishlistOpen: action.open };
    case LayoutActionsTypes.LAYOUT_CART_OPEN:
      return { ...state, ...resetRight, cartOpen: action.open };
    case LayoutActionsTypes.LAYOUT_CART_CLOSE_EXPICITLY:
      return { ...state, ...resetRight, cartExplicitlyClosed: action.open };
    case LayoutActionsTypes.LAYOUT_MENU_OPEN:
      return { ...state, ...resetRight, menuOpen: !state.menuOpen };
    case LayoutActionsTypes.LAYOUT_USER_OPEN:
      return { ...state, ...resetRight, userOpen: action.open };
    case LayoutActionsTypes.LAYOUT_USER_LOGIN:
      return { ...state, ...resetRight, userOpen: action.open };
    case LayoutActionsTypes.LAYOUT_LANG_OPEN:
      return { ...state, ...resetRight, langOpen: action.open };
    case LayoutActionsTypes.LAYOUT_CURRENCY_OPEN:
      return { ...state, ...resetRight, currencyOpen: action.open };
    case LayoutActionsTypes.LAYOUT_COOKIES_ACCEPT:
      return { ...state, cookiesAccepted: action.accept };
    case LayoutActionsTypes.LAYOUT_SCROLL_STATE:
      return { ...state, scrollState: action.scrollState };
  }

  return state;
};

export default layoutReducer;
