import { FilterPanelActionsTypes } from "../actions/ActionTypes";
import { PanelDataVO, PanelItemRenderTypes, PanelItemVo } from "../../common/vo/PanelDataVo";
import { getUnitSymbol } from "../../common/utils/unitUtils";
import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import {
  getAttributeDescription,
  getAttributeLongDescription,
} from "../../common/utils/attributeUtils";
import { SearchState } from "../../State";
import AttributeVo from "../../common/vo/AttributeVo";

/**
 * Convert Algolia Result in FilterPanel UI Data
 * @param {ProductFilterState} state
 * @param action
 * @returns {any}
 */
const filterPanelReducer = (state: PanelDataVO[], action) => {
  switch (action.type) {
    case FilterPanelActionsTypes.FILTER_LOAD_FACETS:
      const attributes: AttributeVo[] = action.attributes;
      const facets = action.facets;
      const allFacets: any[] = action.allFacets;
      const searchQuery: SearchState = action.searchQuery;
      const displayData: PanelDataVO[] = [];

      /*
      const attCount = Object.keys(attributes).length;
      const facCount = Object.keys(facets).length;
      const allFacCount = Object.keys(allFacets).length;
      console.log("attr: " + attCount + " fac: " + facCount + " allFac: " + allFacCount);
      */

      for (const siFacet in allFacets) {
        let singleFacet = siFacet;
        const orgName = siFacet;

        if (siFacet.indexOf(".min") > -1) {
          singleFacet = siFacet.split(".")[0];
        } else if (siFacet.indexOf(".max") > -1) {
          singleFacet = siFacet.split(".")[0];
        }

        if (
          attributes[singleFacet] &&
          attributes[singleFacet].description &&
          attributes[singleFacet].isFilterable
        ) {
          const activeAttribute = attributes[singleFacet];
          singleFacet = orgName;

          let unit = "";
          if (activeAttribute.unitOfMeasureId !== "") {
            unit = getUnitSymbol(activeAttribute.unitOfMeasureId, activeLang());
          }

          const itemsData: PanelItemVo[] = [];

          const valueArray = allFacets[singleFacet];
          for (const valueId in valueArray) {
            let combinedValue = valueId;
            if (unit !== "") {
              combinedValue = combinedValue + " " + unit;
            }
            const data = {
              id: valueId,
              name: combinedValue,
              count: allFacets[singleFacet][valueId],
              checked: false,
            };

            const filterSetting: string[] | string = searchQuery.filterSettings[singleFacet];
            if (
              filterSetting &&
              filterSetting.find(item => {
                return valueId === item;
              })
            ) {
              data.checked = true;
            }

            if (facets[singleFacet] && facets[singleFacet][valueId]) {
              data.count = facets[singleFacet][valueId];
            } else {
              data.count = 0;
            }
            itemsData.push(data);
          }

          let open = false;
          if (state) {
            const found = state.filter(item => item.id === activeAttribute.dataAttributeKey);
            if (found[0]) {
              open = found[0].open;
            }
          }

          let type = PanelItemRenderTypes.CHECKBOX;

          if (activeAttribute.dataType === "integer" || activeAttribute.dataType === "float") {
            if (itemsData.length > 7) {
              type = PanelItemRenderTypes.SLIDER;
            } else {
              type = PanelItemRenderTypes.CHECKBOX;
            }
          } else {
            if (itemsData.length > 7) {
              type = PanelItemRenderTypes.AUTOCOMPLETE;
            } else {
              type = PanelItemRenderTypes.CHECKBOX;
            }
          }

          // Slider Ext
          if (activeAttribute.isRange) type = PanelItemRenderTypes.SLIDER;

          const dt = displayData.find(item => activeAttribute.dataAttributeKey === item.id);
          if (!dt) {
            //var dataType = false;
            let dataType = "";
            if (attributes[singleFacet]) {
              dataType = attributes[singleFacet]["dataType"];
            }

            // sort suggestions according to dataType
            switch (dataType) {
              case "float":
                itemsData.sort(function(a, b) {
                  return Number(a.id) - Number(b.id);
                });
                break;

              case "boolean":
                itemsData.sort(function(a, b) {
                  return Number(b.id) - Number(a.id);
                });
                break;

              default:
                itemsData.sort(sortAlphaNum);
            }

            displayData.push({
              id: activeAttribute.dataAttributeKey,
              title: getAttributeDescription(activeAttribute.dataAttributeKey, activeLang()),
              description: getAttributeLongDescription(
                activeAttribute.dataAttributeKey,
                activeLang(),
              ),
              type,
              dataType,
              open,
              collapsible: true,
              items: itemsData,
              sortOrderDefault: activeAttribute.sortOrderDefault,
              unit: unit,
            });
          } else {
            dt.items = [...dt.items, ...itemsData];
            dt.items.sort(sortAlphaNum);
          }
        }
      }

      displayData.sort(function(a, b) {
        return a.sortOrderDefault - b.sortOrderDefault;
      });

      return displayData;

    case FilterPanelActionsTypes.SET_ITEM_VALUE:
      if (!state) return state;
      const panelData = state.map(item => {
        if (item.title !== action.data.block) return item;

        const items = item.items.map(subItem => {
          if (subItem.name === action.data.item.name) {
            subItem.checked = action.data.value;
          }
          return subItem;
        });

        return { ...item, items };
      });
      return { ...state, panelData };

    case FilterPanelActionsTypes.TOGGLE_FILTER_BLOCK:
      if (!state) return state;

      const nPanelData = state.map(item => {
        if (item.title === action.data.item) {
          return { ...item, open: action.data.open };
        }
        return item;
      });

      return nPanelData;
  }
  if (!state) return null;
  return state;
};

function sortAlphaNum(a, b) {
  let reA = /[^a-zA-Z]/g;
  let reN = /[^0-9]/g;
  let AInt = parseInt(a.name, 10);
  let BInt = parseInt(b.name, 10);
  if (isNaN(AInt) && isNaN(BInt)) {
    let aA = a.name.replace(reA, "");
    let bA = b.name.replace(reA, "");
    if (aA === bA) {
      let aN = parseInt(a.name.replace(reN, ""), 10);
      let bN = parseInt(b.name.replace(reN, ""), 10);
      //alert("in if " + aN + " : " + bN);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  } else if (isNaN(AInt)) {
    //A is not an Int
    return 1; //to make alphanumeric sort first return 1 here
  } else if (isNaN(BInt)) {
    //B is not an Int
    return -1; //to make alphanumeric sort first return -1 here
  } else if (AInt === BInt) {
    let aA = a.name.replace(reA, "");
    let bA = b.name.replace(reA, "");
    return aA > bA ? 1 : -1;
  } else {
    return AInt > BInt ? 1 : -1;
  }
}

export default filterPanelReducer;
