import * as React from "react";
import { Link } from "react-router-dom";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { CheckoutActions } from "../actions/checkoutActions";
import { routerActions } from "connected-react-router";

interface Props {
  step: string;
  history: any;
  isLoading: boolean;
  allowToProceed: boolean;
}

class CheckoutSubmit extends React.PureComponent<Props> {
  onClickBack(e) {
    e.preventDefault();
    window.history.back();
  }

  onClickProceedToOptions = e => {
    ElstrCore.store.dispatch(CheckoutActions.validateProceedToOptions(e));
  };

  onClickProceedToOverview = e => {
    ElstrCore.store.dispatch(CheckoutActions.validateProceedToOverview(e));
  };

  onClickProceedToOrdered = e => {
    // Clicked KOSTENPFLICHTIG BESTELLEN
    ElstrCore.store.dispatch(CheckoutActions.validateProceedToOrderedAndProcessOrder(e));
  };

  onClickProceedToHome = e => {
    ElstrCore.store.dispatch(routerActions.push("/home"));
  };

  render() {
    const backButton = {
      textId: "ZURUECK",
      onClick: this.onClickBack,
    };
    const proceedButton = {
      linkTo: "/shop/checkout/options",
      textId: "WEITER",
      onClick: this.onClickProceedToOptions,
    };
    if (this.props.step === "options") {
      proceedButton.linkTo = "/shop/checkout/overview";
      proceedButton.onClick = this.onClickProceedToOverview;
    }
    if (this.props.step === "overview") {
      proceedButton.linkTo = "/shop/checkout/ordered";
      proceedButton.textId = "KOSTENPFLICHTIG BESTELLEN";
      proceedButton.onClick = this.onClickProceedToOrdered;
    }
    if (this.props.step === "ordered") {
      proceedButton.linkTo = `/catalog/${activeLang()}/`;
      proceedButton.textId = "ZUM KATALOG UND SHOP";
      proceedButton.onClick = this.onClickProceedToHome;
    }
    if (this.props.isLoading) {
      return (
        <div className="row checkout__submit">
          <div className="loading" />
        </div>
      );
    } else {
      return (
        <div className="row checkout__submit">
          {this.props.step !== "ordered" && (
            <a href="anmelden.html" onClick={backButton.onClick} className="btn input--reset" title={dic(backButton.textId)}>
              {dic(backButton.textId)}
            </a>
          )}
          {this.props.allowToProceed === true && (
            <form className="float--right">
              <Link
                to={proceedButton.linkTo}
                className={"btn input"}
                onClick={proceedButton.onClick}
              >
                {dic(proceedButton.textId)}
              </Link>
            </form>
          )}
        </div>
      );
    }
  }
}

export default CheckoutSubmit;
