import userReducer from "./userReducer";
import elstrUserReducer from "elstr-jslib-4/dist/reducers/elstrUserReducer";

/**
 * Combine the reducers since they share the same state of the store (store.user)
 * https://stackoverflow.com/a/32675900
 * @param reducers
 * @return {(previous, current) => any}
 */
function reduceReducers(...reducers) {
  return (previous, current) => reducers.reduce((p, r) => r(p, current), previous);
}

export const userReducers = {
  user: reduceReducers(userReducer, elstrUserReducer),
};
