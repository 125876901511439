export enum ProductActionTypes {
  SET_PRODUCT_DATA = "SET_PRODUCT_DATA",
  SET_RELATED_TAB_DATA = "SET_RELATED_TAB_DATA",
  SET_RELATED_TAB_ACTIVE_INDEX = "SET_RELATED_TAB_ACTIVE_INDEX",
  OPEN_CLOSE_FOLDING_BOX = "OPEN_CLOSE_FOLDING_BOX",
  TAKE_COUNT_CLICK_CHANGE = "TAKE_COUNT_CLICK_CHANGE",
  TAKE_COUNT_INPUT_CHANGE = "TAKE_COUNT_INPUT_CHANGE",
  SET_PRODUCT_LINK_ALTERNATES = "SET_PRODUCT_LINK_ALTERNATES",
  SET_PRODUCT_FILES_MSG = "SET_PRODUCT_FILES_MSG",
  SET_RELATED_SORTING = "SET_RELATED_SORTING",
}
