export enum FilterPanelActionsTypes {
  FILTER_LOAD_FACETS = "FILTER_LOAD_FACETS",
  LOAD_CLASSIFICATIONS = "LOAD_CLASSIFICATIONS",
  SET_ITEM_VALUE = "SET_ITEM_VALUE",
  TOGGLE_FILTER_BLOCK = "TOGGLE_FILTER_BLOCK",
  CONVERT_DATA_TO_URL_HASH = "CONVERT_DATA_TO_URL_HASH",
  CONVERT_URL_HASH_TO_DATA = "CONVERT_URL_HASH_TO_DATA",

  CREATE_TREE_DATA = "CREATE_TREE_DATA",
}
