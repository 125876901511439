
export const getCountryCode = (setCountryCode: (geolocationData: { country_code:string }) => void)=> {
  //todo: extract api_key and use dedicated one from compona, this is our (intelliacts) key for testing purposes
  const url = "https://ipgeolocation.abstractapi.com/v1/?api_key=d938413065ad446e9271794e4efe7d0c&ip_address=";

  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(data => {
      setCountryCode(JSON.parse(data));
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
}
