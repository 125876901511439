import * as React from "react";
import { AdrVo } from "../../../State";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { v1 as uuidv1 } from "uuid";

interface Props {
  address: AdrVo;
  isEditable: boolean;
  isDisablable: boolean;
  displayInr?: boolean;
  styleEditable?: boolean;
  onClickEdit?: (inr) => void;
  onClickDisable?: (inr) => void;
}

class AddressCard extends React.PureComponent<Props> {
  onClickEdit = e => {
    e.preventDefault();
    if (this.props.onClickEdit) {
      this.props.onClickEdit(this.props.address.inr);
    }
  };

  onClickDisable = e => {
    e.preventDefault();
    if (this.props.onClickDisable) {
      this.props.onClickDisable(this.props.address.inr);
    }
  };

  renderZeilen(zeilenStringParts, cardId) {
    return zeilenStringParts.map((zeile, index) => {
      return (
        <React.Fragment key={`address_zeile_${index}_${cardId}`}>
          {zeile}
          <br />
        </React.Fragment>
      );
    });
  }

  renderEmail(email, cardId) {
    if (email && email.length > 0) {
      return (
        <React.Fragment key={`address_email_${cardId}`}>
          <dt className="dllist__label">{dic("EMAIL")}:</dt>
          <dd className="dllist__cont">{email}</dd>
        </React.Fragment>
      );
    }
  }

  renderTel(tel, cardId) {
    if (tel && tel.length > 0) {
      return (
        <React.Fragment key={`address_tel_${cardId}`}>
          <dt className="dllist__label">{dic("TELEFON")}:</dt>
          <dd className="dllist__cont">{tel}</dd>
        </React.Fragment>
      );
    }
  }

  render() {
    const cardId = uuidv1();
    const nameStringParts: string[] = [];
    const zeilenStringParts: string[] = [];
    const ortStringParts: string[] = [];
    const address = this.props.address;

    if (address.name && address.name.length) {
      nameStringParts.push(address.name);
    }
    if (address.vorname && address.vorname.length) {
      nameStringParts.push(address.vorname);
    }
    if (address.zeile2 && address.zeile2.length) {
      zeilenStringParts.push(address.zeile2);
    }
    if (address.zeile3 && address.zeile3.length) {
      zeilenStringParts.push(address.zeile3);
    }
    if (address.zeile4 && address.zeile4.length) {
      zeilenStringParts.push(address.zeile4);
    }
    if (address.land && address.land.length) {
      ortStringParts.push(address.land);
    }
    if (address.plz && address.plz.length) {
      ortStringParts.push(address.plz);
    }
    if (address.ort && address.ort.length) {
      ortStringParts.push(address.ort);
    }
    zeilenStringParts.push(ortStringParts.join(" "));

    let typeContText = "";
    if (address.type === "org") {
      typeContText = dic("HAUPTADRESSE");
    }
    if (address.type === "delivery") {
      typeContText = dic("LIEFERADRESSE");
    }
    if (address.type === "invoice") {
      typeContText = dic("RECHNUNGSADRESSE");
    }

    return (
      <div
        className={`address-box${
          this.props.isEditable || this.props.styleEditable ? " editable" : ""
        }`}
      >
        {this.props.isDisablable && (
          <div onClick={this.onClickDisable} className="address__delete" />
        )}
        {this.props.isEditable && (
          <div onClick={this.onClickEdit} className="address__edit" />
        )}
        {typeContText.length > 0 && (
          <dl className="dllist">
            <dt className="dllist__label">{dic("TYP")}</dt>
            <dd className="dllist__cont">{typeContText}</dd>
          </dl>
        )}
        <dl className="dllist">
          {address.zeile1 && address.zeile1.length && (
              <>
                <dt className="dllist__label">{dic("FIRMA")}</dt>
                <dd className="dllist__cont">{address.zeile1}</dd>
              </>
          )}
          <dt className="dllist__label">{dic("NAME")}</dt>
          <dd className="dllist__cont">{nameStringParts.join(" ")}</dd>
          <dt className="dllist__label">{dic("ADRESSE")}:</dt>
          <dd className="dllist__cont">{this.renderZeilen(zeilenStringParts, cardId)}</dd>
        </dl>
        <dl className="dllist">
          {this.renderEmail(address.email, cardId)}
          {this.renderTel(address.tel, cardId)}
        </dl>
        {this.props.displayInr && this.props.address.inr && this.props.address.inr.length && (
          <dl className="dllist">
            <dt className="dllist__label">{dic("INR")}:</dt>
            <dd className="dllist__cont">{this.props.address.inr}</dd>
          </dl>
        )}
      </div>
    );
  }
}

export default AddressCard;
