import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import ClassificationVo from "../../filterPanel/vo/ClassificationVo";
import { CatalogActionsTypes } from "./ActionTypes";

declare var COMPONA_STATIC_CACHE;

export const CatalogAlgoliaActions = {
  getClassifications: function(parentId: string) {
    const allClassifications: ClassificationVo[] =
      COMPONA_STATIC_CACHE.allClassifications[activeLang()];
    const filteredClassifications: ClassificationVo[] = allClassifications.filter(
      classifcation => classifcation.parentId === parentId,
    );
    return filteredClassifications;
  },

  getClassification: function(objectId: string) {
    const allClassifications: ClassificationVo[] =
      COMPONA_STATIC_CACHE.allClassifications[activeLang()];
    const filteredClassifications = allClassifications.filter(
      classifcation => classifcation.objectID === objectId,
    );
    return filteredClassifications;
  },

  /**
   * Get Manufactors and Tools data
   * @returns {(dispatch) => Promise<void>}
   */
  getManufacturersAndFunctions: function() {
    return async (dispatch, getState) => {
      let group = getState().search.group;
      let manufacturerId = "";
      if (typeof group === "object") {
        const curArray = group["bredCrumbsId"].split(" > ");
        manufacturerId = curArray[1];
      }

      let funk: any = null;
      let manu: any = null;
      let subfunk: any = null;
      let submanu: any = null;
      let manuData: any = null;

      if (group instanceof Object) {
        if (group.hasOwnProperty("classificationId")) {
          if (group["classificationId"] === "hersteller") {
            funk = CatalogAlgoliaActions.getClassifications("funktion");
            submanu = CatalogAlgoliaActions.getClassifications(group.objectID);
            if (group.objectID !== manufacturerId) {
              group = CatalogAlgoliaActions.getClassification(group.objectID);
              manu = CatalogAlgoliaActions.getClassification(manufacturerId);
              manuData = {
                hersteller: manu[0],
                group: group[0],
                sub: submanu,
              };
            } else {
              //group = await AlgoliaServices.getManufacturer(activeLang(), group.objectID);
              group = CatalogAlgoliaActions.getClassification(group.objectID);
              manuData = { hersteller: group[0], sub: submanu };
            }
            dispatch(CatalogAlgoliaActions.createFunctionTiles({ funktion: funk }));
            dispatch(CatalogAlgoliaActions.createManufacturerTiles(manuData));
          } else if (group["classificationId"] === "funktion") {
            subfunk = CatalogAlgoliaActions.getClassifications(group.objectID);
            manu = CatalogAlgoliaActions.getClassifications("hersteller");
            group = CatalogAlgoliaActions.getClassification(group.objectID);
            const funkData = { funktion: group[0], sub: subfunk };
            dispatch(CatalogAlgoliaActions.createFunctionTiles(funkData));
            dispatch(CatalogAlgoliaActions.createManufacturerTiles({ hersteller: manu }));
          }
        }
      }

      if (funk === null && manu === null) {
        funk = CatalogAlgoliaActions.getClassifications("funktion");
        manu = CatalogAlgoliaActions.getClassifications("hersteller");
        dispatch(CatalogAlgoliaActions.createFunctionTiles({ sub: funk }));
        dispatch(CatalogAlgoliaActions.createManufacturerTiles({ sub: manu }));
      }
    };
  },

  createFunctionTiles: function(data: any) {
    return {
      type: CatalogActionsTypes.CATALOG_FUNCTION_TILES,
      data,
    };
  },

  createManufacturerTiles: function(data: any) {
    return {
      type: CatalogActionsTypes.CATALOG_MANUFACTURES_TILES,
      data,
    };
  }
}
