import * as React from "react";

interface Props {
  label: string;
  count: number;
}

class CatalogSubtitle extends React.PureComponent<Props> {
  render() {
    return (
      <h2 className="catalog__subtitle">
        {this.props.label} <span className="title__counter">({this.props.count})</span>
      </h2>
    );
  }
}

export default CatalogSubtitle;
