import { OrderState } from "../../State";
import { OrderActionTypes } from "../actions/ActionTypes";
// import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";

const orderReducer = (state: OrderState, action) => {
  if (!state) return null;

  switch (action.type) {
    case OrderActionTypes.SET_ORDER_DATA:
      // console.log(action.payload);
      return {
        ...state,
        ...action.payload.data,
      };
  }

  return state;
};

export default orderReducer;
