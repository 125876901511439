import * as React from "react";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { KeyEvent, ListType } from "../../common/constants/Constants";

interface Props {
  createList: (name, listType, comment) => void;
}

class WishlistAddNew extends React.PureComponent<Props> {
  private readonly listName: any;

  constructor(props) {
    super(props);
    this.listName = React.createRef();
    this.createList = this.createList.bind(this);
    this.checkInput = this.checkInput.bind(this);
  }

  createList(e) {
    e.preventDefault();

    // overwrite listName if it's an empty string
    const listName =
      this.listName.current.value === "" ? dic("NEUE MERKLISTE") : this.listName.current.value;

    this.props.createList(listName, ListType.wishlist, "");

    // reset input field
    this.listName.current.value = "";
  }

  checkInput(e) {
    if (e.keyCode === KeyEvent.DOM_VK_RETURN) {
      this.createList(e);
    }
  }

  render() {
    return (
      <div className="wishlist__block wishlist-entry">
        <div className="title">{dic("NEUE MERKLISTE")}</div>
        <div className="entry__input">
          <input
            onKeyDown={this.checkInput}
            ref={this.listName}
            type="text"
            className="input--text"
          />
          <div onClick={this.createList} className="product__icon-save" />
        </div>
      </div>
    );
  }
}

export default WishlistAddNew;
