import * as React from "react";
import { createImgUrl } from "../../utils/createUrls";

interface Props {
  data: any;
  w: string;
  h: string;
  className?: string;
  alt?: string;
  title?: string;
}

class Image extends React.PureComponent<Props> {
  render() {
    return (
      <img
        className={this.props.className}
        src={createImgUrl(this.props.data, this.props.w, this.props.h)}
        alt={this.props.alt}
        title={this.props.title}
      />
    );
  }
}

export default Image;
