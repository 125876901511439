import * as React from "react";
import TransactionLogsVo from "../../vo/TransactionLogsVo";

interface Props {
  transactionLogs: TransactionLogsVo[];
}

class TransactionLogs extends React.PureComponent<Props> {
  render() {
    if (this.props.transactionLogs.length > 0) {
      return (
        <div className="row">
          <h2 className="subtitle">Transaction Log</h2>
          {this.props.transactionLogs.map((transactionLog: TransactionLogsVo, index) => {
            return (
              <div className="transaction-log" key={transactionLog._id}>
                <h3 className="smltitle">{transactionLog._insertDate}</h3>
                <label>Request Body</label>
                <pre>{transactionLog.requestBody}</pre>
                <label>Response Body</label>
                <pre>{transactionLog.responseBody}</pre>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="row">
          <h2 className="subtitle">No Transaction Log</h2>
        </div>
      );
    }
  }
}

export default TransactionLogs;
