import * as React from "react";
import { Link } from "react-router-dom";
import ProductVo from "../../common/vo/ProductVo";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import ProductHeaderTagParentParts from "../components/ProductHeaderTagParentParts";
import { createGroupUrl } from "../../common/utils/createUrls";

declare var COMPONA_STATIC_CACHE;

interface Props {
  data: ProductVo;
}

class ProductHeaderTags extends React.PureComponent<Props> {
  renderTags(dataAttributeKey) {
    if (
      COMPONA_STATIC_CACHE.allClassifications &&
      COMPONA_STATIC_CACHE.allClassifications[activeLang()]
    ) {
      const allClassifications = window.COMPONA_STATIC_CACHE.allClassifications[activeLang()];
      if (this.props.data[dataAttributeKey]) {
        return this.props.data[dataAttributeKey].map((groupId, index) => {
          const groupObject = allClassifications.find(
            classification => classification.objectID === groupId,
          );
          if (groupObject && groupObject.parentIds) {
            return (
              <p className="product-tag tag--tree" key={`product-tag-${groupId}`}>
                {dic(dataAttributeKey.toUpperCase())}:{"\u00A0"}
                <ProductHeaderTagParentParts
                  parentIds={groupObject.parentIds}
                  allClassifications={allClassifications}
                />
                <Link to={createGroupUrl(groupObject, activeLang())} className="tag-tree__item">
                  {groupObject.descShort}
                </Link>
              </p>
            );
          }
          return null;
        });
      }
    }
  }

  render() {
    return (
      <div className="product-header__tag">
        {this.renderTags("hersteller")}
        {this.renderTags("funktion")}
      </div>
    );
  }
}

export default ProductHeaderTags;
