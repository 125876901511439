import * as React from "react";
import ManufactureItem from "./ManufactureItem";
import CatalogSubtitle from "./CatalogSubtitle";
import ManufactureVo from "../../common/vo/ManufactureVo";
import { Link } from "react-router-dom";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
//import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  title: string;
  showAllItems?: boolean;
  showLessLink?: boolean;
  data: ManufactureVo[];
  cls: string;
}

class Manufacture extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showAllItems: false,
    showLessLink: true,
  };

  render() {
    const cls = this.props.cls + " grids";
    return (
      <>
        <CatalogSubtitle label={this.props.title} count={this.props.data.length} />

        <div className={cls}>{this.renderItems()}</div>

        {this.renderGreyBox()}
      </>
    );
  }

  renderGreyBox() {
    if (this.props.data.length <= 6) return undefined;

    if (this.props.showAllItems) {
      if (this.props.showLessLink) {
        return (
          <div className="grey-box">
            <Link className="btn btn--arrow" to={`/catalog/${activeLang()}`}>{`${dic(
              "WENIGER ANZEIGEN",
            )}`}</Link>
          </div>
        );
      } else {
        return;
      }
    } else {
      return (
        <div className="grey-box">
          <Link className="btn btn--arrow" to={`/catalog/${activeLang()}/m`}>{`${dic(
            "MEHR ANZEIGEN",
          )}`}</Link>
        </div>
      );
    }
  }

  renderItems() {
    const topManufacturer = config("topManufacturer");

    if (this.props.data instanceof Object) {
      if (Object.keys(this.props.data).length > 0) {
        return this.props.data.map((item: ManufactureVo) => {
          if (!this.props.showAllItems) {
            var isTop = false;
            Object.keys(topManufacturer).forEach(function(key) {
              let urlArray = item.href.split("/");
              let objID = urlArray[3];
              if (topManufacturer[key] === objID) {
                isTop = true;
              }
            });
            if (isTop) {
              return (
                <ManufactureItem
                  name={item.name}
                  description={item.description}
                  img={item.img}
                  href={item.href}
                  key={item.name}
                />
              );
            }
          } else {
            return (
              <ManufactureItem
                name={item.name}
                description={item.description}
                img={item.img}
                href={item.href}
                key={item.name}
              />
            );
          }
          return false;
        });
      }
    }
  }
}

export default Manufacture;
