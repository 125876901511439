import { ProductListState } from "../../State";
import { CatalogActionsTypes } from "../actions/ActionTypes";

declare var COMPONA_STATIC_CACHE;

const productListReducer = (state: ProductListState, action) => {
  if (!state) return null;

  switch (action.type) {
    case CatalogActionsTypes.NEW_COL_AUTO_SELECTED:
      const nArr = state.columnsViewSettings.slice();
      const newCols = state.columnsAvaible.filter(item => item.dataAttributeKey === action.payLoad);
      if (newCols.length === 1) {
        nArr.push(newCols[0]);
      }
      // nArr.push({name: action.payLoad, value: "LongDescription", sorted: 0});
      // nArr.push(action.payLoad);
      return { ...state, columnsViewSettings: nArr, newAutoValue: "" };

    case CatalogActionsTypes.NEW_COL_AUTO_CHANGE:
      return { ...state, newAutoValue: action.payLoad };

    case CatalogActionsTypes.TOGGLE_PRODUCT_VIEW_SETTINGS_BOX:
      return { ...state, viewSettingsOpen: action.payLoad };

    case CatalogActionsTypes.CHANGE_DRAG_DROP_HANDLER:
      const items = reorder(
        state.columnsViewSettings,
        action.payLoad.source.index,
        action.payLoad.destination.index,
      );

      return { ...state, columnsViewSettings: items };

    case CatalogActionsTypes.SAVE_VIEW_SETTINGS_COLUMNS:
      const columns = JSON.parse(JSON.stringify(state.columnsViewSettings)).slice(0);
      return {
        ...state,
        columns,
      };

    case CatalogActionsTypes.RESET_VIEW_SETTINGS_COLUMNS:
      const staticCacheAttributes = window.COMPONA_STATIC_CACHE.attributes;
      const columnsViewSettings: any[] = [];
      for (const dataAttributeKey in staticCacheAttributes) {
        const attribute = staticCacheAttributes[dataAttributeKey];
        if (attribute.listDisplayAllowed && attribute.listDisplayDefault) {
          columnsViewSettings.push(attribute);
        }
      }

      columnsViewSettings.sort(function(a, b) {
        if (a.listOrderDefault > b.listOrderDefault) {
          return 1;
        }
        if (a.listOrderDefault < b.listOrderDefault) {
          return -1;
        }
        return 0;
      });

      return {
        ...state,
        columnsViewSettings,
        columns: columnsViewSettings,
      };

    case CatalogActionsTypes.REMOVE_VIEW_SETTINGS_COLUMN:
      return {
        ...state,
        columnsViewSettings: state.columnsViewSettings.filter(item => item.dataAttributeKey !== action.payLoad),
      };
  }

  return state;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default productListReducer;
