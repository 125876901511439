import * as React from "react";
import { Link } from "react-router-dom";

import { activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { createGroupUrl } from "../../common/utils/createUrls";

interface Props {
  parentIds: string[];
  allClassifications: any[];
}

class ProductHeaderTagParentParts extends React.PureComponent<Props> {
  render() {
    if (this.props.parentIds) {
      return this.props.parentIds
        .map((groupId, index) => {
          const groupObject = this.props.allClassifications.find(
            classification => classification.objectID === groupId,
          );
          return (
            <Link
              to={createGroupUrl(groupObject, activeLang())}
              className="tag-tree__item"
              key={`tag-tree-item-${groupId}`}
            >
              {groupObject.descShort}
            </Link>
          );
        })
        .reverse();
    }
  }
}

export default ProductHeaderTagParentParts;
