import * as React from "react";
import ProductVo from "../../vo/ProductVo";
import { ProductTableColumnVo } from "./ProductTable";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { Link } from "react-router-dom";
import Image from "../../../common/components/media/Image";
import { createProductUrl } from "../../utils/createUrls";
import { getFullText } from "../../utils/textAttributeDataType";
import { getAttributeDescription } from "../../utils/attributeUtils";
import AttributeVo from "../../vo/AttributeVo";
import { ListType } from "../../constants/Constants";
import { productCanBeAddedToCart, productGetTitleString } from "../../utils/productUtils";

// declare var COMPONA_STATIC_CACHE;

interface Props {
  data: ProductVo;
  columns: ProductTableColumnVo[];
  priceScaleArray: any[];
  onAddCartClick?: () => void;
  onAddWishClick?: () => void;
  onPlusClick?: () => void;
  onClickProductLink: (product: ProductVo) => void;
  addProduct: (listType, objectID, pos, quantity, comment) => void;
  onClickScroll: (direction) => void;
}

class ProductTableRow extends React.PureComponent<Props> {
  onClickHandler = () => {
    this.props.onClickProductLink(this.props.data);
    return null;
  };

  addProduct = (listType: ListType) => {
    const objectID = this.props.data.objectID;
    const pos = null;
    const quantity = this.props.data.mindestbestellmenge;
    const comment = "";
    this.props.addProduct(listType, objectID, pos, quantity, comment);
  };

  addProductToWishlist = e => {
    e.preventDefault();
    this.addProduct(ListType.wishlist);
  };

  addProductToCart = e => {
    e.preventDefault();
    this.addProduct(ListType.cart);
  };

  onClickArrowLeft = e => {
    this.props.onClickScroll("left");
  };
  onClickArrowRight = e => {
    this.props.onClickScroll("right");
  };

  render() {
    return (
      <div className="list__row">
        <div className="list-row__product">
          <article className="product">
            <div className="product__img">
              <Link
                onClick={this.onClickHandler}
                to={createProductUrl(this.props.data, activeLang())}
              >
                <Image
                  data={this.props.data.elstrImage}
                  w={"88"}
                  h={"88"}
                  title={productGetTitleString(this.props.data)}
                  alt={productGetTitleString(this.props.data)}
                />
              </Link>
            </div>
            <Link
              onClick={this.onClickHandler}
              to={createProductUrl(this.props.data, activeLang())}
              className="product__link"
            >
              <h3 className="product__title">{this.props.data.ShortDescription}</h3>
            </Link>

            <dl className="product-nr">
              <dt className="product-nr__title">{dic("ART NR")}</dt>
              <dd className="product-nr__cont"> {this.props.data.objectID}</dd>
              <dt className="product-nr__title">{dic("HERSTELLER TYPEN NR")}</dt>
              <dd className="product-nr__cont">
                {this.props.data.hersteller_typennummer_formatiert}
              </dd>
            </dl>
          </article>
        </div>
        <div className="list-row__items">{this.renderCols()}</div>

        <div className="list-row__actions">
          <div className="product__actions">
            <Link
              to={createProductUrl(this.props.data, activeLang())}
              className="product__icon-plus-lupe"
            />
            <span onClick={this.addProductToWishlist} className="product__icon-wishlist" />
            {productCanBeAddedToCart(this.props.data) && (
              <span onClick={this.addProductToCart} className="product__icon-add-cart" />
            )}
          </div>
        </div>
        <div className="circle-arrow list-row__slider-icon--left" onClick={this.onClickArrowLeft}>
          <span className="sicon sicon__arrow-left" />
        </div>
        <div className="circle-arrow list-row__slider-icon--right" onClick={this.onClickArrowRight}>
          <span className="sicon sicon__arrow-right" />
        </div>
      </div>
    );
  }

  renderCols() {
    return this.props.columns.map((attribute, index) => {
      return this.renderCell(this.props.data, attribute, `${attribute.dataAttributeKey}-${index}`);
    });
  }

  renderCell(productData: ProductVo, attribute: AttributeVo, key: string) {
    // const attribute = window.COMPONA_STATIC_CACHE.attributes[dataAttributeKey];

    let text = "";
    if (attribute) {
      if (attribute.dataType && attribute.dataType === "calculated") {
        if (attribute.dataAttributeKey === "price") {
          if (this.props.priceScaleArray.length > 0) {
            const price = this.props.priceScaleArray[0];
            text = `${price.betrag.toFixed(4)} / ${price.verkaufsEinheit}`;
          }
        }
        if (attribute.dataAttributeKey === "priceScale") {
          text = this.renderPriceScale(this.props.priceScaleArray, key);
        }
      } else {
        if (attribute.dataAttributeKey === "verfuegbareMenge") {
          // #5712: EOL Artikel - Lagerbestand sichtbar
          if(this.props.data.status && this.props.data.status <= 650){
            text = getFullText(productData, attribute);
          } else {
            text = '---'
          }
        } else {
          text = getFullText(productData, attribute);
        }
      }
    }

    return (
      <div className="list-row__item" key={key}>
        <span className="list__label">
          {getAttributeDescription(attribute.dataAttributeKey, activeLang())}
        </span>
        <span className="list__text">{text}</span>
      </div>
    );
  }

  renderPriceScale(priceScaleArray, key) {
    const Fragment = React.Fragment;
    return priceScaleArray.map((price, index) => {
      const text = `ab ${price.mengeAb} ${price.verkaufsEinheit} ${
        price.dstIso
      } ${price.betrag.toFixed(4)} / ${price.verkaufsEinheit}`;
      if (index > 0) {
        return (
          <Fragment key={`${key}-price-${index}`}>
            <br />
            <span>{text}</span>
          </Fragment>
        );
      } else {
        return <span key={`${key}-price-${index}`}>{text}</span>;
      }
    });
  }
}

export default ProductTableRow;
