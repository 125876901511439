import Filesaver from "file-saver";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ManageUsersData } from "../../State";
import { keysToDisplay } from "../components/UsersDataTable";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

/*
// lib moved to Manage.tsx into the <Helmet> Component

// dynamic load of xlsx file because it has a size around 1 MB
const script = document.createElement("script");
script.type = "text/javascript";
script.src = "https://unpkg.com/xlsx@0.14.0/dist/xlsx.full.min.js";
document.getElementsByTagName("head")[0].appendChild(script);
script.onerror = loadFromOwnServer;

function loadFromOwnServer() {
  const backup = document.createElement("script");
  backup.type = "text/javascript";
  backup.src = "/scripts/xlsx.full.min.js";
  document.getElementsByTagName("head")[0].appendChild(backup);
}
*/

declare var XLSX: any;

const addressKeys = [
  "inr",
  "anrNr",
  "subjekttyp",
  "name",
  "vorname",
  "zeile1",
  "zeile2",
  "zeile3",
  "zeile4",
  "zeile5",
  "land",
  "plz",
  "ort",
  "tel",
  "tel2",
  "email",
  "sprache",
  "type",
];

function create() {
  const wsName = "users_report";

  const wb = {
    SheetNames: [wsName],
    Sheets: {},
  };

  const users: ManageUsersData[] = ElstrCore.store.getState().manage.users.data;

  // CREATE THE HEADER DATA //
  const headers: string[] = [];

  const attributes = [...keysToDisplay, ...addressKeys];

  attributes.forEach(key => {
    headers.push(dic(key.toUpperCase()));
  });

  // CREATE THE WORKSHEET DATA ARRAY //
  const wsData: any[] = [];
  wsData.push(headers);

  // PUSH ROWS TO WORKSHEET //
  users.forEach(user => {
    const userColumn = keysToDisplay.map(key => String(user[key]));

    user.adr.forEach(adr => {
      const adrColumn = addressKeys.map(key => String(adr[key]));
      wsData.push([...userColumn, ...adrColumn]);
    });
  });

  const ws = XLSX.utils.aoa_to_sheet(wsData);

  /* Load the worksheet object */
  wb.Sheets[wsName] = ws;

  const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

  const wbout = XLSX.write(wb, wopts);

  const filename = `${wsName}_${new Date()}.xlsx`;

  /* the saveAs call downloads a file on the local machine */
  Filesaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), filename);
}

export default create;
