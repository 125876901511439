import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AccountState, OrderState, State, UserState } from "../../State";
import { LayoutActions } from "../../layout/actions/layoutActions";
import { compose } from "redux";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { routerActions } from "connected-react-router";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import AccountSettings from "../components/AccountSettings";
import AccountOrders from "../components/AccountOrders";
// import { AccountActions } from "../actions/accountActions";

interface AccountStateToProps {}

interface AccountDispatchToProps {
  onMount: () => void;
}

interface Props extends AccountStateToProps, AccountDispatchToProps {
  user: UserState;
  account: AccountState;
  order: OrderState;
  match: any;
}

class Account extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.onClickTabOrders = this.onClickTabOrders.bind(this);
    this.onClickTabSettings = this.onClickTabSettings.bind(this);
  }

  componentDidMount() {
    this.props.onMount();
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props.match);
  }

  onClickTabOrders(e) {
    const username = this.props.user.username;
    ElstrCore.store.dispatch(routerActions.push(`/account/orders/${username}`));
  }
  onClickTabSettings(e) {
    const username = this.props.user.username;
    ElstrCore.store.dispatch(routerActions.push(`/account/settings/${username}`));
  }

  render() {
    if (
      decodeURIComponent(this.props.match.params.username) === this.props.user.username ||
      this.props.user.isManager === true ||
      this.props.user.isAdmin === true
    ) {
      return (
        <main className="main main--std" role="main">
          <div className="content">
            <h1 className="content__page-title">{dic("MEIN KONTO")}</h1>
            <div className="tab-header">
              <nav className="tab-nav">
                <ul className="tab">
                  <li
                    onClick={this.onClickTabSettings}
                    className={`tab__item ${
                      this.props.match.params.tab === "settings" ? "active" : ""
                    }`}
                  >
                    {dic("EINSTELLUNGEN")}
                  </li>
                  <li
                    onClick={this.onClickTabOrders}
                    className={`tab__item ${
                      this.props.match.params.tab === "orders" ? "active" : ""
                    }`}
                  >
                    {dic("BESTELLUNGEN")}
                  </li>
                </ul>
              </nav>
            </div>
            {this.props.match.params.tab === "settings" && (
              <AccountSettings user={this.props.user} account={this.props.account} />
            )}
            {this.props.match.params.tab === "orders" && <AccountOrders order={this.props.order} />}
          </div>
        </main>
      );
    } else {
      return (
        <main className="main main--checkout" role="main">
          <div className="content">
            <h1 className="content__page-title">{dic("KEIN ZUGRIFF")}</h1>
          </div>
        </main>
      );
    }
  }
}

const mapStateToProps = (state: State): AccountStateToProps => ({
  user: state.user,
  account: state.account,
  order: state.order,
});

const mapDispatchToProps = (dispatch): AccountDispatchToProps => ({
  onMount: () => {
    dispatch(LayoutActions.showFilterPanel(false));
    dispatch(LayoutActions.showHomeHeader(false));
  },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Account);
