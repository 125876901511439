import * as React from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ListVo from "../../common/vo/ListVo";
import ListItem from "../../list/components/ListItem";
import { KeyEvent, ListType } from "../../common/constants/Constants";
import { UserState } from "../../State";
import { priceCalculateScaleArray } from "../../common/utils/priceCalculations";

interface Props {
  wishlist: ListVo;
  activeListId: null | string;
  addProductFromListToCart: (listType, objectID, pos, comment, product, take) => void;
  addProductsFromListToCart: (listId) => void;
  removeProduct: (listId, objectId, pos) => void;
  removeProductsFromList: (listId) => void;
  onInputTakeChangeWishlist: (quantity, listId, index) => void;
  onBlurInputTakeWishlist: (quantity, listId, objectID, pos, comment, index) => void;
  onClickTakeChangeWishlist: (direction, listId, objectID, pos, comment, index) => void;
  updateList: (listId, name, comment) => void;
  updateCommentWishlistItem: (listId, comment, index) => void;
  updateProduct: (listId, objectID, pos, quantity, comment) => void;
  deleteList: (listId, type) => void;
  setActiveListId: (listId) => void;
  user: UserState;
}

class Wishlist extends React.PureComponent<Props, any> {
  private readonly listName: any;

  constructor(props) {
    super(props);
    this.listName = React.createRef();
  }

  state = {
    edit: false,
  };

  updateList = e => {
    e.preventDefault();
    this.toggleEdit(e);

    const listId = this.props.wishlist._id;
    const listName = this.listName.current.value;
    const comment = "";

    this.props.updateList(listId, listName, comment);
  };

  checkInput = e => {
    if (e.keyCode === KeyEvent.DOM_VK_RETURN) {
      this.updateList(e);
    }
  };

  checkIfActiveListIsCurrent = () => {
    return this.props.activeListId === this.props.wishlist._id;
  };

  toogleOpenedAndSetActive = () => {
    const isActive = this.checkIfActiveListIsCurrent();

    // start action
    if (isActive) {
      // reset this list as active due overriding with null
      this.props.setActiveListId(null);
    } else {
      // set the current wishlist as active list id
      this.props.setActiveListId(this.props.wishlist._id);
    }
  };

  isOpen = () => {
    return this.checkIfActiveListIsCurrent() ? "opened" : "";
  };

  deleteList = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.deleteList(this.props.wishlist._id, this.props.wishlist.type);
  };

  arrowUpOrDown = () => {
    return this.checkIfActiveListIsCurrent() ? "sicon__arrow-up" : "sicon__arrow-down";
  };

  toggleEdit = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => {
      return { edit: !prevState.edit };
    });
  };

  addProductsFromListToCart = () => {
    this.props.addProductsFromListToCart(this.props.wishlist._id);
  };

  removeProductsFromList = () => {
    this.props.removeProductsFromList(this.props.wishlist._id);
  };

  render() {
    const edit = this.state.edit;
    return (
      <div className={`wishlist__block ${this.isOpen()}`} aria-expanded="true">
        <div
          className={`wishlist__title-bar ${edit && "edit-title"}`}
          onClick={this.toogleOpenedAndSetActive}
        >
          <div className="title">{this.renderNameOrChangeName()}</div>
          <span className="product__actions">
            {edit ? (
              <div className="product__icon-save" onClick={this.updateList}/>
            ) : (
              <div className="product__icon-edit" onClick={this.toggleEdit} />
            )}
            <div className="product__icon-delete" onClick={this.deleteList} />
          </span>
          <span
            className={`sicon ${this.arrowUpOrDown()}`}
            onClick={this.toogleOpenedAndSetActive}
          />
        </div>
        <div className="wishlist__cont">
          {this.renderWishlistItems()}

          <div className="wishlist-cont__submit">
            <input
              className="input--reset"
              type="reset"
              value={dic("MERKLISTE LEEREN")}
              onClick={this.removeProductsFromList}
            />
            <input
              className="input--submit"
              type="submit"
              value={dic("ALLES IN DEN WARENKORB")}
              onClick={this.addProductsFromListToCart}
            />
          </div>
        </div>
      </div>
    );
  }

  renderNameOrChangeName() {
    if (!this.state.edit) {
      return (
        <>
          {`${this.props.wishlist.name} `}
          <span className="title__counter">
            {this.props.wishlist.products ? `(${this.props.wishlist.products.length})` : `(0)`}
          </span>
        </>
      );
    } else {
      return (
        <div className="entry__input" onClick={e => e.stopPropagation()}>
          <input
            onKeyDown={this.checkInput}
            ref={this.listName}
            defaultValue={this.props.wishlist.name}
            type="text"
            className="input--text"
          />
        </div>
      );
    }
  }

  renderWishlistItems() {
    if (!this.props.wishlist.products) return null;

    return this.props.wishlist.products.map((product, i) => {
      const priceScaleArray = priceCalculateScaleArray(product.data[activeLang()], this.props.user);
      return (
        <ListItem
          itemType={ListType.wishlist}
          listId={this.props.wishlist._id}
          index={i}
          product={product}
          addProductFromListToCart={this.props.addProductFromListToCart}
          updateProduct={this.props.updateProduct}
          onInputTakeChangeListItem={this.props.onInputTakeChangeWishlist}
          onBlurInputTakeListItem={this.props.onBlurInputTakeWishlist}
          onClickTakeChangeListItem={this.props.onClickTakeChangeWishlist}
          updateCommentWishlistItem={this.props.updateCommentWishlistItem}
          removeProduct={this.props.removeProduct}
          priceScaleArray={priceScaleArray}
          key={`${product.objectID} ${i}`}
        />
      );
    });
  }
}

export default Wishlist;
