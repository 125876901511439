import * as React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { PopMessageVo } from "../../State";

export interface PopMessageItemProps extends PopMessageVo {
  removePopMessageItem: (index) => void;
  index: number;
}

class PopMessageItem extends React.PureComponent<PopMessageItemProps> {
  constructor(props) {
    super(props);
    this.removePopMessageItem = this.removePopMessageItem.bind(this);

    // Initialize the closing of the element when a time in ms is provided
    if (this.props.removedAfterMs > 0) {
      setTimeout(() => {
        this.props.removePopMessageItem(this.props.index);
      }, this.props.removedAfterMs);
    }
  }

  removePopMessageItem(e) {
    e.preventDefault();
    this.props.removePopMessageItem(this.props.index);
  }

  render() {
    const props = this.props;
    return (
      <ReactCSSTransitionGroup
        transitionName="pop-message__item"
        transitionAppear={true}
        transitionAppearTimeout={800}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className={`pop-message__item pop-message__${props.level ? props.level : "alert"}`}>
          <span className="pop-message__icon">
            <span className={`sicon sicon__${props.sicon}`} />
          </span>

          <span className="pop-message__text">
            {props.textBold.length > 0 && <em>{dic(props.textBold)}</em>}

            {props.textBold.length > 0 &&
              typeof props.text !== "undefined" &&
              props.text.length > 0 && <br />}

            {typeof props.text !== "undefined" &&
              props.text.length > 0 && <span>{dic(props.text)}</span>}
          </span>

          {typeof props.linktext !== "undefined" &&
            typeof props.href !== "undefined" &&
            props.linktext.length > 0 && (
              <a href={props.href} className="pop-message__link">
                {dic(props.linktext)}
              </a>
            )}

          {props.removedAfterMs === 0 && (
            <span
              className="inline-close--circle"
              aria-label="close-pop-message"
              onClick={this.removePopMessageItem}
            />
          )}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

export default PopMessageItem;
