import * as React from "react";
import ListItem from "../../list/components/ListItem";
import CartFooter from "../components/CartFooter";
import { connect } from "react-redux";
import { CartState, State, UserState } from "../../State";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import { ListActions } from "../../list/actions/listActions";
import { ListType } from "../../common/constants/Constants";
import { priceCalculateScaleArray } from "../../common/utils/priceCalculations";
import { CheckoutActions } from "../../checkout/actions/checkoutActions";

interface CartStateToProps {
  cart: CartState;
  user: UserState;
}

interface CartDispatchToProps {
  removeProduct: (listId, objectId, pos) => void;
  removeProductsFromList: (listId) => void;
  startCheckout: () => void;
  updateProduct: (listId, objectID, pos, quantity, comment) => void;
  onInputTakeChangeCart: (quantity, listId, index) => void;
  onBlurInputTakeCart: (quantity, listId, objectID, pos, comment, index) => void;
  onClickTakeChangeCart: (direction, listId, objectID, pos, comment, index) => void;
  updateCommentCartItem: (comment, index) => void;
}

interface Props extends CartStateToProps, CartDispatchToProps {
  onClose: () => void;
}

class CartFlyout extends React.PureComponent<Props> {
  render() {
    return (
      <div className="cart flyout flyout--cart" aria-hidden="true">
        <div className="cart__header">
          <div className="cart__title">
            {`${dic("WARENKORB")} `}
            <span className="title__counter">
              ({this.props.cart.products ? this.props.cart.products.length : 0})
            </span>
          </div>
          <span className="close--circle" onClick={this.props.onClose} />
        </div>

        <div className="cart__body">{this.renderCartItems()}</div>

        <CartFooter
          listId={this.props.cart._id}
          sum={this.props.cart.sum}
          shipping={this.props.cart.shipping}
          total={this.props.cart.total}
          dkoLiefartCd={this.props.cart.dkoLiefartCd}
          removeProductsFromList={this.props.removeProductsFromList}
          startCheckout={this.props.startCheckout}
          dstIsoCurrent={this.props.user.dstIsoCurrent}
        />
      </div>
    );
  }

  renderCartItems() {
    if (!this.props.cart.products) return null;

    return this.props.cart.products.map((product, i) => {
      const priceScaleArray = priceCalculateScaleArray(product.data[activeLang()], this.props.user);
      return (
        <ListItem
          itemType={ListType.cart}
          listId={this.props.cart._id}
          index={i}
          product={product}
          removeProduct={this.props.removeProduct}
          updateProduct={this.props.updateProduct}
          onInputTakeChangeListItem={this.props.onInputTakeChangeCart}
          onClickTakeChangeListItem={this.props.onClickTakeChangeCart}
          onBlurInputTakeListItem={this.props.onBlurInputTakeCart}
          updateCommentCartItem={this.props.updateCommentCartItem}
          priceScaleArray={priceScaleArray}
          key={`${product.objectID} ${i}`}
        />
      );
    });
  }
}

const mapStateToProps = (state: State): CartStateToProps => ({
  cart: state.cart,
  user: state.user,
});

const mapDispatchToProps = (dispatch): CartDispatchToProps => ({
  removeProductsFromList: listId => dispatch(ListActions.removeProductsFromList(listId)),
  startCheckout: () => dispatch(CheckoutActions.startCheckout()),
  removeProduct: (listId, objectId, pos) =>
    dispatch(ListActions.removeProduct(listId, objectId, pos)),
  updateProduct: (listId, objectID, pos, quantity, comment) =>
    dispatch(ListActions.updateProduct(listId, objectID, pos, quantity, comment)),
  onInputTakeChangeCart: (quantity, listId, index) =>
    dispatch(ListActions.onInputTakeChangeCart(quantity, listId, index)),
  onBlurInputTakeCart: (quantity, listId, objectID, pos, comment, index) =>
    dispatch(ListActions.onBlurInputTakeCart(quantity, listId, objectID, pos, comment, index)),
  onClickTakeChangeCart: (direction, listId, objectID, pos, comment, index) =>
    dispatch(ListActions.onClickTakeChangeCart(direction, listId, objectID, pos, comment, index)),
  updateCommentCartItem: (comment, index) =>
    dispatch(ListActions.updateCommentCartItem(comment, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartFlyout);
