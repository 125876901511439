import * as React from "react";
import { AdrVo, UserState } from "../../State";
import AddressEdit from "../../common/components/input/AddressEdit";
import AddressCard from "../../common/components/input/AddressCard";
// import { dic } from "elstr-jslib-4/dist/ElstrLanguage";

interface Props {
  onSubmit: () => void;
  onClickEdit: () => void;
  onInputChange: (name: string, value: string) => void;
  inr: string;
  formAdr: AdrVo;
  user: UserState;
  edit: boolean;
}

class CheckoutAddress extends React.PureComponent<Props> {
  render() {
    if (this.props.inr !== "0" && Array.isArray(this.props.user.adr)) {
      const address = this.props.user.adr.find(adr => adr.inr === this.props.inr);
      if (address) {
        return (
          <AddressCard
            address={address}
            isEditable={false}
            isDisablable={false}
            onClickEdit={this.props.onClickEdit}
          />
        );
      }
      if (this.props.inr === "-1") {
        return (
          <AddressCard
            address={this.props.formAdr}
            isEditable={false}
            isDisablable={false}
            onClickEdit={this.props.onClickEdit}
          />
        );
      }
    }

    if (this.props.edit) {
      return (
        <AddressEdit
          formAdr={this.props.formAdr}
          onSubmit={this.props.onSubmit}
          onInputChange={this.props.onInputChange}
        />
      );
    } else {
      return (
        <AddressCard
          address={this.props.formAdr}
          isEditable={true}
          isDisablable={false}
          onClickEdit={this.props.onClickEdit}
        />
      );
    }
  }
}

export default CheckoutAddress;
