import ElstrIo from "elstr-jslib-4/dist/ElstrIo";

export default class COMPONA_WidgetServer_JSON_Product {
  static async getProductData(objectID: string, listId): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>("COMPONA_WidgetServer_JSON_Product", "getProductData", {
      objectID,
      listId,
    });
  }

  static async getRelatedProducts(objectID: string, type: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(
      "COMPONA_WidgetServer_JSON_Product",
      "getRelatedProducts",
      {
        objectID,
        type,
      },
    );
  }

  static async getClassificationData(objectID: string): Promise<any> {
    return ElstrIo.requestJsonRpc<any, any>(
      "COMPONA_WidgetServer_JSON_Product",
      "getClassificationData",
      {
        objectID,
      },
    );
  }
}
