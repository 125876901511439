import { UserState } from "../../State";
import { UserActionTypes } from "../actions/ActionTypes";

const userReducer = (state: UserState, action) => {
  if (!state) return null;

  switch (action.type) {
    case UserActionTypes.SET_USER_DSTISO_CURRENT: {
      const dstIsoCurrent = action.payload.dstIso;
      return { ...state, dstIsoCurrent };
    }

    case UserActionTypes.SET_USER_FLYOUT_LOGIN_MSG: {
      const userFlyoutLoginMsg = action.payload.userFlyoutLoginMsg;
      return { ...state, userFlyoutLoginMsg };
    }

    case UserActionTypes.SET_USER_DATA: {
      const newUserData = action.payload;
      return { ...state, ...newUserData };
    }
  }

  return state;
};

export default userReducer;
