import ProductVo from "../vo/ProductVo";
import { config } from "elstr-jslib-4/dist/ElstrConfig";
import { ListProductDataVo } from "../vo/ListVo";
import ClassificationVo from "../../filterPanel/vo/ClassificationVo";

declare var COMPONA_STATIC_CACHE;

/**
 *
 * @param {ProductVo} data
 * @returns {string}
 */
export function createProductUrl(data: ProductVo | ListProductDataVo, lang: string): string {
  let url = `/product/${data.objectID}/${lang}/`;
  const routeAdditions: string[] = [];
  const configProduct = config("product");
  if (configProduct.routeAddition) {
    const routeAddition = configProduct.routeAddition;
    routeAddition.forEach(key => {
      if (data[key]) {
        routeAdditions.push(data[key]);
      }
    });
  }
  url += encodeURIComponent(urlify(routeAdditions.join("-")));
  return url;
}

/**
 *
 * @param {string} objectID
 * @returns {string}
 */
export function createReducedProductUrl(objectID: string, lang: string): string {
  const url = `/product/${objectID}/${lang}/`;
  return url;
}

export function createGroupUrl(item: ClassificationVo, lang: string) {
  let url = `/catalog/group/${item.objectID}/${lang}/`;
  let routeAddition = "";
  if (item.breadcrumbDescShort) routeAddition += item.breadcrumbDescShort;

  // cannot translate funktion and hersteller for hreflang link elements!
  // Param lang ist not always === activeLang()
  routeAddition = routeAddition.replace("funktion ", "");
  routeAddition = routeAddition.replace("hersteller ", "");

  url += encodeURIComponent(urlify(routeAddition));
  return url;
}

export function createURLTranslation(urlString: string, oldLang: string, newLang: string) {
  const staticCacheAttributes = window.COMPONA_STATIC_CACHE.attributes;
  let urlArray = urlString.slice(1).split("&");

  let newURL = "";
  for (const urlParam in urlArray) {
    let urlParamArray = urlArray[urlParam].split("=");
    if (urlParamArray[0] === "hersteller" || urlParamArray[0] === "funktion") {
      newURL = newURL + "&" + urlParamArray[0] + "=" + urlParamArray[1];
    } else {
      if (urlParamArray[1] !== "") {
        let params = urlParamArray[1].split("%25C2%25A6");
        var segmentArray: Array<any> = [];
        // for each filter value of a facet
        for (const index in params) {
          const value = params[index];
          if (urlParamArray[0] in staticCacheAttributes) {
            if ("options" in staticCacheAttributes[urlParamArray[0]]) {
              // for each option of this facet
              for (const key in staticCacheAttributes[urlParamArray[0]]["options"]) {
                const compareValue = encodeURIComponent(
                  encodeURIComponent(
                    staticCacheAttributes[urlParamArray[0]]["options"][key][oldLang],
                  ),
                );
                if (compareValue === value) {
                  const langSegment = encodeURIComponent(
                    encodeURIComponent(
                      staticCacheAttributes[urlParamArray[0]]["options"][key][newLang],
                    ),
                  );
                  segmentArray.push(langSegment);
                  break;
                }
              }
            }
          }
        }
        if (segmentArray.length === 0) {
          newURL = newURL + "&" + urlParamArray[0] + "=" + urlParamArray[1];
        } else {
          newURL = newURL + "&" + urlParamArray[0] + "=" + segmentArray.join("%25C2%25A6");
        }
      }
    }
  }
  return "?" + newURL.slice(1);
}

export function createDatatransUrl(orderId: string, amount: string, dkoIsocode: string, sign: string) {
  const origin = window.location.origin;
  const successUrl = `${origin}/shop/checkout/ordered/${orderId}`;
  const errorUrl = `${origin}/shop/checkout/ordered/${orderId}`;
  const cancelUrl = `${origin}/shop/checkout/ordered/${orderId}`;

  const datatrans = config("datatrans");
  const merchantId = datatrans.merchantId;
  let refno = orderId.toString();
  let currency = dkoIsocode.toString();
  if ( datatrans.test.active === "1" ) {
    refno = datatrans.test.refno;
    amount = datatrans.test.amount;
    currency = datatrans.test.currency;
  }

  const datatransUrl = `${datatrans.serviceUrl}?merchantId=${
    merchantId
  }&refno=${refno}&amount=${amount}&currency=${currency}&paymentmethod=${datatrans.paymentmethod.join(
    "&paymentmethod=",
  )}&successUrl=${encodeURIComponent(successUrl)}&errorUrl=${encodeURIComponent(
    errorUrl,
  )}&cancelUrl=${encodeURIComponent(cancelUrl)}&sign=${
    sign
  }&method=authorizationResponse`;

  return datatransUrl;
}

export function createImgUrlFromClassifcation(classification: ClassificationVo, w = "", h = "") {
  const elstrFiles = classification.elstrFiles;
  let elstrFile = undefined;
  if (elstrFiles) {
    for (let iFile = 0; iFile < elstrFiles.length; iFile++) {
      const f = elstrFiles[iFile];
      if (f.type === "bild") {
        elstrFile = f;
        break;
      }
    }
  }
  return createImgUrl(elstrFile, w, h, "0FFF", "png");
}

export function createImgUrl(
  data: any,
  w: string,
  h: string,
  bg = "fff",
  fm = "jpg",
  auto = "format",
  fit = "fill",
): string {
  // use imgix url from config.ini
  const imgixUrl = config("imgixUrl");
  if (imgixUrl) {
    let imgUrl = `${imgixUrl}/noimage`;
    if (data) {
      imgUrl = `${imgixUrl}/${data.publicPath}/${data.name}`;
    }

    const additionalParams: string[] = [];
    if (w.length > 0) {
      additionalParams.push(`w=${w}`);
    }
    if (h.length > 0) {
      additionalParams.push(`h=${h}`);
    }
    if (bg.length > 0) {
      additionalParams.push(`bg=${bg}`);
    }
    if (fit.length > 0) {
      additionalParams.push(`fit=${fit}`);
    }
    if (auto.length > 0) {
      additionalParams.push(`auto=${auto}`);
    }
    if (fm.length > 0) {
      additionalParams.push(`fm=${fm}`);
    }

    if (additionalParams.length > 0) {
      imgUrl += `?${additionalParams.join("&")}`;
    }

    return imgUrl;
  } else {
    return "";
  }
}

function urlify(a) {
  return a
    .toLowerCase()
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue")
    .replace(/é/g, "e")
    .replace(/è/g, "e")
    .replace(/ê/g, "e")
    .replace(/ë/g, "e")
    .replace(/î/g, "i")
    .replace(/ï/g, "i")
    .replace(/ô/g, "o")
    .replace(/û/g, "u")
    .replace(/ù/g, "u")
    .replace(/à/g, "a")
    .replace(/â/g, "a")
    .replace(/ç/g, "c")
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "-")
    .replace(/^-+|-+$/g, "");
}
