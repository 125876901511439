import * as React from "react";
import { OrderDataVo } from "../../State";
import { dic } from "elstr-jslib-4/dist/ElstrLanguage";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ManageActions } from "../../manage/actions/manageActions";

interface Props {
  orderData: OrderDataVo;
}

class OrderManageFunctions extends React.PureComponent<Props> {
  onClickRescheduleOrderForErpOrderCreation = e => {
    e.preventDefault();
    if (this.props.orderData._id) {
      ElstrCore.store.dispatch(
        ManageActions.rescheduleOrderForErpOrderCreation(this.props.orderData._id),
      );
    }
  };

  render() {
    return (
      <div className="row">
        <h2 className="subtitle">{dic("FUNKTIONEN ZUR BESTELLUNGSADMINISTRATION")}</h2>
        <div>
          <div>{`retryErpOrderCreation: ${this.props.orderData.retryErpOrderCreation}`}</div>
          <div>{`erpValidationFailed: ${this.props.orderData.erpValidationFailed}`}</div>
          {this.props.orderData.erpValidationFailedUpdatedAt && (
            <div>{`erpValidationFailedUpdatedAt: ${this.props.orderData.erpValidationFailedUpdatedAt}`}</div>
          )}
        </div>
        <div className="form-submit__wrapper form-row">
          {this.props.orderData.retryErpOrderCreation === 0 &&
            this.props.orderData.erpValidationFailed === 1 && (
              <button
                className="btn input--reset no-margin"
                title="ERP UEBERGABE NEU EINPLANEN"
                onClick={this.onClickRescheduleOrderForErpOrderCreation}
              >
                {dic("ERP UEBERGABE NEU EINPLANEN")}
              </button>
            )}
        </div>
      </div>
    );
  }
}

export default OrderManageFunctions;
