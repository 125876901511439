import * as React from "react";
import ProductVo from "../../common/vo/ProductVo";
import ProductListArticle from "../../common/components/list/ProductListArticle";
import ElstrCore from "elstr-jslib-4/dist/ElstrCore";
import { ProductActions } from "../../product/actions/productActions";
import { dic, activeLang } from "elstr-jslib-4/dist/ElstrLanguage";
import { getUnitDescription } from "../../common/utils/unitUtils";
import { OrderListProductVo } from "../../common/vo/OrderVo";

interface Props {
  dkoIsocode: string;
  listProducts: OrderListProductVo[];
}

// class OrderProductList is very similar to CheckoutProductList

class OrderProductList extends React.PureComponent<Props> {
  onClickProductLink = product => {
    ElstrCore.store.dispatch(ProductActions.setInitialData(product));
  };

  renderProductItems() {
    return this.props.listProducts.map((listPosition: OrderListProductVo, index) => {
      const productData: ProductVo = listPosition.baselineProductDataJson;

      return (
        <div className="list__row" key={`list_row_${productData.objectID}_${index}`}>
          <div className="list-row__product">
            <ProductListArticle
              data={productData}
              onClickProductLink={this.onClickProductLink}
              showHomepageFokusText={false}
              comment={listPosition.comment}
            />
          </div>
          <div className="list-row__items">
            <div className="list-row__item">
              <span className="list__label">{dic("STATUS")}</span>
              <span className="list__text">
                {listPosition.status &&
                  listPosition.status > 0 &&
                  dic(`ORDER STATUS ${listPosition.status}`)}
              </span>
            </div>
            <div className="list-row__item">
              <span className="list__label">{dic("MENGE")}</span>
              <span className="list__text">{listPosition.quantity.toString()}</span>
            </div>
            <div className="list-row__item">
              <span className="list__label">{dic("PRICE")}</span>
              <span className="list__text">
                {this.props.dkoIsocode} {listPosition.pricePerUnit.toFixed(4)} /{" "}
                {getUnitDescription(productData.verkaufsEinheitNummer, activeLang())}
              </span>
            </div>
            <div className="list-row__item">
              <span className="list__label">{dic("GESAMTPREIS")}</span>
              <span className="list__text">
                {this.props.dkoIsocode} {listPosition.price.toFixed(4)}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="checkout-product-list">
        <h2 className="visuallyhidden">{dic("PRODUKTE")}</h2>
        <div className="list__header">
          <div className="list-header__product ">
            <span>{dic("PRODUKTE")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("STATUS")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("MENGE")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("PRICE")}</span>
          </div>
          <div className="list-header__item">
            <span>{dic("GESAMTPREIS")}</span>
          </div>
        </div>
        {this.renderProductItems()}
      </div>
    );
  }
}

export default OrderProductList;
