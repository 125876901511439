import * as React from "react";
import { activeLang, dic } from "elstr-jslib-4/dist/ElstrLanguage";
import SelectBox from "./SelectBox";

declare var COMPONA_STATIC_CACHE;

interface Props {
  selectedValue?: string;
  id?: string;
  name?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

class SelectBoxLaender extends React.PureComponent<Props> {
  sortLaender(a: SelctionVo, b: SelctionVo) {
    return a.name.localeCompare(b.name);
  }

  render() {
    const selections: SelctionVo[] = window.COMPONA_STATIC_CACHE.laender.map((land, index) => {
      return { value: land.key, name: land.description[activeLang()] };
    });

    selections.sort(this.sortLaender);
    selections.unshift({ value: "", name: `${dic("WAEHLEN")}...` });

    return (
      <SelectBox
        selections={selections}
        id={this.props.id}
        name={this.props.name}
        selectedValue={this.props.selectedValue}
        required={this.props.required}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
      />
    );
  }
}

export default SelectBoxLaender;

interface SelctionVo {
  name: string;
  value: string;
}
